import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'Required';
export const requiredFieldMessage = (fieldName: any): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (fieldName?: string, maxLength?: number): string =>
  `${fieldName} Max length is ${maxLength}`;

declare module 'yup' {
  interface StringSchema {
    validateField(): StringSchema;
    validateEmail(): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, 'validateEmail', function () {
  return this.test({
    test(value) {
      const myArray = value?.toString().split(';');
      const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      let isValid = true;
      let isEmail = true;
      myArray?.every(function (element, index) {
        const userinput = element;
        if (userinput && userinput.trim() && !pattern.test(userinput)) {
          isValid = false;
        } else if (userinput && userinput.includes('firstam.com')) {
          isEmail = false;
        } else return true;
      });
      if (!isEmail) {
        return this.createError({
          message: 'Email address cannot contain the First American domain',
          path: 'EmailAddress',
        });
      }
      if (!isValid) {
        return this.createError({
          message: 'Please enter a valid Email address',
          path: 'EmailAddress',
        });
      } else {
        return true;
      }
    },
  });
});

export const AddUserSchema = yup.object().shape({
  FirstName: yup.string().required(requiredFieldMessage('First Name')),
  LastName: yup.string().required(requiredFieldMessage('Last Name')),
  AssociatedOfficeId: yup.number().required(requiredFieldMessage('Office Name')),
  AssociatedFirmId: yup.string().nullable().required(requiredFieldMessage('Firm Name')),
  EmailAddress: yup.string().trim().required(requiredFieldMessage('Email Notification')).validateEmail(),
  PhoneNumber: yup
    .string()
    .required(requiredFieldMessage('Phone Number'))
    .test('is-10-digits', 'Invalid Phone Number.', (value) => {
      if (!value || value.trim() === '') {
        return true; // Skip validation if value is empty
      }
      return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
    }),
  Role: yup.string().required(requiredFieldMessage('Role')),
});

export const EditUserSchema = yup.object().shape({
  FirstName: yup.string().required(requiredFieldMessage('First Name')),
  LastName: yup.string().required(requiredFieldMessage('Last Name')),
  AssociatedOfficeId: yup.string().required(requiredFieldMessage('Office Name')),
  AssociatedFirmId: yup.string().required(requiredFieldMessage('Firm Name')),
  EmailAddress: yup.string().trim().required(requiredFieldMessage('Email Notification')).validateEmail(),
  UserStatus: yup.string().required(requiredFieldMessage('Status')),
  PhoneNumber: yup
    .string()
    .required(requiredFieldMessage('Phone Number'))
    .test('is-10-digits', 'Invalid Phone Number.', (value) => {
      if (!value || value.trim() === '') {
        return true; // Skip validation if value is empty
      }
      return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
    }),
  Role: yup.string().required(requiredFieldMessage('Role')),
});

export const EditUserSchemaForIAM = yup.object().shape({
  FirstName: yup.string().required(requiredFieldMessage('First Name')),
  LastName: yup.string().required(requiredFieldMessage('Last Name')),
  EmailAddress: yup.string().trim().required(requiredFieldMessage('Email Notification')).validateEmail(),
  PhoneNumber: yup
    .string()
    .required(requiredFieldMessage('Phone Number'))
    .test('is-10-digits', 'Invalid Phone Number.', (value) => {
      if (!value || value.trim() === '') {
        return true; // Skip validation if value is empty
      }
      return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
    }),
  Role: yup.string().required(requiredFieldMessage('Role')),
});
