import React from 'react';

function NoRatesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="98" fill="none">
      <circle
        cx="55"
        cy="48.86"
        r="47.86"
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
      ></circle>
      <path fill="#F1F3F9" d="M73.509 22.786H48.014V13.11h33.604c5.222.124 9.46 4.438 9.46 9.676H80.02"></path>
      <path
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M73.509 22.786H48.014V13.11h33.604c5.222.124 9.46 4.438 9.46 9.676H73.51z"
      ></path>
      <path
        fill="#fff"
        stroke="#D6DCE8"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M82.354 13.11c-1.966.124-3.808.925-5.099 2.28a2.684 2.684 0 00-.368.432c-1.167 1.356-2.028 3.143-2.028 5.053v57.56c0 5.361-4.3 9.675-9.644 9.675H18.279V20.444c.123-1.972.86-3.76 2.15-5.053a7.643 7.643 0 015.468-2.28h56.457z"
      ></path>
      <path
        fill="#AAB2C5"
        d="M65.522 26.298H51.577a.946.946 0 01-.922-.924c0-.493.43-.925.922-.925h13.945c.492 0 .922.432.922.925a.906.906 0 01-.922.924z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M45.003 26.298H26.82a.946.946 0 01-.921-.924c0-.493.43-.925.921-.925h18.246c.491 0 .921.432.921.925 0 .493-.43.924-.983.924z"
      ></path>
      <path
        fill="#AAB2C5"
        d="M56.675 35.42H38.552a.946.946 0 01-.921-.925c0-.493.43-.925.921-.925h18.123c.492 0 .922.432.922.925.061.493-.369.924-.922.924z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M32.717 35.42h-5.898a.946.946 0 01-.921-.925c0-.493.43-.925.921-.925h5.898c.491 0 .921.432.921.925 0 .493-.43.924-.921.924zm32.804 9.12H54.402a.946.946 0 01-.922-.924c0-.493.43-.925.922-.925h11.12c.49 0 .92.432.92.925a.906.906 0 01-.92.924z"
      ></path>
      <path
        fill="#AAB2C5"
        d="M49.058 44.54H26.819a.946.946 0 01-.921-.924c0-.493.43-.925.921-.925h22.239c.491 0 .921.432.921.925a.906.906 0 01-.921.924zm7.617 9.121H44.45a.946.946 0 01-.922-.924c0-.493.43-.925.922-.925h12.225c.492 0 .922.432.922.925.061.493-.369.924-.922.924z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M39.106 53.661H26.819a.946.946 0 01-.921-.924c0-.493.43-.925.921-.925h12.348c.492 0 .922.432.922.925 0 .493-.43.924-.983.924z"
      ></path>
      <path
        fill="#AAB2C5"
        d="M65.522 62.781h-8.539a.946.946 0 01-.921-.924c0-.493.43-.924.921-.924h8.54c.49 0 .92.431.92.924a.906.906 0 01-.92.924z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M51.453 62.781H37.14a.946.946 0 01-.921-.924c0-.493.43-.924.921-.924h14.314c.492 0 .922.431.922.924 0 .493-.43.924-.922.924z"
      ></path>
      <path
        fill="#AAB2C5"
        d="M31.795 62.781H26.82a.946.946 0 01-.921-.924c0-.493.43-.924.921-.924h4.976c.491 0 .922.431.922.924 0 .493-.43.924-.922.924zm24.881 9.122h-8.478a.946.946 0 01-.922-.925c0-.493.43-.924.922-.924h8.478c.491 0 .921.431.921.924.062.493-.368.925-.921.925z"
      ></path>
      <path
        fill="#D6DCE8"
        d="M42.607 71.903H26.82a.946.946 0 01-.921-.925c0-.493.43-.924.921-.924h15.788c.492 0 .922.431.922.924 0 .493-.43.925-.922.925z"
      ></path>
      <path
        fill="#F1F3F9"
        stroke="#D6DCE8"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M44.573 80.469h12.41c0 2.095.86 4.006 2.211 5.361 1.352 1.418 3.256 2.219 5.345 2.219H8.696a7.558 7.558 0 01-5.345-2.219 7.606 7.606 0 01-2.211-5.36h36.737s6.819.061 6.696 0z"
      ></path>
      <path
        fill="#D6DCE8"
        stroke="#AAB2C5"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M97.847 74.567l-7.756-7.75 1.778-1.779 7.75 7.757-1.772 1.772z"
      ></path>
      <path
        fill="#858B9B"
        d="M100.544 77.294l-5.948-5.948a1.277 1.277 0 010-1.803 1.277 1.277 0 011.803 0l5.948 5.948a1.278 1.278 0 010 1.803 1.277 1.277 0 01-1.803 0z"
      ></path>
      <path
        fill="#D6DCE8"
        stroke="#AAB2C5"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M100.121 77.674l-7.653-7.652a.463.463 0 010-.65l1.956-1.956a.463.463 0 01.65 0l7.653 7.652a.462.462 0 010 .65l-1.956 1.956a.463.463 0 01-.65 0zM81.31 41.842c-8.118 0-14.698 6.58-14.698 14.698 0 8.112 6.58 14.697 14.692 14.697 8.118 0 14.698-6.579 14.698-14.697 0-8.119-6.58-14.698-14.692-14.698zm0 26.673c-6.518 0-11.797-5.365-11.797-11.982 0-6.616 5.279-11.975 11.797-11.975 6.518 0 11.797 5.365 11.797 11.982 0 6.616-5.285 11.975-11.797 11.975z"
      ></path>
    </svg>
  );
}
export default NoRatesIcon;
