import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Restore } from '@material-ui/icons';
import MailOutlineOutlinedIcon from '@material-ui/icons/EmailOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import ViewHistory from './ViewHistory';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { useAuth } from '@agentnet/auth';
import { postUserActivationEmail } from 'api/manageUsers/manage-users-api';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: ' #423d3d',
    fontSize: '13px',
    padding: '5px',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif;',
    border: '1px solid lightgrey',
  },
  root: {
    width: '100%',
    marginTop: '11.4rem',
  },
});

type UserActionsRendererProps = {
  userRoleType?: string;
  userStatus?: string;
  userId?: number;
  userEmail?: string;
  handleEditClick?: () => void;
};

const UserActionsRenderer = ({
  userRoleType,
  userStatus,
  userId,
  userEmail,
  handleEditClick,
}: UserActionsRendererProps) => {
  const classes = useStyles(); // Declare the 'classes' variable
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [OpenModelFirm, setOpenModelFirm] = useState(false);
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();

  const handleDrawerOpen = () => {
    setDrawerOpen(drawerOpen);
  };
  const dismissSendActivationEmail = () => {
    setOpenModelFirm(false);
  };

  const sendActivationEmail = async () => {
    const token = await getAccessToken();
    const responsedata = await postUserActivationEmail(token, userId);
    if (responsedata) {
      addSnackbarMessage({
        message: 'Activation Email Successfully Sent',
        type: 'success',
      });
    } else {
      addSnackbarMessage({
        message: 'Email Could Not Be Sent. Try Again Later!',
        type: 'error',
      });
    }
    setOpenModelFirm(false);
    setSendEmail(false);
  };
  const dialogText = `Account information and login credential will be sent to ${userEmail}. Do you want to proceed?`;
  const mailIcon =
    userStatus === 'Not Activated' && userRoleType === 'Agent' ? (
      <Tooltip title="Send Account Information" classes={{ tooltip: classes.tooltip }}>
        <MailOutlineOutlinedIcon
          fontSize="small"
          htmlColor="#0074CA"
          onClick={() => {
            setSendEmail(true);
            setOpenModelFirm(true);
          }}
        />
      </Tooltip>
    ) : null;

  const handleEdit = () => {
    console.log('Edit User clicked');
    handleEditClick && handleEditClick();
  };

  return (
    <span style={{ position: 'relative', top: '10px' }}>
      <div className="iconContainer">{mailIcon}</div>
      <div className="iconContainer">
        <Tooltip title="History" classes={{ tooltip: classes.tooltip }}>
          <div>
            <Restore
              fontSize="small"
              htmlColor="#0074CA"
              onClick={() => {
                console.log('View History clicked');
                setDrawerOpen(true);
              }}
            />
          </div>
        </Tooltip>
      </div>
      <div className="iconContainer">
        <Tooltip title="Edit User" classes={{ tooltip: classes.tooltip }}>
          <CreateOutlinedIcon fontSize="small" htmlColor="#0074CA" onClick={() => handleEdit()} />
        </Tooltip>
      </div>
      {drawerOpen ? (
        <ViewHistory userId={userId} handleDrawerOpen={drawerOpen} closeDrawer={() => setDrawerOpen(false)} />
      ) : null}
      {sendEmail && (
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationCancel"
          onConfirm={sendActivationEmail}
          open={OpenModelFirm}
          onDismissAction={dismissSendActivationEmail}
          dialogTitle="Resend Activation Email"
          dialogBtnContent="Yes"
          secondaryActionBtnContent="No"
          dialogText={dialogText}
        />
      )}
    </span>
  );
};

export default UserActionsRenderer;
