import {
  UnderwritingEndorsement,
  UnderwritingEndorsementOption,
  UnderwritingPolicy,
  UnderwritingRequest,
} from './types';
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Grid, IconButton, Popper, TextField, Typography } from '@material-ui/core';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import { AgentNetCheckBox, AgentNetDropdownSelector, AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import AgentNetCheckBoxGroup from 'ui-kit/inputs/CheckBoxGroup/CheckBoxGroup';
import { underwritingText } from './constants';
import clsx from 'clsx';
import { Close } from '@material-ui/icons';
import NoResults from 'ui-kit/components/noResults/NoResults';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { Autocomplete, AutocompleteCloseReason } from '@agentnet/components';
import CloseIcon from 'ui-kit/icons/Close';
// import { Notification } from 'ui-kit/components/notification/Notification';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';

import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { ProposedPolicySchema } from 'utilities/validation/schemas/underwriting-schema';

interface UnderwritingProps {
  uwr?: UnderwritingRequest;
  updateUnderwriting: any;
  submitAttempted: boolean;
  setSubmitAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmit: boolean;
  updateUwrErrorStatus: any;
  isUwrDisabled?: boolean;
  isDisabled?: boolean;
}
const UnderwritingProposedPolicyCollection: React.FC<UnderwritingProps> = ({
  uwr,
  updateUnderwriting,
  submitAttempted,
  setSubmitAttempted,
  isSubmit,
  updateUwrErrorStatus,
  isUwrDisabled,
  isDisabled,
}: UnderwritingProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      actionsTable: {
        width: '100%',
        padding: '0 0 24px',
      },
      actionsTableHeader: {
        backgroundColor: '#F2F5F6',
        borderBottom: '1px solid #D9E0E5',
        fontWeight: 600,
        fontSize: '1.4rem',
      },
      tableHeaderCell: {
        padding: '1.2rem',
      },
      required: {
        color: 'red',
      },
      actionsTableList: {},
      tableRow: {
        borderBottom: `1px solid #E0E0E0`,
      },
      tableCell: {
        padding: `1.2rem`,
        display: 'flex',
        alignItems: 'center',
      },
      my1: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      mb1: {
        marginBottom: theme.spacing(1),
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      ml1: {
        marginLeft: theme.spacing(1),
      },
      deleteRow: {
        padding: 0,
        marginLeft: theme.spacing(1),
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      miscEndoAsterisk: {
        color: theme.palette.error.dark,
        fontSize: '1.4rem',
        fontWeight: 600,
      },
      popper: {
        backgroundColor: '#FFF',
        border: '1px solid #c4c4c4',
        borderRadius: '6px',
        zIndex: 2000,
      },
      popperContent: {
        width: 400,
        boxShadow:
          '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
      },
      popperHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #c4c4c4',
        padding: '0 8px 0 24px',
      },
      popperOption: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: '8px 16px',
        '&[aria-selected="true"]': {
          backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
          backgroundColor: theme.palette.action.hover,
        },
      },
      popperPaper: {
        margin: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
      popperDisablePortal: {
        position: 'relative',
        width: '100% !important',
      },
      popperClose: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      inputBaseWrap: {
        padding: '16px 12px 12px',
        borderBottom: '1px solid #c4c4c4',
      },
      inputBase: {
        width: '100%',
        '& input': {
          borderRadius: 4,
          backgroundColor: theme.palette.common.white,
          padding: 8,
          transition: theme.transitions.create(['border-color', 'box-shadow']),
          border: '1px solid #C4C4C4',
          fontSize: 14,
          '&:focus': {
            boxShadow:
              '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
            borderColor: '#c4c4c4',
          },
        },
      },
      warningMsg: { margin: theme.spacing(1) },
      miscEndoFixedWidth: {
        width: 110,
      },
    }),
  );
  const randomId = () => parseInt((Math.random() + 1).toString(8).substring(7), 10) * -1;
  const miscId = () => Math.floor(10000 + Math.random() * 90000); // Generate 5 digit randomId

  // const actionName = isSubmit ? `Submit` : `Save`;

  // Adding State Variables for the component
  const [proposedPolicies, setProposedPolicies] = useState<UnderwritingPolicy[]>([]);
  const [policyOption, setPolicyOption] = useState<SelectOption[]>([]);

  // const [duplicatePolicyType, setDuplicatePolicyType] = useState<string>('');
  // const [duplicatePolicyId, setDuplicatePolicyId] = useState<any>('');

  const [anchorEls, setAnchorEls] = useState<(null | HTMLElement)[]>([]);
  const [endorsementOptions, setEndorsementOptions] = useState<UnderwritingEndorsementOption[]>([]);
  const [isDeleteEndorsement, setIsDeleteEndorsement] = useState<boolean>(false);
  const [toBeDeletedEndo, setTobeDeletedEndo] = useState<{
    policyIndex: number;
    endorsementId: number;
  }>({ policyIndex: 0, endorsementId: 0 });

  // Delete Policy State Variables
  const [isDeletePolicy, setIsDeletePolicy] = useState<boolean>(false);
  const [toBeDeletedPolicy, setToBeDeletedPolicy] = useState<{
    policyId: number;
    policyIndex: number;
  }>({ policyId: 0, policyIndex: 0 });

  // Form Validation State Vars
  const [showMandatoryField, setShowMandatoryField] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[][]>([]);

  // const { addSnackbarMessage } = useSnackBars();

  const handleClose = (e: any, reason: AutocompleteCloseReason, policyIndex: number) => {
    if (reason === 'toggleInput') {
      return;
    }
    if (anchorEls[policyIndex] && anchorEls[policyIndex] !== null) {
      anchorEls[policyIndex]?.focus();
      return;
    }
    const anchorElems = [...anchorEls];
    anchorElems[policyIndex] = null;
    setAnchorEls(anchorElems);
  };

  const validateErrors = async () => {
    const errs: FieldValidationError[][] = await Promise.all(
      (uwr?.ProposedPolicies ?? []).map(async (policy) => {
        const perPolicyErr = (await doValidate(policy, ProposedPolicySchema)) || [];
        return perPolicyErr;
      }),
    );

    updateUwrErrorStatus('ProposedPolicies', noErrorsObj(errs ?? []));
    setValidationErrors(errs);
  };

  // Set Endorsementoptions ONLY ONCE on page load
  useEffect(() => {
    // Get entire list of policies
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);
    policies.map((policy: any) => {
      if (!('EndorsementsOptions' in policy)) {
        const totalEndorsementOptions = uwr?.TemplateMetaData.EndorsementTypes ?? [];
        // Filter and construct endorsementOptions for popper of the policy
        const filteredEndoOptions = totalEndorsementOptions.filter(
          (e) => !policy.Endorsements.find((s: any) => s.EndorsementTypeId == parseInt(e.Key)),
        );
        policy.EndorsementsOptions = filteredEndoOptions;
        policy.EndorsementsOptions.push({
          FormNumber: '',
          Key: underwritingText.MISC_ENDO_OPTION,
          Name: underwritingText.MISC_ENDO_OPTION,
        } as UnderwritingEndorsementOption);
      }
      return policy;
    });
    updateUnderwriting('ProposedPolicies', policies);
  }, []);

  // Set the page context on page load
  useEffect(() => {
    setProposedPolicies(uwr?.ProposedPolicies ?? []);

    setPolicyOption(
      uwr?.TemplateMetaData.PolicyTypes.map((policyType) => {
        return {
          name: policyType.Name,
          value: policyType.Name,
        };
      }) ?? [],
    );

    const endoOptions = uwr?.TemplateMetaData.EndorsementTypes ?? [];
    const isMiscEndExisting =
      endoOptions.filter((option) => {
        return option.Key === underwritingText.MISC_ENDO_OPTION;
      }).length > 0;

    if (!isMiscEndExisting) {
      endoOptions.push({
        FormNumber: '',
        Key: underwritingText.MISC_ENDO_OPTION,
        Name: underwritingText.MISC_ENDO_OPTION,
      } as UnderwritingEndorsementOption);
    }
    setEndorsementOptions(endoOptions);

    // Validate proposed policy
    validateErrors();
  }, [uwr?.ProposedPolicies]);

  const noErrorsObj = (obj: any) => {
    return Object.values(obj).every((val) => Array.isArray(val) && val.length === 0);
  };

  // Proposed Policy Validation
  useEffect(() => {
    if (submitAttempted) {
      setShowMandatoryField(true);
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  const classes = useStyles();

  const handleNewProposedPolicy = () => {
    setShowMandatoryField(false);
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);
    anchorEls.push(null);
    policies.push({
      Id: randomId(),
      PolicyType: '',
      NameOfInsured: '',
      ExceptionsDeletedFromPolicy: '',
      EstateInterestDescription: '',
      PolicyAmount: 0,
      IsFeeEstateInterest: false,
      IsLeaseEstateInterest: false,
      IsOtherEstateInterest: false,

      IsExpanded: true,
      EndorsementsOptions: endorsementOptions,
    } as UnderwritingPolicy);

    updateUnderwriting('ProposedPolicies', policies);
    // Validate new policy
    validateErrors();
  };

  const handleDeleteEndorsement = (index: number, selectedEndo: UnderwritingEndorsement) => {
    setIsDeleteEndorsement(true);
    setTobeDeletedEndo({ policyIndex: index - 1, endorsementId: selectedEndo.Id });
  };

  const confirmDeleteEndorsement = () => {
    setIsDeleteEndorsement(false);

    // Get entire list of policies
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);

    // Filter Endosement list for the policy
    const filteredEndo = policies[toBeDeletedEndo.policyIndex].Endorsements.filter(
      (endo) => endo.Id != toBeDeletedEndo.endorsementId,
    );
    // update Endosement list for the policy
    policies[toBeDeletedEndo.policyIndex].Endorsements = filteredEndo;

    // Filter endorsementOptions for popper of the policy
    const filteredEndoOptions = endorsementOptions.filter(
      (e) =>
        !policies[toBeDeletedEndo.policyIndex].Endorsements.find(
          (s) => s.Id == parseInt(e.Key) && s.Key != underwritingText.MISC_ENDO_OPTION,
        ),
    );
    // update endorsementOptions for popper of the policy
    policies[toBeDeletedEndo.policyIndex].EndorsementsOptions = filteredEndoOptions;

    updateUnderwriting('ProposedPolicies', policies);

    setTobeDeletedEndo({ policyIndex: 0, endorsementId: 0 });
  };

  const cancelDeleteEndorsement = () => {
    setIsDeleteEndorsement(false);
  };

  const handleNewEndorsement = (policyIndex: number, selectedEndoOption: UnderwritingEndorsementOption) => {
    const policies = uwr?.ProposedPolicies ?? [];
    policies[policyIndex].Endorsements ??= [];

    console.log('Selected', selectedEndoOption);

    policies[policyIndex].Endorsements.push({
      Description: selectedEndoOption.Name === underwritingText.MISC_ENDO_OPTION ? '' : selectedEndoOption.Name,
      FormNumber:
        selectedEndoOption.Name === underwritingText.MISC_ENDO_OPTION
          ? underwritingText.MISC_ENDO_OPTION
          : selectedEndoOption.FormNumber,
      Id:
        selectedEndoOption.Name === underwritingText.MISC_ENDO_OPTION ? miscId() : parseInt(selectedEndoOption.Key, 10),
      ModifiedFormNumber: '',
      CreatedDate: new Date(),
      Key: selectedEndoOption.Key,

      EndorsementTypeId:
        parseInt(selectedEndoOption?.Key === underwritingText.MISC_ENDO_OPTION ? '0' : selectedEndoOption?.Key, 10) ??
        0,
    } as UnderwritingEndorsement);

    // Filter EndorsementList for Popper for indiviual Policy
    const filteredEndorsements = policies[policyIndex].EndorsementsOptions.filter(
      (e) =>
        !policies[policyIndex].Endorsements.find((s) => s.Key == e.Key && s.Key != underwritingText.MISC_ENDO_OPTION),
    );

    policies[policyIndex].EndorsementsOptions = filteredEndorsements;
    validateErrors();

    updateUnderwriting('ProposedPolicies', policies);
  };

  const updatePolicyValue = (index: number, field: string, value: any) => {
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);

    policies[index] = { ...policies[index], [field]: value };
    if (field === 'PolicyType') {
      const policyType = uwr?.TemplateMetaData?.PolicyTypes.find((policyType) => {
        return policyType.Name === value;
      });
      policies[index] = { ...policies[index], PolicyTypeId: parseInt(policyType?.Key ?? '0', 10) };
    }

    validateErrors();
    updateUnderwriting('ProposedPolicies', policies);
  };

  const updateEndorsementValue = (policyIndex: number, endorsementIndex: number, field: string, value: any) => {
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);
    const endorsements = policies[policyIndex].Endorsements;
    endorsements[endorsementIndex] = { ...endorsements[endorsementIndex], [field]: value };

    updatePolicyValue(policyIndex, 'Endorsements', endorsements);
  };

  const handleExpandCollapseAll = (isExpand: boolean) => {
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);

    const updatedPolicies = policies.map((policy) => {
      policy.IsExpanded = isExpand;
      return policy;
    });
    updateUnderwriting('ProposedPolicies', updatedPolicies);
  };

  const handleExpandSingle = (isExpand: boolean, id: string | number) => {
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);

    const updatedPolicies = policies.map((policy) => {
      if (policy.Id === id) {
        return { ...policy, IsExpanded: isExpand };
      }
      return policy;
    });
    updateUnderwriting('ProposedPolicies', updatedPolicies);
  };

  const handleDeleteProposedPolicy = (id: number, index: number) => {
    setIsDeletePolicy(true);
    setToBeDeletedPolicy({ policyId: id, policyIndex: index });
  };

  const confirmDeletePolicy = () => {
    const policies = uwr?.ProposedPolicies ?? ([] as UnderwritingPolicy[]);
    policies.splice(toBeDeletedPolicy.policyIndex, 1);
    updateUnderwriting('ProposedPolicies', policies);
    // Validate on deleting policy
    validateErrors();
    setIsDeletePolicy(false);
    setToBeDeletedPolicy({ policyId: 0, policyIndex: 0 });
  };

  const cancelDeletePolicy = () => {
    setIsDeletePolicy(false);
  };

  const renderEndorsements = (endorsements: UnderwritingEndorsement[], policyIndex: number) => {
    // console.log('endorsements per policy', endorsements);
    return (
      <div className={clsx(classes.actionsTable, 'actionsTableWrapper')}>
        <Grid container className={classes.actionsTableHeader}>
          <Grid item sm={5} className={classes.tableHeaderCell}>
            Endorsement
          </Grid>
          <Grid item sm={3} className={classes.tableHeaderCell}>
            Form Number
          </Grid>
          <Grid item sm={3} className={classes.tableHeaderCell}>
            Modified Form Number
          </Grid>
          <Grid item sm={1} className={classes.tableHeaderCell}>
            {' '}
          </Grid>
        </Grid>
        <Grid container className={classes.actionsTableList}>
          {endorsements.map((h: UnderwritingEndorsement, i) => (
            <Grid container key={i} className={classes.tableRow}>
              {h.FormNumber !== underwritingText.MISC_ENDO_OPTION ? (
                <Grid item sm={5} className={classes.tableCell}>
                  <Typography variant="body2">{h.Description}</Typography>
                </Grid>
              ) : (
                <>
                  <Grid item sm={5} className={classes.tableCell}>
                    <Grid item sm={3}>
                      <Typography variant="body2" className={classes.miscEndoFixedWidth}>
                        MISC. END <span className={classes.miscEndoAsterisk}>*</span>
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <AgentNetTextInput
                        name={`Endorsements[${i}].Description`}
                        value={h.Description}
                        onChange={(e) => updateEndorsementValue(policyIndex - 1, i, 'Description', e.target.value)}
                        required
                        showValidation={showMandatoryField}
                        errs={validationErrors[policyIndex - 1]}
                        fullWidth={true}
                        disabled={isUwrDisabled || isDisabled}
                      ></AgentNetTextInput>
                    </Grid>
                  </Grid>
                </>
              )}
              <Grid item sm={3} className={classes.tableCell}>
                {h.FormNumber !== underwritingText.MISC_ENDO_OPTION ? (
                  <Typography variant="body2">{h.FormNumber}</Typography>
                ) : (
                  <Grid item sm={12}>
                    <AgentNetTextInput
                      variant="outlined"
                      name={`Endorsements[${i}].ModifiedFormNumber`}
                      required
                      showValidation={showMandatoryField}
                      errs={validationErrors[policyIndex - 1]}
                      value={h.ModifiedFormNumber}
                      onChange={(e) => updateEndorsementValue(policyIndex - 1, i, 'ModifiedFormNumber', e.target.value)}
                      fullWidth={true}
                      disabled={isUwrDisabled || isDisabled}
                    ></AgentNetTextInput>
                  </Grid>
                )}
              </Grid>
              <Grid item sm={3} className={classes.tableCell}>
                {h.FormNumber !== underwritingText.MISC_ENDO_OPTION ? (
                  <AgentNetTextInput
                    value={h.ModifiedFormNumber}
                    onChange={(e) => updateEndorsementValue(policyIndex - 1, i, 'ModifiedFormNumber', e.target.value)}
                    disabled={isUwrDisabled || isDisabled}
                  ></AgentNetTextInput>
                ) : (
                  <Typography variant="body2">N/A</Typography>
                )}
              </Grid>
              {!isDisabled && (
                <Grid item sm={1} className={classes.tableCell}>
                  <IconButton
                    className={classes.deleteRow}
                    data-qa={'UnderwritingPolicy' + [policyIndex] + 'RemoveENDOIcon' + [i + 1]}
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    aria-label="delete endorsement"
                    onClick={() => {
                      // pass policyIndex and selected endorsement
                      handleDeleteEndorsement(policyIndex, h);
                    }}
                    disabled={isUwrDisabled}
                  >
                    <Close color="disabled" fontSize="large" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  const renderPolicies = () => {
    return uwr?.ProposedPolicies?.map((policy, i) => (
      <Grid container spacing={3} key={'UnderwritngPolicyContriner' + [i + 1]}>
        <Grid item sm={12}>
          <AccordionContent
            accordianQAAttribute={'UnderwritingPolicyAccordionContent' + [i + 1]}
            icon={'document'}
            type={'underwriting'}
            title={policy.PolicyType === '' ? 'New Policy' : policy.PolicyType}
            subtitle={[
              policy.NameOfInsured === '' ? 'Name of Insured' : policy.NameOfInsured,
              `Policy Amount: $${parseFloat(policy.PolicyAmount.toString()).toFixed(2)}`,
            ]}
            key={`underwritingPolicy_${policy.Id}`}
            id={policy.Id}
            expanded={policy.IsExpanded}
            onExpandChange={handleExpandSingle}
            status={''}
            className={classes.mb2}
            hideStatus={true}
            onDelete={() => handleDeleteProposedPolicy(policy.Id, i)}
            isDeleteDisabled={isUwrDisabled || isDisabled}
            removeAsterisk
          >
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <AgentNetDropdownSelector
                  label={'Policy Type'}
                  name={'PolicyType'}
                  qaAttribute={'UnderwritingPolicyType' + [i + 1]}
                  options={policyOption}
                  value={policy.PolicyType}
                  menuOption={(val: string, name: any) => {
                    updatePolicyValue(i, 'PolicyType', val);
                    // const samePolicyType = uwr.ProposedPolicies.filter((e) => e.PolicyType === val);
                    // if (samePolicyType.length > 1) {
                    //   setDuplicatePolicyType(samePolicyType[1]?.PolicyType);
                    //   setDuplicatePolicyId(samePolicyType[0].Id);
                    // }
                  }}
                  required={true}
                  disabled={isUwrDisabled || isDisabled}
                  dropdowntype="outlined"
                  showValidation={showMandatoryField}
                  errs={validationErrors[i]}
                />
              </Grid>
              <Grid item sm={3}>
                <AgentNetTextInput
                  fullWidth
                  variant="outlined"
                  label="Name Of Insured"
                  name="NameOfInsured"
                  Data-QA={'UnderwritingPolicyNameOfInsured' + [i + 1]}
                  value={policy.NameOfInsured ?? ''}
                  disabled={isUwrDisabled || isDisabled}
                  onChange={(event: any) => {
                    updatePolicyValue(i, 'NameOfInsured', event.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={3}>
                <CurrencyField
                  fullWidth
                  variant="outlined"
                  label="Policy Amount"
                  name="PolicyAmount"
                  Data-QA={'UnderwritingPolicyPolicyAmount'}
                  value={policy.PolicyAmount}
                  required={false}
                  disabled={isUwrDisabled || isDisabled}
                  onChange={(event: any) => {
                    updatePolicyValue(i, 'PolicyAmount', event.target.value);
                  }}
                />
              </Grid>
              {/* <Grid>
                {duplicatePolicyType && policy.Id !== duplicatePolicyId && policy.PolicyType === duplicatePolicyType && (
                  <>
                    <Notification inline severity="warning" className={classes.warningMsg}>
                      {duplicatePolicyType} {underwritingText.DUPLICATE_POLICY_TYPE_WARNING_MSG}
                    </Notification>
                  </>
                )}
              </Grid> */}
              <Grid item sm={6}>
                <AgentNetTextInput
                  fullWidth
                  variant="outlined"
                  label="Exceptions Deleted From Policy"
                  name="ExceptionsDeletedFromPolicy"
                  Data-QA={'UnderwritingPolicyException' + [i + 1]}
                  value={policy.ExceptionsDeletedFromPolicy ?? ''}
                  disabled={isUwrDisabled || isDisabled}
                  onChange={(event: any) => {
                    updatePolicyValue(i, 'ExceptionsDeletedFromPolicy', event.target.value);
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <AgentNetCheckBoxGroup label="Estate/Interest" row={true} removeMarginTop={true}>
                  <AgentNetCheckBox
                    label={'Fee'}
                    name={'EstateInterestFee'}
                    value={policy.IsFeeEstateInterest}
                    disabled={isUwrDisabled || isDisabled}
                    checkHandler={(checked: boolean, name: string) =>
                      updatePolicyValue(i, 'IsFeeEstateInterest', checked)
                    }
                  />
                  <AgentNetCheckBox
                    label={'Lease'}
                    name={'EstateInterestLease'}
                    value={policy.IsLeaseEstateInterest}
                    disabled={isUwrDisabled || isDisabled}
                    checkHandler={(checked: boolean, name: string) =>
                      updatePolicyValue(i, 'IsLeaseEstateInterest', checked)
                    }
                  />
                  <AgentNetCheckBox
                    label={'Other'}
                    name={'EstateInterestOther'}
                    value={policy.IsOtherEstateInterest}
                    disabled={isUwrDisabled || isDisabled}
                    checkHandler={(checked: boolean, name: string) =>
                      updatePolicyValue(i, 'IsOtherEstateInterest', checked)
                    }
                  />
                </AgentNetCheckBoxGroup>
              </Grid>
              {policy.PolicyType === 'Other' && (
                <Grid item sm={6}>
                  <AgentNetTextInput
                    fullWidth
                    variant="outlined"
                    label="Describe Policy Type"
                    name="DescribePolicyType"
                    disabled={isUwrDisabled || isDisabled}
                    Data-QA={'DescribePolicyType' + [i + 1]}
                    value={policy.PolicyTypeDescription ?? ''}
                    required={true}
                    onChange={(event: any) => {
                      updatePolicyValue(i, 'PolicyTypeDescription', event.target.value);
                    }}
                    showValidation={showMandatoryField}
                    errs={validationErrors[i]}
                  />
                </Grid>
              )}
              {policy.IsOtherEstateInterest && (
                <Grid item sm={6}>
                  <AgentNetTextInput
                    fullWidth
                    variant="outlined"
                    label="Describe Estate/Interest"
                    name="DescribeEstateInterest"
                    disabled={isUwrDisabled || isDisabled}
                    Data-QA={'DescribeEstateInterest' + [i + 1]}
                    value={policy.EstateInterestDescription ?? ''}
                    required
                    onChange={(event: any) => {
                      updatePolicyValue(i, 'EstateInterestDescription', event.target.value);
                    }}
                    showValidation={showMandatoryField}
                    errs={validationErrors[i]}
                  />
                </Grid>
              )}
              <Grid item sm={12}>
                <AgentNetDivider
                  title={'Endorsements'}
                  typoVariant="h3"
                  primaryButtonName="Add Endorsements"
                  primaryButtonDisable={isUwrDisabled || isDisabled}
                  onClickPrimary={(e: any) => {
                    if (!anchorEls[i]) {
                      const anchorElems = [...anchorEls];
                      anchorElems[i] = e.currentTarget as HTMLElement;
                      setAnchorEls(anchorElems);
                      // console.log(i);
                    } else {
                      const anchorElems = [...anchorEls];
                      anchorElems[i] = null;
                      setAnchorEls(anchorElems);
                    }
                  }}
                  disableButton={isUwrDisabled || isDisabled}
                  disablePadding
                />
                <Popper
                  className={classes.popper}
                  placement="bottom-end"
                  anchorEl={anchorEls[i]}
                  open={Boolean(anchorEls[i])}
                >
                  <div className={classes.popperContent}>
                    <div className={classes.popperHeader}>
                      <Typography variant="h2" color="textPrimary">
                        Add Endorsements
                      </Typography>
                      <IconButton
                        className={classes.popperClose}
                        aria-label="Close Endorsements Popper"
                        onClick={() => {
                          const anchorElems = [...anchorEls];
                          anchorElems[i] = null;
                          setAnchorEls(anchorElems);
                        }}
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                      >
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </div>
                    <Autocomplete
                      id={'endo-popper-' + i}
                      options={endorsementOptions}
                      filterOptions={(options, state) => {
                        return policy.EndorsementsOptions.filter((endo) => {
                          return endo.Name.toLowerCase().indexOf(state.inputValue.toLowerCase()) >= 0;
                        });
                      }}
                      getOptionLabel={(option) => option.Name}
                      open
                      onClose={(e: any, reason: AutocompleteCloseReason) => handleClose(e, reason, i)}
                      multiple
                      classes={{
                        option: classes.popperOption,
                        popperDisablePortal: classes.popperDisablePortal,
                        paper: classes.popperPaper,
                      }}
                      onChange={(event, value, reason, details) => {
                        // console.log('onChange', i);
                        if (details?.option) {
                          handleNewEndorsement(i, details?.option);
                        }
                      }}
                      disablePortal
                      renderTags={() => null}
                      noOptionsText="No labels"
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <div className={classes.inputBaseWrap}>
                          <TextField
                            variant="outlined"
                            label="Search Endorsements"
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            autoFocus
                            className={classes.inputBase}
                            InputLabelProps={{ shrink: true }}
                            data-qa={'UnderwritingSearchEndorsements' + [i]}
                          />
                        </div>
                      )}
                    />
                  </div>
                </Popper>
              </Grid>
              {(policy?.Endorsements?.length ?? 0) > 0 && (
                <Grid item sm={12}>
                  {renderEndorsements(policy.Endorsements, i + 1)}
                </Grid>
              )}
            </Grid>
          </AccordionContent>
        </Grid>
      </Grid>
    ));
  };

  return (
    <>
      <Container>
        <Grid container spacing={3}>
          <Grid item sm={12}>
            {(uwr?.ProposedPolicies?.length ?? 0) === 0 && (
              <div className="underwritingWrap">
                <AgentNetDivider title={'Proposed Policies'} typoVariant="h2" disablePaddingX />
                <NoResults
                  showButton={true}
                  type="proposed-policies"
                  titleText="No Proposed Policies Found"
                  pText={underwritingText.CREATE_NEW_UNDERWRITING}
                  buttonText="Add New Policy"
                  dataQA="AddNewUnderwriting"
                  onClick={handleNewProposedPolicy}
                  disableButton={isUwrDisabled || isDisabled}
                />
              </div>
            )}
            {(uwr?.ProposedPolicies?.length ?? 0) > 0 && (
              <AgentNetDivider
                title={'Proposed Policies'}
                typoVariant="h2"
                primaryButtonName="Add New Policy"
                primaryButtonDisable={isUwrDisabled || isDisabled}
                buttonName={'Close All'}
                buttonName2={'Expand All'}
                onClick={() => {
                  handleExpandCollapseAll(false);
                }}
                onClick2={() => {
                  handleExpandCollapseAll(true);
                }}
                primaryButtonQaAttr="AddNewPolicyTop"
                buttonNameQaAttr="UnderwritingPoliciesCloseAll"
                buttonName2QaAttr="UnderwritingPoliciesExpandAll"
                onClickPrimary={handleNewProposedPolicy}
                disablePaddingX
              />
            )}
          </Grid>
        </Grid>
        {renderPolicies()}
        {(uwr?.ProposedPolicies?.length ?? 0) > 0 && (
          <AgentNetButton
            variant="outlined"
            size="small"
            onClick={handleNewProposedPolicy}
            disabled={isUwrDisabled || isDisabled}
            data-qa="AddNewPolicyBottom"
          >
            Add New Policy
          </AgentNetButton>
        )}
      </Container>
      <>
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationEndorsementDelete"
          onConfirm={confirmDeleteEndorsement}
          open={isDeleteEndorsement}
          onDismissAction={cancelDeleteEndorsement}
          dialogTitle="Delete Endorsement"
          dialogBtnContent="Yes, Delete"
          dialogText={underwritingText.DELETE_ENDO_CONFIRM_MSG}
        />
      </>
      <>
        <AgentNetConfirmationDialog
          qaAttrPrefix="ConfirmationPolicyDelete"
          onConfirm={confirmDeletePolicy}
          open={isDeletePolicy}
          onDismissAction={cancelDeletePolicy}
          dialogTitle="Delete Proposed Policy"
          dialogBtnContent="Yes, Delete"
          dialogText={underwritingText.DELETE_POLICY_CONFIRM_MSG}
        />
      </>
    </>
  );
};

export default UnderwritingProposedPolicyCollection;
