import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { dateTooltipValue, openDocument } from 'utilities/utilities';
import { filterParams, noFilesText, numberValueFormatter } from './invoicesListConfig';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { Grid, Typography } from '@material-ui/core';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import NoResults from 'ui-kit/components/noResults/NoResults';
import '../files/files-summary/files-list/FilesList.scss';
import './Invoice.scss';
import { StatusRenderer } from 'features/files/files-summary/files-list/filesListConfigs';
import InvoiceSummary from './InvoiceSummary';
import MonetizationIcon from 'ui-kit/icons/MonetizationIcon';
import PDFIcon from 'ui-kit/icons/PDF';
import InvoiceDateRenderer from './InvoiceDateRenderer';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import useAsync from 'hooks/useAsync';
import { exportReports, getInvoiceDocument, getInvoices } from 'api/serviceorderinvoice/serviceorderinvoice-api';
import { getEpayAccount } from 'api/payment/api';
import { useAuth } from '@agentnet/auth';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import { DocumentType, excelDocType, newWindow, WindowFeatures } from 'features/constants';
import { GetServiceOrderInvoiceRequest, InvoiceDocumentRequest } from 'features/ServiceOrderInvoices/type';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import moment from 'moment';

export interface InvoicesListInterface {
  tabSelection: number;
  tabName: string;
  contentClassName?: string;
  handleTabChange: (tab: number) => void;
}

const InvoicesList = ({ tabSelection, tabName, contentClassName, handleTabChange }: InvoicesListInterface) => {
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - ${selectedRows?.length ? '356px' : '296px'}) !important`,
        fontFamily: theme.typography.fontFamily,
      },
      selectedRows: {
        paddingBottom: 12,
      },
      selectedRowsActive: {
        marginBottom: '72px',
        paddingBottom: 0,
      },
      filterWrap: {
        marginBottom: theme.spacing(2),
        display: 'flex',
      },
      filterDash: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      mr1: {
        marginRight: theme.spacing(1),
      },
      mr2: {
        marginRight: theme.spacing(2),
      },
    }),
  );
  const classes = useStyles();
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const gridRef = useRef<any>(null);
  const [gridApi, setGridApi] = useState<any>(null);
  const [columnApi, setColumnApi] = useState<any>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [invoiceRowData, setInvoiceRowData] = useState<any>([]);
  const keyToSum = 'invoiceBalance';
  const sumWithReduce = selectedRows.reduce(
    (accumulator: any, obj: { [x: string]: any }) => accumulator + obj[keyToSum],
    0,
  );
  const columnToHide = 'underwriter';
  const isAllOpenStatus = selectedRows?.every(
    (row: any) => row['invoiceStatus'] === 'Open' || row['invoiceStatus'] === 'Manual Pay in Process',
  );
  const isPremiumInvoice = selectedRows?.some((row: any) => row['isPremium'] === true);
  const [showOnlyOpenStatusMsg, setShowOnlyOpenStatusMsg] = useState<boolean>(false);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);
  const exportedFiles = selectedRows;
  const selectedFilesCount = { files: selectedRows?.map((data: any) => data.fileId) };
  const payFiles = { firmId: userFirm?.firmId, invoiceData: selectedRows?.map((data: any) => data) };
  const [openReportModal, setOpenReportModal] = useState<boolean>(false);
  const [openInvoiceSummary, setOpenInvoiceSummary] = useState<boolean>(false);
  const [hasPremiumInvoice, setHasPremiumInvoice] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [doNotReset, setDoNotReset] = useState<boolean>(true);
  const { addSnackbarMessage } = useSnackBars();
  const [invoiceDocumentRequest, setInvoiceDocumentRequest] = useState<InvoiceDocumentRequest>();
  const [invoiceDateFrom, setInvoiceDateFrom] = useState<string>('');
  const [invoiceDateTo, setInvoiceDateTo] = useState<string>('');
  const [isCustomDateSelected, setIsCustomDateSelected] = useState<boolean>(false);
  const [ePayAccounts, setEPayAccounts] = useState<any>([]);

  const [errMsgs, setErrMsgs] = useState([]);
  const [invoiceFilter, setinvoiceFilter] = useState<string>('allInvoices');
  const totalFileCount = tabName === 'Pay' ? rowData?.length : invoiceRowData?.length;
  let externalinvoiceFilter = 'allInvoices';
  const { getAccessToken } = useAuth();
  const today = new Date();
  const nintyDaysAgo = new Date(today);
  nintyDaysAgo.setDate(today.getDate() - 90);

  const getInvoiceData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    const payload: GetServiceOrderInvoiceRequest =
      tabName === 'Pay'
        ? {
            firmId: userFirm?.firmId,
            isViewInvoice: false,
            fromDate: null,
            toDate: null,
          }
        : {
            firmId: userFirm?.firmId,
            isViewInvoice: true,
            fromDate: invoiceDateFrom == '' ? moment(nintyDaysAgo).format('MM/DD/YYYY') : invoiceDateFrom,
            toDate: invoiceDateTo == '' ? moment(today).format('MM/DD/YYYY') : invoiceDateTo,
          };
    setinvoiceFilter('allInvoices');
    externalFilterChanged('allInvoices');
    setIsCustomDateSelected(false);
    setDoNotReset(true);
    setSelectedRows([]);
    setOpenInvoiceSummary(false);
    setHasPremiumInvoice(false);
    return await getInvoices(token, payload);
  };

  const getExportData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await exportReports(exportedFiles, token, tabName);
  };

  const getInvoicePdf = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getInvoiceDocument(invoiceDocumentRequest!, token);
  };

  const onViewPdfClick = (orderNumber: string, invoiceDate: Date, regionId: number) => {
    setInvoiceDocumentRequest({ orderNumber: orderNumber, invoiceDate: invoiceDate, regionId: regionId });
  };
  const { profile } = profileCtx;
  const payRights = useMemo(() => {
    return Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 61));
  }, [profile?.activityRights]);
  const payManualRights = useMemo(() => {
    return Boolean(profile?.activityRights?.find((rights: any) => rights.ActivityRightId === 83));
  }, [profile?.activityRights]);

  const headerCheckboxRenderer = () => {
    return <input type="checkbox" data-qa={`SO${tabName}AllCheckbox`} />;
  };

  const ePayAccountsPayload = {
    underwriter: 'FAST',
    invoiceTypes: 'FASTInvoice',
  };

  const rowCheckboxRenderer = (params: any) => {
    return <input type="checkbox" data-qa={`SO${tabName}Order${params.node.id}Checkbox`} />;
  };

  const {
    execute: executeGetInvoiceList,
    status: invoiceListStatus,
    value: invoiceListResults,
    errors: invoiceListErrors,
  } = useAsync<any>(getInvoiceData, false);

  const {
    execute: executeGetInvoicePdf,
    status: executeGetInvoicePdfStatus,
    value: invoicePdfResults,
    errors: invoicePdfErrors,
  } = useAsync<any>(getInvoicePdf, false);

  const {
    execute: executeExportData,
    status: executeExportDataStatus,
    value: exportResults,
    errors: exportErrors,
  } = useAsync<any>(getExportData, false);

  const getEPaymentDetails = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getEpayAccount(token, userFirm?.firmId ?? '', ePayAccountsPayload);
  };

  const {
    execute: executeEPaymentAccounts,
    status: executeEPaymentDetailsStatus,
    value: ePaymentResults,
    errors: ePaymentAccountsErrors,
  } = useAsync<any>(getEPaymentDetails, false);

  useEffect(() => {
    setInvoiceRowData(invoiceListResults);
    setRowData(invoiceListResults);
  }, [invoiceListResults]);

  useEffect(() => {
    if (userFirm?.firmId && !payManualRights) {
      executeEPaymentAccounts().then();
    }
  }, [userFirm?.firmId, payManualRights]);

  useEffect(() => {
    if (invoiceDocumentRequest) executeGetInvoicePdf().then();
  }, [invoiceDocumentRequest]);

  useEffect(() => {
    const isError =
      invoiceListStatus === 'error' || executeExportDataStatus === 'error' || executeGetInvoicePdfStatus === 'error';
    setDisplayError(isError);
  }, [invoiceListStatus, executeExportDataStatus, executeGetInvoicePdfStatus]);

  useEffect(() => {
    if (executeExportDataStatus === 'success') {
      if (exportResults) {
        openDocument(exportResults, excelDocType, newWindow, WindowFeatures);
      }
    }
  }, [executeExportDataStatus]);

  useEffect(() => {
    if (executeGetInvoicePdfStatus === 'success') {
      if (invoicePdfResults?.DocumentData) {
        openDocument(invoicePdfResults?.DocumentData, DocumentType, newWindow, WindowFeatures);
      }
    }
  }, [executeGetInvoicePdfStatus]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [
        ...(invoiceListErrors?.length ? invoiceListErrors : []),
        ...(exportErrors ?? []),
        ...(invoicePdfErrors ?? []),
      ];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  const defaultColDef = useMemo<ColDef>(() => {
    executeGetInvoiceList().then();
    if (tabName === 'Pay') {
      setInvoiceDateFrom('');
      setInvoiceDateTo('');
    } else if (tabName === 'View') {
      if (invoiceDateFrom == '' && invoiceDateTo == '') {
        setInvoiceDateFrom(moment(nintyDaysAgo).format('MM/DD/YYYY'));
        setInvoiceDateTo(moment(today).format('MM/DD/YYYY'));
      }
    }
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: true,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, [tabSelection, isCustomDateSelected]);

  useEffect(() => {
    const hideColumnIfNeeded = () => {
      if (tabName === 'Pay') {
        const columnApi = gridRef.current?.columnApi;
        setSelectedRows([]);
        if (columnApi) {
          columnApi.setColumnVisible(columnToHide, false);
        }
      } else {
        setSelectedRows([]);
      }
    };

    if (gridRef.current?.api) {
      setDisplayedRowCount(gridRef.current.api.getDisplayedRowCount());
      hideColumnIfNeeded();
    }

    if (gridRef?.current?.api?.getDisplayedRowCount() === 0) {
      gridRef.current?.api.showNoRowsOverlay();
    } else {
      gridRef.current?.api?.hideOverlay();
    }
  }, [gridRef.current?.api, tabName, columnToHide, rowData]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  const resetFiltersAndSelection = (qfilter = '', invoiceFilter = '') => {
    if (doNotReset) {
      setSelectedRows([]);
      setOpenInvoiceSummary(false);
      setHasPremiumInvoice(false);
    }
    gridRef.current?.api?.deselectAll();
    gridRef.current?.api?.setFilterModel(null);
    externalFilterChanged(invoiceFilter || 'allInvoices');
    setDoNotReset(true);
  };

  const getColumnConfig = () => (tabName === 'Pay' ? payColumnsConfig : reportColumnsConfig);

  const payColumnsConfig: any = [
    {
      field: '',
      maxWidth: 40,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      headerComponentFramework: headerCheckboxRenderer,
      cellRendererFramework: rowCheckboxRenderer,
    },
    {
      field: '',
      maxWidth: 30,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return params?.data?.isPremium ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MonetizationIcon fontSize="small" />
          </div>
        ) : (
          ''
        );
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'Status',
      field: 'invoiceStatus',
      tooltipField: 'invoiceStatus',
      cellRenderer: StatusRenderer,
      sort: 'desc',
    },
    {
      headerName: 'Invoice Number',
      field: 'invoiceNumber',
      tooltipField: 'invoiceNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Agent File Number',
      field: 'fileNumber',
      tooltipField: 'fileNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Office Address',
      field: 'officeAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'officeAddress',
    },
    {
      headerName: 'Property Address',
      field: 'propertyAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
    },
    { headerName: 'Buyers', field: 'buyer', filter: 'agTextColumnFilter', tooltipField: 'buyer' },
    {
      headerName: 'Date',
      field: 'invoiceDate',
      filterParams: filterParams,
      filter: 'agDateColumnFilter',
      cellRenderer: (params: any) => {
        return <InvoiceDateRenderer value={params.data.invoiceDate} tab={tabSelection}></InvoiceDateRenderer>;
      },
      //tooltipValueGetter: dateTooltipValue,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      headerName: 'Original Amount',

      field: 'invoicedAmount',
      tooltipField: 'invoicedAmount',
      filter: 'agTextColumnFilter',
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      // maxWidth: 120,
    },
    {
      headerName: 'Amount Due',
      field: 'invoiceBalance',
      tooltipField: 'invoiceBalance',
      filter: 'agTextColumnFilter',
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      // maxWidth: 120,
    },
    {
      field: '',
      maxWidth: 35,
      filter: false,
      floatingFilter: false,
      tooltipField: 'View Invoice',
      cellRenderer: (params: any) => {
        return (
          <span style={{ position: 'relative', top: '10px' }}>
            <PDFIcon
              onClick={() =>
                onViewPdfClick(params?.data?.orderNumber, params?.data?.invoiceDate, params?.data?.regionId)
              }
              fontSize="small"
              htmlColor="#0074CA"
            />
          </span>
        );
      },
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];

  const reportColumnsConfig: any = [
    {
      field: '',
      maxWidth: 40,
      filter: false,
      floatingFilter: false,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      pinned: 'left',
      headerComponentFramework: headerCheckboxRenderer,
      cellRendererFramework: rowCheckboxRenderer,
    },
    {
      field: '',
      maxWidth: 30,
      filter: false,
      floatingFilter: false,
      cellRenderer: (params: any) => {
        return params?.data?.isPremium ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MonetizationIcon fontSize="small" />
          </div>
        ) : (
          ''
        );
      },
      cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    },
    {
      headerName: 'Status',
      field: 'invoiceStatus',
      tooltipField: 'invoiceStatus',
      cellRenderer: StatusRenderer,
      sort: 'desc',
    },
    {
      headerName: 'Invoice Number',
      field: 'invoiceNumber',
      tooltipField: 'invoiceNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Agent File Number',
      field: 'fileNumber',
      tooltipField: 'fileNumber',
      filter: 'agTextColumnFilter',
    },
    {
      headerName: 'Office Address',
      field: 'officeAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'officeAddress',
    },
    {
      headerName: 'Property Address',
      field: 'propertyAddress',
      filter: 'agTextColumnFilter',
      tooltipField: 'propertyAddress',
    },
    { headerName: 'Buyers', field: 'buyer', filter: 'agTextColumnFilter', tooltipField: 'buyer' },
    {
      headerName: 'Date',
      field: 'invoiceDate',
      filterParams: filterParams,
      filter: 'agDateColumnFilter',
      cellRenderer: (params: any) => {
        return <InvoiceDateRenderer value={params.data.invoiceDate} tab={tabSelection}></InvoiceDateRenderer>;
      },
      tooltipValueGetter: dateTooltipValue,
      minWidth: 120,
      maxWidth: 120,
    },
    {
      headerName: 'Amount',
      field: 'invoicedAmount',
      tooltipField: 'invoicedAmount',
      filter: 'agTextColumnFilter',
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      maxWidth: 95,
    },
    {
      headerName: 'Balance',
      field: 'invoiceBalance',
      tooltipField: 'invoiceBalance',
      filter: 'agTextColumnFilter',
      valueFormatter: numberValueFormatter,
      tooltipValueGetter: numberValueFormatter,
      maxWidth: 95,
    },
    {
      field: '',
      maxWidth: 35,
      filter: false,
      floatingFilter: false,
      tooltipField: 'View Invoice',
      cellRenderer: (params: any) => {
        return (
          <span style={{ position: 'relative', top: '10px' }}>
            <PDFIcon
              onClick={() =>
                onViewPdfClick(params?.data?.orderNumber, params?.data?.invoiceDate, params?.data?.regionId)
              }
              fontSize="small"
              htmlColor="#0074CA"
            />
          </span>
        );
      },
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  };

  const getDisplayedRowCount = () => {
    if (gridApi) {
      return gridApi.getDisplayedRowCount();
    }
    return 0;
  };

  const onSelectionChanged = useCallback(() => {
    const currentselectedRows = gridRef.current?.api.getSelectedRows();
    setSelectedRows(currentselectedRows);
    if (currentselectedRows?.filter((value: any) => value.invoiceStatus == 'ePay in Process').length >= 1) {
      setShowOnlyOpenStatusMsg(true);
    }
  }, []);

  const onFilterChanged = () => {
    const filterModel = gridRef.current?.api.getFilterModel() ?? {};
    if (filterModel.errorCategory) {
      let errorCode = filterModel.errorCategory?.type;
      errorCode = errorCode === 'Stat Code(s)' ? 'Stat' : errorCode;
      errorCode === 'All' ? gridRef.current?.api.setQuickFilter('') : gridRef.current?.api.setQuickFilter(errorCode);
    }

    if (gridRef.current?.api?.getDisplayedRowCount() === 0) {
      gridRef.current?.api.showNoRowsOverlay();
    } else {
      gridRef.current?.api.hideOverlay();
    }
    setTimeout(() => {
      setDisplayedRowCount(getDisplayedRowCount());
    }, 3000);
  };

  const externalFilterChanged = useCallback((newValue: string) => {
    setinvoiceFilter(newValue);
    externalinvoiceFilter = newValue;
    gridRef.current?.api?.onFilterChanged();
  }, []);

  const isExternalFilterPresent = useCallback((): boolean => {
    return tabName === 'View' || externalinvoiceFilter !== 'allInvoices';
  }, []);

  const doesExternalFilterPass2 = useCallback(
    (node: any): boolean => {
      if (node.data) {
        switch (externalinvoiceFilter) {
          case 'premiumOnlyFiles':
            return node.data.isPremium;
          case 'excludePremiumOnlyFiles':
            return !node.data.isPremium;
          default:
            return true;
        }
      }
      return true;
    },
    [externalinvoiceFilter],
  );

  const doesExternalFilterPass1 = useCallback(
    (node: any): boolean => {
      if (node.data) {
        let isinvoiceFilterPassed = true;

        if (externalinvoiceFilter === 'premiumOnlyFiles') {
          isinvoiceFilterPassed = node.data.isCplOnly;
        } else if (externalinvoiceFilter === 'excludePremiumOnlyFiles') {
          isinvoiceFilterPassed = !node.data.isCplOnly;
        }

        return isinvoiceFilterPassed;
      }
      return true;
    },
    [externalinvoiceFilter],
  );

  const CustomOverlayNoRows = () => {
    if (!invoiceRowData || invoiceRowData.length === 0) {
      return (
        <div className={contentClassName}>
          <NoResults
            showButton={false}
            type="files"
            titleText={`No Invoices to ${tabName}`}
            pText={noFilesText['invoice']}
          />
        </div>
      );
    } else {
      return (
        <div className={contentClassName}>
          <NoResults
            showButton={false}
            type="results"
            titleText="No Results Found"
            pText="Try adjusting your filters to find what you’re looking for."
          />
        </div>
      );
    }
  };

  const tabText: any = {
    0: `Selecting ${selectedRows?.length} invoice(s) for ${numberValueFormatter(sumWithReduce, true)}`,
    1: `Exporting ${selectedRows?.length} invoice(s) for ${numberValueFormatter(sumWithReduce, true)}`,
  };

  const selectedFilesText: string = tabText[tabSelection];

  const handlePrimaryAction = () => {
    setOpenReportModal(true);
    if (isAllOpenStatus) {
      setOpenInvoiceSummary(true);
      if (isPremiumInvoice) {
        setHasPremiumInvoice(true);
      }
    }
  };

  useEffect(() => {
    if (showOnlyOpenStatusMsg && tabName === 'Pay' && payRights) {
      addSnackbarMessage({
        message: 'Only Invoices in Open & Manual Pay Status can be submitted for payment',
        type: 'warning',
        onClose: () => setShowOnlyOpenStatusMsg(false),
      });
    }
  }, [showOnlyOpenStatusMsg]);

  const onDateCalenderChange = (e: any, isFromDate: boolean) => {
    let message = '';
    if (!e || e === '') {
      // Reset the date if input is empty
      isFromDate ? setInvoiceDateFrom('') : setInvoiceDateTo('');
      return; // Early return to avoid deep nesting
    }

    const selectedDate = moment(e, 'MM/DD/YYYY', true);
    if (!selectedDate.isValid()) {
      // Handle invalid date
      message = isFromDate ? 'Invalid From date.' : 'Invalid To date.';
      isFromDate ? setInvoiceDateFrom('') : setInvoiceDateTo('');
    } else if (selectedDate.isAfter()) {
      // Handle future date
      message = 'Date cannot be a future date.';
      isFromDate ? setInvoiceDateFrom('') : setInvoiceDateTo('');
    } else {
      // Handle valid date
      if (isFromDate) {
        const toDate = moment(invoiceDateTo, 'MM/DD/YYYY', true);
        if (invoiceDateTo && selectedDate.isAfter(toDate)) {
          message = 'From date cannot be greater than To date.';
          setInvoiceDateFrom('');
        } else if (toDate.isValid() && selectedDate.diff(toDate, 'years', true) < -1) {
          // Corrected condition for checking date range more than 1 year
          message = 'Date range should not be more than 1 year.';
          setInvoiceDateFrom('');
        } else {
          setInvoiceDateFrom(e);
        }
      } else {
        const fromDate = moment(invoiceDateFrom, 'MM/DD/YYYY', true);
        if (invoiceDateFrom && selectedDate.isBefore(fromDate)) {
          message = 'To date cannot be less than From date.';
          setInvoiceDateTo('');
        } else if (fromDate.isValid() && fromDate.diff(selectedDate, 'years', true) < -1) {
          // Corrected condition for checking date range more than 1 year
          message = 'Date range should not be more than 1 year.';
          setInvoiceDateTo('');
        } else {
          setInvoiceDateTo(e);
        }
      }
    }

    if (message) {
      addSnackbarMessage({
        message,
        type: 'warning',
        onClose: () => setShowOnlyOpenStatusMsg(false),
      });
    }
  };

  const goBackAction = () => {
    handleTabChange(0);
    setDoNotReset(true);
    setSelectedRows([]);
    setOpenInvoiceSummary(false);
    setHasPremiumInvoice(false);
    executeGetInvoiceList().then();
  };

  useEffect(() => {
    setEPayAccounts(ePaymentResults ?? []);
  }, [ePaymentResults]);

  if (openInvoiceSummary) {
    return (
      <InvoiceSummary
        payFiles={payFiles}
        goBackAction={goBackAction}
        selectedRows={selectedRows}
        isAllOpenStatus={isAllOpenStatus}
        isPremiumInvoice={hasPremiumInvoice}
        paymentAccounts={ePayAccounts}
      />
    );
  }

  const CustomOverlayLoading = () => {
    return (
      <div className="files-container">
        <div className="no-rows">
          <LoadingSpinner status="pending" className="files-container--spinner-root" />
        </div>
      </div>
    );
  };

  if (invoiceListStatus === 'pending') {
    return <> {CustomOverlayLoading()}</>;
  }

  return (
    <>
      <div>
        <AgentNetDivider typoVariant="h1" title={`${tabName} Service Order Invoices`} />

        {tabName === 'View' ? (
          <>
            <Grid container justifyContent="flex-start" className={clsx(classes.filterWrap, classes.paddingX)}>
              <Grid item>
                <DateField
                  name="from"
                  label="From"
                  qaAttribute={'invoiceDateFrom'}
                  disableFuture
                  required
                  value={invoiceDateFrom}
                  onChange={(e: any) => {
                    onDateCalenderChange(e, true);
                  }}
                />
              </Grid>
              <Typography className={classes.filterDash}> — </Typography>
              <Grid item className={classes.mr2}>
                <DateField
                  name="to"
                  label="To"
                  qaAttribute={'invoiceDateTo'}
                  minDate={invoiceDateFrom}
                  disableFuture
                  required
                  value={invoiceDateTo}
                  onChange={(e: any) => {
                    onDateCalenderChange(e, false);
                  }}
                />
              </Grid>
              {invoiceDateFrom && invoiceDateFrom != '' && invoiceDateTo && invoiceDateTo != '' ? (
                <>
                  <AgentNetButton variant="contained" onClick={(e1) => setIsCustomDateSelected(true)} size="large">
                    Apply
                  </AgentNetButton>
                </>
              ) : (
                <></>
              )}
            </Grid>
          </>
        ) : (
          <Grid container justifyContent="flex-start" className={clsx(classes.filterWrap, classes.paddingX)}>
            {/* <AgentNetDropdownSelector
              name="payFilter"
              Data-QA={`${tabName}Filter`}
              options={invoiceFilters ?? []}
              value={invoiceFilter}
              id="invoiceFilter"
              dropdowntype="outlined"
              menuOption={(value: any) => {
                externalFilterChanged(value);
                return false;
              }}
            /> */}
            <MonetizationIcon fontSize="small" className={classes.mr1} />
            <Typography>
              <em>Indicates invoice contains premium. If ePay, only eCheck (ACH) permitted.</em>
            </Typography>
          </Grid>
        )}
      </div>
      <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
        <AgGridReact
          onGridReady={onGridReady}
          ref={gridRef}
          rowData={tabName === 'Pay' ? rowData : invoiceRowData}
          columnDefs={getColumnConfig()}
          defaultColDef={defaultColDef}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          onSelectionChanged={onSelectionChanged}
          onFilterChanged={onFilterChanged}
          animateRows={true}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={tabName === 'View' ? doesExternalFilterPass1 : doesExternalFilterPass2}
          components={{
            customDropdownFilter: FloatingFilterComponent,
            customOverlayNoRows: CustomOverlayNoRows,
          }}
          noRowsOverlayComponent={'customOverlayNoRows'}
        ></AgGridReact>
      </div>
      <div
        className={clsx('selected-rows', classes.paddingX, classes.selectedRows, {
          [classes.selectedRowsActive]: selectedFilesCount?.files?.length,
        })}
      >
        <Grid>
          <span>
            Showing {displayedRowCount ?? getDisplayedRowCount()} of {totalFileCount} files
          </span>
          {tabName === 'Pay' ? (
            <Typography>
              <i>Note: The above data does not include credits.</i>
            </Typography>
          ) : (
            ''
          )}
        </Grid>

        {selectedFilesText}
      </div>
      {selectedRows?.length ? (
        <BottomAppBar
          cancelLabel="Clear All"
          onCancel={() => resetFiltersAndSelection()}
          primaryActionLabel={tabName === 'Pay' && (payRights || payManualRights) ? 'View Invoice & Pay' : ''}
          disablePrimary={isAllOpenStatus && (payRights || payManualRights) ? false : true}
          cancelDataQa={`SO${tabName}ClearAll`}
          onPrimaryClick={handlePrimaryAction}
          primaryActionDataQa={tabName === 'Pay' ? `SO${tabName}ViewInvoicePay` : ``}
          fourthActionLabel={'Export'}
          onFourthClick={() => executeExportData().then()}
          fourthActionDataQa={`SO${tabName}Export`}
        />
      ) : null}
    </>
  );
};

export default InvoicesList;
