import React, { useEffect, useRef, useState } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: '1px solid #c4c4c4',
  },
}))(Tooltip);

const OverflowTip = ({ children, backgroundColor }: any) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      isOverflowed: {
        position: 'relative',
        '&::after': {
          content: '"..."',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 0,
          background: backgroundColor ? backgroundColor : 'white',
          height: '100%',
          textAlign: 'right',
          fontSize: '1.4rem',
          fontFamily: 'Inter',
          lineHeight: '2rem',
          color: theme.palette.text.primary,
        },
      },
    }),
  );
  const classes = useStyles();
  const [isOverflowed, setIsOverflow] = useState<any>(false);
  const textElementRef = useRef<any>();
  useEffect(() => {
    setIsOverflow(textElementRef.current.scrollWidth > textElementRef.current.clientWidth);
  }, []);
  return (
    <LightTooltip title={children} disableHoverListener={!isOverflowed} interactive onClick={(e) => e.preventDefault()}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        className={isOverflowed ? classes.isOverflowed : ''}
      >
        {children}
      </div>
    </LightTooltip>
  );
};

export default OverflowTip;
