import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import formatISO from 'date-fns/formatISO';
import {
  FileNumber as FileNumberRenderer,
  FormatDate as CreateDateRenderer,
  getDateRange,
  StatusRenderer,
} from './filesListConfigs';
import './FilesList.scss';
import SearchField from '../search-form/SearchField';
import { makeStyles } from '@material-ui/core/styles';
import { FileResults, searchOptionsType } from '../types';
import { Office } from '../../../../api/profile/types';
import { getFileDetail, searchFiles } from '../../../../api/file/file-api';
import useAsync from '../../../../hooks/useAsync';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import LoadingSpinner from '../../../../ui-kit/components/LoadingSpinner';
import NoResultFoundIcon from '../../../../ui-kit/icons/NoResultFound';
import {
  dateRangeFilters,
  DocumentType,
  fileStatus,
  httpErrorCodes,
  newWindow,
  transactionTypes,
  WindowFeatures,
} from '../../../constants';
import statesList from '../../../../ui-kit/inputs/StateSelectField/states.json';
import { Notification } from '../../../../ui-kit/components/notification/Notification';
import { useAuth } from '@agentnet/auth';
import { AgGridReact } from 'ag-grid-react';
import { FilterChangedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import _debounce from 'lodash/debounce';
import PDFIcon from 'ui-kit/icons/PDF';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import FloatingFilterComponent from './FloatingFilterComponent';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import { dateTooltipValue, openDocument } from 'utilities/utilities';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  paper: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  searchWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  tableStyles: {
    marginTop: '0px !important',
    height: 'calc(100vh - 219px) !important',
    width: '100% !important',
  },
  noResultsType: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

interface IGridFilterState {
  filters: { [key: string]: any };
  number: number;
}
const selectAllOption = 'All';

const FilesList: React.FC = () => {
  const { getAccessToken } = useAuth();
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const defaultSearchPayLoad = {
    firmId: userFirm?.firmId ?? null,
    pageSize: 500,
    searchText: '',
    status: fileStatus[1].value,
    openDateFrom: formatISO(new Date().setFullYear(new Date().getFullYear() - 10)),
    openDateTo: formatISO(new Date()),
  };
  const history = useHistory();
  const [searchPayload, setSearchPayload] = useState<searchOptionsType>(defaultSearchPayLoad);
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = React.useState<FileResults>({ files: [], total: 0 });
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [offices, setOffices] = useState<Office[]>([]);
  const [selectedFileId, setSelectedFileId] = useState<string>('');

  useEffect(() => {
    if (!userFirm?.firmId) {
      history.push('/err');
    }
  }, [userFirm]);

  // This is called by the useAsync execute
  const search = async (): Promise<{ result: FileResults; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await searchFiles(searchPayload, token);
  };
  const {
    execute,
    status,
    value: fileResults,
  } = useAsync<{ result: FileResults; errorCode?: string | number; message?: string }>(search, false);

  const getFilePdf = async (): Promise<unknown> => {
    const token = await getAccessToken();
    return getFileDetail(selectedFileId ?? '', token);
  };

  const {
    execute: executeFilePdf,
    value: pdfValue,
    status: pdfStatus,
    errors: getPdfError,
  } = useAsync<any>(getFilePdf, false);

  useEffect(() => {
    if (pdfStatus === 'success') {
      if (pdfValue?.pdfData) openDocument(pdfValue.pdfData, DocumentType, newWindow, WindowFeatures);
    }
  }, [pdfStatus]);

  const filterOnSearch = useCallback(
    _debounce((searchText: string) => {
      setSearchPayload((prevPayload) => ({ ...prevPayload, searchText }));
    }, 250),
    [rows],
  );

  const resetGridInputFocus = () => {
    const focusElement = document.querySelectorAll('.ag-header-cell.ag-floating-filter.ag-focus-managed');
    focusElement.forEach((item) => {
      item.removeAttribute('tabindex');
    });
  };

  useEffect(() => {
    if (selectedFileId !== '') {
      executeFilePdf().then();
      setTimeout(() => setSelectedFileId(''), 3000);
    }
  }, [selectedFileId]);

  useEffect(() => {
    if (userFirm?.offices) {
      const sortedOffices: Office[] =
        userFirm?.offices.sort((a, b) => {
          const officeNameA = a.name ? a.name.toLowerCase().replace(/\s/g, '') : '';
          const officeNameB = b.name ? b.name.toLowerCase().replace(/\s/g, '') : '';
          if (officeNameA < officeNameB) return -1;
          if (officeNameA > officeNameB) return 1;
          return 0;
        }) ?? [];
      setOffices(sortedOffices);
    }
  }, [userFirm]);

  useEffect(() => {
    const hasOneCharValue = Object.entries(searchPayload)
      .filter(([key]) => {
        if (key === 'firmId' || key === 'state') return false;
        return true;
      })
      .some(([key, value]) => {
        if (value) return (value as string).length < 3;
        return false;
      });

    if (!hasOneCharValue) execute().then();
    try {
      if (gridRef.current) {
        gridRef.current?.api.hideOverlay();
      }
    } catch (error) {
      console.warn('AgGrid fail to hide Overlay');
    }
  }, [searchPayload]);

  useEffect(() => {
    if (fileResults?.result) {
      setDisplayError(false);
      setRows(fileResults?.result);

      if (!fileResults?.result.total) gridRef.current?.api.showNoRowsOverlay();
      setRowData(fileResults?.result);
    } else {
      if (fileResults?.errorCode && gridRef.current) gridRef.current?.api.hideOverlay();
      if (
        fileResults?.errorCode == httpErrorCodes.notFound ||
        fileResults?.errorCode == httpErrorCodes.badRequest ||
        fileResults?.errorCode == httpErrorCodes.forbidden ||
        fileResults?.errorCode == httpErrorCodes.internalServerError
      ) {
        setErrMsg(fileResults?.message ?? 'Error.  Failed to GET.');
        setDisplayError(true);
      }
    }
  }, [fileResults]);

  const gridRef = useRef<any>(null);
  const saveFilter = useRef<boolean>(true);
  const secondaryFilterOptions = useRef<{ [key: string]: any }>({ searchText: '', state: '' });
  const defaultGridFilter = {
    status: { filterType: 'text', type: 'Open' },
    office: { filterType: 'text', type: selectAllOption },
    transactionType: { filterType: 'text', type: selectAllOption },
  };
  const [gridFilterState, setGridFilterState] = useState<IGridFilterState>({
    filters: defaultGridFilter,
    number: 3,
  });

  const hideOverlay = useCallback(() => {
    if (gridRef.current) {
      const rowCount = gridRef.current?.api.getDisplayedRowCount();
      if (rowCount) {
        gridRef.current?.api.hideOverlay();
      }
    }
    resetGridInputFocus();
  }, []);

  const savedFilterModel = useCallback((event: FilterChangedEvent) => {
    if (saveFilter.current) {
      const filterModel = gridRef.current?.api.getFilterModel() ?? {};
      const payload: searchOptionsType = {
        ...defaultSearchPayLoad,
        searchText: secondaryFilterOptions.current.searchText,
      };
      if (filterModel.status) {
        payload.status = filterModel.status.type;
      }
      if (filterModel.fileNumber) {
        payload.fileNumber = filterModel.fileNumber.filter;
      }
      if (filterModel.address) {
        if (filterModel.address.type !== selectAllOption) {
          payload.state = filterModel.address.type;
        } else if (statesList?.some((ele) => ele.abbreviation === filterModel?.address?.filter?.toUpperCase())) {
          payload.state = filterModel?.address?.filter?.toUpperCase();
        } else {
          payload.address = filterModel.address.filter;
        }
      }
      if (filterModel.buyer) {
        payload.buyer = filterModel.buyer.filter;
      }
      if (filterModel.lender) {
        payload.lender = filterModel.lender.filter;
      }
      if (filterModel.seller) {
        payload.seller = filterModel.seller.filter;
      }
      if (filterModel.office && filterModel.office.type !== selectAllOption) {
        payload.office = filterModel.office.type;
      }
      if (filterModel.transactionType && filterModel.transactionType.type !== selectAllOption) {
        payload.transactionType = filterModel.transactionType.type;
      }
      if (filterModel.openDate) {
        const dateRange = getDateRange(
          filterModel.openDate.type,
          filterModel.openDate.dateFrom,
          filterModel.openDate.dateTo,
        );
        payload.openDateFrom = dateRange.dateFromFormatISO;
        payload.openDateTo = dateRange.dateToFormatISO;
        if (filterModel.openDate.type !== 'inRange')
          filterModel.openDate.dateTo = filterModel.openDate.dateTo ?? filterModel.openDate.dateFrom;
      }
      if (filterModel.modifiedDate) {
        const dateRange = getDateRange(
          filterModel.modifiedDate.type,
          filterModel.modifiedDate.dateFrom,
          filterModel.modifiedDate.dateTo,
        );
        payload.modifiedDateFrom = dateRange.dateFromFormatISO;
        payload.modifiedDateTo = dateRange.dateToFormatISO;
        if (filterModel.modifiedDate.type !== 'inRange')
          filterModel.modifiedDate.dateTo = filterModel.modifiedDate.dateTo ?? filterModel.modifiedDate.dateFrom;
      }
      setSearchPayload(payload);
      setGridFilterState({ filters: filterModel, number: Object.keys(filterModel).length ?? 0 });
      hideOverlay();
    }
    saveFilter.current = true;
  }, []);

  const applyFilterModel = () => {
    if (gridRef.current) {
      gridRef.current?.api.setFilterModel(gridFilterState.filters);
    }
    hideOverlay();
    saveFilter.current = false;
  };

  const CustomOverlayLoading = () => {
    return (
      <div className="no-rows">
        <LoadingSpinner status="pending" className="files-container--spinner-root" />
      </div>
    );
  };

  const CustomOverlayNoRows = () => {
    return (
      <div className="no-rows">
        <NoResultFoundIcon />
        <Typography variant="h3" className={classes.noResultsType}>
          No Results Found
        </Typography>
        <Typography variant="body2" className="adjust-your-search" color="textSecondary">
          Try adjusting your search or filter to find what you’re looking for
        </Typography>
      </div>
    );
  };

  const setRowData = (rows: FileResults) => {
    const rowData = rows?.files ?? [];
    if (gridRef.current) {
      gridRef.current.api.setRowData(
        rowData.map((data) => {
          data.summary = data.summary ? data.summary : '';
          data.openDate = data.openDate ? data.openDate : null;
          data.modifiedDate = data.modifiedDate ? data.modifiedDate : null;
          return data;
        }),
      );
    }
    applyFilterModel();
  };

  resetGridInputFocus();

  const { addSnackbarMessage } = useSnackBars();

  return (
    <div className={classes.root}>
      <div>
        {displayError && (
          <Notification
            msg={errMsg}
            severity="error"
            className="files-container--alert"
            action={
              <Button color="inherit" variant="text" endIcon={<CloseIcon />} onClick={() => setDisplayError(false)}>
                ACTION
              </Button>
            }
          />
        )}
        <Grid className={classes.paper}>
          <AgentNetDivider typoVariant="h1" title="All Files" disablePaddingX />
          <div className={classes.searchWrap}>
            <SearchField
              onChange={(e) => {
                setSearchText(e.target.value);
                secondaryFilterOptions.current.searchText = e.target.value;
                filterOnSearch(searchText);
              }}
              value={searchText}
              qaAttribute="FileListMyFile"
              onSearch={filterOnSearch}
              placeholder="Filter any column..."
            />
          </div>
          {status === 'error' ? (
            <h3>Error in retrieving files</h3>
          ) : (
            <>
              <div className="grid-summary">
                <div className="files-list-grid">
                  <div className={`ag-theme-alpine table-grid ${classes.tableStyles}`}>
                    <AgGridReact
                      ref={gridRef}
                      onGridReady={() => {
                        gridRef.current?.api.showLoadingOverlay();
                      }}
                      components={{
                        statusCellRenderer: StatusRenderer,
                        fileNumberRenderer: FileNumberRenderer,
                        createDateRenderer: CreateDateRenderer,
                        summaryRenderer: ({ id }: any) => (
                          <PDFIcon
                            fontSize="small"
                            onClick={() => {
                              addSnackbarMessage({
                                message: `Generating PDF Summary...`,
                                type: 'success',
                              });
                              setSelectedFileId(id);
                            }}
                          />
                        ),
                        customOverlayLoading: CustomOverlayLoading,
                        customOverlayNoRows: CustomOverlayNoRows,
                        customFloatingFilter: FloatingFilterComponent,
                      }}
                      loadingOverlayComponent={'customOverlayLoading'}
                      noRowsOverlayComponent={'customOverlayNoRows'}
                      defaultColDef={{
                        cellClass: 'left-align-column',
                        headerClass: 'left-align-header',
                        sortable: true,
                        filter: 'agTextColumnFilter',
                        floatingFilter: true,
                        filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                        suppressMenu: true,
                        width: 180,
                        minWidth: 180,
                        resizable: true,
                        suppressSizeToFit: false,
                      }}
                      onFilterChanged={savedFilterModel}
                      domLayout="normal"
                    >
                      <AgGridColumn
                        field="status"
                        headerName="Status"
                        tooltipField="status"
                        cellRenderer="statusCellRenderer"
                        width={120}
                        minWidth={120}
                        filterParams={{
                          filterOptions: fileStatus.map((status) => ({
                            key: status.value,
                            displayKey: status.value,
                            displayName: status.name,
                            test: (filterValue: string, cellValue: string) => {
                              if (status.value.toLowerCase() === 'all') return true;
                              return cellValue?.toLowerCase() === status.value.toLowerCase();
                            },
                            hideFilterInput: true,
                          })),
                          disabled: true,
                        }}
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" data-qa="FileListStatusIcon" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListStatusSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                        floatingFilterComponent="customFloatingFilter"
                      />
                      <AgGridColumn
                        field="fileNumber"
                        floatingFilterComponent="customFloatingFilter"
                        width={220}
                        minWidth={220}
                        headerName="File Number"
                        tooltipField="fileNumber"
                        cellRendererParams={(row: ValueFormatterParams) => ({
                          id: row.data.fileId,
                          fileNumber: row.data.fileNumber,
                          accountNumber: row.data.accountNumber,
                        })}
                        cellRenderer="fileNumberRenderer"
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListFileNumberSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                        filterParams={{
                          qaAttribute: 'FileListFileNumber',
                          filterOptions: [
                            {
                              key: selectAllOption,
                              displayKey: selectAllOption,
                              displayName: selectAllOption,
                              test: () => true,
                            },
                          ],
                        }}
                      />
                      <AgGridColumn
                        field="address"
                        headerName="Property Address"
                        tooltipField="address"
                        width={300}
                        minWidth={300}
                        floatingFilterComponent="customFloatingFilter"
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListPropertyAddressSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                        filterParams={{
                          qaAttribute: 'FileListPropertyAddress',
                          filterOptions: [
                            {
                              key: 'contains',
                              displayKey: selectAllOption,
                              displayName: selectAllOption,
                              test: () => true,
                            },
                            ...statesList.map((state) => ({
                              key: 'contains',
                              displayKey: state.abbreviation,
                              displayName: state.abbreviation,
                              test: () => true,
                              hideFilterInput: true,
                            })),
                          ],
                        }}
                      />
                      <AgGridColumn
                        field="buyer"
                        headerName="Buyer"
                        tooltipField="buyer"
                        floatingFilterComponent="customFloatingFilter"
                        filterParams={{
                          qaAttribute: 'FileListBuyer',
                          filterOptions: [
                            {
                              key: 'contains',
                              displayKey: selectAllOption,
                              displayName: selectAllOption,
                              test: () => true,
                            },
                          ],
                          hideFilterButton: true,
                        }}
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListBuyerSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                      />
                      <AgGridColumn
                        field="office"
                        headerName="Office"
                        tooltipField="office"
                        minWidth={370}
                        floatingFilterComponent="customFloatingFilter"
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListOfficeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                        filterParams={{
                          qaAttribute: 'FileListOffice',
                          disabled: true,
                          filterOptions: [
                            {
                              officeId: selectAllOption,
                              name: selectAllOption,
                              address: { address1: '', address2: '', city: '', county: '', postalCode: '', state: '' },
                            },
                            ...offices,
                          ].map((office) => {
                            const officeDisplayName = office.name;
                            return {
                              key: office.officeId,
                              displayKey: officeDisplayName,
                              displayName: officeDisplayName,
                              test: (filterValue: string, cellValue: string) => true,
                              hideFilterInput: true,
                            };
                          }),
                        }}
                      />
                      <AgGridColumn
                        field="transactionType"
                        headerName="Transaction Type"
                        tooltipField="transactionType"
                        floatingFilterComponent="customFloatingFilter"
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListTransactionTypeSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                        filterParams={{
                          qaAttribute: 'FileListTransactionType',
                          disabled: true,
                          filterOptions: [{ name: selectAllOption, value: selectAllOption }, ...transactionTypes].map(
                            (status) => ({
                              key: status.value,
                              displayKey: status.value,
                              displayName: status.name,
                              test: () => true,
                              hideFilterInput: true,
                            }),
                          ),
                        }}
                      />
                      <AgGridColumn
                        field="openDate"
                        headerName="Created Date"
                        cellRenderer="createDateRenderer"
                        floatingFilterComponent="customFloatingFilter"
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListCreatedDateSort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                        tooltipValueGetter={dateTooltipValue}
                        filter="agDateColumnFilter"
                        filterParams={{
                          type: 'date',
                          qaAttribute: 'FileListCreatedDate',
                          filterOptions: [
                            {
                              displayKey: dateRangeFilters.equal,
                              displayName: dateRangeFilters.equal,
                              predicate: () => true,
                            },
                            {
                              displayKey: dateRangeFilters.greaterThan,
                              displayName: dateRangeFilters.greaterThan,
                              predicate: () => true,
                            },
                            {
                              displayKey: dateRangeFilters.lessThan,
                              displayName: dateRangeFilters.lessThan,
                              predicate: () => true,
                            },
                            {
                              key: 'inRange',
                              displayKey: 'inRange',
                              displayName: dateRangeFilters.inRange,
                              test: () => true,
                              numberOfInputs: 2,
                            },
                          ],
                          defaultOption: dateRangeFilters.equal,
                          comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
                            if (cellValue == null) return 0;
                            const cellDate = new Date(cellValue);
                            if (cellDate < filterLocalDateAtMidnight) {
                              return -1;
                            } else if (cellDate > filterLocalDateAtMidnight) {
                              return 1;
                            }
                            return 0;
                          },
                        }}
                        sort="desc"
                      />
                      <AgGridColumn
                        field="pdf"
                        headerName="Summary"
                        cellClass="center-align-column jacket-pdf-cell"
                        cellRenderer="summaryRenderer"
                        cellRendererParams={(row: ValueFormatterParams) => ({
                          id: row.data.fileId,
                          fileNumber: row.data.fileNumber,
                        })}
                        width={120}
                        minWidth={120}
                        filter={false}
                        floatingFilter={false}
                        pinned="right"
                        headerComponentParams={{
                          template: `<div class="ag-cell-label-container" role="presentation">
                                        <div ref="eLabel" class="ag-header-cell-label" role="presentation">
                                            <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                                            <span ref="eFilter" class="ag-header-icon ag-header-label-icon ag-filter-icon"></span>
                                            <span ref="eSortOrder" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden"></span>
                                            <span ref="eSortAsc" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon ag-hidden"></span>
                                            <span ref="eSortDesc" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon ag-hidden"></span>
                                            <span ref="eSortNone" data-qa="FileListSummarySort" class="ag-header-icon ag-header-label-icon ag-sort-none-icon ag-hidden"></span>
                                        </div>
                                    </div>`,
                        }}
                      />
                    </AgGridReact>
                  </div>
                </div>
              </div>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default FilesList;
