import { handleError } from 'utilities/utilities';
import { getApiHost } from 'api/host-by-env';
import { AddUser, EditUser, GetHistoryResponse } from 'features/admin/manageUsers/type';

export async function getUsersDetailList(token: string, usersInput: any): Promise<any> {
  const url = `${getApiHost()}firms/manageUsers/searchUsers`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(usersInput),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });

  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getUserActivityHistoryDetail(token: string, userId: any): Promise<GetHistoryResponse[]> {
  const url = `${getApiHost()}firms/manageUsers/history/${userId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function postUserActivationEmail(token: string, userId: any): Promise<any> {
  const url = `${getApiHost()}firms/manageUsers/activate/email`;
  const emailData = { UserId: userId };
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(emailData),
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
export async function postAddNewUser(payload: AddUser, token: string): Promise<any> {
  const url = `${getApiHost()}firms/manageUsers/user/new`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function EditNewUser(payload: EditUser, token: string): Promise<boolean> {
  const url = `${getApiHost()}firms//manageUsers/user/edit`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function getActivityRights(token: string): Promise<any> {
  const url = `${getApiHost()}firms/manageUsers/rights`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function searchFirmByDetails(token: string, firmSearch: any | null): Promise<any> {
  const url = `${getApiHost()}firms/search/officeDetails`;
  const searchData = {
    firmName: firmSearch.firmName,
    isActive: firmSearch.status,
    officeName: firmSearch.officeName,
    officeAddress: firmSearch.officeAddress,
  };
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(searchData),
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getUserDetailInfo(token: string, userId: any): Promise<any> {
  const url = `${getApiHost()}firms/manageUsers/details/${userId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getOfficeDetails(token: string, id: number): Promise<any> {
  const url = `${getApiHost()}firms/${id}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}
