import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import AgentNetButton from '../button/AgentNetButton';
import { useViewState } from 'hooks/ViewStateContext';
import { Tooltip } from '@material-ui/core';

interface BottomAppBarProps {
  showPrimary?: boolean;
  cancelLabel?: string;
  primaryActionLabel?: string;
  primaryLabel?: string;
  secondaryActionLabel?: string;
  secondaryFilled?: boolean;
  thirdActionLabel?: string;
  fourthActionLabel?: string;
  onCancel?: () => void;
  onPrimaryClick?: () => void;
  onSecondaryClick?: () => void;
  onThirdClick?: () => void;
  onFourthClick?: () => void;
  disableCancel?: boolean;
  disablePrimary?: boolean;
  disabledPrimaryHoverText?: string;
  disableSecondary?: boolean;
  disableThird?: boolean;
  disableFourth?: boolean;
  cancelDataQa?: string;
  primaryActionDataQa?: string;
  secondaryActionDataQa?: string;
  thirdActionDataQa?: string;
  fourthActionDataQa?: string;
  accordionView?: boolean;
  primaryButtonLoader?: boolean;
  hideCancel?: boolean;
  showFullWidth?: boolean;
}

const BottomAppBar = ({
  showPrimary = true,
  cancelLabel = 'Cancel',
  primaryActionLabel = '',
  secondaryActionLabel = '',
  primaryLabel = '',
  onCancel,
  onPrimaryClick,
  onSecondaryClick,
  secondaryFilled,
  disableCancel,
  disablePrimary,
  disabledPrimaryHoverText = '',
  disableSecondary,
  disableThird,
  disableFourth,
  cancelDataQa = '',
  primaryActionDataQa = '',
  secondaryActionDataQa = '',
  primaryButtonLoader = false,
  accordionView,
  thirdActionDataQa,
  thirdActionLabel,
  onThirdClick,
  fourthActionDataQa,
  fourthActionLabel,
  onFourthClick,
  hideCancel,
  showFullWidth,
}: BottomAppBarProps): JSX.Element => {
  const { menuCollapsed } = useViewState();
  const [drawerWidth, setDrawerWidth] = React.useState(menuCollapsed ? 123 : 359);
  React.useEffect(() => {
    if (showFullWidth) {
      setDrawerWidth(45);
    } else {
      setDrawerWidth(menuCollapsed ? 123 : 359);
    }
  }, [menuCollapsed]);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      footerWrap: {
        position: 'fixed',
        bottom: 0,
        background: '#E7F1FA',
        borderTop: `1px solid`,
        borderColor: theme.palette.divider,
        width: `calc(100% - ${drawerWidth}px)`,
        padding: `11px 24px`,
        display: 'flex',
        justifyContent: 'space-between',
        zIndex: 11,
      },
      hideCancelView: {
        justifyContent: 'flex-end',
      },
      footerActions: {
        '& .MuiButton-root': {
          marginLeft: theme.spacing(2),
        },
      },
      accordionView: {
        left: menuCollapsed ? '72px' : '311px',
      },
      cancelBtn: {
        backgroundColor: theme.palette.brand.lightGrey50,
      },
    }),
  );
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.footerWrap, {
        [classes.accordionView]: accordionView === true,
        [classes.hideCancelView]: hideCancel,
      })}
    >
      {!hideCancel && (
        <AgentNetButton
          variant="outlined"
          onClick={onCancel}
          disabled={disableCancel}
          Data-QA={cancelDataQa}
          className={classes.cancelBtn}
        >
          {cancelLabel}
        </AgentNetButton>
      )}
      <div className={classes.footerActions}>
        {fourthActionLabel && (
          <AgentNetButton
            variant={'outlined'}
            onClick={onFourthClick}
            Data-QA={fourthActionDataQa}
            disabled={disableFourth}
          >
            {fourthActionLabel}
          </AgentNetButton>
        )}
        {thirdActionLabel && (
          <AgentNetButton
            variant={'outlined'}
            onClick={onThirdClick}
            Data-QA={thirdActionDataQa}
            disabled={disableThird}
          >
            {thirdActionLabel}
          </AgentNetButton>
        )}
        {secondaryActionLabel && (
          <AgentNetButton
            variant={secondaryFilled ? 'contained' : 'outlined'}
            onClick={onSecondaryClick}
            Data-QA={secondaryActionDataQa}
            disabled={disableSecondary}
          >
            {secondaryActionLabel}
          </AgentNetButton>
        )}
        {primaryActionLabel && showPrimary && (
          <Tooltip title={disablePrimary ? disabledPrimaryHoverText : ''} arrow placement="top">
            <span>
              <AgentNetButton
                variant="contained"
                onClick={onPrimaryClick}
                Data-QA={primaryActionDataQa}
                disabled={disablePrimary}
                loading={primaryButtonLoader}
              >
                {primaryActionLabel}
              </AgentNetButton>
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export default BottomAppBar;
