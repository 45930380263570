import React, { useEffect, useRef, useState } from 'react'; // Add this line
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AgGridReact } from 'ag-grid-react';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { GetHistoryResponse } from './type';
import { getUserActivityHistoryDetail } from 'api/manageUsers/manage-users-api';
import NoResults from 'ui-kit/components/noResults/NoResults';
import { ColDef } from 'ag-grid-community';
import FloatingFilterComponent from 'features/files/files-summary/files-list/FloatingFilterComponent';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';

interface ViewHistoryProps {
  userId: number | undefined;
  handleDrawerOpen: boolean;
  closeDrawer: any;
}

const ViewHistory = ({ userId, handleDrawerOpen, closeDrawer }: ViewHistoryProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      gridContainer: {
        height: 'calc(110vh - 280px) !important',
        width: '100%',
        maxWidth: '100%',
        fontFamily: theme.typography.fontFamily,
        justifyContent: 'center',
        alignItems: 'center',
      },
    }),
  );
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const gridRef = useRef<any>(null);
  const { addGlobalMsg } = useGlobalMessages();
  const [rowData, setRowData] = useState<any>([]);
  const [errMsgs, setErrMsgs] = useState([]);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [gridApi, setGridApi] = useState<any>(null);

  const columnDefs: ColDef[] = [
    {
      field: 'Attribute',
      headerName: 'Attribute',
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 540,
      tooltipField: 'Attribute',
      flex: 1,
    },
    {
      field: 'Value',
      headerName: 'Value',
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 300,
      tooltipField: 'Value',
      flex: 1,
    },
    {
      field: 'Action',
      headerName: 'Action',
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 300,
      tooltipField: 'Action',
      flex: 1,
    },
    {
      field: 'ChangeBy',
      headerName: 'By',
      filter: 'agTextColumnFilter',
      sortable: true,
      width: 300,
      tooltipField: 'ChangeBy',
      flex: 1,
    },
    {
      field: 'ChangeDate',
      headerName: 'Date/Time',
      width: 300,
      filter: 'agDateColumnFilter',
      tooltipField: 'ChangeDate',
      filterParams: {
        suppressAndOrCondition: true,
        inRangeInclusive: true,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (cellValue == null) return 0;
          const dateParts = cellValue.split('/');
          const year = Number(dateParts[2].split(' ')[0]);
          const day = Number(dateParts[1]);
          const month = Number(dateParts[0]) - 1;
          const cellDate = new Date(year, month, day);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    },
  ];

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };
  const onFilterChanged = () => {
    if (gridApi?.getDisplayedRowCount() === 0) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }
  };
  const CustomOverlayNoRows = () => {
    return (
      <div>
        <NoResults
          showButton={false}
          type="results"
          titleText="No Results Found"
          pText="Try adjusting your filters to find what you’re looking for."
        />
      </div>
    );
  };
  useEffect(() => {
    executeGetHistory().then();
  }, []);

  const fetchPolicyUploadHistory = async (): Promise<GetHistoryResponse[]> => {
    const token = await getAccessToken();
    const response = await getUserActivityHistoryDetail(token, userId);
    return response;
  };

  const {
    execute: executeGetHistory,
    value: getHistoryValue,
    status: getHistoryStatus,
    errors: getHistoryError,
  } = useAsync<GetHistoryResponse[]>(fetchPolicyUploadHistory, false);

  useEffect(() => {
    setDisplayError(getHistoryStatus === 'error');
  }, [getHistoryStatus]);

  useEffect(() => {
    const errorMessages = getHistoryError?.length ? getHistoryError : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
        closeDrawer();
      });
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (Array.isArray(getHistoryValue)) {
      setRowData(getHistoryValue);
    } else {
      setRowData([]);
    }
  }, [getHistoryValue]);

  const actionStatus = getHistoryStatus == 'pending' ? 'pending' : 'idle';
  return (
    <>
      <FormDrawerComponent
        width={960}
        title="History"
        open={handleDrawerOpen}
        primaryActionLabel="Done"
        onPrimaryAction={() => closeDrawer()}
        onDismissAction={() => closeDrawer()}
        dismissActionProps={{
          hide: true,
          size: 'small',
        }}
      >
        <LoadingSpinner status={actionStatus} variant="linear" />
        {getHistoryStatus == 'success' && (
          <div className={`ag-theme-alpine ${classes.gridContainer}`}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={{
                cellClass: 'left-align-column',
                headerClass: 'left-align-header',
                sortable: true,
                filter: true,
                floatingFilter: true,
                filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                suppressMenu: true,
                resizable: true,
              }}
              onGridReady={onGridReady}
              components={{
                customDropdownFilter: FloatingFilterComponent,
                customOverlayNoRows: CustomOverlayNoRows,
              }}
              onFilterChanged={onFilterChanged}
              noRowsOverlayComponent={'customOverlayNoRows'}
            />
          </div>
        )}
      </FormDrawerComponent>
    </>
  );
};

export default ViewHistory;
