import React, { useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';
import SignatureCanvas from 'react-signature-canvas';
import { addSignatureMsg, agreementMsg, cautionText } from '../constants';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { AgentNetCheckBox } from 'ui-kit/inputs';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { postSignature, putSignature } from 'api/admin/signature-api';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { content } from 'features/serviceorder/constants';
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons';

interface EsignatureProps {
  isDrawerOpen: boolean;
  closeDrawer: any;
  sliderSignatureCtx: any;
  saveSuccess?: any;
}

const AddSignatureSliderFC: React.FC<EsignatureProps> = ({
  isDrawerOpen,
  closeDrawer,
  sliderSignatureCtx,
  saveSuccess,
}: EsignatureProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        padding: '0 0 24px 0',
      },
      sigContent: {
        border: '1px solid #D9E0E5',
        padding: 20,
      },
      paintArea: { borderBottom: '1px solid #D9E0E5' },
      clrBtn: {
        padding: '24px 0 0 24px',
        textAlign: 'end',
      },
      checkBoxStyle: {
        marginLeft: '-20px',
        marginBottom: '10px',
        display: 'flex',
        marginTop: theme.spacing(2),
        paddingLeft: '24px',
      },
      buttonStyle: { display: 'inline-box' },
    }),
  );

  const [sign, setSign] = useState<any>();
  const [isSearching, setSearchFlag] = useState(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [checkBoxValue, setCheckBox] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [checkBoxDisable, setCheckBoxDisable] = useState(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();

  //  Handle clearing signature
  const handleClear = () => {
    sign.clear();
  };

  const saveSignatureRequest = async (): Promise<any> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    if (sliderSignatureCtx.firmESignatureId == 0) {
      const signatureReq = {
        firmId: sliderSignatureCtx.firmId,
        signatoryId: sliderSignatureCtx.signatoryId,
        fullName: sliderSignatureCtx.fullName,
        eSignature: sliderSignatureCtx.eSignature,
        isESignatureEnable: true,
      } as any;
      return postSignature(signatureReq, token);
    } else {
      const signatureReq = {
        firmId: sliderSignatureCtx.firmId,
        signatoryId: sliderSignatureCtx.signatoryId,
        fullName: sliderSignatureCtx.fullName,
        eSignature: sliderSignatureCtx.eSignature,
        isESignatureEnable: true,
        firmESignatureId: sliderSignatureCtx.firmESignatureId,
      } as any;
      return putSignature(signatureReq, token);
    }
  };

  const {
    execute: executeSaveSignature,
    value: saveSignatureValue,
    status: saveSignatureStatus,
    errors: saveSignatureError,
  } = useAsync<any>(saveSignatureRequest, false);

  useEffect(() => {
    if (saveSignatureValue) {
      if (sliderSignatureCtx && sliderSignatureCtx.firmESignatureId == 0) {
        addSnackbarMessage({
          message: 'Signature Added Successfully',
          type: 'success',
        });
      } else {
        addSnackbarMessage({
          message: 'Signature Updated Successfully',
          type: 'success',
        });
      }
    }
  }, [saveSignatureValue]);

  useEffect(() => {
    setDisplayError(saveSignatureStatus === 'error');
  }, [saveSignatureStatus]);

  useEffect(() => {
    const errorMessages = saveSignatureError?.length ? saveSignatureError : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (sliderSignatureCtx) {
      sliderSignatureCtx.isESignatureEnable ? setCheckBox(true) : setCheckBox(false);
    }
  }, [sliderSignatureCtx]);

  useEffect(() => {
    if (sliderSignatureCtx) {
      if (checkBoxValue && sliderSignatureCtx.isESignatureEnable) {
        setCheckBoxDisable(true);
      }
    }
  }, [sliderSignatureCtx, checkBoxValue]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (saveSignatureStatus === 'success') {
      closeDrawer();
      saveSuccess(true);
      setSearchFlag(false);
    }
  });

  const saveSignature = () => {
    // setBinarySign(sign.getTrimmedCanvas().toDataURL('image/jpeg'));
    if (!sign.isEmpty()) {
      //Add padding to original canvas
      const originalCanvas = sign.getCanvas();
      const padding = 70; // Space to add on top and bottom
      const newCanvas = document.createElement('canvas');
      newCanvas.width = originalCanvas.width;
      newCanvas.height = originalCanvas.height + 2 * padding;

      const newContext = newCanvas.getContext('2d');
      if (newContext != null) {
        newContext.fillStyle = '#fff'; // Optional: Set background color if needed
        newContext.fillRect(0, 0, newCanvas.width, newCanvas.height);
        newContext.drawImage(originalCanvas, 0, padding);
      }

      // Save data to API
      // const binSign = sign.getTrimmedCanvas().toDataURL().split(',')[1];
      //const binSign = sign.getSignaturePad().toDataURL().split(',')[1];
      const binSign = newCanvas.toDataURL().split(',')[1];
      // update Binary Signature in the array
      sliderSignatureCtx.eSignature = binSign;
      executeSaveSignature().then();
    }
  };
  return (
    <>
      <div className="paper-layout contained">
        <LoadingSpinner status={isSearching ? 'pending' : 'success'} variant="linear" />
      </div>
      <FormDrawerComponent
        title={
          sliderSignatureCtx && sliderSignatureCtx.firmESignatureId == 0 ? 'Add New eSignature' : 'Edit eSignature'
        }
        open={isDrawerOpen}
        primaryActionLabel="Save"
        onPrimaryAction={saveSignature}
        disabled={!checkBoxValue}
        onDismissAction={() => closeDrawer()}
        width={770}
      >
        <>
          <Grid container>
            <Grid className={classes.content}>
              <Typography color="textSecondary">{addSignatureMsg}</Typography>
            </Grid>
            <Grid className={classes.sigContent}>
              <Grid item className={classes.paintArea} sm={12}>
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{ width: 680, height: 185, className: 'sigCanvas' }}
                  ref={(data: any) => setSign(data)}
                />
              </Grid>
              <Grid item className={classes.clrBtn} sm={12}>
                <AgentNetButton onClick={handleClear} variant="text" data-qa="clearSignature">
                  Clear
                </AgentNetButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            <Grid className={classes.checkBoxStyle}>
              <AgentNetCheckBox
                label={agreementMsg}
                name={'IsAggreed'}
                value={checkBoxValue}
                checkHandler={(val: any) => {
                  setCheckBox(val);
                }}
                required={true}
                disabled={checkBoxDisable}
              />
              <Grid className={classes.buttonStyle}>
                <AgentNetButton
                  color="primary"
                  size="medium"
                  variant="text"
                  //className={'button'}
                  onClick={() => setShowMore(!showMore)}
                  endIcon={showMore ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
                >
                  {showMore ? 'Show less' : 'Show more'}
                </AgentNetButton>
                <br />
              </Grid>
            </Grid>
            {showMore ? (
              <Typography variant="body1">
                {/* <br /> */}
                <strong>{content}</strong>
                <br />
                {cautionText}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </>
      </FormDrawerComponent>
    </>
  );
};

export default AddSignatureSliderFC;
