import React, { useEffect, useState } from 'react';
import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { AgentNetDropdownSelector, AgentNetTextInput } from 'ui-kit/inputs';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import ContactUsBG from '../../common-assets/images/Contact-Us-Banner.jpg';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { contactUsReasons } from 'features/constants';
import { contactUsSchema } from 'utilities/validation/schemas/contact-us-schema';
import { contactUsSendMessage } from 'api/contactUs/contactUs-api';
import PhoneField from 'ui-kit/inputs/PhoneField/PhoneField';
import { Notification } from 'ui-kit/components/notification/Notification';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      position: 'relative',
      width: '720px',
      height: '137px',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    image: { minHeight: '100%', width: '100%', position: 'absolute', top: 0 },
    imageOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: alpha(theme.palette.common.black, 0.55),
      zIndex: 1,
    },
    imageText: {
      zIndex: 2,
      color: theme.palette.common.white,
      textAlign: 'center',
      maxWidth: '35ch',
    },
    mainContainer: {
      background: theme.palette.common.white,
      paddingBottom: theme.spacing(4),
      overflowX: 'hidden',
    },
    textContainer: {
      margin: theme.spacing(3),
    },
    text: { maxWidth: '60ch' },
    table: { marginBottom: '1.5rem' },
    tableCell: {
      padding: 0,
      borderWidth: 0,
    },
    inputContainer: {
      margin: theme.spacing(5, 1),
    },
  }),
);

const ContactUs = (): JSX.Element => {
  const classes = useStyles();
  const [contactUsObj, setContactUsObj] = useState<any>({
    contactUsFullName: '',
    contactUsFirm: '',
    contactUsEmail: '',
    contactUsPhone: '',
    contactUsReason: '',
    contactUsMessage: '',
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false);
  const [showNotificationError, setShowNotificationError] = useState<boolean>(false);
  const handleSendMessage = async () => {
    setIsSubmitted(true);
    setShowNotificationSuccess(false);
    setShowNotificationError(false);
    if (!validationErrors || validationErrors.length === 0) {
      setIsSendingMessage(true);
      const requestObj = {
        fullName: contactUsObj.contactUsFullName,
        firm: contactUsObj.contactUsFirm,
        email: contactUsObj.contactUsEmail,
        phone: contactUsObj.contactUsPhone,
        reason: contactUsObj.contactUsReason,
        message: contactUsObj.contactUsMessage,
      };
      if (await contactUsSendMessage(requestObj)) {
        setIsSubmitted(false);
        setContactUsObj({
          contactUsFullName: '',
          contactUsFirm: '',
          contactUsEmail: '',
          contactUsPhone: '',
          contactUsReason: '',
          contactUsMessage: '',
        });
        setShowNotificationSuccess(true);
      } else {
        setShowNotificationError(true);
      }
      setIsSendingMessage(false);
    }
  };
  useEffect(() => {
    doValidate(contactUsObj, contactUsSchema(false)).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
  }, [contactUsObj]);

  return (
    <Grid className="small-content-wrap box-shadow">
      <Grid item xs={12}>
        <div className={classes.imageContainer} id="b-6">
          <img className={classes.image} src={ContactUsBG} alt="contact-us" />
          <div className={classes.imageOverlay}></div>
          <Typography variant="h1" className={classes.imageText}>
            We&apos;re here to help!
          </Typography>
        </div>
      </Grid>
      <div className="p-24">
        <h3>Contact Us</h3>
        <div className="flex-split m-20-0">
          <div className="w-100">
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label="Full Name"
              name="contactUsFullName"
              id="contactUsFullName"
              required
              Data-QA={'contactUsFullName'}
              value={contactUsObj.contactUsFullName}
              showValidation={isSubmitted}
              onChange={(event: any) => setContactUsObj({ ...contactUsObj, contactUsFullName: event.target.value })}
              errs={validationErrors}
            />
          </div>
          <div className="w-100">
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label="Firm"
              name="contactUsFirm"
              id="contactUsFirm"
              required
              Data-QA={'contactUsFirm'}
              value={contactUsObj.contactUsFirm}
              showValidation={isSubmitted}
              onChange={(event: any) => setContactUsObj({ ...contactUsObj, contactUsFirm: event.target.value })}
              errs={validationErrors}
            />
          </div>
        </div>
        <div className="flex-split m-20-0">
          <div className="w-100">
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label="Email"
              name="contactUsEmail"
              id="contactUsEmail"
              required
              Data-QA={'contactUsEmail'}
              value={contactUsObj.contactUsEmail}
              showValidation={isSubmitted}
              onChange={(event: any) => setContactUsObj({ ...contactUsObj, contactUsEmail: event.target.value })}
              errs={validationErrors}
            />
          </div>
          <div className="w-100">
            <PhoneField
              fullWidth
              variant="outlined"
              label="Phone"
              name="contactUsPhone"
              id="contactUsPhone"
              Data-QA={'contactUsPhone'}
              value={contactUsObj.contactUsPhone}
              showValidation={isSubmitted}
              onChange={(event: any) => setContactUsObj({ ...contactUsObj, contactUsPhone: event.target.value })}
              errs={validationErrors}
            />
          </div>
        </div>
        <div className="w-50 m-20-0">
          <AgentNetDropdownSelector
            name="contactUsReason"
            required
            showValidation={isSubmitted}
            label="What can we help you with?"
            options={contactUsReasons}
            value={contactUsObj.contactUsReason}
            id="contactUsReason"
            menuOption={(val: string) => setContactUsObj({ ...contactUsObj, contactUsReason: val })}
            qaAttribute="ContactUsReason"
            dropdowntype="outlined"
            errs={validationErrors}
          />
        </div>
        <div className="m-20-0">
          <AgentNetTextInput
            multiline
            fullWidth
            variant="outlined"
            label="Message"
            name="contactUsMessage"
            id="contactUsMessage"
            required
            Data-QA={'ContactUsMessage'}
            value={contactUsObj.contactUsMessage}
            showValidation={isSubmitted}
            onChange={(event: any) => setContactUsObj({ ...contactUsObj, contactUsMessage: event.target.value })}
            errs={validationErrors}
          />
        </div>
        <AgentNetButton
          color="primary"
          size="medium"
          className="button"
          variant="contained"
          onClick={handleSendMessage}
          loading={isSendingMessage}
          disabled={isSendingMessage}
        >
          Send Message
        </AgentNetButton>
        <div>
          {showNotificationError && (
            <Notification inline severity="error" msg="Unable to send message. Please try again." />
          )}
          {showNotificationSuccess && <Notification inline severity="success" msg="Your message has been sent." />}
        </div>
      </div>
    </Grid>
  );
};
export default ContactUs;
