import { UnderwritingRequest, UnderwritingRequestInformation } from './types';
import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Grid, Tooltip } from '@material-ui/core';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';

import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import AgentNetDropdownSelector, { SelectOption } from 'ui-kit/inputs/AgentNetDropdownSelector';
import { AgentNetTextInput, CurrencyField } from 'ui-kit/inputs';
import { HelpOutline } from '@material-ui/icons';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { RequestInformationSchema } from 'utilities/validation/schemas/underwriting-schema';
import { underwritingText } from './constants';
import clsx from 'clsx';
import DateFieldString from 'ui-kit/inputs/DateField/DateFieldString';
import { format as formatDate } from 'date-fns';
import moment from 'moment';

interface UnderwritingProps {
  uwr?: UnderwritingRequest;
  updateUnderwriting: any;
  estimatedDate: string | null;
  setEstimatedDate: (value: string | null) => void;
  submitAttempted: boolean;
  setSubmitAttempted: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmit: boolean;
  updateUwrErrorStatus: any;
  openDocumentDrawer: any;
  openReopenConformation: any;
  isUwrDisabled?: boolean;
  isDisabled?: boolean;
}

const UnderwritingRequestInformationFC: React.FC<UnderwritingProps> = ({
  uwr,
  updateUnderwriting,
  estimatedDate,
  setEstimatedDate,
  submitAttempted,
  setSubmitAttempted,
  isSubmit,
  updateUwrErrorStatus,
  openDocumentDrawer,
  openReopenConformation,
  isUwrDisabled,
  isDisabled,
}: UnderwritingProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      UnderwritingWrap: {
        padding: `0 2.4rem 8.8rem`,
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      ml12: {
        marginLeft: '12px',
      },
      fieldPadding: {
        marginTop: theme.spacing(2),
      },
      fieldPadding2: {
        marginTop: theme.spacing(2.5),
      },
      twoCol: {
        display: 'flex',
      },
      warningMsg: { marginBottom: theme.spacing(3) },
      dropDownWithToolTip: {
        display: `flex`,
      },
    }),
  );

  const actionName = isSubmit ? `Submit` : `Save`;
  const iconStyles = { marginLeft: '1.2rem', marginTop: '6px' };

  const mortgageModifiedOptions: RadioProps[] = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  const multisiteOptions: RadioProps[] = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  // Adding state variables
  const [transactionTypeOptions, setTransactionTypeOptions] = useState<SelectOption[]>([]);
  const [approverOptions, setapproverOptions] = useState<SelectOption[]>([]);

  // Form Validation State Vars
  const [showMandatoryField, setShowMandatoryField] = useState(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  // const { addSnackbarMessage } = useSnackBars();

  const validateErrors = async (uwrInfo: UnderwritingRequestInformation) => {
    const errs: FieldValidationError[] = (await doValidate(uwrInfo, RequestInformationSchema)) || [];

    setValidationErrors(errs);
    updateUwrErrorStatus('RequestInformation', errs?.length === 0);
  };

  useEffect(() => {
    const transactionTypeOptionsMap =
      uwr?.TemplateMetaData.TransactionTypes.map((x, i) => {
        return { name: x.Name, value: x.Name };
      }) ?? [];

    const approverOptionsMap =
      uwr?.TemplateMetaData.Approvers.map((x, i) => {
        return { name: x.Name, value: x.Name };
      }) ?? [];

    setTransactionTypeOptions(transactionTypeOptionsMap);
    setapproverOptions(approverOptionsMap);
  }, [uwr?.TemplateMetaData]);

  useEffect(() => {
    validateErrors(uwr?.RequestInformation || {});
  }, [uwr?.RequestInformation]);

  useEffect(() => {
    if (submitAttempted) {
      setShowMandatoryField(true);
      setSubmitAttempted(false);
    }
  }, [submitAttempted]);

  //   const { getAccessToken } = useAuth();
  //   const randomId = () => (Math.random() + 1).toString(36).substring(7);
  const classes = useStyles();

  const getFormattedDate = (input: string | Date | undefined) => {
    if (!input) {
      return '';
    }

    const padded = (num: number) => {
      return num < 10 ? '0' + num : '' + num;
    };

    try {
      const date = new Date(input);

      return `${padded(date.getMonth() + 1)}/${date.getDate()}/${date.getFullYear()}`;
    } catch {
      return '';
    }
  };

  const updateUnderwritingReqInfo = (field: string, value: any) => {
    const reqInfo = { ...uwr?.RequestInformation, [field]: value };
    updateUnderwriting('RequestInformation', reqInfo);
  };

  useEffect(() => {
    let time = '';
    if (estimatedDate) {
      time = new Date(estimatedDate !== null ? estimatedDate : '').toLocaleString();
    }
    time = moment(time).format('YYYY-MM-DD');
    updateUnderwritingReqInfo('EstimatedClosingDate', time);
  }, [estimatedDate]);

  return (
    <Container>
      <Grid container>
        {!isDisabled && (
          <Grid item sm={12}>
            <AgentNetDivider typoVariant="h2" title={'Underwriting Request Information'} disablePaddingX />
          </Grid>
        )}
        {isDisabled && (
          <Grid item sm={12}>
            <AgentNetDivider
              typoVariant="h2"
              title={'Underwriting Request Information'}
              disablePaddingX
              primaryButtonName="Reopen Request"
              onClickPrimary={(e: any) => {
                openReopenConformation();
              }}
              primaryButtonDisable={isUwrDisabled}
              buttonName="Add Document(s)"
              onClick={(e: any) => {
                openDocumentDrawer();
              }}
              disableButton={isUwrDisabled}
            />
          </Grid>
        )}
      </Grid>

      {(uwr?.RequestInformation?.RequestId ?? 0) != 0 && (
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <AgentNetTextInput
              fullWidth
              variant="standard"
              label="Request ID"
              name="RequestId"
              Data-QA={'UnderwritingRequestId'}
              value={uwr?.RequestInformation.RequestId ?? ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 18 },
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <AgentNetTextInput
              fullWidth
              variant="standard"
              label="Submitted Date"
              name="SubmittedDate"
              Data-QA={'UnderwritingSubmittedDate'}
              value={getFormattedDate(uwr?.RequestInformation.SubmittedDate)}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 18 },
              }}
            />
          </Grid>
          <Grid item sm={3}>
            <AgentNetTextInput
              fullWidth
              variant="standard"
              label="Request Status"
              name="RequestStatus"
              Data-QA={'UnderwritingRequestStatus'}
              value={uwr?.RequestInformation.RequestStatus ?? ''}
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 18 },
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item sm={3} className={classes.fieldPadding}>
          <AgentNetTextInput
            fullWidth
            variant="outlined"
            label="Request Name"
            name="RequestName"
            disabled={isUwrDisabled || isDisabled}
            Data-QA={'UnderwritingRequestName'}
            value={uwr?.RequestInformation.RequestName ?? ''}
            required={true}
            onChange={(event: any) => {
              updateUnderwritingReqInfo('RequestName', event.target.value);
            }}
            showValidation={showMandatoryField}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={3} className={classes.fieldPadding}>
          <DateFieldString
            fullWidth
            variant="outlined"
            label="Estimated Closing Date"
            name="EstimatedClosingDate"
            disabled={isUwrDisabled || isDisabled}
            Data-QA={'UnderwritingEstimatedClosingDate'}
            value={(() => {
              const dateParsed = Date.parse(estimatedDate || '');
              return dateParsed ? formatDate(dateParsed, 'MM/dd/yyyy') : estimatedDate;
            })()}
            required={true}
            onChange={(val: any) => {
              setEstimatedDate(val !== '' ? val : null);
            }}
            showValidation={showMandatoryField}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={3} className={classes.fieldPadding} style={{ display: 'flex' }}>
          <Grid item sm={12}>
            <CurrencyField
              fullWidth
              variant="outlined"
              label="Maximum Liability Amount"
              name="MaximumLiabilityAmount"
              id="MaximumLiabilityAmount"
              Data-QA={'UnderwritingMaximumLiabilityAmount'}
              value={uwr?.RequestInformation.MaximumLiabilityAmount}
              max={999999999}
              allowNegative={false}
              min={0}
              required={true}
              onChange={(event: any) => {
                updateUnderwritingReqInfo('MaximumLiabilityAmount', event.target.value);
              }}
              disabled={isUwrDisabled || isDisabled}
              showValidationOnBlur={showMandatoryField}
              alwaysDisplayError={showMandatoryField}
              errs={validationErrors}
            />
          </Grid>
          <Tooltip
            data-qa={'maxLiabililityToolTip'}
            title={underwritingText.MAX_LIABILITY_TOOLTIP_MSG}
            style={iconStyles}
          >
            <HelpOutline fontSize="large" color="primary" />
          </Tooltip>
        </Grid>
        <Grid item sm={3} className={classes.fieldPadding}>
          <AgentNetDropdownSelector
            label={'Transaction Type'}
            name={'TransactionType'}
            qaAttribute={'UnderwritingTransactionType'}
            options={transactionTypeOptions}
            value={uwr?.RequestInformation.TransactionType}
            menuOption={(val: string, name: any) => {
              updateUnderwritingReqInfo('TransactionType', val);
            }}
            disabled={isUwrDisabled || isDisabled}
            dropdowntype="outlined"
          />
        </Grid>
      </Grid>

      {uwr?.RequestInformation.TransactionType === 'Other Financing' && (
        <Grid container spacing={3} className={classes.fieldPadding}>
          <Grid item sm={6}>
            <AgentNetTextInput
              fullWidth
              variant="outlined"
              label="Describe Transaction Type"
              name="TransactionTypeDescription"
              Data-QA={'UnderwritingTransactionTypeDescription'}
              value={uwr?.RequestInformation.TransactionTypeDescription ?? ''}
              disabled={isUwrDisabled || isDisabled}
              required
              showValidation={showMandatoryField}
              errs={validationErrors}
              onChange={(event: any) => {
                updateUnderwritingReqInfo('TransactionTypeDescription', event.target.value);
              }}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        <Grid item sm={3} className={clsx('dropDownWithToolTip', classes.dropDownWithToolTip, classes.fieldPadding2)}>
          <AgentNetDropdownSelector
            label={'Assign To'}
            name={'AssignedTo'}
            qaAttribute={'UnderwritingAssignedTo'}
            options={approverOptions}
            value={uwr?.RequestInformation.AssignedTo ?? approverOptions[0]?.name}
            disabled={isUwrDisabled || uwr?.RequestInformation.RequestStatus === 'Submitted' ? true : isDisabled}
            required
            menuOption={(val: string, name: any) => {
              updateUnderwritingReqInfo('AssignedTo', val);
            }}
            dropdowntype="outlined"
            showValidation={showMandatoryField}
            errs={validationErrors}
          />
          <Tooltip data-qa={'assignToToolTip'} title={underwritingText.ASSIGN_TO_TOOLTIP_MSG} style={iconStyles}>
            <HelpOutline fontSize="large" color="primary" />
          </Tooltip>
        </Grid>
        <Grid item sm={3} className={classes.fieldPadding2}>
          <AgentNetTextInput
            fullWidth
            variant="outlined"
            label="Email Notification"
            name="EmailNotification"
            Data-QA={'UnderwritingEmailNotification'}
            value={uwr?.RequestInformation.EmailNotification ?? ''}
            onChange={(event: any) => {
              updateUnderwritingReqInfo('EmailNotification', event.target.value);
            }}
            disabled={isUwrDisabled || isDisabled}
          />
        </Grid>
        <Grid item sm={6} className={classes.twoCol}>
          <Grid item sm={6}>
            <RadioGroup
              options={mortgageModifiedOptions}
              onChange={(el: React.ChangeEvent<HTMLInputElement>) => {
                updateUnderwritingReqInfo('IsMortgageModified', el.target.value === 'true');
              }}
              label={'Mortgage Modification?'}
              selected={mortgageModifiedOptions.find((i) => {
                return i.value == `${uwr?.RequestInformation.IsMortgageModified}`;
              })}
              compact
              disabled={isUwrDisabled || isDisabled}
            />
          </Grid>
          <Grid item sm={5} className={classes.ml12}>
            <RadioGroup
              options={multisiteOptions}
              onChange={(el: React.ChangeEvent<HTMLInputElement>) => {
                updateUnderwritingReqInfo('IsMultisite', el.target.value === 'true');
              }}
              label={'Multisite?'}
              selected={multisiteOptions.find((i) => {
                return i.value == `${uwr?.RequestInformation.IsMultisite}`;
              })}
              compact
              disabled={isUwrDisabled || isDisabled}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.fieldPadding}>
        <AgentNetTextInput
          fullWidth
          multiline
          variant="outlined"
          label="Description"
          name="Description"
          Data-QA={'UnderwritingDescription'}
          value={uwr?.RequestInformation.Description ?? ''}
          onChange={(event: any) => {
            updateUnderwritingReqInfo('Description', event.target.value);
          }}
          disabled={isUwrDisabled || isDisabled}
        />
      </Grid>
    </Container>
  );
};

export default UnderwritingRequestInformationFC;
