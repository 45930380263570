import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
import { AgentNetDivider } from 'ui-kit/components/dividers/AgentNetDivider2';
import {
  Content,
  RegexAlphanumeric,
  RegexNumeric,
  StartnewBTSearch,
  docType,
  WindowFeatures,
  DocumentType,
  BackTitlePdfWindowName,
} from 'features/constants';
import './BackTitleContent.scss';
import { Grid, Typography } from '@material-ui/core';
import { AgentNetTextInput, AgentNetDropdownSelector } from 'ui-kit/inputs';
import DateField from 'ui-kit/inputs/DateField/DateFieldString';
import { Switch, FormControlLabel } from '@material-ui/core';
import { getDocument, getStatesCountiesData, performSearch, parseAddress } from '../../../api/backtitle/backtitle-api';
import { useAuth } from '@agentnet/auth';
import { CountiesType, StatesCountiesType } from 'api/backtitle/types';
import { AgGridReact } from 'ag-grid-react';
import NoResultFoundIcon from 'ui-kit/icons/NoResultFound';
import { DocumentRequest, DocumentResponseItem, ParseAddressRequest, SearchRequest } from 'api/backtitle/interfaces';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import StateSelectField, { stateType } from 'ui-kit/inputs/StateSelectField';
import { ColDef } from 'ag-grid-community';
import { openDocument } from 'utilities/utilities';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import GoogleAutocompleteSearch from 'ui-kit/inputs/GoogleAutocompleteSearch/GoogleAutocompleteSearch';
import { PlaceAddress } from 'ui-kit/inputs/GoogleAutocompleteSearch/Models';
import NoResults from 'ui-kit/components/noResults/NoResults';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { getFileInfoProps } from 'api/file/file-api';
import NoBacktitle from 'ui-kit/icons/NoBacktitle';
import { useViewState } from '../../../hooks/ViewStateContext';

const BackTitleContent = (): JSX.Element => {
  const [isFileView, setFileView] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      flexDirection: 'column',
      display: 'flex',
      marginTop: '11.4rem',
      width: '100%',
    },
    height: {
      height: '40rem',
    },
    pt2: {
      paddingTop: theme.spacing(2),
      marginTop: 0,
    },
    marginFileView: {
      marginTop: '0rem',
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    noResults: {
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      paddingTop: theme.spacing(4),
    },
    backTitleGridContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: !isFileView ? 'calc(100vh - 230px)' : 'calc(100vh - 330px)',
    },
  }));

  const { fileId } = useParams<getFileInfoProps>();
  const [backTitleSearchId, setBackTitleSearchId] = useState(0);
  const [isSearching, setSearchFlag] = useState(false);
  const content = Content;
  const startnewBTSearch = StartnewBTSearch;
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const [rowData, setRowData] = useState<DocumentResponseItem[]>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const [backTitleSearch, setBackTitleSearch] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [statesCountiesList, setStatesCountiesList] = useState<StatesCountiesType[]>([]);
  const [statesList, setStatesList] = useState<stateType[]>([]);
  const [countiesList, setCountiesList] = useState<CountiesType[]>([]);
  const [showContent, setShowContent] = useState(true);
  const [dialog, setDialog] = useState('');
  const [isError, setError] = useState(false);
  const { addSnackbarMessage } = useSnackBars();
  const { addGlobalMsg } = useGlobalMessages();
  const qaAttributePrefix = 'BackTitle';
  const properties = window.sessionStorage.getItem('properties');
  const parsedProperty = properties ? JSON.parse(properties) : null;
  const getPropertyFromSession = parsedProperty ? parsedProperty[0] : null;
  const isFileIdExist = fileId !== null && fileId !== undefined && fileId !== '';
  const propertyData = isFileIdExist ? getPropertyFromSession : null;
  const [fileRestricted, setFileRestricted] = useState(false);
  const { routerParamValue, setRouterParamValue } = useViewState();

  useEffect(() => {
    setRouterParamValue(fileId || '');
  }, [routerParamValue, setRouterParamValue]);

  const defaultFields = {
    address1: '',
    apn: '',
    city: '',
    state: '',
    zip: '',
    county: '',
    streetNumber: '',
    streetName: '',
    block: '',
    platBook: '',
    platPage: '',
    fileNumber: '',
    acreage: '',
    lastNameEntity: '',
    district: '',
    policyNumber: '',
    subdivision: '',
    policyDateFrom: '',
    policyDateTo: '',
    briefLegal: '',
    unitNumber: '',
  };

  const [searchFields, setSearchFields] = useState(defaultFields);
  const previousSearchFields = React.useRef(searchFields).current;

  const modifySearch = () => {
    setSearchFlag(false);
    setBackTitleSearch(false);
  };

  const loadData = async () => {
    setSearchFlag(true);
    resetForm();
    if (isFileIdExist) {
      setShowContent(false);
      setBackTitleSearch(true);
      setFileView(true);
      if (propertyData !== null) {
        await streetDetails(
          {
            address1: propertyData?.address1 ?? '',
            zipcode: propertyData?.postalCode ?? '',
            state: propertyData?.state ?? '',
            city: propertyData?.city ?? '',
            county: propertyData?.county ?? '',
          },
          propertyData?.country ?? '',
          true,
        );
        await search(true);
        setShowContent(true);
        setSearchFlag(false);
        fetchStatesCountiesList();
      }
    } else {
      fetchStatesCountiesList();
    }
  };

  const isEqual = (obj1: any, obj2: any): boolean => {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);

    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }

    for (const objKey of obj1Keys) {
      if (obj1[objKey] !== obj2[objKey]) {
        return false;
      }
    }
    return true;
  };

  const [showBottomAppBar, setShowBottomAppBar] = useState(false);
  useEffect(() => {
    if (!isEqual(previousSearchFields, searchFields)) {
      setShowBottomAppBar(true);
    }
  }, [searchFields]);

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (propertyData !== null) {
      setCounties(propertyData?.state ?? '');
      setSearchFields({
        ...searchFields,
        county: propertyData?.county ?? '',
      });
    }
  }, [statesCountiesList]);

  const isStateExist = isFileView && !!searchFields.state;
  const resetForm = () => {
    setSearchFields((prevFields) => ({
      ...defaultFields,
      state: isStateExist ? prevFields.state : '',
    }));
    setSearchFlag(false);
    setBackTitleSearch(false);
    setError(false);
    if (!isStateExist) setCountiesList([]);
  };

  const validateSearchCriteria = (): boolean => {
    const backTitleSearchFields = Object.values(searchFields).filter((e) => {
      return e !== '' && e !== null;
    });

    if (backTitleSearchFields.length <= 0) {
      setBackTitleSearch(false);
      setError(true);
      return false;
    }

    if (searchFields.state === undefined || searchFields.state === '' || searchFields.state === null) {
      setError(true);
      return false;
    }

    if (searchFields.state && backTitleSearchFields.length < 2) {
      addSnackbarMessage({
        message: 'Please enter at least 1 Search criteria value in addition to State.',
        type: 'warning',
        duration: 1000,
      });
      setBackTitleSearch(false);
      return false;
    }

    if (
      searchFields.policyDateTo != null &&
      searchFields.policyDateFrom != null &&
      searchFields.policyDateFrom != '' &&
      searchFields.policyDateTo != ''
    ) {
      if (searchFields.policyDateTo < searchFields.policyDateFrom) {
        addSnackbarMessage({
          message: "The 'To' date must be greater than or equal to the 'From' date ",
          type: 'warning',
        });
        setBackTitleSearch(false);
        return false;
      }
    }
    return true;
  };

  const SearchBackTitle = () => {
    setSearchFlag(true);
    if (validateSearchCriteria()) {
      search(false);
      setError(false);
    } else {
      setSearchFlag(false);
    }
  };

  const setField = (field: string, value: any) => {
    setSearchFields({
      ...searchFields,
      [field]: value,
    });
  };

  const keyPress = (e: React.KeyboardEvent) => {
    const regex = new RegExp(RegexAlphanumeric);
    if (regex.test(e.key)) return true;
    e.preventDefault();
    return false;
  };

  const keyPressnumber = (e: React.KeyboardEvent) => {
    const regex = new RegExp(RegexNumeric);
    if (regex.test(e.key)) return true;
    e.preventDefault();
    return false;
  };

  const fetchStatesCountiesList = async () => {
    const token = await getAccessToken();
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }
    const result = await getStatesCountiesData(token, firmId).catch((error) => {
      console.error('Error in fetching states & counties:', error);
      setSearchFlag(false);
    });
    if (result != null) {
      setStatesList([]);
      setStatesCountiesList(result);
      const statesList: stateType[] = [];
      result.forEach((row) => {
        if (statesList.filter((e) => e.name === row.StateCode).length == 0) {
          statesList.push({ abbreviation: row.StateCode, name: row.StateCode });
        }
      });
      setStatesList(statesList);
      setSearchFlag(false);
    }
    setSearchFlag(false);
  };
  const streetDetails = async (place: PlaceAddress, county: string, isFileLoad: boolean) => {
    setSearchFlag(true);
    const token = await getAccessToken();
    const payload: ParseAddressRequest = {
      address1: place.address1,
      streetNumber: '',
      streetName: '',
    };
    const result = await parseAddress(token, payload).catch((error) => {
      console.error('Error in parsing Address1:', error);
    });
    if (result != null) {
      if (isFileLoad) {
        setSearchFields({
          ...searchFields,
          address1: result.StreetName ?? '',
          zip: '',
          state: place?.state ?? '',
          city: place?.city ?? '',
          county: county ?? '',
          streetName: result.StreetName,
          streetNumber: '',
        });
        if (propertyData) {
          propertyData.address1 = result.StreetName;
          propertyData.postalCode = '';
        }
      } else {
        setSearchFields({
          ...searchFields,
          address1: place?.address1 ?? '',
          zip: place?.zipcode ?? '',
          state: place?.state ?? '',
          city: place?.city ?? '',
          county: county ?? '',
          streetName: result.StreetName,
          streetNumber: result.StreetNumber,
        });
      }
    }
    setSearchFlag(false);
  };

  const formatZip = (zip: string | null): string => {
    if (zip) {
      return zip.split('-')[0];
    }
    return '';
  };

  const search = async (isFirstLoad: boolean) => {
    const token = await getAccessToken();
    let firmId = null;
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = parseInt(userFirm.firmId);
    }

    let streetName = '';
    let streetNumber = '';

    if (advancedSearch) {
      streetName = searchFields.streetName;
      streetNumber = searchFields.streetNumber;
    }

    const payload: SearchRequest = isFirstLoad
      ? {
          ...searchFields,
          firmId: firmId,
          fileId: fileId,
          address1: propertyData?.address1 ?? '',
          zip: formatZip(propertyData?.postalCode) ?? '',
          state: propertyData?.state ?? '',
          city: propertyData?.city ?? '',
          county: propertyData?.county ?? '',
          streetNumber: '',
          streetName: '',
          fileCounty: propertyData?.county ?? '',
        }
      : {
          ...searchFields,
          firmId: firmId,
          fileId: fileId,
          streetName: streetName,
          streetNumber: streetNumber,
          fileCounty: propertyData?.county ?? '',
          zip: formatZip(searchFields.zip),
        };
    const result = await performSearch(token, payload).catch((error) => {
      console.error('Error in fetching states & counties:', error);
      addGlobalMsg({
        message: 'System Error. Please try again later',
        type: 'error',
      });
    });
    if (result != null) {
      if (result.IsFileRestricted) {
        setFileRestricted(true);
        setRowData([]);
        setSearchFlag(false);
      } else {
        if (result.BackTitleDocuments.length > 200) {
          setBackTitleSearchId(result.BackTitleSearchId ?? 0);
          setRowData(result.BackTitleDocuments);
          addSnackbarMessage({
            message:
              'Your search returned more records than displayed. Please narrow your search criteria and try again if you do not find what you are searching for.',
            type: 'warning',
            duration: 1000,
          });
        } else {
          setBackTitleSearchId(result.BackTitleSearchId ?? 0);
          setRowData(result.BackTitleDocuments);
        }
      }
    } else {
      setRowData([]);
    }
    setBackTitleSearch(true);
    setSearchFlag(false);
  };

  const fetchDocument = async (docType: string, docGuid: string) => {
    setSearchFlag(true);
    const token = await getAccessToken();
    let firmId = null;
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = parseInt(userFirm.firmId);
    }
    const payload: DocumentRequest = {
      backTitleSearchId: backTitleSearchId,
      firmId: firmId,
      documentType: docType,
      docGuid: docGuid,
      fileId: fileId,
    };
    const result = await getDocument(token, payload).catch((error) => {
      console.error('Error in getting document :', error);
      addGlobalMsg({
        message: 'System Error. Please try again later',
        type: 'error',
      });
    });
    if (result) {
      if (result.Message) {
        addSnackbarMessage({
          message: result.Message,
          type: 'warning',
          duration: 1000,
        });
      } else {
        if (docType == 'Legal Desc') {
          setDialog(result.Content);
        } else {
          openDocument(result.Content, DocumentType, BackTitlePdfWindowName, WindowFeatures);
        }
      }
    } else {
      addGlobalMsg({
        message: 'System Error. Please try again later',
        type: 'error',
      });
    }
    setSearchFlag(false);
  };

  const prioritizeStateOptions = (states: Array<{ name: string; value: string }>) => {
    const currentStateList: {
      name: string;
      value: string;
    }[] = [];
    const remainingStateList: {
      name: string;
      value: string;
    }[] = [];

    const stateArray: stateType[] =
      isStateExist || (!isFileView && searchFields.state !== '')
        ? [{ abbreviation: searchFields.state, name: searchFields.state }]
        : [...statesList];
    for (const state of states) {
      if (
        state?.name
          ?.split(',')
          .some((addressItem) =>
            stateArray.find((x) => x.abbreviation.toLowerCase() === addressItem.toLowerCase().trim()),
          )
      ) {
        currentStateList.push(state);
      } else {
        remainingStateList.push(state);
      }
    }
    return [...currentStateList];
  };
  const columnDefs: ColDef[] = [
    {
      field: 'DocumentDescription',
      headerName: 'Document',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: any) => {
        const handleClick = () => {
          fetchDocument(params.data.PolicyType, params.data.DocGuid);
          console.log(params.data.id);
        };

        return (
          <div className="documentName" onClick={handleClick}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: 'DocumentType',
      headerName: 'Doc. Type',
      filter: 'agTextColumnFilter',
      filterParams: {
        docType: docType.sort((a, b) => (a.name > b.name ? 1 : -1)),
        filterOptions: docType.slice(0).map((docType) => ({
          key: docType.value,
          displayKey: docType.value,
          displayName: docType.name,
          test: (filterValue: string, cellValue: string) => {
            if (docType.value.toLowerCase() === 'all') return true;
            return cellValue?.toLowerCase() === docType.value.toLowerCase();
          },
          hideFilterInput: true,
        })),
        disabled: true,
      },
    },
    { field: 'PropertyAddress', headerName: 'Property Address', filter: 'agTextColumnFilter' },
    { field: 'Owners', headerName: 'Last Name/Entity', filter: 'agTextColumnFilter' },
    { field: 'CondoSub', headerName: 'Condo/Subdivision', filter: 'agTextColumnFilter' },
    { field: 'UnitNumber', headerName: 'Unit/Lot', filter: 'agTextColumnFilter' },
    {
      field: 'FormattedPolicyEffectiveDate',
      headerName: 'Order Date',
      filter: 'agDateColumnFilter',
      filterParams: {
        suppressAndOrCondition: true,
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          if (cellValue == null) return 0;
          const dateParts = cellValue.split('/');
          const year = Number(dateParts[2]);
          const day = Number(dateParts[1]);
          const month = Number(dateParts[0]) - 1;
          const cellDate = new Date(year, month, day);
          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          } else {
            return 0;
          }
        },
      },
    },
  ];

  const onGridReady = (params: any) => {
    const defaultSortModel = [{ colId: 'FormattedPolicyEffectiveDate', sort: 'desc', sortIndex: 1 }];
    params.columnApi.applyColumnState({ state: defaultSortModel });
  };

  const allColumnIds = useState([]);
  const autoSizeGrid = (params: any) => {
    params.columnApi.getAllColumns().forEach(function (column: { colId: any }) {
      allColumnIds.push(column.colId);
    });
    params.columnApi.autoSizeColumns(allColumnIds);
  };

  const setCounties = (value: any) => {
    if (value === null || value === undefined || value === '') {
      setSearchFields({
        ...searchFields,
        state: '',
        county: '',
      });
      return;
    }
    const countiesList: CountiesType[] = [];
    setCountiesList([]);
    statesCountiesList?.map((row) => {
      if (row.StateCode === value) {
        countiesList.push({ name: row.CountyName, value: row.CountyName });
      }
    });
    setCountiesList(countiesList);
  };
  return (
    <>
      <div className={showContent ? '' : 'loading-container'}>
        <LoadingSpinner status={showContent ? '' : 'pending'} size={30} />
      </div>
      {showContent && (
        <div
          className={clsx(
            classes.root,
            { [classes.height]: isFileView && rowData?.length <= 0 },
            { [classes.marginFileView]: isFileView },
          )}
        >
          <LoadingSpinner status={isSearching ? 'pending' : 'success'} variant="linear" />
          <AgentNetConfirmationDialog
            onConfirm={() => {
              navigator.clipboard.writeText(dialog);
              addSnackbarMessage({
                message: 'Copied to clipboard',
                type: 'success',
                duration: 3000,
              });
            }}
            open={dialog !== ''}
            onDismissAction={() => setDialog('')}
            dialogTitle="Legal Description"
            dialogBtnContent="Copy Text"
            dialogText={dialog}
            size="md"
            textArea
          />
          {backTitleSearch ? (
            <div className="full-width">
              <div>
                <AgentNetDivider title="Back Title Search Results" />
                <div className="backtitle-search-result">
                  {rowData?.length > 0 ? (
                    <div className={classes.backTitleGridContainer}>
                      <div className="ag-theme-alpine table-grid back-title-grid">
                        <AgGridReact
                          rowData={rowData}
                          columnDefs={columnDefs}
                          defaultColDef={{
                            cellClass: 'left-align-column',
                            headerClass: 'left-align-header',
                            sortable: true,
                            filter: true,
                            floatingFilter: true,
                            filterParams: { closeOnApply: true, suppressAndOrCondition: true },
                            suppressMenu: true,
                            resizable: true,
                          }}
                          onFirstDataRendered={autoSizeGrid}
                          onGridReady={onGridReady}
                        ></AgGridReact>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Typography variant="body1" color="textSecondary" className="p-b-25">
                        {content}
                      </Typography>
                      {isFileView && fileRestricted ? (
                        <>
                          <div className={clsx(classes.noResults)}>
                            <div className={classes.mb3}>{<NoBacktitle />}</div>
                            <Typography variant="h3" className={classes.mb1}>
                              {
                                'Back Title searches are not available for this county and/or state. Please contact your local First American representative.'
                              }
                            </Typography>
                          </div>
                        </>
                      ) : isFileView && !fileRestricted ? (
                        <>
                          <NoResults
                            type="results"
                            titleText="No Back Title Results Found"
                            pText="We could not find any documents associated with the address on this file. Would you like to modify this search?"
                            buttonText="Modify Search"
                            dataQA={qaAttributePrefix + 'ModifySearch'}
                            onClick={() => {
                              modifySearch();
                            }}
                            className={classes.pt2}
                          />
                        </>
                      ) : (
                        <div className="no-results-msg">
                          <NoResultFoundIcon className="no-backtitles" />
                          <Typography variant="h3">No Back Title Results Found</Typography>
                          <Typography variant="body1">{startnewBTSearch}</Typography>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              {isFileView && rowData?.length <= 0 ? null : (
                <BottomAppBar
                  cancelLabel="Modify Search"
                  cancelDataQa={qaAttributePrefix + 'ModifySearch'}
                  onCancel={modifySearch}
                  primaryActionLabel="Start New Search"
                  primaryActionDataQa={qaAttributePrefix + 'StartNewSearch'}
                  onPrimaryClick={resetForm}
                />
              )}
            </div>
          ) : (
            <>
              <div>
                <div className="backtitle-search-container">
                  <AgentNetDivider title="Back Title Search" message="*required" />
                  <div className="backtitle-search-content">
                    <Typography variant="body1" color="textSecondary" className="p-b-25">
                      {content}
                    </Typography>
                    <Grid container spacing={3} className="searchFieldsWrap">
                      {advancedSearch ? (
                        <>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Street #"
                              name="streetNumber"
                              Data-QA={qaAttributePrefix + 'StreetNumber'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 10 }}
                              value={searchFields.streetNumber}
                              onChange={(e) => {
                                setField('streetNumber', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Street Name"
                              name="streetName"
                              Data-QA={qaAttributePrefix + 'StreetName'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 45 }}
                              value={searchFields.streetName}
                              onChange={(e) => {
                                setField('streetName', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="APN"
                              name="apn"
                              Data-QA={qaAttributePrefix + 'APN'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 25 }}
                              value={searchFields.apn}
                              onChange={(e) => {
                                setField('apn', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Last Name/Entity"
                              name="lastNameEntity"
                              Data-QA={qaAttributePrefix + 'LastNameEntity'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 25 }}
                              value={searchFields.lastNameEntity}
                              onChange={(e) => {
                                setField('lastNameEntity', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="City"
                              name="city"
                              Data-QA={qaAttributePrefix + 'City'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 30 }}
                              value={searchFields.city}
                              onChange={(e) => {
                                setField('city', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <StateSelectField
                              name="state"
                              label="State"
                              Data-QA={qaAttributePrefix + 'State'}
                              stateOptions={statesList}
                              value={searchFields.state}
                              onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setField('state', typeof e === 'string' ? e : e.target.value);
                                setCounties(e);
                              }}
                              required
                              error={isError}
                              disabled={isFileView}
                              helperText={isError && 'State is a required field'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Zip Code"
                              name="zip"
                              Data-QA={qaAttributePrefix + 'Zip'}
                              onKeyPress={keyPressnumber}
                              inputProps={{ maxLength: 9 }}
                              value={searchFields.zip}
                              onChange={(e) => {
                                setField('zip', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetDropdownSelector
                              name="county"
                              label="County"
                              Data-QA={qaAttributePrefix + 'County'}
                              options={countiesList}
                              value={searchFields.county}
                              menuOption={(e) => {
                                setField('county', e);
                              }}
                              dropdowntype="outlined"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Condo/Subdivision Name"
                              name="subdivision"
                              Data-QA={qaAttributePrefix + 'CondoSubDivisionName'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 50 }}
                              value={searchFields.subdivision}
                              onChange={(e) => {
                                setField('subdivision', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Unit/Lot #"
                              name="unitNumber"
                              Data-QA={qaAttributePrefix + 'UnitLotNumber'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 50 }}
                              value={searchFields.unitNumber}
                              onChange={(e) => {
                                setField('unitNumber', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Block/Square"
                              name="block"
                              Data-QA={qaAttributePrefix + 'BlockSquare'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 30 }}
                              value={searchFields.block}
                              onChange={(e) => {
                                setField('block', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Section/Acreage"
                              name="acreage"
                              Data-QA={qaAttributePrefix + 'SectionAcreage'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 20 }}
                              value={searchFields.acreage}
                              onChange={(e) => {
                                setField('acreage', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="District"
                              name="district"
                              Data-QA={qaAttributePrefix + 'District'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 30 }}
                              value={searchFields.district}
                              onChange={(e) => {
                                setField('district', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Plat Book"
                              name="platBook"
                              Data-QA={qaAttributePrefix + 'PlatBook'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 20 }}
                              value={searchFields.platBook}
                              onChange={(e) => {
                                setField('platBook', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Plat Page"
                              name="platPage"
                              Data-QA={qaAttributePrefix + 'PlatPage'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 10 }}
                              value={searchFields.platPage}
                              onChange={(e) => {
                                setField('platPage', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Brief Legal"
                              name="briefLegal"
                              Data-QA={qaAttributePrefix + 'BriefLegal'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 75 }}
                              value={searchFields.briefLegal}
                              onChange={(e) => {
                                setField('briefLegal', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="File #"
                              name="fileNumber"
                              Data-QA={qaAttributePrefix + 'FileNumber'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 50 }}
                              value={searchFields.fileNumber}
                              onChange={(e) => {
                                setField('fileNumber', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Policy #"
                              name="policyNumber"
                              Data-QA={qaAttributePrefix + 'PolicyNumber'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 50 }}
                              value={searchFields.policyNumber}
                              onChange={(e) => {
                                setField('policyNumber', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <DateField
                              name="policyDateFrom"
                              label="Policy Date - From"
                              qaAttribute={qaAttributePrefix + 'PolicyDateFrom'}
                              disableFuture
                              value={searchFields.policyDateFrom}
                              onChange={(e: any) => {
                                setField('policyDateFrom', e);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <DateField
                              name="policyDateTo"
                              label="Policy Date - To"
                              qaAttribute={qaAttributePrefix + 'PolicyDateTo'}
                              minDate={searchFields.policyDateFrom}
                              disableFuture
                              value={searchFields.policyDateTo}
                              onChange={(e: any) => {
                                setField('policyDateTo', e);
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} sm={6}>
                            <GoogleAutocompleteSearch
                              variant="outlined"
                              fullWidth
                              required={false}
                              label="Address 1"
                              id="address1"
                              name="address1"
                              Data-QA={qaAttributePrefix + 'Address1'}
                              firstLoad={false}
                              orderOptions={prioritizeStateOptions}
                              value={searchFields.address1 ?? ''}
                              onChange={(e) => {
                                setField('address1', e.target.value);
                              }}
                              {...(isStateExist || (!isFileView && searchFields.state !== '')
                                ? { stateConstraint: searchFields.state }
                                : {})}
                              onPlaceSelect={(place) => {
                                if (
                                  statesList.find((x) => x.abbreviation.toLowerCase() === place?.state?.toLowerCase())
                                ) {
                                  let county = '';
                                  setCounties(place?.state ?? '');
                                  if (place && place.county) {
                                    county = place.county.split(' County')[0].toUpperCase();
                                  }
                                  streetDetails(place, county, false);
                                } else {
                                  setSearchFields({
                                    ...searchFields,
                                    address1: '',
                                    zip: '',
                                    state: '',
                                    city: '',
                                    county: '',
                                    streetName: '',
                                    streetNumber: '',
                                  });
                                }
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="APN"
                              name="apn"
                              Data-QA={qaAttributePrefix + 'APN'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 25 }}
                              value={searchFields.apn}
                              onChange={(e) => {
                                setField('apn', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Last Name/Entity"
                              name="lastNameEntity"
                              Data-QA={qaAttributePrefix + 'LastNameEntity'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 25 }}
                              value={searchFields.lastNameEntity}
                              onChange={(e) => {
                                setField('lastNameEntity', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="City"
                              name="city"
                              Data-QA={qaAttributePrefix + 'City'}
                              onKeyPress={keyPress}
                              inputProps={{ maxLength: 30 }}
                              value={searchFields.city}
                              onChange={(e) => {
                                setField('city', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <StateSelectField
                              name="state"
                              label="State"
                              Data-QA={qaAttributePrefix + 'State'}
                              stateOptions={statesList}
                              value={searchFields.state}
                              onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                setField('state', typeof e === 'string' ? e : e.target.value);
                                setCounties(e);
                              }}
                              required
                              disabled={isFileView}
                              error={isError}
                              helperText={isError && 'State is a required field'}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetTextInput
                              variant="outlined"
                              label="Zip"
                              name="zip"
                              Data-QA={qaAttributePrefix + 'Zip'}
                              onKeyPress={keyPressnumber}
                              inputProps={{ maxLength: 9 }}
                              value={searchFields.zip}
                              onChange={(e) => {
                                setField('zip', e.target.value);
                              }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <AgentNetDropdownSelector
                              name="county"
                              label="County"
                              Data-QA={qaAttributePrefix + 'County'}
                              options={countiesList}
                              value={searchFields.county}
                              menuOption={(e) => {
                                setField('county', e);
                              }}
                              dropdowntype="outlined"
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={advancedSearch}
                          onChange={() => {
                            if (advancedSearch) {
                              setSearchFields({
                                ...searchFields,
                              });
                            } else {
                              setSearchFields({
                                ...searchFields,
                              });
                            }
                            setAdvancedSearch(!advancedSearch);
                          }}
                          name="advancedSearch"
                        />
                      }
                      label={'Advanced Search'}
                      data-qa={qaAttributePrefix + 'AdvancedSearch'}
                      value={advancedSearch}
                      className="form-control-label"
                    />
                  </div>
                </div>
                {showBottomAppBar && (
                  <BottomAppBar
                    primaryActionLabel="Search"
                    primaryActionDataQa={qaAttributePrefix + 'Search'}
                    cancelLabel="Clear All"
                    cancelDataQa={qaAttributePrefix + 'ClearAll'}
                    onCancel={resetForm}
                    onPrimaryClick={SearchBackTitle}
                  />
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default BackTitleContent;
