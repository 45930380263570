import {
  ApprovedAttorney,
  CancelUnderwritingRequest,
  CancelUnderwritingResponse,
  ClosingAttorney,
  GetUnderwritingDocumentResponse,
  GetUnderwritingResponse,
  LetterType,
  ReopenUnderwritingRequest,
  ReopenUnderwritingResponse,
  SaveAdditionalDocumentResponse,
  SaveUnderwritingResponse,
  SecondPartyDto,
  UnderwritingDocument,
  UnderwritingRequest,
} from 'features/underwriting/types';
import { getAgencyApiHost, getApiHost } from './host-by-env';
import { handleError } from 'utilities/utilities';
import { fileInfoType } from './file/interfaces/get-file';

export async function getUnderwritingRequest(
  fileId: string,
  accountNumber: number,
  stateCode: string,
  token: string,
): Promise<GetUnderwritingResponse> {
  const url = `${getApiHost()}underwriting/request/${fileId}/${accountNumber}/${stateCode}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function getDecryptedFileId(fileId: string, token: string) {
  const url = `${getApiHost()}file/${fileId}/decrypt`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function saveUnderwritingRequest(
  fileId: number,
  accountNumber: number,
  underwritingRequest: UnderwritingRequest | undefined,
  token: string,
): Promise<SaveUnderwritingResponse> {
  const url = `${getAgencyApiHost()}underwriting-service/v1/request`;
  const postUnderwritingData = {
    fileId: fileId,
    accountNumber: accountNumber,
    underwritingRequest: underwritingRequest,
  };

  const userUploadedDocs = postUnderwritingData?.underwritingRequest?.Documents?.filter((x) => x.IsUserUploaded);
  postUnderwritingData.underwritingRequest != null
    ? (postUnderwritingData.underwritingRequest.Documents = userUploadedDocs ?? [])
    : [];

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(postUnderwritingData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function getUnderwritingDocument(
  FileId: number,
  AccountNumber: number,
  DocumentId: Array<number>,
  token: string,
): Promise<GetUnderwritingDocumentResponse> {
  const url = `${getAgencyApiHost()}underwriting-service/v1/request/documents`;
  const getUnderwritingDocumentData = {
    fileId: FileId,
    accountNumber: AccountNumber,
    documentId: DocumentId,
  };
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(getUnderwritingDocumentData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function saveAdditionalDocuments(
  fileId: number,
  requestId: number,
  accountNumber: number,
  documents: UnderwritingDocument[],
  token: string,
): Promise<SaveAdditionalDocumentResponse> {
  const url = `${getAgencyApiHost()}underwriting-service/v1/documents`;

  const additionalDocumentData = {
    fileId: fileId,
    accountNumber: accountNumber,
    underwritingDocumentRequest: {
      underwritingRequestId: requestId,
      underwritingDocuments: documents,
    },
  };

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(additionalDocumentData),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  if ((json.Errors?.length ?? 0) > 0) {
    const messages = json.Errors.map((error: any) => {
      return error.Message;
    });
    throw { messages: messages };
  }
  return json.Result;
}

export async function cancelUwrRequest(
  fileId: number,
  accountNumber: number,
  cancelUwrRequest: CancelUnderwritingRequest | undefined,
  token: string,
): Promise<CancelUnderwritingResponse> {
  const url = `${getApiHost()}underwriting/request/cancel`;
  const cancelUnderwritingData = {
    fileId: fileId,
    accountNumber: accountNumber,
    requestId: cancelUwrRequest?.RequestId,
    notes: cancelUwrRequest?.Notes,
  };

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(cancelUnderwritingData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  // console.log('JSON RESULT===>', JSON.stringify(json));
  handleError(json);

  let apiResponse;
  if (json['result'] !== undefined) {
    apiResponse = json.result;
  } else if (json['Result'] !== undefined) {
    apiResponse = json.Result;
  }

  return apiResponse;
}

export async function reopenUwrRequest(
  fileId: number,
  accountNumber: number,
  reopenUwrRequest: ReopenUnderwritingRequest | undefined,
  token: string,
): Promise<ReopenUnderwritingResponse> {
  const url = `${getApiHost()}underwriting/request/re-open`;
  const reopenUnderwritingData = {
    fileId: fileId,
    accountNumber: accountNumber,
    requestId: reopenUwrRequest?.RequestId,
    notes: reopenUwrRequest?.Notes ?? '',
  };

  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(reopenUnderwritingData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const json = await response.json();
  // console.log('JSON RESULT===>', JSON.stringify(json));
  handleError(json);

  let apiResponse;
  if (json['result'] !== undefined) {
    apiResponse = json.result;
  } else if (json['Result'] !== undefined) {
    apiResponse = json.Result;
  }

  return apiResponse;
}

export async function getLetterTypes(
  fileData: fileInfoType | null | undefined,
  token: string,
): Promise<LetterType | undefined> {
  if (fileData?.properties && fileData?.properties?.length > 0) {
    const url = `${getApiHost()}underwriting/request/letter-types/${fileData?.accountId}/${fileData?.propertyState}/${
      fileData?.properties[0]?.propertyType
    }`;

    const response = await fetch(url, {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  }
}

export async function myClosingAttorneys(firmId: string | undefined, token: string): Promise<ClosingAttorney[]> {
  const url = `${getAgencyApiHost()}underwriting-service/v1/request/my-closing-attorneys?firmId=${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}
export async function getSecondPartyDetails(
  roleType: string,
  propertyStateCode: string,
  token: string,
): Promise<SecondPartyDto[]> {
  const url = `${getAgencyApiHost()}underwriting-service/v1/request/Second-Party?roleType=${roleType}&propertyStateCode=${propertyStateCode}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function agentClosingAttorneys(firmId: string | undefined, token: string): Promise<ClosingAttorney[]> {
  const url = `${getAgencyApiHost()}underwriting-service/v1/request/agent-closing-attorneys?firmId=${firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}

export async function approvedAttorneys(stateCode: string | undefined, token: string): Promise<ApprovedAttorney[]> {
  const url = `${getAgencyApiHost()}underwriting-service/v1/request/approved-attorneys?propertyStateCode=${stateCode}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}
