import React, { useEffect, useState } from 'react';
import { AgentNetTextInput, AgentTextInputProps } from '../TextField';
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { Card, Typography } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { FieldValidationError } from 'utilities/validation/validation';

export type SuggestItemType = {
  Id?: number | string | undefined | null;
  Name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  stateOrProvince?: string;
  postalCode?: string;
  County?: string;
  id?: number | string | undefined | null;
  value: any;
  name: string;
};

export type SuggestDropdownProps = AgentTextInputProps & {
  label?: string;
  value?: string | number;
  recentOptions: SuggestItemType[];
  fullOptions?: SuggestItemType[];
  onSearch?: (searchStr: string) => void;
  onChange?: (value: any) => void;
  disabled?: boolean;
  showValidation?: boolean;
  errs?: FieldValidationError[];
  required?: boolean;
  name?: string;
  dataQa?: string;
  title?: string;
};

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

// eslint-disable-next-line react/display-name
const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 48 : 56;

  const getChildSize = (child: React.ReactNode) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 2 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const useStyles = makeStyles((theme) =>
  createStyles({
    listbox: {
      boxSizing: 'border-box',
      overflowX: `hidden!important` as any,
      '& ul': {
        padding: 0,
        margin: 0,
        '& li': {
          fontSize: `1.6rem`,
        },
      },
    },
    option: {
      whiteSpace: 'normal',
    },
  }),
);

const useCustomStyles = makeStyles((theme) =>
  createStyles({
    promptDiv: {
      backgroundColor: theme.palette.action.hover,
      textAlign: 'center',
      paddingTop: `1.6rem`,
      paddingBottom: `1.2rem`,
    },
    mostRecent: {
      color: theme.palette.text.secondary,
      paddingLeft: theme.spacing(3),
      paddingTop: '0.6rem',
      paddingBottom: 0,
    },
    listbox: {
      '& ul': {
        '& li': {
          fontSize: '1.6rem',
        },
      },
    },
  }),
);

const renderGroup = (params: AutocompleteRenderGroupParams) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

export default function SuggestDropdown({
  label = 'Search',
  recentOptions = [] as SuggestItemType[],
  fullOptions = [] as SuggestItemType[],
  onChange = () => undefined,
  value: valueProp = '',
  showValidation,
  errs,
  disabled = false,
  required = false,
  name = '',
  dataQa,
  title,
}: SuggestDropdownProps) {
  const classes = useStyles();
  const customStyles = useCustomStyles();
  const [query, setQuery] = useState('');
  const [showSearch, setShowSearch] = useState(false);

  const PaperComponent = ({ children }: any) => (
    <Card>
      <div className={customStyles.mostRecent}>
        <Typography variant="overline">Most Recent</Typography>
      </div>
      {children}
      <div className={customStyles.promptDiv}>
        <Typography variant="caption">Enter text to refine results.</Typography>
      </div>
    </Card>
  );

  function optionsById(val: any) {
    return fullOptions?.find((ele) => ele.value === val) ?? recentOptions?.find((ele) => ele.value === val);
  }

  const [value, setValue] = useState<any | null | undefined>('');
  const [inputValue, setInputValue] = useState<any | null | undefined>('');

  useEffect(() => {
    //set initial value
    if (valueProp) {
      setValue(optionsById(valueProp));
      setInputValue(fullOptions.find((ele) => ele.value === valueProp)?.name);
    } else {
      setInputValue('');
    }
  }, [valueProp, fullOptions]);

  const getOptionLabel = (option: SuggestItemType) => {
    if (option) return option.name;
    return '';
  };

  return (
    <>
      {showSearch ? (
        <Autocomplete
          Data-QA={dataQa}
          classes={classes}
          disabled={disabled}
          ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
          renderGroup={renderGroup}
          options={fullOptions}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <AgentNetTextInput
              {...params}
              label={label}
              title={value && value.name ? value.name : label}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              showValidation={showValidation}
              errs={errs}
              required={required}
              name={name}
            />
          )}
          open={true}
          renderOption={(option) => <Typography>{option.name}</Typography>}
          popupIcon={<ArrowDropDown fontSize="large" />}
          getOptionSelected={(option, value) => option.value === value}
          inputValue={inputValue}
          onInputChange={(e: any, value, reason) => {
            if (reason === 'reset') {
              setInputValue('');
            } else {
              setInputValue(value);
            }
          }}
          value={value}
          onChange={(e: any, value, reason) => {
            if (value && reason === 'select-option') {
              onChange(value);
              setValue(value);
            } else if (reason === 'clear') {
              setShowSearch(false);
              setValue('');
              setInputValue('');
              onChange('');
            }
          }}
        />
      ) : (
        <Autocomplete
          Data-QA={dataQa}
          classes={{
            listbox: customStyles.listbox,
          }}
          disabled={disabled}
          options={recentOptions}
          getOptionLabel={getOptionLabel}
          PaperComponent={PaperComponent}
          renderOption={(option) => <Typography>{option.name}</Typography>}
          renderInput={(params) => (
            <AgentNetTextInput
              {...params}
              variant="outlined"
              label={label}
              title={value && value.name ? value.name : label}
              InputLabelProps={{ shrink: true }}
              showValidation={showValidation}
              errs={errs}
              required={required}
              name={name}
            />
          )}
          popupIcon={<ArrowDropDown fontSize="large" />}
          inputValue={inputValue}
          onInputChange={(e: any, value, reason) => {
            if (reason === 'reset') {
              setInputValue('');
            } else {
              setShowSearch(true);
              setInputValue(value);
            }
          }}
          value={value}
          onChange={(e: any, value, reason) => {
            if (value && reason === 'select-option') {
              setQuery(value?.name);
              setValue(value);
              setShowSearch(true);
              onChange(value);
            } else if (reason === 'clear') {
              setShowSearch(false);
              setValue('');
              setInputValue('');
              onChange('');
            }
          }}
          getOptionSelected={(option: SuggestItemType, value) => option.value === value}
        />
      )}
    </>
  );
}
