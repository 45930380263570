import { useCallback, useEffect, useState } from 'react';

/*
A custom hook for handling async requests, from https://usehooks.com/useAsync/

Usage:
function App() {
    const { execute, status, value, error } = useAsync<string>(myFunction, false);
    return (
        <div>
            {status === "idle" && <div>Start your journey by clicking a button</div>}
            {status === "success" && <div>{value}</div>}
            {status === "error" && <div>{error}</div>}
            <button onClick={execute} disabled={status === "pending"}>
                {status !== "pending" ? "Click me" : "Loading..."}
            </button>
        </div>
    );
}
*/

export class NoSessionError extends Error {}

// An async function for testing the hook. Will be successful 50% of the time.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const testAsyncFunction = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const rnd = Math.random() * 10;
      rnd <= 5 ? resolve('Submitted successfully 🙌') : reject('Oh no there was a (simulated) error 😞.');
    }, 2000);
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAsync = <T, E = string>(asyncFunction: (...args: any[]) => Promise<T>, immediate = true) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
  const [value, setValue] = useState<T | null>(null);
  const [errors, setErrors] = useState([]);
  const [errorDetails, setErrorDetails] = useState<any>('');

  // The execute function wraps asyncFunction and handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called on every render, but only if asyncFunction changes.
  const execute = useCallback(
    (...args: any[]) => {
      setStatus('pending');
      setValue(null);
      setErrors([]);
      setErrorDetails('');
      return asyncFunction(...args)
        .then((response) => {
          setValue(response);
          setStatus('success');
        })
        .catch((error) => {
          if (error.errorMessage && error.errorMessage.includes('User does not have an existing session')) {
            console.error('LOGIN IS REQUIRED');
            /* Identify and re-throw this specific error, to be handled by the caller, probably by forcing login */
            throw new NoSessionError(error.errorMessage);
          } else {
            console.error('USE-ASYNC ERR: ', error);
          }
          setErrors(error.messages);
          setStatus('error');
          error.args = args;
          setErrorDetails(error);
        });
    },
    [asyncFunction],
  );

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as in an onClick handler.
  useEffect(() => {
    if (immediate) {
      execute().then();
    }
  }, [execute, immediate]);
  return { execute, status, value, errors, errorDetails };
};

export default useAsync;
