import React, { useEffect, useState } from 'react';
import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AgentNetCheckBox, AgentNetTextInput } from 'ui-kit/inputs';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import PhoneField from 'ui-kit/inputs/PhoneField/PhoneField';
import { accountRegistrationSubmit } from 'api/accountRegistration/accountRegistration-api';
import { accountRegistrationSchema } from '../validation/schemas/account-registration-schema';
import StateSelectField from '../../ui-kit/inputs/StateSelectField';
import AccountRegistrationBG from '../../common-assets/images/Account-Registration-Banner.jpg';
import { Typography } from '@material-ui/core';
import { Notification } from 'ui-kit/components/notification/Notification';
import './AccountRegistration.scss';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      position: 'relative',
      width: '720px',
      height: '137px',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    image: { minHeight: '100%', width: '100%', position: 'absolute', top: 0 },
    imageOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: alpha(theme.palette.common.black, 0.55),
      zIndex: 1,
    },
    imageText: {
      zIndex: 2,
      color: theme.palette.common.white,
      textAlign: 'center',
      maxWidth: '35ch',
    },
    mainContainer: {
      background: theme.palette.common.white,
      paddingBottom: theme.spacing(4),
      overflowX: 'hidden',
    },
    textContainer: {
      margin: theme.spacing(3),
    },
    text: { maxWidth: '60ch' },
    table: { marginBottom: '1.5rem' },
    tableCell: {
      padding: 0,
      borderWidth: 0,
    },
    inputContainer: {
      margin: theme.spacing(5, 1),
    },
  }),
);

const AccountRegistration = (): JSX.Element => {
  const classes = useStyles();

  const [accountRegistrationObj, setAccountRegistrationObj] = useState<any>({
    accountRegistrationFirstName: '',
    accountRegistrationMiddleName: '',
    accountRegistrationLastName: '',
    accountRegistrationEmail: '',
    accountRegistrationCompany: '',
    accountRegistrationAddress: '',
    accountRegistrationAddress2: '',
    accountRegistrationCity: '',
    accountRegistrationStateCode: '',
    accountRegistrationZip: '',
    accountRegistrationPhone: '',
    accountRegistrationFax: '',
    accountRegistrationCanRemit: false,
    accountRegistrationCanPay: false,
  });

  const clearFields = () => {
    setAccountRegistrationObj({
      accountRegistrationFirstName: '',
      accountRegistrationMiddleName: '',
      accountRegistrationLastName: '',
      accountRegistrationEmail: '',
      accountRegistrationCompany: '',
      accountRegistrationAddress: '',
      accountRegistrationAddress2: '',
      accountRegistrationCity: '',
      accountRegistrationStateCode: '',
      accountRegistrationZip: '',
      accountRegistrationPhone: '',
      accountRegistrationFax: '',
      accountRegistrationCanRemit: false,
      accountRegistrationCanPay: false,
    });
    setShowNotificationSuccess(false);
    setShowNotificationError(false);
  };

  const validateFields = () => {
    doValidate(accountRegistrationObj, accountRegistrationSchema).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
  };

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isShowValidation, setIsShowValidation] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);

  const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false);
  const [showNotificationError, setShowNotificationError] = useState<boolean>(false);
  const handleAccountRegistration = async () => {
    setIsSubmitted(true);
    setIsShowValidation(true);
    validateFields();
    setShowNotificationSuccess(false);
    setShowNotificationError(false);
    if (!validationErrors || validationErrors.length === 0) {
      const requestObj = {
        firstName: accountRegistrationObj.accountRegistrationFirstName,
        middleName: accountRegistrationObj.accountRegistrationMiddleName,
        lastName: accountRegistrationObj.accountRegistrationLastName,
        email: accountRegistrationObj.accountRegistrationEmail,
        companyName: accountRegistrationObj.accountRegistrationCompany,
        address: accountRegistrationObj.accountRegistrationAddress,
        address2: accountRegistrationObj.accountRegistrationAddress2,
        city: accountRegistrationObj.accountRegistrationCity,
        stateCode: accountRegistrationObj.accountRegistrationStateCode,
        zip: accountRegistrationObj.accountRegistrationZip,
        phone: accountRegistrationObj.accountRegistrationPhone,
        fax: accountRegistrationObj.accountRegistrationFax,
        canRemit: accountRegistrationObj.accountRegistrationCanRemit,
        canPay: accountRegistrationObj.accountRegistrationCanPay,
      };
      if (await accountRegistrationSubmit(requestObj)) {
        setIsSubmitted(true);
        setIsShowValidation(false);
        setShowNotificationSuccess(true);
      } else {
        setShowNotificationError(true);
      }
    }
  };
  useEffect(() => {
    if (isShowValidation) {
      validateFields();
    }
  }, [accountRegistrationObj]);

  return (
    <Grid id="AccountReg" className="small-content-wrap box-shadow">
      <Grid item xs={12}>
        <div className={classes.imageContainer} id="b-6">
          <img className={classes.image} src={AccountRegistrationBG} alt="account-registration" />
          <div className={classes.imageOverlay}></div>
          <Typography variant="h1" className={classes.imageText}>
            Join Us!
          </Typography>
        </div>
      </Grid>
      <div className="p-24">
        <h3>Request Account</h3>
        <div>
          <div className="flex-split m-20-0">
            <div className="split">
              <div>
                <AgentNetTextInput
                  fullWidth
                  variant="outlined"
                  label="First Name"
                  name="accountRegistrationFirstName"
                  id="accountRegistrationFirstName"
                  required
                  value={accountRegistrationObj.accountRegistrationFirstName}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationFirstName: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></AgentNetTextInput>
              </div>
              <div>
                <AgentNetTextInput
                  variant="outlined"
                  label="Middle Name"
                  name="accountRegistrationMiddleName"
                  id="accountRegistrationMiddletName"
                  value={accountRegistrationObj.accountRegistrationMiddleName}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationMiddleName: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></AgentNetTextInput>
              </div>
            </div>
            <div className="split">
              <div>
                <AgentNetTextInput
                  variant="outlined"
                  label="Last Name"
                  name="accountRegistrationLastName"
                  id="accountRegistrationLastName"
                  required
                  value={accountRegistrationObj.accountRegistrationLastName}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationLastName: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></AgentNetTextInput>
              </div>
            </div>
          </div>
          <div className="flex-split m-20-0">
            <div className="split">
              <div>
                <AgentNetTextInput
                  variant="outlined"
                  label="Email"
                  name="accountRegistrationEmail"
                  id="accountRegistrationEmail"
                  required
                  value={accountRegistrationObj.accountRegistrationEmail}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationEmail: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></AgentNetTextInput>
              </div>
            </div>
            <div className="split">
              <div>
                <AgentNetTextInput
                  variant="outlined"
                  label="Company Name"
                  name="accountRegistrationCompany"
                  id="accountRegistrationCompany"
                  required
                  value={accountRegistrationObj.accountRegistrationCompany}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationCompany: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></AgentNetTextInput>
              </div>
            </div>
          </div>
          <div className="m-20-0">
            <div className="w-100">
              <AgentNetTextInput
                variant="outlined"
                label="Address"
                name="accountRegistrationAddress"
                id="accountRegistrationAddress"
                required
                value={accountRegistrationObj.accountRegistrationAddress}
                onChange={(event: any) =>
                  setAccountRegistrationObj({
                    ...accountRegistrationObj,
                    accountRegistrationAddress: event.target.value,
                  })
                }
                showValidation={isShowValidation}
                errs={validationErrors}
              ></AgentNetTextInput>
            </div>
          </div>
          <div className="flex-split m-20-0">
            <div className="w-100">
              <AgentNetTextInput
                variant="outlined"
                label="Address2"
                name="accountRegistrationAddress2"
                id="accountRegistrationAddress2"
                value={accountRegistrationObj.accountRegistrationAddress2}
                onChange={(event: any) =>
                  setAccountRegistrationObj({
                    ...accountRegistrationObj,
                    accountRegistrationAddress2: event.target.value,
                  })
                }
                showValidation={isShowValidation}
                errs={validationErrors}
              ></AgentNetTextInput>
            </div>
          </div>
          <div className="flex-split m-20-0">
            <div className="split">
              <div>
                <AgentNetTextInput
                  variant="outlined"
                  label="City"
                  name="accountRegistrationCity"
                  id="accountRegistrationCity"
                  required
                  value={accountRegistrationObj.accountRegistrationCity}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationCity: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></AgentNetTextInput>
              </div>
            </div>
            <div className="split">
              <div>
                <StateSelectField
                  variant="outlined"
                  name="accountRegistrationStateCode"
                  label="State"
                  value={accountRegistrationObj.accountRegistrationStateCode}
                  onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationStateCode: e,
                    });
                  }}
                  required
                  showValidation={isShowValidation}
                  errs={validationErrors}
                />
              </div>
              <div>
                <AgentNetTextInput
                  variant="outlined"
                  label="Zip Code"
                  name="accountRegistrationZip"
                  id="accountRegistrationZip"
                  required
                  value={accountRegistrationObj.accountRegistrationZip}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationZip: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></AgentNetTextInput>
              </div>
            </div>
          </div>
          <div className="flex-split m-20-0">
            <div className="split">
              <div>
                <PhoneField
                  variant="outlined"
                  label="Phone"
                  name="accountRegistrationPhone"
                  id="accountRegistrationPhone"
                  required
                  value={accountRegistrationObj.accountRegistrationPhone}
                  onChange={(event: any) =>
                    setAccountRegistrationObj({
                      ...accountRegistrationObj,
                      accountRegistrationPhone: event.target.value,
                    })
                  }
                  showValidation={isShowValidation}
                  errs={validationErrors}
                ></PhoneField>
              </div>
            </div>
            <div className="split">
              <PhoneField
                variant="outlined"
                label="Fax"
                name="accountRegistrationFax"
                id="accountRegistrationFax"
                value={accountRegistrationObj.accountRegistrationFax}
                onChange={(event: any) =>
                  setAccountRegistrationObj({
                    ...accountRegistrationObj,
                    accountRegistrationFax: event.target.value,
                  })
                }
                showValidation={isShowValidation}
                errs={validationErrors}
              ></PhoneField>
            </div>
          </div>
        </div>
        <p className="f-16 fw-500 m-b-5">Requested Activity Rights</p>
        <p className="f-14 grey-txt m-t-0">Special approval is required.</p>
        <div className="p-b-20 flex-split j-start">
          <div className="outline-brd">
            <AgentNetCheckBox
              label="Can Remit"
              value={accountRegistrationObj.accountRegistrationCanRemit ?? false}
              checkHandler={(checkVal: boolean) => {
                setAccountRegistrationObj({
                  ...accountRegistrationObj,
                  accountRegistrationCanRemit: checkVal,
                });
              }}
            />
          </div>
          <div className="outline-brd">
            <AgentNetCheckBox
              label="Can Pay"
              value={accountRegistrationObj.accountRegistrationCanPay ?? false}
              checkHandler={(checkVal: boolean) => {
                setAccountRegistrationObj({
                  ...accountRegistrationObj,
                  accountRegistrationCanPay: checkVal,
                });
              }}
            />
          </div>
        </div>
        <div className="j-sb">
          <AgentNetButton
            color="primary"
            size="medium"
            className="button"
            variant="contained"
            onClick={handleAccountRegistration}
          >
            Submit Request
          </AgentNetButton>
          <div color="float-right">
            <AgentNetButton
              color="default"
              size="medium"
              className="outline-btn m-r-15"
              variant="contained"
              onClick={clearFields}
            >
              Clear
            </AgentNetButton>
            <AgentNetButton
              color="default"
              size="medium"
              className="outline-btn"
              variant="contained"
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Cancel
            </AgentNetButton>
          </div>
        </div>
        <div>
          {showNotificationError && (
            <Notification inline severity="error" msg="Unable to send request. Please try again." />
          )}
          {showNotificationSuccess && <Notification inline severity="success" msg="Your request has been sent." />}
        </div>
      </div>
    </Grid>
  );
};
export default AccountRegistration;
