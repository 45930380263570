import React, { ReactNode } from 'react';
import { Divider, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

export type DrawerActionsProps = {
  children?: ReactNode;
  className?: string;
  loading?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      '& .MuiButtonBase-root, & .MuiButtonBase-root + .MuiButtonBase-root': {
        margin: theme.spacing(0, 0, 2),
      },
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 4),
    flex: 1,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
      '& > :not(:first-child)': {
        marginLeft: theme.spacing(2),
      },
    },
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
      paddingBottom: 0,
    },
  },
}));

const DrawerActions = ({ children, className, loading = false, ...props }: DrawerActionsProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)} {...props}>
      {loading ? <LinearProgress /> : <Divider />}
      <div className={classes.container}>{children}</div>
    </div>
  );
};

export default DrawerActions;
