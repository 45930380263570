import React, { useEffect, useState } from 'react';
import PartyInformationNonLenderParty from './PartyInformationNonLenderParty';
import { Box, Grid, Typography } from '@material-ui/core';
import { AgentNetTextInput } from 'ui-kit/inputs/TextField';
import { AgentNetCheckBox } from 'ui-kit/inputs/Checkbox';
import { fileInfoType } from 'api/file/interfaces/get-file';
import { IGetLenderResponse } from 'features/files/parties/interfaces/CreateLenderRequest';
import AutocompleteSearch from 'ui-kit/inputs/AutocompleteSearch/AutocompleteSearch';
import DomesticAddressField from 'features/files/file-create/DomesticAddress';
import AgentNetDropdownSelector from 'ui-kit/inputs/AgentNetDropdownSelector';
import { mortgageClause, MortgageClauseTextOptions } from 'features/files/parties/form/MortgageClause';
import { CreateCPLType, partyRequirement } from 'features/cpl/types';
import { IGetBuyerSellerResponse } from 'features/files/parties/interfaces/CreateBuyerSellerRequest';
import { AddressFields } from 'ui-kit/form/interfaces/AddressModal';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { domesticAddressSchemaRequired, lenderSchema } from 'utilities/validation/schemas/cpl-schema';
import { LenderClauseTypeValue, MyLenderDropdown } from 'api/file/constants';
import { CoveredParties } from 'features/cpl/cpl-list/constants';

interface PartyInformationProps {
  index?: number;
  fileData: fileInfoType | null | undefined;
  firmLenders: Array<IGetLenderResponse> | null | undefined;
  value: CreateCPLType | null;
  setCplFormData: (data: IGetLenderResponse, key: string) => void;
  showValidation: boolean;
  validationErrors: FieldValidationError[];
  setLenderAddressValid: (val: boolean) => void;
  setBuyerAddressValid: (val: boolean) => void;
  setSellerAddressValid: (val: boolean) => void;
  lenderRequirements: partyRequirement;
  buyerRequirements: partyRequirement;
  sellerRequirements: partyRequirement;
  isStarsStatusActive?: boolean;
  noStarsStatusActive?: boolean;
  getFilteredErrors: (errs: FieldValidationError[], fields: string[]) => void;
  setEditedPartyName?: (val: string) => void;
}
interface DropdownItem {
  name: string;
  value: string;
}

const lenderDropdownName = (lender: IGetLenderResponse) => {
  if (!lender) return '';
  const { name = '', address = { address1: '', city: '', postalCode: '', stateOrProvince: '' } } = lender;
  const { address1 = '', city = '', postalCode = '', stateOrProvince = '' } = address;
  const displayName = [name, address1, city, stateOrProvince, postalCode].filter((value) => value).join(', ');
  return displayName;
};

const PartyInformation: React.FC<PartyInformationProps> = ({
  index,
  fileData,
  firmLenders,
  value,
  setCplFormData,
  showValidation,
  validationErrors,
  setLenderAddressValid,
  setBuyerAddressValid,
  setSellerAddressValid,
  lenderRequirements,
  buyerRequirements,
  sellerRequirements,
  isStarsStatusActive,
  noStarsStatusActive,
  getFilteredErrors,
  setEditedPartyName,
}: PartyInformationProps) => {
  const isFormDisable = value?.cpl?.status === 'Void' || isStarsStatusActive || noStarsStatusActive;
  const [lender, setLender] = useState<IGetLenderResponse>(fileData?.lenders ? fileData?.lenders[0] : {});
  const [initialNonLenderParty, setInitialNonLenderParty] = useState<{
    initialBuyerNames?: string;
    initialSellerNames?: string;
    initialCustomBuyerAddress?: AddressFields;
    initialCustomSellerAddress?: AddressFields;
  }>({ initialBuyerNames: '', initialSellerNames: '', initialCustomBuyerAddress: {}, initialCustomSellerAddress: {} });

  const noMatchingLenderText = MyLenderDropdown.noMatchingLender;
  const lenderInfo = fileData?.lenders?.map((el) => {
    return { name: lenderDropdownName(el), value: el?.lenderAddressBookId ?? el?.lenderId };
  });

  const lenderFieldsType = value?.partyFields?.lender;
  const sellerFieldsType = value?.partyFields?.seller;
  const buyerFieldsType = value?.partyFields?.buyer;

  const getPartyNames = (parties: IGetBuyerSellerResponse[] | undefined) => {
    const propertyState = fileData?.propertyState;
    let partyNames = '';
    parties?.forEach((party) => {
      if (party.partyType === 'Individual') {
        partyNames += ` ${party.individual?.firstName ?? ''} ${party.individual?.middleName ?? ''} ${
          party.individual?.lastName ?? ''
        } ${party.individual?.suffix ?? ''}`;
        propertyState === 'NJ'
          ? party?.individual?.maritalStatus
            ? (partyNames += ` (${party?.individual?.maritalStatus}),`)
            : (partyNames += ',')
          : (partyNames += ',');
      }
      if (party.partyType === 'A Married Couple') {
        partyNames += ` ${party.couple?.spouse1?.firstName ?? ''} ${party.couple?.spouse1?.middleName ?? ''} ${
          party.couple?.spouse1?.lastName ?? ''
        } ${party.couple?.spouse1?.suffix ?? ''} and ${party.couple?.spouse2?.firstName ?? ''} ${
          party.couple?.spouse2?.middleName ?? ''
        } ${party.couple?.spouse2?.lastName ?? ''} ${party.couple?.spouse2?.suffix ?? ''}`;
        propertyState === 'NJ'
          ? party?.couple?.maritalStatus
            ? (partyNames += ` (${party?.couple?.maritalStatus}),`)
            : (partyNames += ',')
          : (partyNames += ',');
      }
      if (party.partyType === 'Entity') partyNames += ` ${party.entity?.name},`;
      if (party.partyType === 'Trust/Estate') partyNames += ` ${party.trustEstate?.name},`;
    });
    return partyNames.replace(/  +/g, ' ').slice(0, -1).trim();
  };
  const createAddressName = (address: AddressFields) => {
    const { address1, address2, city, state, stateOrProvince, postalCode } = address;
    return [address1, address2, city, stateOrProvince || state, postalCode]
      .filter((value) => value)
      .join(', ')
      .replace(',,', ',');
  };
  const addressOptionsList = (party: IGetBuyerSellerResponse[] | undefined) => {
    const addOptions = new Array<DropdownItem>();
    party?.forEach((item: IGetBuyerSellerResponse) => {
      if (item.address) {
        const name = createAddressName(item.address);
        if (!name) return;
        const addressItem = {
          name,
          value: item?.partyId ?? '',
        };
        addOptions.push(addressItem);
      }
    });

    const primeProperty = fileData?.properties?.[0];
    if (primeProperty) {
      const propName = createAddressName(primeProperty);
      addOptions.push({
        name: propName,
        value: 'property_' + primeProperty.propertyId,
      });
    }

    addOptions.push({ name: 'Custom', value: 'Custom' });
    return addOptions;
  };

  const filteredFirmLenders = firmLenders
    ?.filter(
      (elem) => !fileData?.lenders?.some(({ lenderAddressBookId }) => elem.lenderAddressBookId === lenderAddressBookId),
    )
    .map((el) => {
      return { name: lenderDropdownName(el), value: el?.lenderAddressBookId };
    });

  // need to perform deep equality check for fileData
  useEffect(() => {
    if (typeof lender?.lenderId === 'number' && !lender?.lenderId && fileData?.lenders?.[0]?.lenderId) {
      setLender(fileData?.lenders?.[0] ?? {});
    }
  }, [fileData]);

  useEffect(() => {
    if (value && value?.cpl) {
      if (value.cpl.buyer) {
        const initialBuyerNames = value.cpl.buyer?.names || '';
        const initialCustomBuyerAddress = {
          address1: value.cpl.buyer?.address1 || '',
          address2: value.cpl.buyer?.address2 || '',
          addressSelection: value.cpl.buyer?.addressSelection || 0,
          city: value.cpl.buyer?.city || '',
          postalCode: value.cpl.buyer?.postalCode || '',
          stateOrProvince: value.cpl.buyer?.stateOrProvince || '',
        };
        setInitialNonLenderParty((prevState) => ({
          ...prevState,
          initialBuyerNames,
          initialCustomBuyerAddress,
        }));
      }
      if (value.cpl.seller) {
        const initialSellerNames = value.cpl.seller?.names || '';
        const initialCustomSellerAddress = {
          address1: value.cpl.seller?.address1 || '',
          address2: value.cpl.seller?.address2 || '',
          addressSelection: value.cpl.seller?.addressSelection || 0,
          city: value.cpl.seller?.city || '',
          postalCode: value.cpl.seller?.postalCode || '',
          stateOrProvince: value.cpl.seller?.stateOrProvince || '',
        };
        setInitialNonLenderParty((prevState) => ({
          ...prevState,
          initialSellerNames,
          initialCustomSellerAddress,
        }));
      }
    }
  }, [value]);

  useEffect(() => {
    if (value?.cpl && value?.cpl.lender) {
      setLender(value.cpl.lender);
    }
  }, [value]);

  useEffect(() => {
    setCplFormData(lender, 'lender');
    doValidate(lender, lenderSchema(lenderFieldsType, lenderRequirements.name)).then((errs: FieldValidationError[]) => {
      getFilteredErrors(errs, ['name', 'attention', 'loanNumber', 'lenderClauseText']);
    });
  }, [lender, lenderRequirements.name]);

  const handleLenderInfo = (id: string) => {
    const lenderSelected =
      fileData?.lenders?.find((ele) => ele.lenderAddressBookId === id || ele.lenderId === id) ??
      firmLenders?.find((ele) => ele.lenderAddressBookId === id);

    setLender(
      lenderSelected ?? {
        name: '',
        value: id === '' ? '' : noMatchingLenderText,
      },
    );
  };

  const onLenderClauseTypeChange = (type: string) => {
    for (const obj in MortgageClauseTextOptions) {
      if (obj === type) {
        if (type === 'MERS') {
          setLender({
            ...lender,
            lenderClauseType: type,
            lenderClauseText: MortgageClauseTextOptions[obj].value?.replace('{{ COMPANY NAME }}', String(lender?.name)),
          });
        } else {
          setLender({ ...lender, lenderClauseType: type, lenderClauseText: MortgageClauseTextOptions[obj].value });
        }
      }
    }
  };

  return (
    <div>
      <Box my={3}>
        <Typography variant="h3">Lender</Typography>
      </Box>
      <Grid container spacing={3} className="CPAEditFormContainer">
        <Grid item sm={6}>
          <AutocompleteSearch
            label="Lender"
            options={lenderInfo ?? []}
            Data-QA={index ? `CPL${index}LenderList` : 'CPLLenderList'}
            additionalOptions={filteredFirmLenders ?? []}
            required={lenderRequirements.name || lender.value === noMatchingLenderText}
            hideSearchIcon
            onChange={(value) => {
              handleLenderInfo(value);
            }}
            value={lender?.lenderAddressBookId ?? lender?.lenderId ?? ''}
            secondaryLabel="---Additional Lenders Below---"
            disabled={isFormDisable}
          />
        </Grid>
        <Grid item sm={3}>
          <AgentNetTextInput
            Data-QA={index ? `CPL${index}LoanNumber` : 'CPLLoanNumber'}
            variant="outlined"
            fullWidth
            label={'Loan Number'}
            value={lender?.loanNumber ?? ''}
            onChange={(e) => setLender({ ...lender, loanNumber: e.target.value })}
            disabled={isFormDisable}
            name="loanNumber"
            showValidation={showValidation}
            errs={validationErrors}
          />
        </Grid>
        <Grid item sm={6}>
          <AgentNetTextInput
            Data-QA={index ? `CPL${index}LenderName` : 'CPLLenderName'}
            variant="outlined"
            fullWidth
            label={'Company Name'}
            value={lender?.name}
            required={lenderRequirements.name || lender.value === noMatchingLenderText}
            showValidation={showValidation}
            errs={validationErrors}
            onChange={(e) => {
              setLender({
                ...lender,
                name: e.target.value,
                ...(lender.lenderClauseType === LenderClauseTypeValue.mers
                  ? {
                      lenderClauseText: MortgageClauseTextOptions[LenderClauseTypeValue.mers].value?.replace(
                        '{{ COMPANY NAME }}',
                        String(e.target.value.trim()),
                      ),
                    }
                  : {}),
              });
              if (
                value?.cpl?.coveredParty === CoveredParties.lender ||
                value?.cpl?.coveredParty === CoveredParties.lender
              )
                setEditedPartyName && setEditedPartyName(e.target.value);
            }}
            name="name"
            disabled={isFormDisable}
          />
        </Grid>
        <Grid item sm={6}>
          <AgentNetTextInput
            Data-QA={index ? `CPL${index}LenderAttention` : 'CPLLenderAttention'}
            variant="outlined"
            fullWidth
            label={'Attention'}
            value={lender?.attention ?? ''}
            onChange={(e) => setLender({ ...lender, attention: e.target.value })}
            disabled={isFormDisable}
            name="attention"
            showValidation={showValidation}
            errs={validationErrors}
          />
        </Grid>
        {lender?.value === noMatchingLenderText && lender.value !== '' ? (
          <Grid item sm={12}>
            <AgentNetCheckBox
              Data-QA={index ? `CPL${index}AddLender` : 'CPLAddLender'}
              label="Add lender to address book"
              value={lender?.addToAddressBook ?? false}
              checkHandler={(val: boolean) => {
                setLender({ ...lender, addToAddressBook: val });
              }}
            />
          </Grid>
        ) : null}
        <Grid item sm={12}>
          <DomesticAddressField
            qaAttributePrefix={index ? `CPL${index}Lender` : 'CPLLender'}
            showAllValidation={showValidation}
            schema={domesticAddressSchemaRequired(lenderFieldsType?.address, lenderRequirements?.address)}
            displayRequiredAddressFields={lenderRequirements?.address || lender.value === noMatchingLenderText}
            value={{
              address1: lender?.address?.address1 ?? '',
              address2: lender?.address?.address2 ?? '',
              city: lender?.address?.city ?? '',
              postalCode: lender?.address?.postalCode ?? '',
              stateOrProvince: lender?.address?.stateOrProvince ?? lender?.address?.state ?? '',
            }}
            onChange={(val) => setLender({ ...lender, address: val })}
            setIsValid={setLenderAddressValid}
            disabled={isFormDisable}
            parentValidation={lenderRequirements?.address}
            isOutsideJurisdiction={true}
          />
        </Grid>
        <Grid item xs={6} className="lender-address-info-section-container">
          <AgentNetDropdownSelector
            Data-QA={index ? `CPL${index}LenderClause` : 'CPLLenderClause'}
            name="myLender"
            id="myLender"
            label="Care of, DBA, Mortgage Clause"
            options={mortgageClause}
            value={lender?.lenderClauseType ?? onLenderClauseTypeChange('Standard')}
            menuOption={(val) => {
              onLenderClauseTypeChange(val);
            }}
            dropdowntype="outlined"
            disabled={isFormDisable}
          />
        </Grid>
        <Grid item xs={12} className="lender-address-info-section-container">
          <AgentNetTextInput
            name="lenderClauseText"
            showValidation={showValidation}
            errs={validationErrors}
            Data-QA={index ? `CPL${index}LenderNotes` : 'CPLLenderNotes'}
            variant="outlined"
            fullWidth
            multiline
            minRows="2"
            label="Mortgage Clause"
            value={lender?.lenderClauseText ?? ''}
            onChange={(e) => {
              setLender({ ...lender, lenderClauseText: e.target.value });
            }}
            disabled={isFormDisable}
          />
        </Grid>
      </Grid>
      <PartyInformationNonLenderParty
        index={index}
        disabled={isFormDisable || false}
        buyerRequirements={buyerRequirements}
        sellerRequirements={sellerRequirements}
        buyerAddressOptions={addressOptionsList(fileData?.buyers)}
        sellerAddressOptions={addressOptionsList(fileData?.sellers)}
        buyerNames={getPartyNames(fileData?.buyers)}
        sellerNames={getPartyNames(fileData?.sellers)}
        fileData={fileData}
        setCplFormData={setCplFormData}
        initialBuyerNames={initialNonLenderParty.initialBuyerNames}
        initialSellerNames={initialNonLenderParty.initialSellerNames}
        initialCustomBuyerAddress={initialNonLenderParty.initialCustomBuyerAddress}
        initialCustomSellerAddress={initialNonLenderParty.initialCustomSellerAddress}
        showValidation={showValidation}
        validationErrors={validationErrors}
        setBuyerAddressValid={setBuyerAddressValid}
        setSellerAddressValid={setSellerAddressValid}
        sellerFieldsType={sellerFieldsType}
        buyerFieldsType={buyerFieldsType}
        getFilteredErrors={getFilteredErrors}
        setEditedPartyName={setEditedPartyName}
        cplData={value}
      />
    </div>
  );
};
export default PartyInformation;
