import * as yup from 'yup';
import { isAfter, isBefore, isValid } from 'date-fns';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'Required';
export const INVALID_FIELD_MESSAGE_TEXT = 'Invalid entry, you can only enter current date or future date';
export const requiredFieldMessage = (fieldName: any): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const invalidFieldMessage = (fieldName: any): string => `${fieldName} ${INVALID_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (fieldName?: string, maxLength?: number): string =>
  `${fieldName} Max length is ${maxLength}`;

declare module 'yup' {
  interface StringSchema {
    validateField(): StringSchema;
    validateEmail(): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, 'validateField', function () {
  return this.test({
    test(value) {
      const { parent, createError } = this;

      const isDateValueInvalid = parent.type === 'date' && value && !isValidDate(new Date(value ?? ''));

      if (isDateValueInvalid) {
        return createError({ path: parent.name, message: invalidFieldMessage(parent.label) });
      }

      if (parent.isRequired && (!value || (parent.name == 'DisclaimerAccepted' && value != 'true'))) {
        if ((parent.type == 'dropdown' && !parent.options) || parent.options?.length == 0) {
          if (parent.name == 'ProductTransactionType') {
            return createError({
              path: 'CustomError',
              message:
                'Transaction selected from file is not setup to a product. Please contact ASC at 1-866-701-3361 or AgencySupport@firstam.com to complete your setup',
            });
          } else if (parent.name == 'BusinessSegment') {
            return createError({
              path: 'CustomError',
              message:
                'Product selected is not mapped to NAIC Property Type you selected "name of NAIC property type" . Please contact ASC at 1-866-701-3361 or AgencySupport@firstam.com to complete your setup',
            });
          }
        } else {
          return createError({ path: parent.name, message: requiredFieldMessage(parent.label) });
        }
      }

      return true;
    },
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'validateEmail', function () {
  return this.test({
    test(value) {
      const myArray = value?.toString().split(';');
      const pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
      let isValid = true;
      myArray?.every(function (element, index) {
        const userinput = element;
        if (userinput && userinput.trim() && !pattern.test(userinput)) {
          isValid = false;
        } else return true;
      });

      if (!isValid) {
        return this.createError({
          message: 'Please enter a valid Email address',
          path: 'orderNotificationEmail',
        });
      } else {
        return true;
      }
    },
  });
});

export const ServiceOrderSchema = yup.object().shape({
  productName: yup.string().required(requiredFieldMessage('ProductName')),
  orderNotificationEmail: yup.string().trim().required(requiredFieldMessage('Email Notification')).validateEmail(),
  productField: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        value: yup.string().nullable().trim().validateField(),
      }),
    )
    .optional(),
});

export const ServiceOrderUpdateSchema = yup.object().shape({
  updateType: yup.string().required(requiredFieldMessage('Update Type')),
  orderNotificationEmail: yup.string().trim().required(requiredFieldMessage('Email Notification')).validateEmail(),
  note: yup.string().trim().required(requiredFieldMessage('Note')),
});

const isValidDate = (date: Date | null): boolean | null => {
  const yesterday = new Date(Date.now() - 86400000);
  return date && isValid(date) && isAfter(date, yesterday) && isBefore(date, new Date('12/31/2999'));
};
