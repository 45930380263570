import React, { useEffect, useState } from 'react';
import { alpha, createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Logo from 'ui-kit/icons/AgentNetLogo';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { AgentNetTextInput } from 'ui-kit/inputs';
import { AgentNetDivider } from 'ui-kit/components/dividers';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import VerificationBG from '../../common-assets/images/verification.jpg';
import { avlValidatedCode, validatedCode } from 'api/verification/verification-api';
import useAsync from 'hooks/useAsync';
import { CPLErrorCodes } from 'features/cpl/cpl-list/constants';
import moment, { MomentInput } from 'moment';
import { openDocument } from 'utilities/utilities';
import { DocumentType, newWindow, WindowFeatures } from 'features/constants';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { AvlVerificationStatusType, VerificationStatusType } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      position: 'relative',
      width: '100%',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      borderRadius: '6px 6px 0 0',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    headerBar: {
      background: '#013A6F',
      height: '65px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 25px',
    },
    contentContainer: {
      boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: '6px',
      height: 'fit-content',
      maxWidth: '900px',
      margin: '50px auto',
    },
    image: { minHeight: '100%', width: '100%', position: 'absolute', top: 0 },
    imageOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      backgroundColor: alpha(theme.palette.common.black, 0.55),
      zIndex: 1,
    },
    imageText: {
      zIndex: 2,
      padding: '50px 0',
      color: theme.palette.common.white,
      textAlign: 'center',
      maxWidth: '35ch',
    },
    mainContainer: {
      background: theme.palette.common.white,
      paddingBottom: theme.spacing(4),
      overflowX: 'hidden',
    },
    textContainer: {
      margin: theme.spacing(3),
    },
    text: { maxWidth: '60ch' },
    table: { marginBottom: '1.5rem' },
    tableCell: {
      padding: 0,
      borderWidth: 0,
    },
    inputContainer: {
      margin: theme.spacing(5, 1),
    },
    linkHref: { marginLeft: '4px' },
  }),
);

const Verification = (): JSX.Element => {
  const { addGlobalMsg } = useGlobalMessages();
  const classes = useStyles();
  const searchParams = new URLSearchParams(location.search);
  const [validationCode, setValidationCode] = useState(searchParams.get('DigitalSignature') ?? '');
  const [isCpl, setCpl] = useState<boolean>(validationCode && validationCode.indexOf('AVL') > -1 ? false : true);
  const [displayError, setDisplayError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [rows, setRows] = useState<{ title: string; value: string }[] | null>(null);
  const [pdfData, setPdfData] = useState('');
  const loadVerificationStatus = () => (isCpl ? validatedCode(validationCode) : avlValidatedCode(validationCode));
  const { execute, value, status, errors, errorDetails } = useAsync<any>(loadVerificationStatus, false);
  const [shouldAutoSearch, setShouldAutoSearch] = useState<boolean>(
    searchParams.get('DigitalSignature') ? true : false,
  );

  useEffect(() => {
    if (value?.result) {
      let tableRows;
      if (isCpl) {
        const result: VerificationStatusType = value.result;
        tableRows = [
          { title: 'File #', value: result?.fileNumber ?? '' },
          { title: 'Letter Type', value: result?.letterType ?? '' },
          { title: 'Created Date', value: moment(result?.updatedDate).format('L') || '' },
          { title: 'Office Originated in', value: result?.officeName ?? '' },
          { title: 'Created By', value: result?.updatedBy ?? '' },
        ];

        setPdfData(value.result?.pdfData);
      } else {
        const result: AvlVerificationStatusType = value.result;
        tableRows = [
          { title: 'Letter Type', value: result?.LetterType ?? '' },
          { title: 'Agent', value: result?.AgentName ?? '' },
          { title: 'Address', value: `${result?.AgentAddressLine1}, ${result?.AgentAddressLine2}` },
          { title: 'Created Date', value: moment(result?.CreatedDate).format('L') || '' },
          { title: 'Office Originated in', value: result?.OfficeName ?? '' },
          { title: 'Office Id', value: result?.OfficeId?.toString() ?? '' },
          { title: 'Created By', value: result?.CreatedBy ?? '' },
        ];

        setPdfData(result?.Document?.Content);
      }
      setRows(tableRows);
    }
  }, [value]);

  useEffect(() => {
    if (errorDetails && errorDetails?.errorCode != CPLErrorCodes.forbidden) {
      errors?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    } else if (errorDetails && errorDetails?.errorCode == CPLErrorCodes.forbidden) {
      const date = errorDetails?.data?.data?.updatedDate ?? null;
      const name = errorDetails?.data?.data?.updatedBy ?? '';

      const errorText = `The CPL you are searching for has been voided on ${moment(date as MomentInput).format(
        'MM/DD/YYYY',
      )} by ${name}; therefore the CPL cannot be verified.`;
      setErrMsg(errorText);
    }
  }, [errorDetails?.errorCode]);

  useEffect(() => {
    if (searchParams.get('DigitalSignature') && shouldAutoSearch) {
      setShouldAutoSearch(false);
      onValidationSearch();
    }
  });

  const onValidationSearch = () => {
    if (validationCode) {
      setRows(null);
      setDisplayError(false);
      setErrMsg('');
      execute().then();
    } else {
      setDisplayError(true);
    }
  };
  return (
    <Grid container>
      <div className={classes.headerBar}>
        <Logo />
      </div>

      <div className={classes.contentContainer}>
        <div className={classes.imageContainer}>
          <img className={classes.image} src={VerificationBG} alt="verification" />
          <div className={classes.imageOverlay}></div>
          <Typography variant="h1" className={classes.imageText}>
            Closing Protection Letters and Agency Verification Made Simple and Convenient
          </Typography>
        </div>

        <div className={classes.mainContainer}>
          <Grid container spacing={3} alignItems="center" className={classes.inputContainer}>
            <Grid item xs={5}>
              <AgentNetTextInput
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onValidationSearch();
                  }
                }}
                variant="outlined"
                fullWidth
                label={'Digital Signature'}
                value={validationCode}
                onChange={(e) => {
                  e.target.value && e.target.value.indexOf('AVL') > -1 ? setCpl(false) : setCpl(true);
                  setValidationCode(e.target.value);
                  setDisplayError(false);
                }}
                error={displayError}
                helperText={displayError && 'You must provide a Validation Code.'}
                style={{ marginTop: displayError ? '2.2rem' : 0 }}
              />
            </Grid>
            <Grid item xs={1}>
              <AgentNetButton
                color="primary"
                size="medium"
                className="button"
                variant="contained"
                loading={status === 'pending' ? true : undefined}
                disabled={status === 'pending' ? true : undefined}
                onClick={() => {
                  onValidationSearch();
                }}
              >
                Search
              </AgentNetButton>
            </Grid>
          </Grid>
          {rows && (
            <Grid item xs={12} className={classes.textContainer}>
              <AgentNetDivider title={isCpl ? 'Additional CPL Information' : 'Additional AVL Information'} />
              <TableContainer className={classes.table}>
                <Table aria-label="simple table">
                  <TableBody>
                    {rows &&
                      rows.map((row) => (
                        <TableRow key={row.title}>
                          <TableCell component="th" scope="row" width={180} className={classes.tableCell}>
                            {row.title}:
                          </TableCell>
                          <TableCell className={classes.tableCell}>{row.value}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <AgentNetButton
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (pdfData) openDocument(pdfData, DocumentType, newWindow, WindowFeatures);
                }}
              >
                Download PDF
              </AgentNetButton>
            </Grid>
          )}
          <Grid item xs={12} className={classes.textContainer}>
            {errMsg && (
              <Typography variant="body1" color="error" className={classes.text}>
                {errMsg}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={classes.textContainer}>
            <Typography variant="h5">
              IMPORTANT: First American Title Insurance Company, provides this Closing Protection Letter and Agency
              Verification Letter verification as a service to the lender community. Any abuse of this service may
              result in your exclusion from this service or other penalties as appropriate.
            </Typography>
            <br />
            <br />
            <Typography variant="h5">
              Note: Closing Letters are generated in a PDF format. You will need a PDF Reader to view Closing Letters.
              You can get a free one at
              <Link
                href="http://www.adobe.com/products/acrobat/readstep2.html"
                target="_blank"
                className={classes.linkHref}
              >
                Adobe Acrobat
              </Link>
            </Typography>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};
export default Verification;
