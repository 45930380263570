import { makeStyles } from '@material-ui/core/styles';
import './usersList.scss';
import React from 'react';

const useStyles = makeStyles({
  tooltip: {
    backgroundColor: '#fff',
    color: ' #423d3d',
    fontSize: '13px',
    padding: '5px',
    whiteSpace: 'nowrap',
    fontFamily: 'Inter, "Helvetica Neue", Arial, sans-serif;',
    border: '1px solid lightgrey',
  },
});

type LoginUserNameRendererProps = {
  loginUserId?: string;
  handleEditClick: () => void;
};

const LoginUserNameRenderer = ({ loginUserId, handleEditClick }: LoginUserNameRendererProps) => {
  const classes = useStyles(); // Declare the 'classes' variable
  return (
    <div data-qa={loginUserId} onClick={handleEditClick} className="loginUserId">
      {loginUserId}
    </div>
  );
};

export default LoginUserNameRenderer;
