import React, { useEffect } from 'react';
import clsx from 'clsx';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { ExpandMore, ListAltOutlined, MoreHoriz } from '@material-ui/icons';
import {
  BuildingOutlined,
  CoupleOutlined,
  DocumentOutlined,
  HelpOutlined,
  IndividualOutlined,
  PinOutlined,
} from '../../icons/AgentNetIcon';

import VerifiedUserIcon from 'ui-kit/icons/VerifiedUser';
import WarningIcon from 'ui-kit/icons/Warning';
import AgentNetButton from '../button/AgentNetButton';
import PDFIcon from 'ui-kit/icons/PDF';

export interface AccordionContentProps {
  id: number | string;
  title: string;
  subtitle?: any[];
  hideSubtitle?: boolean;
  createdDate?: string;
  icon?: string;
  children?: React.ReactElement;
  expanded?: boolean;
  expandhide?: boolean;
  closeAll?: boolean;
  className?: string;
  sdnMatch?: boolean;
  isEditing?: boolean;
  isEditDisabled?: boolean;
  isVoidDisabled?: boolean;
  isUnvoidDisabled?: boolean;
  isPdfDisabled?: boolean;
  isDeleteDisabled?: boolean;
  type?: string;
  status?: string;
  onGenerateReport?: (fullAddress: string) => void;
  onDelete?: (id: number | string) => void;
  onEdit?: (id: number | string) => void;
  onVoid?: (id: number | string) => void;
  onPDF?: (id: number | string) => void;
  onMore?: (id: number | string) => void;
  onMoreLabel?: string;
  onExpandChange?: (expand: boolean, id: number | string) => void;
  onUnvoid?: (id: number | string) => void;
  generateReportQAAttribute?: string;
  deleteQAAttribute?: string;
  voidQAAttribute?: string;
  unvoidQAAttribute?: string;
  editQAAttribute?: string;
  pdfQAAttribute?: string;
  sdnQAAttribute?: string;
  accordianQAAttribute?: string;
  hideStatus?: boolean;
  openSdnPDF?: () => void;
  removeAsterisk?: boolean;
  onCustom?: (id: number | string) => void;
  onCustomLabel?: string;
  isCollapseDisabled?: boolean;
}

const Accordion = withStyles((theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '4px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
}))(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    transition: '.1s all',
    borderRadius: '4px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: -1,
    minHeight: 45,
    '&$expanded': {
      minHeight: 45,
      backgroundColor: theme.palette.brand.sky25,
    },
  },
  content: {
    margin: '8px 0',
    width: '100%',
    '&$expanded': {
      '& > .MuiSvgIcon-root': {
        transform: 'scale(1.5) translateY(-2px)',
      },
    },
    '&.Mui-expanded': {
      margin: '8px 0',
    },
  },
  expanded: {
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    backgroundColor: theme.palette.brand.sky25,
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: `20px 16px 20px`,
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryWrap: {
      display: 'flex',
    },
    iconWrap: {
      backgroundColor: 'transparent',
      borderRadius: '4px',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: `0 ${theme.spacing(1)}px`,
    },
    underwritingType: {
      backgroundColor: '#013A6F',
      color: '#FFFFFF',
      fontSize: '3.1rem',
    },
    propertyType: {
      backgroundColor: '#0C6961',
    },
    buyerType: {
      backgroundColor: '#6B21A8',
    },
    sellerType: {
      backgroundColor: '#B63F0C',
    },
    lenderType: {
      backgroundColor: '#901949',
    },
    statusDraft: {
      color: theme.palette.text.secondary,
    },
    statusOpen: {
      color: '#056B2E',
    },
    statusPending: {
      color: '#AD5F00',
    },
    statusError: {
      color: '#8F0E0E',
    },
    statusCancelled: {
      color: '#9E9E9E',
    },
    statusClosed: {
      color: '#013A6F',
    },
    statusVoid: {
      color: '#4F4178',
    },
    statusReported: {
      color: '#48799C',
    },
    cplDraft: {
      backgroundColor: '#666',
    },
    cplOpen: {
      backgroundColor: '#056B2E',
    },
    cplPending: {
      backgroundColor: '#AD5F00',
    },
    cplError: {
      backgroundColor: '#8F0E0E',
    },
    cplCancelled: {
      backgroundColor: '#9E9E9E',
    },
    cplClosed: {
      backgroundColor: '#013A6F',
    },
    cplVoid: {
      backgroundColor: '#4F4178',
    },
    cplReported: {
      backgroundColor: '#48799C',
    },
    undefinedIcon: {
      backgroundColor: 'transparent',
      border: '1px dashed #7FA9D1',
    },
    truncate: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      minHeight: '21px',
      maxWidth: '500px',
    },
    subtitleWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    subtitle: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
      maxWidth: '350px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    titleWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: `0 ${theme.spacing(1)}px`,
      height: '43px',
    },
    summaryActions: {
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center',
      '& .MuiSvgIcon-root': {
        fontSize: '2.1rem',
      },
    },
    summaryActionBtn: {
      marginLeft: `1.2rem`,
    },
    filePDFBtn: {
      marginLeft: `1.2rem`,
      minWidth: 'auto',
      padding: '3px 4px',
    },
    generateReportBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      alignItems: 'center',
    },
    expandIcon: {
      margin: `0 ${theme.spacing(1)}px`,
      transform: 'scale(1.5) rotate(-90deg)',
      transition: `.156s all`,
      fill: theme.palette.text.secondary,
      alignSelf: 'center',
    },
    moreIcon: {
      padding: '8px',
    },
    sdnResult: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 500,
      '& .MuiSvgIcon-root': {
        marginLeft: theme.spacing(0.5),
      },
    },
    isMatched: {
      color: theme.palette.error.dark,
    },
    isMatchedBtn: {
      marginLeft: '-6px',
      marginRight: '12px',
      transition: '.3s all',
      '&:hover': {
        backgroundColor: '#FDECEA',
      },
    },
    noMatch: {
      color: theme.palette.success.dark,
    },
    noMatchBtn: {
      marginLeft: '-6px',
      marginRight: '12px',
      transition: '.3s all',
      '&:hover': {
        backgroundColor: '#EFF7EE',
      },
    },
    isEditing: {
      backgroundColor: '#FBF0DB !important',
      '& .MuiAccordionSummary-content': {
        backgroundColor: 'transparent',
      },
    },
    status: {
      marginRight: theme.spacing(2),
      '& span': {
        marginRight: theme.spacing(1),
      },
    },
    cplContainer: {
      flexDirection: 'column',
    },
    cplNotice: {
      padding: `${theme.spacing(2)}px 0 ${theme.spacing(3)}px`,
    },
    titleOnly: {
      lineHeight: '3.8rem',
    },
    selectableText: {
      userSelect: 'text',
      cursor: 'text',
    },
  }),
);

export default function AccordionContent({
  title = '8 Elderberry Street, Irvine, CA 92603',
  subtitle = ['Residential (1-4 Family)', 'APN: 931-676-91', 'Orange County'],
  hideSubtitle = false,
  createdDate,
  icon,
  children,
  expanded,
  expandhide,
  closeAll,
  className,
  id,
  sdnMatch,
  isEditing,
  isEditDisabled,
  isUnvoidDisabled,
  isVoidDisabled,
  isPdfDisabled,
  isDeleteDisabled,
  type,
  status,
  onGenerateReport,
  onDelete,
  onEdit,
  onVoid,
  onUnvoid,
  onPDF,
  onMore,
  onMoreLabel,
  onExpandChange,
  generateReportQAAttribute,
  deleteQAAttribute,
  voidQAAttribute,
  unvoidQAAttribute,
  editQAAttribute,
  pdfQAAttribute,
  sdnQAAttribute,
  accordianQAAttribute,
  hideStatus,
  openSdnPDF,
  removeAsterisk,
  onCustom,
  onCustomLabel,
  isCollapseDisabled,
}: AccordionContentProps): JSX.Element {
  const classes = useStyles();
  const [expand, setExpanded] = React.useState<boolean>(expanded ? expanded : false);
  const handleChange = () => {
    if (!isCollapseDisabled) {
      setExpanded(!expand);
    }
  };

  useEffect(() => {
    expanded !== undefined && setExpanded(expanded);
  }, [expanded, closeAll]);

  useEffect(() => {
    onExpandChange && onExpandChange(expand, id);
  }, [expand]);

  useEffect(() => {
    isEditing && setExpanded(true);
  }, [isEditing]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onDelete && onDelete(id);
    handleMenuClose();
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={`more-menu-${id}`}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleDelete}>{onMoreLabel}</MenuItem>
    </Menu>
  );

  const removeAsteriskFn = (title: string) => {
    const i = title.indexOf('*');
    if (i !== -1) {
      return title.slice(0, i) + title.slice(i + 1);
    }
    return title;
  };

  return (
    <div className={className}>
      <Accordion square expanded={expand} onChange={handleChange}>
        <AccordionSummary
          aria-controls={`panel-${id}`}
          id={`panel-${id}`}
          {...(accordianQAAttribute ? { 'data-qa': accordianQAAttribute } : {})}
          className={isEditing ? classes.isEditing : ''}
        >
          <ExpandMore fontSize="large" className={classes.expandIcon} visibility={expandhide ? 'hidden' : 'visible'} />
          <div className={classes.summaryWrap}>
            {type && (
              <div
                className={clsx(classes.iconWrap, {
                  [classes.underwritingType]: type === 'underwriting',
                  [classes.propertyType]: type === 'property',
                  [classes.buyerType]: type === 'buyer' || type === 'Buyer/Borrower',
                  [classes.sellerType]: type === 'seller' || type === 'Seller/Owner',
                  [classes.lenderType]: type === 'lender' || type === 'Lender',
                  [classes.undefinedIcon]: type === 'unknown',
                  [classes.cplDraft]: status === 'Draft',
                  [classes.cplOpen]: status === 'Open' || status === 'Order Placed',
                  [classes.cplError]: status === 'Error' || status === 'Opened In Error',
                  [classes.cplPending]: status === 'Pending' || status === 'Pending Remit' || status === 'Pending Void',
                  [classes.cplCancelled]: status === 'Cancelled',
                  [classes.cplClosed]: status === 'Closed',
                  [classes.cplVoid]: status === 'Void',
                  [classes.cplReported]: status === 'Reported',
                  [classes.cplReported]:
                    status === 'Remitted' ||
                    status === 'Reported' ||
                    status === 'Order Delivered' ||
                    status === 'Update Delivered' ||
                    status === 'Update Requested',
                })}
              >
                {icon === 'property' && <PinOutlined />}
                {icon === 'Individual' && <IndividualOutlined />}
                {icon === 'A Married Couple' && <CoupleOutlined />}
                {icon === 'Entity' && <BuildingOutlined />}
                {icon === 'Trust/Estate' && <BuildingOutlined />}
                {icon === 'Lender' && <BuildingOutlined />}
                {icon === 'unknown' && <HelpOutlined />}
                {icon === 'document' && <DocumentOutlined />}
                {icon === 'list' && <ListAltOutlined fontSize="inherit" color="inherit" />}
              </div>
            )}
            <div className={classes.titleWrap}>
              <Typography variant="h5" className={clsx(classes.truncate, { [classes.titleOnly]: hideSubtitle })}>
                <span onClick={(e) => e.stopPropagation()} className={classes.selectableText}>
                  {removeAsterisk ? removeAsteriskFn(title) : title}
                </span>
              </Typography>
              {hideSubtitle === false && (
                <div className={classes.subtitleWrap}>
                  {!hideStatus && status && (
                    <Typography
                      variant="body2"
                      className={clsx(classes.status, {
                        [classes.statusDraft]: status === 'Draft',
                        [classes.statusOpen]:
                          status === 'Open' || status === 'Order Placed' || status === 'Update Requested',
                        [classes.statusError]: status === 'Error' || status === 'Opened In Error',
                        [classes.statusPending]:
                          status === 'Pending' || status === 'Pending Remit' || status === 'Pending Void',
                        [classes.statusCancelled]: status === 'Cancelled',
                        [classes.statusClosed]: status === 'Closed',
                        [classes.statusVoid]: status === 'Void',
                        [classes.statusReported]:
                          status === 'Reported' || status === 'Order Delivered' || status === 'Update Delivered',
                      })}
                    >
                      <span>&#x25cf;</span>
                      {status}
                    </Typography>
                  )}

                  {sdnMatch === true && (
                    <AgentNetButton
                      variant="text"
                      className={classes.isMatchedBtn}
                      size="small"
                      endIcon={<WarningIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        openSdnPDF && openSdnPDF();
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={clsx(classes.sdnResult, classes.isMatched)}
                        Data-QA={sdnQAAttribute + 'Match'}
                      >
                        SDN Match
                      </Typography>
                    </AgentNetButton>
                  )}
                  {sdnMatch === false && (
                    <AgentNetButton
                      variant="text"
                      className={classes.noMatchBtn}
                      size="small"
                      endIcon={<VerifiedUserIcon />}
                      onClick={(e) => {
                        e.stopPropagation();
                        openSdnPDF && openSdnPDF();
                      }}
                    >
                      <Typography
                        variant="body2"
                        className={clsx(classes.sdnResult, classes.noMatch)}
                        Data-QA={sdnQAAttribute + 'NoMatch'}
                      >
                        SDN
                      </Typography>
                    </AgentNetButton>
                  )}
                  {subtitle.length ? (
                    <>
                      {subtitle.map((e) => (
                        <Typography variant="body2" key={e} className={classes.subtitle}>
                          <span onClick={(e) => e.stopPropagation()} className={classes.selectableText}>
                            {e}
                          </span>
                        </Typography>
                      ))}
                    </>
                  ) : (
                    <Typography variant="body2" className={classes.subtitle}>
                      -
                    </Typography>
                  )}
                  {createdDate ? (
                    <Typography variant="body2" className={classes.subtitle}>
                      <span onClick={(e) => e.stopPropagation()} className={classes.selectableText}>
                        {`Created: ${createdDate}`}
                      </span>
                    </Typography>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className={classes.summaryActions}>
            {onEdit && (
              <AgentNetButton
                variant="outlined"
                className={classes.summaryActionBtn}
                disabled={isEditDisabled ? isEditDisabled : isEditing}
                onClick={(e) => {
                  onEdit(id);
                }}
                {...(editQAAttribute ? { 'data-qa': editQAAttribute } : {})}
              >
                {isEditing ? 'Editing' : 'Edit'}
              </AgentNetButton>
            )}
            {onVoid && (
              <AgentNetButton
                variant="outlined"
                className={classes.summaryActionBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  onVoid(id);
                }}
                disabled={isVoidDisabled}
                {...(voidQAAttribute ? { 'data-qa': voidQAAttribute } : {})}
              >
                Void
              </AgentNetButton>
            )}
            {onUnvoid && (
              <AgentNetButton
                variant="outlined"
                className={classes.summaryActionBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  onUnvoid(id);
                }}
                disabled={isUnvoidDisabled && isUnvoidDisabled}
                {...(unvoidQAAttribute ? { 'data-qa': unvoidQAAttribute } : {})}
              >
                Unvoid
              </AgentNetButton>
            )}
            {onGenerateReport && (
              <AgentNetButton
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  onGenerateReport(title);
                }}
                {...(generateReportQAAttribute ? { 'data-qa': generateReportQAAttribute } : {})}
              >
                <span className={classes.generateReportBtn}>Generate Property Report</span>
              </AgentNetButton>
            )}
            {onDelete && (
              <AgentNetButton
                variant="text"
                className={classes.summaryActionBtn}
                danger
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(id);
                }}
                disabled={isDeleteDisabled && isDeleteDisabled}
                {...(deleteQAAttribute ? { 'data-qa': deleteQAAttribute } : {})}
              >
                Delete
              </AgentNetButton>
            )}
            {onCustom && (
              <AgentNetButton
                variant="outlined"
                className={classes.summaryActionBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  onCustom(id);
                }}
                disabled={isDeleteDisabled && isDeleteDisabled}
                {...(deleteQAAttribute ? { 'data-qa': deleteQAAttribute } : {})}
              >
                {onCustomLabel ? onCustomLabel : 'Action'}
              </AgentNetButton>
            )}
            {onPDF && (
              <AgentNetButton
                variant="outlined"
                className={classes.filePDFBtn}
                onClick={(e) => {
                  e.stopPropagation();
                  onPDF(id);
                }}
                disabled={isPdfDisabled && isPdfDisabled}
                {...(pdfQAAttribute ? { 'data-qa': pdfQAAttribute } : {})}
              >
                <PDFIcon />
              </AgentNetButton>
            )}
            {onMore && (
              <>
                <IconButton
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMenuOpen(e);
                  }}
                  onFocus={(e) => e.stopPropagation()}
                  className={classes.moreIcon}
                >
                  <MoreHoriz fontSize="large" />
                </IconButton>
                {renderMenu}
              </>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </div>
  );
}
