import React from 'react';
import { Switch, useLocation } from 'react-router-dom';
import RouteWithSubRoutes from '../routes/RouteWithSubRoutes';
import Grid from '@material-ui/core/Grid';

type RouteContentWrapperProps = {
  routes: Array<never>;
  className?: string;
};

// A wrapper for <Route> that handles "sub"-routes by passing them as
// `routes` prop to the component it renders.
const NestedRouteLayout = ({ routes, className }: RouteContentWrapperProps): JSX.Element => {
  const location = useLocation();

  // For some paths, we want to hide the left nav
  const pathsWithoutLeftNav = ['/files'];

  return (
    <div id="core-content-container" className={className && className}>
      <Grid container>
        <>
          <Grid item xs={12}>
            <div>
              {/* ROUTES */}
              <Switch>
                {routes?.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
              </Switch>
            </div>
          </Grid>
        </>
      </Grid>
    </div>
  );
};
export default NestedRouteLayout;
