import React, { FocusEvent, useEffect, useRef, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  TextField,
  TextFieldProps,
  useForkRef,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export type ObscurableFieldProps = {
  /*For resetting the visiblity icon */
  resetVisibility?: boolean;
  /**
   * obscurePattern: substring or regexp to obscure defaults to /./g
   */
  obscurePattern?: string | RegExp;
  /**
   * obscureReplacer: replacement text string or function used to return replacement text see
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_function_as_a_parameter
   **/
  obscureReplacer?: string | ((substring: string, ...args: Array<string | number>) => string);
} & TextFieldProps;

/**
 * Component that handles obscuring the input
 */
function ObscurableInput({
  isVisible,
  pattern = /./g,
  replacer = '•',
  inputRef,
  onChange,
  inputComponent: InputComponent = 'input',
  ...props
}: InputBaseComponentProps) {
  // Fork ref so we can access the value
  const innerRef = useRef<HTMLInputElement | null>(null);
  const handleRef = useForkRef(innerRef, inputRef);
  const patterns = ['__-_______', '___-__-____'];
  const checkPattern = (value: any) => patterns.indexOf(value) !== -1;
  const moveCaretToStart = (el: any) => {
    if (typeof el.selectionStart == 'number') {
      el.selectionStart = el.selectionEnd = 0;
    } else if (typeof el.createTextRange != 'undefined') {
      el.focus();
      const range = el.createTextRange();
      range.collapse(true);
      range.select();
    }
  };

  const handleCaretPosition = (e: any) => {
    e.target.focus();
    if (checkPattern(props.value) || props.value === '') {
      setTimeout(() => moveCaretToStart(e.target), 0);
    }
  };
  return (
    <>
      <input
        style={{ ...(isVisible && { height: '0', width: '0', position: 'absolute' }) }}
        {...props}
        aria-label="obscurable-display"
        /* Focus InputComponent onFocus and onBlur so not to lose focus - needed for accessibility across different browsers*/
        // onFocus={() => innerRef.current?.focus()}
        // onBlur={() => innerRef.current?.focus()}
        value={
          checkPattern(props.value) ? '' : (props.value || innerRef?.current?.value || '').replace(pattern, replacer)
        }
        readOnly
        tabIndex={-1}
      />
      <InputComponent
        {...props}
        aria-label="obscurable-input"
        style={{ ...(!isVisible && { height: '0', width: '0', position: 'absolute', pointerEvents: 'none' }) }}
        onChange={onChange}
        ref={handleRef}
        onKeyUp={(e: any) => {
          if (e.keyCode === 9) {
            handleCaretPosition(e);
          }
        }}
        onMouseOver={(e: any) => {
          handleCaretPosition(e);
        }}
      />
    </>
  );
}

export function ObscurableField({
  obscurePattern = /./g,
  obscureReplacer = '•',
  onFocus = () => undefined,
  onBlur = () => undefined,
  InputProps = {},
  resetVisibility = false,
  disabled,
  ...props
}: ObscurableFieldProps): JSX.Element {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (resetVisibility) {
      setVisible(false);
    }
  }, [resetVisibility]);
  const toggleVisible = () => setVisible(!isVisible);

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    setVisible(true);
    onFocus(event);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setVisible(false);
    onBlur(event);
  };

  return (
    <TextField
      {...props}
      variant="outlined"
      onFocus={handleFocus}
      onBlur={handleBlur}
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        ...InputProps,
        inputComponent: ObscurableInput,
        inputProps: {
          isVisible,
          pattern: obscurePattern,
          replacer: obscureReplacer,
          inputComponent: InputProps.inputComponent,
          ...InputProps.inputProps,
        },
        endAdornment: !disabled ? (
          <InputAdornment position="end" tabIndex={-1}>
            <IconButton size="small" tabIndex={-1} onClick={toggleVisible}>
              {!isVisible ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
}
export default ObscurableField;
