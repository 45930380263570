import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';
import { AccessTime } from '@material-ui/icons';
import { createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import { FieldValidationError } from 'utilities/validation/validation';
import ErrorSection from 'features/files/file-create/ErrorSection';

export type TimeFieldProps = KeyboardTimePickerProps & {
  disableNative?: boolean;
  step?: number;
  onChange?: (time: string | null) => void;
  name?: string;
  errs?: FieldValidationError[];
  showValidation?: boolean;
  qaAttribute?: string;
  disabled?: boolean;
  value?: any;
};

export type TimePickerEventType = {
  eventTime: Date | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nativeInput: {
      marginRight: theme.spacing(2),
      minWidth: 200,
      '& input[type="time"]::-webkit-calendar-picker-indicator': {
        width: `24px`,
        height: '24px',
        backgroundImage: `url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.5 12C2.5 6.48 6.97 2 12.49 2C18.02 2 22.5 6.48 22.5 12C22.5 17.52 18.02 22 12.49 22C6.97 22 2.5 17.52 2.5 12ZM4.5 12C4.5 16.42 8.08 20 12.5 20C16.92 20 20.5 16.42 20.5 12C20.5 7.58 16.92 4 12.5 4C8.08 4 4.5 7.58 4.5 12ZM11.5 7H13V12.25L17.5 14.92L16.75 16.15L11.5 13V7Z' fill='%230054A4'/%3E%3C/svg%3E%0A")`,
      },
      '& input': {
        paddingRight: `4px`,
      },
    },
  }),
);

const DEFAULT_TIME_FORMAT = 'hh:mm a';
const DEFAULT_VARIANT_TYPE = 'inline';

const TimeField: FC<TimeFieldProps> = (props: TimeFieldProps) => {
  const [fieldValidationErrors, setFieldValidationError] = useState(false);
  const [displayErrors, setDisplayError] = useState(props.showValidation);

  const classes = useStyles();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  useEffect(() => {
    const hasError = props.errs?.find((err) => err.field === props.name);
    hasError ? setFieldValidationError(true) : setFieldValidationError(false);
  }, [props.errs]);

  useEffect(() => {
    setDisplayError(props.showValidation);
  }, [props.showValidation]);

  return (
    <>
      {!props.disableNative ? (
        <TextField
          label={props.label && props.label}
          type="time"
          defaultValue=""
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: props.step ? props.step : 300, // 5 min
          }}
          className={classes.nativeInput}
          placeholder={DEFAULT_TIME_FORMAT}
          onChange={handleChange}
          error={fieldValidationErrors && displayErrors}
          data-qa={props.qaAttribute}
          disabled={props.disabled}
          value={props.value}
        />
      ) : (
        <KeyboardTimePicker
          autoOk
          keyboardIcon={<AccessTime fontSize="large" />}
          KeyboardButtonProps={{
            size: 'medium',
            disableFocusRipple: true,
            disableRipple: true,
            color: 'primary',
            edge: 'end',
          }}
          format={DEFAULT_TIME_FORMAT}
          data-qa={props.qaAttribute}
          {...props}
          variant={DEFAULT_VARIANT_TYPE}
          inputVariant="outlined"
          error={fieldValidationErrors && displayErrors}
          disabled={props.disabled}
          value={props.value}
        />
      )}
      {fieldValidationErrors && props.name && displayErrors && <ErrorSection errs={props.errs} field={props.name} />}
    </>
  );
};

export default TimeField;
