import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Radio from '@material-ui/core/Radio';
import RGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './RadioGroup.scss';
import ErrorSection from '../../../features/files/file-create/ErrorSection';
import { FieldValidationError } from '../../../utilities/validation/validation';

export type RadioProps = {
  label: string;
  value: string;
  dataQA?: string;
};

export type RadioGroupProps = {
  options: Array<RadioProps>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  disabled?: boolean;
  selected?: any;
  row?: boolean;
  border?: boolean;
  compact?: boolean;
  errs?: FieldValidationError[];
  className?: string;
  name?: string;
  required?: boolean;
  showValidation?: boolean;
  removeMarginTop?: boolean;
};

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 500,
    fontSize: '1.3rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.8),
  },
  required: {
    color: '#AC1209',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  removeMarginTop: {
    marginTop: '-12px',
  },
}));

export default function RadioGroup({
  options,
  onChange,
  label,
  selected,
  disabled = false,
  row = true,
  border = true,
  compact,
  errs,
  className,
  name,
  required,
  showValidation,
  removeMarginTop,
  ...props
}: RadioGroupProps): JSX.Element {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(selected ? selected.value : '');
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(showValidation);

  useEffect(() => {
    const hasError = errs?.find((err) => err.field === name);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setChecked(e.target.value);
  };

  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);

  return (
    <FormControl component="fieldset" className={clsx({ [classes.removeMarginTop]: removeMarginTop })}>
      {label && (
        <FormLabel component="legend">
          <Typography variant="body1" className={classes.label}>
            {label} {required && <span className={classes.required}>*</span>}
          </Typography>
        </FormLabel>
      )}
      <RGroup
        className="radio-group-wrap"
        row={row}
        aria-label="position"
        name="position"
        defaultValue="top"
        value={checked}
        onChange={handleChange}
      >
        {options.map((option, i) => (
          <FormControlLabel
            className={`radio-group-button ${option.value === checked ? 'active' : ''} ${disabled ? 'disabled' : ''} ${
              border ? 'border' : ''
            } ${compact ? 'compact' : ''} ${className && className}`}
            Data-QA={option.dataQA}
            key={option.value}
            value={option.value}
            disabled={disabled}
            control={<Radio color="primary" disableRipple disableFocusRipple />}
            label={
              <Typography className="radio-group-button-label" variant="body1">
                {option.label}
              </Typography>
            }
          />
        ))}
      </RGroup>
      {errors && displayErrors && <ErrorSection errs={errs} field={name ?? ''} />}
    </FormControl>
  );
}
