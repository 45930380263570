import React from 'react';
import AddressBookLender from '../AddressBook/AddressBookLender';
import AddressBookClosingAttorney from '../AddressBook/AddressBookClosingAttorney';
import { ContactType } from '../constants';

interface AddressBookContactContainerProps {
  openDrawer: any;
  setOpenDrawer: any;
  data: any;
  contactType: any;
}

const AddressBookContactForm = ({
  openDrawer,
  setOpenDrawer,
  contactType,
  data,
}: AddressBookContactContainerProps): JSX.Element => {
  return (
    <>
      {contactType == ContactType.LENDER.toString() ? (
        <AddressBookLender data={data} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}></AddressBookLender>
      ) : contactType == ContactType.CLOSING_ATTORNEY.toString() ? (
        <AddressBookClosingAttorney
          data={data}
          openDrawer={openDrawer}
          setOpenDrawer={setOpenDrawer}
        ></AddressBookClosingAttorney>
      ) : null}
    </>
  );
};

export default AddressBookContactForm;
