import { useAuth } from '@agentnet/auth';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AgGridReact } from 'ag-grid-react';
import { getDocument } from 'api/myReports/report-api';
import useAsync from 'hooks/useAsync';
import React, { useEffect, useMemo, useState } from 'react';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import clsx from 'clsx';
import { StatusRenderer } from 'features/files/files-summary/files-list/filesListConfigs';
import { openDocument } from 'utilities/utilities';
import { DocumentType, newWindow, WindowFeatures, xlsDocType } from 'features/constants';
import { ReportDocumentRequest } from './types';
import { ColDef } from 'ag-grid-community';
import NoResults from 'ui-kit/components/noResults/NoResults';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { DocumentPDF, DocumentXLS } from 'ui-kit/icons/DocIcons';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';

export interface MyReportsListInterface {
  getHistoryResults?: [];
}
const MyReportsList = ({ getHistoryResults }: MyReportsListInterface) => {
  const { getAccessToken } = useAuth();
  const [selectedRows, setSelectedRows] = useState<any>([]);
  const [historyRowData, setHistoryRowData] = useState<any>([]);
  const [reportDocumentRequest, setReportDocumentRequest] = useState<ReportDocumentRequest>();
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const { addGlobalMsg } = useGlobalMessages();
  const [searchFlag, setSearchFlag] = useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paddingX: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: 'auto',
      },
      tableDefault: {
        height: `calc(100vh - ${selectedRows?.length ? '356px' : '296px'}) !important`,
        fontFamily: theme.typography.fontFamily,
      },
      selectedRows: {
        paddingBottom: 12,
      },
      selectedRowsActive: {
        marginBottom: '72px',
        paddingBottom: 0,
      },
      filterWrap: {
        marginBottom: theme.spacing(2),
        display: 'flex',
      },
      filterDash: {
        fontWeight: 'bold',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      mr1: {
        marginRight: theme.spacing(1),
      },
      mr2: {
        marginRight: theme.spacing(2),
      },
      contentClass: {
        margin: '0 auto auto',
      },
      docButton: {
        paddingBottom: 1,
      },
    }),
  );
  const classes = useStyles();

  const getDocumentData = async (): Promise<{ result: any; errorCode?: string | number }> => {
    setSearchFlag(true);
    const token = await getAccessToken();
    return await getDocument(reportDocumentRequest?.reportQueueId ?? 0, token);
  };

  const {
    execute: executeGetDocument,
    status: getDocumentStatus,
    value: getDocumentResults,
    errors: getDocuumentErrors,
  } = useAsync<any>(getDocumentData, false);

  useEffect(() => {
    setDisplayError(getDocumentStatus === 'error');
  }, [getDocumentStatus]);

  useEffect(() => {
    const errorMessages = getDocuumentErrors?.length ? getDocuumentErrors : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
      setSearchFlag(false);
    }
  }, [displayError, errMsgs]);

  useEffect(() => {
    if (getHistoryResults) {
      getHistoryResults?.map((x: any) => {
        const date = ConvertDateFormat(x.CreatedDate);
        x.CreatedDate = date;
      });
      setHistoryRowData(getHistoryResults);
    }
  }, [getHistoryResults]);

  useEffect(() => {
    if (getDocumentStatus === 'success') {
      if (getDocumentResults?.DocumentData && getDocumentResults?.ReportFormat === 'PDF') {
        openDocument(getDocumentResults?.DocumentData, DocumentType, newWindow, WindowFeatures);
      } else if (getDocumentResults?.DocumentData && getDocumentResults?.ReportFormat === 'XLS') {
        openDocument(getDocumentResults?.DocumentData, xlsDocType, newWindow, WindowFeatures);
      }
      setSearchFlag(false);
    }
  }, [getDocumentStatus]);

  const onViewDocument = (reportQueueId: number) => {
    setReportDocumentRequest({ reportQueueId: reportQueueId });
  };

  const ConvertDateFormat = (dateToBeFormatted: string) => {
    const date_arr = dateToBeFormatted.split('T');
    const new_date_arr = date_arr[0].split('-');
    const date = new_date_arr[1] + '/' + new_date_arr[2] + '/' + new_date_arr[0];
    const time_arr = date_arr[1].split(':');
    const time = time_arr[0] + ':' + time_arr[1] + ':' + time_arr[2].split('.')[0];

    const formattedDate = date + ' ' + time + ' PT';
    return formattedDate;
  };

  useEffect(() => {
    if (reportDocumentRequest) executeGetDocument().then();
  }, [reportDocumentRequest]);

  function generateIcon(fileType?: any) {
    if (fileType === 'PDF') {
      return <DocumentPDF />;
    }
    if (fileType === 'EXCEL') {
      return <DocumentXLS />;
    }
  }

  const historyColumnsConfig: any = [
    {
      headerName: 'Created Date',
      field: 'CreatedDate',
      tooltipField: 'CreatedDate',
      filter: 'false',
      floatingFilter: false,
    },
    {
      headerName: 'Report Name',
      field: 'ReportName',
      tooltipField: 'ReportName',
      filter: 'false',
      floatingFilter: false,
    },
    {
      headerName: 'Report Description',
      field: 'ReportDescription',
      tooltipField: 'ReportDescription',
      filter: 'false',
      floatingFilter: false,
    },
    {
      headerName: 'Status',
      field: 'QueueStatus',
      tooltipField: 'QueueStatus',
      cellRenderer: StatusRenderer,
      filter: 'false',
      floatingFilter: false,
    },
    {
      field: '',
      maxWidth: 38,
      filter: false,
      floatingFilter: false,
      tooltipField: 'View Document',
      cellRenderer: (params: any) => {
        {
          return (
            <div className={classes.docButton}>
              {params?.data?.QueueStatus == 'Processed' && (
                <AgentNetButton
                  onClick={(e1) => onViewDocument(params?.data?.ReportQueueId)}
                  data-qa="ServiceOrderDocumentView"
                >
                  {generateIcon(params.data.ReportFormat)}
                </AgentNetButton>
              )}
            </div>
          );
        }
      },
      cellStyle: { display: 'flex', textAlign: 'right', justifyContent: 'center', alignItems: 'center' },
      pinned: 'right',
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 2,
      floatingFilter: true,
      filter: true,
      sortable: true,
      resizable: false,
      editable: false,
      suppressMenu: true,
      filterParams: { closeOnApply: true, suppressAndOrCondition: true },
    };
  }, []);

  const CustomOverlayNoRows = () => {
    if (!historyRowData || historyRowData.length === 0) {
      return (
        <div className={classes.contentClass}>
          <NoResults
            showButton={false}
            type="results"
            titleText="No Results Found"
            pText="Try adjusting your filters to find what you’re looking for."
            dataQA="MyReportNoResultsFound"
          />
        </div>
      );
    }
  };

  return (
    <>
      <div className="paper-layout contained">
        <LoadingSpinner status={searchFlag ? 'pending' : 'success'} variant="linear" />
      </div>
      <div>
        <AgentNetDivider
          typoVariant="h1"
          title="Search Results"
          subtitle="Reports will be sent to your profile email address. Retained in the log for 24 hours. All dates and times are in Pacific Time."
          subtitleMuted
        />
      </div>
      <div className={clsx('ag-theme-alpine', classes.tableDefault, classes.paddingX)}>
        <AgGridReact
          rowData={historyRowData}
          columnDefs={historyColumnsConfig}
          defaultColDef={defaultColDef}
          rowSelection={'multiple'}
          suppressRowClickSelection={true}
          //onSelectionChanged={onSelectionChanged}
          //   onFilterChanged={onFilterChanged}
          //   onFilterOpened={false}
          //isExternalFilterPresent={isExternalFilterPresent()=></div>}
          //   doesExternalFilterPass={tabName === 'View' ? doesExternalFilterPass1 : doesExternalFilterPass2}
          components={{
            customOverlayNoRows: CustomOverlayNoRows,
          }}
          noRowsOverlayComponent={'customOverlayNoRows'}
        ></AgGridReact>
      </div>
    </>
  );
};
export default MyReportsList;
