import { useAuth } from '@agentnet/auth';
import { getEndorsements, getJacketTypes, voidJacket, postJackets, getJacketPdf, putJackets } from 'api/jacket-api';
import React, { useEffect, useReducer, useState } from 'react';
import { Endorsement, Jacket, JacketEndorsement, JacketPdf, PolicyField, PolicyType, Warning } from './types';
import useAsync from 'hooks/useAsync';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import JacketForm from './JacketForm';
import JacketActions from './JacketActions';
import {
  JacketStatus,
  JacketsActions,
  noActiveJackets,
  openJacketsLimit,
  policiesWithDefaultPolicyAmount,
} from './constants';
import { Container } from '@material-ui/core';
import { Notification } from 'ui-kit/components/notification/Notification';
import { AgentNetConfirmationDialog } from 'ui-kit/components/modal/ConfirmationDialog';
import { openDocument } from 'utilities/utilities';
import { newWindow, WindowFeatures, DocumentType } from '../constants';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { fileInfoType, propertyType } from 'api/file/interfaces/get-file';

interface JacketProps {
  fileId?: string;
  state?: string;
  county?: string;
  underwriter?: string;
  setJacketFlag?: any;
  accountNumber?: number;
  jacketList?: Jacket[] | null;
  fileData?: fileInfoType | null;
  isAddNewJacketClicked?: boolean;
  lenders?: any[];
  OnCancel?: () => void;
  handleAddNewJacket?: () => void;
  refreshJacketsData?: () => void;
  addWarnings: (warningMessage: Warning[]) => void;
  warnings: Warning[] | null;
  hideWarningOnCancel: (isWarning: boolean) => void;
  showWarnings: boolean;
}
const JacketFC: React.FC<JacketProps> = ({
  fileId,
  state,
  county,
  underwriter,
  setJacketFlag,
  accountNumber,
  jacketList,
  fileData,
  isAddNewJacketClicked,
  lenders,
  OnCancel,
  handleAddNewJacket,
  refreshJacketsData,
  addWarnings,
  warnings,
  hideWarningOnCancel,
  showWarnings,
}: JacketProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        background: theme.palette.common.white,
      },
      jacketsWrap: {
        padding: `0 2.4rem 8.8rem`,
      },
      mb2: {
        marginBottom: theme.spacing(2),
      },
      warningMsg: { marginBottom: theme.spacing(3) },
    }),
  );

  const { getAccessToken } = useAuth();
  const randomId = () => (Math.random() + 1).toString(36).substring(7);
  const classes = useStyles();

  const [draftMode, setDraftMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [jacketTypes, setJacketTypes] = useState<PolicyType[]>([]);
  const [jackets, setJackets] = useState<Jacket[]>([]);
  const [initialJackets, setInitialJackets] = useState<Jacket[]>([]);
  const [activeJacketsCount, setActiveJacketsCount] = useState<number>(0);

  const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
  const [updateAttempted, setUpdateAttempted] = useState<boolean>(false);
  const [endorsementType, setEndorsementType] = useState<Endorsement[]>([]);

  const { addGlobalMsg } = useGlobalMessages();
  const { addSnackbarMessage } = useSnackBars();
  const [errMsgs, setErrMsgs] = useState([]);
  const [openVoidModal, setOpenVoidModal] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [selectedPolicyNumber, setSelectedPolicyNumber] = useState<any>('');
  const [editingPolicyNumber, setEditingPolicyNumber] = useState<any>('');
  const [triggerSaveCall, setTriggerSaveCall] = useState(false);
  const [triggerUpdateCall, setTriggerUpdateCall] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const [isdeleteJacket, setIsDeleteJacket] = useState(false);
  const [deleteJacketRandomId, setDeleteJacketRandomId] = useState<any>('');
  const [closeEndorsement, setCloseEndorsement] = useState(false);
  const [disableCreate, setDisableCreate] = useState(false);
  const [openCancelModel, setOpenCancelModel] = useState(false);
  const [enableCancelJacket, setEnableCancelJacket] = useState(false);

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case JacketsActions.edit:
        return { ...state, isEditing: true, selectedPolicyNumber: action.payload };
      case JacketsActions.view:
        return { ...state, isEditing: false, selectedPolicyNumber: '', closeAllActive: !state.closeAllActive };
      case JacketsActions.expandCollapseActive:
        return { ...state, isExpandActive: action.payload, closeAllActive: !state.closeAllActive };
      case JacketsActions.expandCollapseInActive:
        return { ...state, isExpandInActive: action.payload, closeAllInActive: !state.closeAllInActive };
      case JacketsActions.onInactiveToggleChange:
        return { ...state, isInactiveToggle: !state.isInactiveToggle };
      case 'OnExpandChangeActive':
        return { ...state, closeAllActive: false };
      case 'OnExpandChangeInActive':
        return { ...state, closeAllInActive: false };
      case 'OnChangingJacketId':
        return { ...state, selectedPolicyNumber: action.payload };
      default:
        throw new Error();
    }
  };

  const [jacketActionsState, dispatch] = useReducer(reducer, {
    isEditing: false,
    selectedPolicyNumber: '',
    isExpandActive: false,
    isExpandInActive: false,
    isInactiveToggle: false,
    closeAllActive: false,
    closeAllInActive: false,
  });

  const fetchJacketTypes = async (): Promise<PolicyType[]> => {
    const token = await getAccessToken();
    return getJacketTypes(state ?? '', accountNumber ?? 0, token);
  };

  const {
    execute: executeGetJacketTypes,
    value: getJacketTypesValue,
    status: getJacketTypesStatus,
  } = useAsync<PolicyType[]>(fetchJacketTypes, false);

  const saveJacketsData = async (): Promise<Jacket[]> => {
    const token = await getAccessToken();
    return postJackets(
      {
        fileId: fileId,
        accountNumber: accountNumber ?? 0,
        jackets: jackets.filter((x) => x.formNumber !== ''),
        fileDetails: undefined,
      },
      token,
    );
  };

  const updateJacketsData = async (): Promise<Jacket[]> => {
    const token = await getAccessToken();
    return putJackets(
      {
        fileId: fileId,
        accountNumber: accountNumber ?? 0,
        jackets: jackets.filter(
          (x) => x.formNumber !== '' && x.policyStatus === JacketStatus.open && x.policyNumber === editingPolicyNumber,
        ),
        fileDetails: null,
      },
      token,
    );
  };

  const {
    execute: executeSaveJacketsData,
    value: saveJacketsDataValue,
    status: saveJacketsDataStatus,
    errors: saveJacketDataError,
  } = useAsync<Jacket[] | any>(saveJacketsData, false);

  const {
    execute: executeUpdateJacketsData,
    value: updateJacketsDataValue,
    status: updateJacketsDataStatus,
    errors: updateJacketDataError,
  } = useAsync<Jacket[] | any>(updateJacketsData, false);

  useEffect(() => {
    setTriggerSaveCall(false);
  }, [saveJacketsDataValue, saveJacketDataError]);

  useEffect(() => {
    setTriggerUpdateCall(false);
  }, [updateJacketsDataValue, updateJacketDataError]);

  useEffect(() => {
    if (getJacketTypesValue) {
      setJacketTypes(getJacketTypesValue);
    }
  }, [getJacketTypesValue]);

  useEffect(() => {
    if (saveJacketsDataValue || updateJacketsDataValue) {
      jacketWarningMessage(saveJacketsDataValue ? saveJacketsDataValue ?? [] : updateJacketsDataValue ?? []);
      //refreshJacketsData && refreshJacketsData();
      saveJacketsDataValue?.jackets?.map((jacket: any) => {
        if (jacket.document) openDocument(jacket.document, DocumentType, newWindow, WindowFeatures);
      });
      updateJacketsDataValue?.jackets?.map((jacket: any) => {
        if (jacket.document) openDocument(jacket.document, DocumentType, newWindow, WindowFeatures);
      });
    }
  }, [saveJacketsDataValue, updateJacketsDataValue]);

  useEffect(() => {
    if (warnings && showWarnings) {
      setIsWarning(true);
    }
  }, [warnings]);

  useEffect(() => {
    if (jacketList) {
      const getactiveJacketsCount = jacketList?.filter(
        (item) =>
          item.policyStatus === JacketStatus.open ||
          item.policyStatus === JacketStatus.remitted ||
          item.policyStatus === JacketStatus.pendingRemit,
      ).length;
      if (getactiveJacketsCount === 0) {
        dispatch({ type: JacketsActions.onInactiveToggleChange });
      }
      setInitialJackets(jacketList);
      setJackets(jacketList);
      setActiveJacketsCount(getactiveJacketsCount ?? 0);
    }
  }, [jacketList]);

  const voidSingleJacket = async (): Promise<unknown> => {
    const token = await getAccessToken();
    return voidJacket(jacketActionsState.selectedPolicyNumber ?? '', token);
  };

  const {
    execute: executeVoidJacket,
    value: voidJacketValue,
    status: voidStatus,
    errors: voidJacketError,
  } = useAsync<any>(voidSingleJacket, false);

  useEffect(() => {
    if (voidJacketValue?.policyStatus) {
      // setDisplayError(false);
      addSnackbarMessage({
        message: 'Voided Jacket Successfully',
        type: 'success',
      });
      if (voidStatus === 'success' || voidStatus === 'pending') {
        setActiveJacketsCount((prevCount) => prevCount - 1);
      }

      refreshJacketsData && refreshJacketsData();

      setEditMode(false);
      jacketActionsState.isEditing = false;
    }
    setOpenVoidModal(false);
  }, [voidJacketValue]);

  useEffect(() => {
    setDisplayError(saveJacketsDataStatus === 'error' || voidStatus === 'error' || updateJacketsDataStatus === 'error');
  }, [saveJacketsDataStatus, voidStatus, updateJacketsDataStatus]);

  useEffect(() => {
    const errorMessages = voidJacketError?.length
      ? voidJacketError
      : saveJacketDataError?.length
      ? saveJacketDataError
      : updateJacketDataError?.length
      ? updateJacketDataError
      : jacketPdfError?.length
      ? jacketPdfError
      : [];
    setErrMsgs(errorMessages);
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [displayError, errMsgs]);

  const handleCloseDialog = () => {
    if (voidStatus === 'pending') return;
    setOpenVoidModal(false);
  };

  const handleVoidJacketDialog = () => {
    executeVoidJacket().then();
  };

  const emptyJacket: Jacket = {
    policyNumber: '',
    formNumber: '',
    policyType: '',
    policyStatus: '',
    policyAmount: 0,
    rateType: 0,
    policyFields: [],
    jacketEndorsements: [],
    jacketIncorporatedEndorsements: [],
    randomId: randomId(),
    expanded: true,
    status: 'Draft',
    lenderId: 0,
  };

  const fetchEndorsements = async (): Promise<Endorsement[]> => {
    const token = await getAccessToken();
    return getEndorsements(state ?? '', underwriter ?? '', token);
  };

  const { execute: executeGetEndorsements, value: getEndorsementsValue } = useAsync<Endorsement[]>(
    fetchEndorsements,
    false,
  );
  const fetchJacketPdf = async (): Promise<JacketPdf> => {
    const token = await getAccessToken();
    return getJacketPdf(selectedPolicyNumber ?? '', token);
  };

  const {
    execute: executeGetJacketPdf,
    value: jacketPdfValue,
    status: jacketPdfStatus,
    errors: jacketPdfError,
  } = useAsync<any>(fetchJacketPdf, false);

  useEffect(() => {
    if (selectedPolicyNumber !== '') {
      setSelectedPolicyNumber('');
      executeGetJacketPdf().then();
    }
  }, [selectedPolicyNumber]);

  useEffect(() => {
    if (jacketPdfValue?.length > 0) {
      const jacketPdfBytes = jacketPdfValue[0].jacketDocBytes;
      openDocument(jacketPdfBytes, DocumentType, newWindow, WindowFeatures);
    }
  }, [jacketPdfValue]);

  useEffect(() => {
    if (getEndorsementsValue) {
      const miscEndorsement: Endorsement = {
        endorsementName: 'Miscellaneous Endorsement',
        endorsementFormNumber: 'Miscellaneous Endorsement',
        policyCategories: [],
        policyCoverages: [],
      };
      const endorsements = [...getEndorsementsValue, miscEndorsement];
      setEndorsementType(endorsements);
    }
  }, [getEndorsementsValue]);

  useEffect(() => {
    if (!getJacketTypesValue) executeGetJacketTypes().then();
    if (!getEndorsementsValue) executeGetEndorsements().then();
  }, []);

  useEffect(() => {
    if (isAddNewJacketClicked) {
      jacketList && jacketList?.length > 0 ? add1Jacket() : add2Jacket();
      setDraftMode(true);
    }
  }, [isAddNewJacketClicked]);

  const previousFileStatus = React.useRef(fileData?.fileStatus).current;
  useEffect(() => {
    if (fileData?.fileStatus) {
      if (fileData?.fileStatus !== previousFileStatus) {
        refreshJacketsData && refreshJacketsData();
      }
    }
  }, [fileData?.fileStatus]);

  const add2Jacket = () => {
    setActiveJacketsCount(2);
    if (lenders && lenders?.length > 0) {
      setJackets([
        { ...emptyJacket, randomId: randomId(), lenderId: lenders[0].lenderId, policyAmount: lenders[0].loanAmount },
        { ...emptyJacket, randomId: randomId(), lenderId: lenders[0].lenderId, policyAmount: lenders[0].loanAmount },
      ]);
    } else {
      setJackets([
        { ...emptyJacket, randomId: randomId() },
        { ...emptyJacket, randomId: randomId() },
      ]);
    }
  };

  const add1Jacket = () => {
    setActiveJacketsCount((prevCount) => prevCount + 1);
    setJackets([
      {
        ...emptyJacket,
        randomId: randomId(),
        lenderId: lenders && lenders?.length > 0 ? lenders[0].lenderId : 0,
        policyAmount: lenders && lenders?.length > 0 ? lenders[0].loanAmount : 0,
      },
    ]);
  };

  const handleNewJacket = () => {
    setActiveJacketsCount((prevCount) => prevCount + 1);
    setDraftMode(true);
    const updatedJackets = [
      ...jackets,
      {
        ...emptyJacket,
        randomId: randomId(),
        lenderId: lenders && lenders?.length > 0 ? lenders[0].lenderId : 0,
        policyAmount: lenders && lenders?.length > 0 ? lenders[0].loanAmount : 0,
      },
    ];
    setJackets(updatedJackets);
  };

  const handleIndividual = (isExpand: boolean, id: string | number) => {
    const updatedJackets = jackets.map((obj) => {
      if (obj.randomId === id) {
        return { ...obj, expanded: isExpand };
      }
      return obj;
    });
    setJackets(updatedJackets);
    setCloseEndorsement(!closeEndorsement);
  };

  const handleCloseAll = () => {
    const updatedJackets = jackets.map((obj) => {
      if (obj.expanded === true) {
        return { ...obj, expanded: false };
      }
      return obj;
    });
    setJackets(updatedJackets);
  };

  const handleExpandAll = () => {
    const updatedJackets = jackets.map((obj) => {
      if (obj.expanded === false) {
        return { ...obj, expanded: true };
      }
      return obj;
    });
    setJackets(updatedJackets);
    setCloseEndorsement(false);
  };

  const updateJacket = (randomId: string, key: string, value: any, isFieldValue: boolean, isCheckBox = false) => {
    let policyName = '';
    let resetAmount = false;
    let resetEndorsement = false;

    if (key === 'jacketTypeChange') {
      const updatedJackets = jackets.map((obj) => {
        if (obj.randomId === randomId) {
          obj = { ...emptyJacket, randomId: randomId };
        }
        return obj;
      });
      setJackets(updatedJackets);
      return;
    }

    if (key === 'formNumber') {
      const selectedJacket = jacketTypes.find((x) => x.formNumber === value);
      policyName = selectedJacket?.policyName ?? '';
      resetAmount = selectedJacket?.insuredName !== 'Loan';
      resetEndorsement = true;
    }

    if (key === 'lenderId') {
      const lender = lenders?.find((x) => x.lenderId === value);
      const updatedJackets = jackets.map((obj) => {
        if (obj.randomId === randomId) {
          obj.lenderId = lender.lenderId;
          obj.policyAmount = lender.loanAmount;
          obj.policyFields.map((x) => {
            if (x.name === 'LoanNumber') {
              x.value = lender.loanNumber;
            } else if (x.name === 'PolicyAmount') {
              x.value = lender.loanAmount;
            }
          });
        }
        return obj;
      });
      setJackets(updatedJackets);
      return;
    }

    const updatedJackets = jackets.map((obj) => {
      if (obj.randomId === randomId) {
        if (resetAmount) {
          obj.policyAmount = 0;
        }
        if (resetEndorsement) {
          obj.jacketEndorsements = [];
        }
        if (key === 'RateType') {
          obj.policyFields.map((x) => {
            if (x.name === 'StatCode') {
              x.value = '';
            }
          });
        }
        return isFieldValue
          ? {
              ...obj,
              rateType: key === 'RateType' ? value : obj.rateType,
              policyFields: obj.policyFields?.map((field) => ({
                ...field,
                value: field.name === key ? value : field.value,
                isChecked: field.name === key && isCheckBox ? !field.isChecked : field.isChecked,
              })),
              policyAmount: key === 'PolicyAmount' ? value : obj.policyAmount,
            }
          : {
              ...obj,
              rateType: key === 'RateType' ? value : obj.rateType,
              policyFields: obj.policyFields?.map((field) => ({ ...field })),
              policyType: key === 'formNumber' ? policyName : obj.policyType,
              [key]: value,
            };
      }
      return obj;
    });
    setJackets(updatedJackets);
    console.log('jackets', updatedJackets);
  };

  const addJacketFields = (
    randomId: string,
    formNumber: string,
    fields: PolicyField[],
    endorsements: JacketEndorsement[],
    isLenderPolicy: boolean,
    isOwnerPolicy: boolean,
  ) => {
    let updatedPolicyAmount = 0;
    const mapLenderFields = lenders && lenders?.length > 0 && isLenderPolicy;

    fields.map((x) => {
      if (x.name === 'LoanNumber' && mapLenderFields) {
        x.value = lenders[0].loanNumber;
      }

      if (x.name === 'PolicyAmount' && !policiesWithDefaultPolicyAmount.includes(formNumber)) {
        if ((x.name === 'PolicyAmount' || x.name === 'MortgageAmount') && mapLenderFields) {
          x.value = lenders[0].loanAmount;
        } else if (x.name === 'PolicyAmount' && isOwnerPolicy) {
          x.value = fileData?.salesPrice?.toString() ?? '0';
        }
      }

      if (x.name === 'Borrower' || x.name === 'Buyer') {
        x.value = fileData && fileData?.buyers ? getPartyNames(fileData.buyers) : x.value;
      }

      if (x.name === 'Seller' || x.name === 'VestedIn') {
        x.value = fileData && fileData?.sellers ? getPartyNames(fileData.sellers) : x.value;
      }

      if (x.name === 'Insured') {
        x.value = fileData && fileData?.lenders ? getLenderNames(fileData.lenders) : x.value;
      }

      if (x.name === 'PolicyAmount' && x.value !== '') {
        updatedPolicyAmount = Number(x.value);
      }

      if (x.name === 'LotNumber') {
        x.value = fileData && fileData?.properties ? getPropertyDetails(fileData?.properties, 'legalLot') : x.value;
      }

      if (x.name === 'DistrictName') {
        x.value =
          fileData && fileData?.properties ? getPropertyDetails(fileData?.properties, 'legalDistrict') : x.value;
      }

      if (x.name === 'SectionName') {
        x.value = fileData && fileData?.properties ? getPropertyDetails(fileData?.properties, 'legalSection') : x.value;
      }

      if (x.name === 'BlockNumber') {
        x.value = fileData && fileData?.properties ? getPropertyDetails(fileData?.properties, 'legalBlock') : x.value;
      }

      if (x.name === 'PolicyComplianceDate') {
        let previousJacket: Jacket | null = null;
        if (jackets.length > 1) {
          previousJacket = jackets[jackets.length - 2];
        } else if (jacketList && jacketList.length > 0) {
          previousJacket = jacketList[jacketList.length - 1];
        }

        if (previousJacket) {
          if (previousJacket.policyFields.find((x) => x.name === 'PolicyComplianceDate')) {
            if (previousJacket.policyFields.find((x) => x.name === 'PolicyComplianceDate')?.value) {
              x.value =
                previousJacket.policyFields.find((x) => x.name === 'PolicyComplianceDate')?.value.toString() ?? '';
            }
          }
        }
      }
    });
    const updatedJackets = jackets.map((obj) => {
      if (obj.randomId === randomId) {
        return {
          ...obj,
          policyFields: [...fields],
          jacketIncorporatedEndorsements: [...endorsements],
          policyAmount: updatedPolicyAmount > 0 ? updatedPolicyAmount : obj.policyAmount,
        };
      }
      return obj;
    });
    setJackets(updatedJackets);
  };

  const disableCreateJacket = (value: any) => {
    setDisableCreate(value);
  };

  const isPolicyTypeDisabled = (): boolean => {
    return jackets[0].policyType === '';
  };

  const saveJackets = () => {
    setSubmitAttempted(true);
  };

  const updateJackets = () => {
    setUpdateAttempted(true);
  };

  const cancelJacketModel = () => {
    setOpenCancelModel(true);
  };

  const dismissCancelJackets = () => {
    setOpenCancelModel(false);
  };

  const enableCancleJacket = (value: any) => {
    setEnableCancelJacket(value);
  };

  const cancelJackets = () => {
    hideWarningOnCancel(false);
    refreshJacketsData && refreshJacketsData();
  };

  useEffect(() => {
    if (triggerSaveCall) {
      executeSaveJacketsData().then();
    }
  }, [triggerSaveCall]);

  useEffect(() => {
    if (triggerUpdateCall) {
      executeUpdateJacketsData().then();
    }
  }, [triggerUpdateCall]);

  const isCreateJacketDisabled = (): boolean => {
    return !(jackets.filter((element) => element.policyType !== '').length > 0 && actionStatus !== 'pending');
  };

  const updateIncorporatedEndorsement = (
    jacketId: string | null,
    endoId: string | null,
    starsNumber: string | null,
    type: string | null,
    value: string | null,
    fieldName: string | null,
  ) => {
    const updateIncorporated: Jacket[] = jackets?.map((jacket) => {
      if (jacket.randomId === jacketId && jacket.jacketIncorporatedEndorsements) {
        const updateIncorporatedEndorsement: JacketEndorsement[] = jacket.jacketIncorporatedEndorsements.map(
          (incorp) => {
            if (incorp.endorsementId === endoId && type === 'checkBox') {
              incorp.isChecked = incorp.isChecked ? false : true;
            }
            const updateEndorsementInputFields = incorp?.endorsementInputFields?.map((inputField) => {
              {
                switch (inputField.type) {
                  case 'text':
                    if (inputField.name === fieldName) {
                      inputField.value = value ? value : '';
                    }
                    break;
                  case 'textarea':
                    if (inputField.name === fieldName && inputField.isProtected === false) {
                      inputField.value = value ? value : '';
                    }
                    break;
                  case 'radio':
                    if (inputField.name === fieldName) {
                      inputField.isChecked = value === 'true';
                    }
                    break;
                }
              }
              return inputField;
            });
            incorp.endorsementInputFields = updateEndorsementInputFields;
            return incorp;
          },
        );
        jacket.jacketIncorporatedEndorsements = updateIncorporatedEndorsement;
      }
      return jacket;
    });
    setJackets(updateIncorporated);
  };

  const updateJacketEndorsement = (
    jacketId: string | null,
    id: string | null,
    rflId: number | null,
    name: string | null,
    amount: number | null,
    reason: string,
    type: string,
    starsNumber: string | null = '',
  ) => {
    // A value for 'Type' attribute will be present only in update scenario. Misc Endo is added in the else Block
    if (type === 'MISC') {
      switch (reason) {
        case 'update-name': {
          const updatedJackets = jackets?.map((jacket) => {
            if (jacket.randomId === jacketId && jacket.jacketEndorsements) {
              const updatedEndos = jacket.jacketEndorsements.map((endo) => {
                if (endo.randomId === id) {
                  endo.endorsementName = name ?? '';
                }
                return endo;
              });
              jacket.jacketEndorsements = updatedEndos;
            }
            return jacket;
          });
          setJackets(updatedJackets);
          break;
        }
        case 'update-amount': {
          const updatedJackets = jackets?.map((jacket) => {
            if (jacket.randomId === jacketId && jacket.jacketEndorsements) {
              const updatedEndos = jacket.jacketEndorsements.map((endo) => {
                if (endo.randomId === id) {
                  endo.calculatedAmount = amount;
                }
                return endo;
              });
              jacket.jacketEndorsements = updatedEndos;
            }
            return jacket;
          });
          setJackets(updatedJackets);
          break;
        }
        case 'remove-misc': {
          const newobj = jackets.map((obj) => {
            if (obj.randomId === jacketId) {
              const newEndo = obj.jacketEndorsements?.filter((e) => e.randomId !== id);
              return { ...obj, jacketEndorsements: newEndo };
            }
            return obj;
          });
          setJackets(newobj);
          break;
        }
      }
    } else {
      if (reason === 'remove') {
        const newobj = jackets?.map((obj) => {
          if (obj.randomId === jacketId) {
            return { ...obj, jacketEndorsements: obj.jacketEndorsements?.filter((endo) => endo.randomId !== id) };
          }
          return obj;
        });
        setJackets(newobj);
      } else {
        const updatedJacket: Jacket[] = jackets.map((jacket) => {
          if (jacket.randomId === jacketId) {
            const newEndorsement: JacketEndorsement = {
              endorsementFormNumber: starsNumber ?? '',
              randomId: randomId(),
              isIncorporated: false,
              endorsementName: name ?? '',
              calculatedAmount: null,
              endorsementId: null,
              group: null,
              isChecked: null,
              endorsementInputFields: null,
              pricingDetails: null,
            };
            const addMiscEndorsement = (): JacketEndorsement[] => {
              const newMiscEndorsement: JacketEndorsement = {
                endorsementFormNumber: 'MISC. END.',
                randomId: randomId(),
                isIncorporated: false,
                endorsementName: '',
                calculatedAmount: null,
                endorsementId: null,
                group: null,
                isChecked: null,
                endorsementInputFields: null,
                pricingDetails: null,
              };
              return [...jacket.jacketEndorsements, newMiscEndorsement];
            };
            const updatedEndorsements: JacketEndorsement[] =
              name === 'Miscellaneous Endorsement'
                ? addMiscEndorsement()
                : [...jacket.jacketEndorsements, newEndorsement];

            jacket.jacketEndorsements = updatedEndorsements;
          }
          return jacket;
        });
        setJackets(updatedJacket);
      }
    }
  };

  function getAccordianProps(jacket: Jacket, index: number) {
    const i = index + 1;
    return {
      closeAll:
        (jacket.policyStatus !== JacketStatus.void &&
          jacket.policyStatus !== JacketStatus.pendingVoid &&
          jacketActionsState.closeAllActive) ||
        ((jacket.policyStatus === JacketStatus.void || jacket.policyStatus === JacketStatus.pendingVoid) &&
          jacketActionsState.closeAllInActive),
      expanded:
        (jacket.policyStatus !== JacketStatus.void &&
          jacket.policyStatus !== JacketStatus.pendingVoid &&
          jacketActionsState.isExpandActive) ||
        ((jacket.policyStatus === JacketStatus.void || jacket.policyStatus === JacketStatus.pendingVoid) &&
          jacketActionsState.isExpandInActive),
      isEditing: jacket.policyNumber === jacketActionsState.selectedPolicyNumber && jacketActionsState.isEditing,
      isEditDisabled:
        jacketActionsState.isEditing ||
        fileData?.fileStatus !== JacketStatus.open ||
        jacket.policyStatus == JacketStatus.remitted ||
        jacket.policyStatus == JacketStatus.pendingRemit,
      isVoidDisabled:
        (jacketActionsState.isEditing && jacket.policyNumber !== jacketActionsState.selectedPolicyNumber) ||
        fileData?.fileStatus !== JacketStatus.open ||
        jacket.policyStatus == JacketStatus.remitted ||
        jacket.policyStatus == JacketStatus.pendingRemit,
      //isPdfDisabled: jacketActionsState.isEditing,
      isCollapseDisabled: jacketActionsState.isEditing,
      accordianQAAttribute: `JacketSummary${
        jacket.policyStatus === JacketStatus.pendingVoid || jacket.policyStatus === JacketStatus.void
          ? 'Inactive'
          : 'Active'
      }${i}`,
      voidQAAttribute: `Jacket${i}Void`,
      editQAAttribute: `Jacket${i}Edit`,
      pdfQAAttribute: `Jacket${i}ViewPDF`,
      id: jacket.policyNumber,
      title: `${jacket.policyType}`,
      type: 'Jackets',
      status: jacket.policyStatus === JacketStatus.remitted ? JacketStatus.reported : jacket.policyStatus,
      subtitle: [
        `Policy No: ${jacket.policyNumber}`,
        `Policy Amount: ${
          jacket.policyAmount > 0
            ? Number(jacket.policyAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            : ''
        }`,
      ],
      icon: 'document',
      className: 'cpl-accordion',
      removeAsterisk: true,
      onExpandChange: (expand: boolean, id: string | number) => {
        if (expand) {
          setIsWarning(false);
          jacket.policyStatus === JacketStatus.void
            ? dispatch({ type: 'OnExpandChangeActive' })
            : dispatch({ type: 'OnExpandChangeInActive' });
        }
      },
      ...(jacket.policyStatus !== JacketStatus.void &&
        jacket.policyStatus !== JacketStatus.pendingVoid && {
          onEdit: (id: number | string) => {
            dispatch({ type: JacketsActions.edit, payload: id });
            setEditingPolicyNumber(id);
            setEditMode(true);
            setIsWarning(false);
          },
        }),
      ...(jacket.policyStatus !== JacketStatus.void &&
        jacket.policyStatus !== JacketStatus.pendingVoid && {
          onVoid: (id: number | string) => {
            dispatch({ type: 'OnChangingJacketId', payload: id });
            setOpenVoidModal(true);
            addWarnings([]);
          },
        }),
      ...((jacket.policyStatus === JacketStatus.open ||
        jacket.policyStatus === JacketStatus.pendingRemit ||
        jacket.policyStatus == JacketStatus.remitted) &&
        jacket.policyNumber !== editingPolicyNumber && {
          onPDF: (id: number | string) => {
            setSelectedPolicyNumber(id);
            setIsWarning(false);
          },
        }),
    };
  }

  const renderJacketDetails = (jackets: Jacket[] | undefined) => {
    return jackets?.map((jacket, index) => (
      <AccordionContent key={jacket.policyNumber} {...getAccordianProps(jacket, index)}>
        <JacketForm
          index={index + 1}
          jacket={jacket}
          jacketSelectedEndorsements={jacket.jacketEndorsements}
          jacketEndorsements={endorsementType}
          jacketTypes={getJacketTypesValue ?? []}
          contentDisabled={
            !(jacket.policyNumber === jacketActionsState.selectedPolicyNumber && jacketActionsState.isEditing)
          }
          updateJacket={updateJacket}
          updateJacketEndorsement={(_jacketid, id, rflId, name, amount, reason, type, starsNumber) =>
            updateJacketEndorsement(jacket.randomId, id, rflId, name, amount, reason, type, starsNumber)
          }
          account={accountNumber}
          state={state}
          county={county}
          lenders={lenders}
          addJacketFields={addJacketFields}
          isPolicyTypeDisabled={false}
          isEditDisabled={editMode ? true : false}
          submitAttempted={submitAttempted}
          setSubmitAttempted={setSubmitAttempted}
          updateIncorporatedEndorsement={(_jacketId, endoId, starsNumber, type, value, fieldName) =>
            updateIncorporatedEndorsement(jacket.randomId, endoId, starsNumber, type, value, fieldName)
          }
          updateAttempted={updateAttempted}
          setUpdateAttempted={setUpdateAttempted}
          setTriggerSaveCall={setTriggerSaveCall}
          setTriggerUpdateCall={setTriggerUpdateCall}
          selectedPolicyNumber={jacketActionsState.selectedPolicyNumber}
          disableCreate={disableCreateJacket}
          enableCancelJacket={enableCancleJacket}
        />
      </AccordionContent>
    ));
  };

  const activeJackets = jackets?.filter(
    (item) =>
      item.policyStatus === JacketStatus.open ||
      item.policyStatus === JacketStatus.remitted ||
      item.policyStatus === JacketStatus.pendingRemit,
  );

  const inactiveJackets = jackets?.filter(
    (item) => item.policyStatus === JacketStatus.void || item.policyStatus === JacketStatus.pendingVoid,
  );

  const deleteJacket = () => {
    const availableJackets = jackets.filter((jacket) => jacket.randomId !== deleteJacketRandomId);
    setJackets(availableJackets);
    setActiveJacketsCount((prevCount) => prevCount - 1);
    setIsDeleteJacket(false);
  };

  const cancelDeleteJacket = () => {
    setIsDeleteJacket(false);
  };

  const jacketWarningMessage = (postJacketsResponse: any) => {
    const warnings: Warning[] = postJacketsResponse['warning'];

    if (warnings) {
      addWarnings(warnings);
      refreshJacketsData && refreshJacketsData();
    } else {
      addWarnings([]);
      refreshJacketsData && refreshJacketsData();
    }
  };

  const getPropertyDetails = (property: propertyType[], type: string) => {
    switch (type) {
      case 'legalLot': {
        let lotNumber: any = '';
        lotNumber = property[0].legalLot;
        return lotNumber;
      }
      case 'legalDistrict': {
        let districtName: any = '';
        districtName = property[0].legalDistrict;
        return districtName;
      }
      case 'legalSection': {
        let sectionName: any = '';
        sectionName = property[0].legalSection;
        return sectionName;
      }
      case 'legalBlock': {
        let blockNumber: any = '';
        blockNumber = property[0].legalBlock;
        return blockNumber;
      }
    }
  };

  const getPartyNames = (elements: any[]): string => {
    const partyNames: any[] = [];
    elements.map((e) => partyNames.push(extractPartyName(e)));
    return partyNames.join(',');
  };

  const getLenderNames = (elements: any[]): string => {
    let lenderName = '';
    if (elements && elements?.length === 1) {
      lenderName = elements[0].name;
    }
    return lenderName;
  };

  const extractPartyName = (element: any): string => {
    let partyName = '';
    let spouse1Name = '';
    let spouse2Name = '';
    let hasMarriedCouple = false;

    switch (element.partyType) {
      case 'Individual':
        partyName = [
          element.individual.firstName,
          element.individual.middleName,
          element.individual.lastName,
          element.individual.suffix,
        ]
          .filter((val) => val)
          .join(' ');
        break;

      case 'Trust/Estate':
        partyName = element.trustEstate.name;
        break;

      case 'Entity':
        partyName = element.entity.name;
        break;

      case 'A Married Couple':
        hasMarriedCouple = true;
        spouse1Name = [
          element.couple.spouse1?.firstName,
          element.couple.spouse1?.middleName,
          element.couple.spouse1?.lastName,
          element.couple.spouse1?.suffix,
        ]
          .filter((val) => val)
          .join(' ');
        spouse2Name = [
          element.couple.spouse2?.firstName,
          element.couple.spouse2?.middleName,
          element.couple.spouse2?.lastName,
          element.couple.spouse2?.suffix,
        ]
          .filter((val) => val)
          .join(' ');
        break;
    }

    return hasMarriedCouple ? spouse1Name + ' & ' + spouse2Name : partyName;
  };

  const actionStatus =
    getJacketTypesStatus === 'pending' ||
    saveJacketsDataStatus === 'pending' ||
    jacketPdfStatus === 'pending' ||
    updateJacketsDataStatus === 'pending'
      ? 'pending'
      : 'idle';

  const openJackets = jacketList?.filter((j) => j.policyStatus && j.policyStatus === 'Open') || [];

  return (
    <>
      {isWarning &&
        warnings &&
        warnings.map((warning) => {
          return (
            <Notification
              sticky={true}
              title="Warnings"
              key={warning.code}
              severity="warning"
              msg={warning.message}
              stickyClass="sticky-z-index"
              handleClose={() => setIsWarning(false)}
            />
          );
        })}
      <LoadingSpinner status={actionStatus} variant="linear" />
      {!isAddNewJacketClicked && initialJackets ? (
        <div className="jackets-summary">
          <div className="paper-layout">
            <Container>
              <AgentNetDivider
                title={draftMode ? 'Create Jackets' : 'Jackets'}
                typoVariant="h1"
                primaryButtonName="Add New Jacket"
                //disableButton={editMode}
                onClickPrimary={handleAddNewJacket}
                primaryButtonQaAttr="AddNewJacketLandingTop"
                primaryButtonDisable={activeJacketsCount >= 5 || fileData?.fileStatus == 'Closed' || editMode}
                buttonName={activeJacketsCount === 0 ? '' : 'Close All'}
                onClick={() => {
                  setIsWarning(false);
                  dispatch({ type: JacketsActions.expandCollapseActive, payload: false });
                }}
                buttonName2={activeJacketsCount === 0 ? '' : 'Expand All'}
                onClick2={() => {
                  setIsWarning(false);
                  dispatch({ type: JacketsActions.expandCollapseActive, payload: true });
                }}
                disablePaddingX
                buttonNameQaAttr="JacketCloseAllActive"
                buttonName2QaAttr="JacketExpandAllActive"
                id={draftMode ? `CreateJackets${state}` : `Jackets${state}`}
              />
              {editMode && (
                <Notification
                  inline
                  severity="warning"
                  msg="You are in edit mode. Finish editing the Jacket to enable all page features."
                  disablePadding
                  className={classes.warningMsg}
                />
              )}
              {activeJacketsCount >= 5 && (
                <Notification inline severity="warning" className={classes.warningMsg}>
                  {openJacketsLimit}
                </Notification>
              )}
              {activeJacketsCount === 0 && (
                <Notification inline severity="warning" className={classes.warningMsg}>
                  {noActiveJackets}
                </Notification>
              )}
              {renderJacketDetails(activeJackets)}
              {inactiveJackets?.length ? (
                <AgentNetDivider
                  title="Show Inactive Jackets"
                  {...(jacketActionsState.isInactiveToggle && {
                    buttonName: 'Close All',
                    buttonName2: 'Expand All',
                  })}
                  onClick={() => {
                    dispatch({ type: JacketsActions.expandCollapseInActive, payload: false });
                  }}
                  onClick2={() => {
                    dispatch({ type: JacketsActions.expandCollapseInActive, payload: true });
                  }}
                  disablePaddingX
                  onSwitch={() => {
                    dispatch({ type: JacketsActions.onInactiveToggleChange });
                    setIsWarning(false);
                  }}
                  switchValue={jacketActionsState.isInactiveToggle}
                  buttonNameQaAttr="JacketCloseAllInActive"
                  buttonName2QaAttr="JacketExpandAllInActive"
                  toggleQaAttr="JacketShowInActive"
                />
              ) : null}
              {jacketActionsState.isInactiveToggle ? renderJacketDetails(inactiveJackets) : null}
              <div>
                <AgentNetConfirmationDialog
                  qaAttrPrefix="ConfirmationVoid"
                  disabled={voidStatus === 'pending'}
                  onConfirm={handleVoidJacketDialog}
                  open={openVoidModal}
                  onDismissAction={handleCloseDialog}
                  dialogTitle="Void Jacket?"
                  dialogBtnContent="Yes, Continue"
                  dialogText="Are you sure you want to void this Jacket?"
                />
              </div>
            </Container>
          </div>
        </div>
      ) : (
        ''
      )}
      {isAddNewJacketClicked && jackets.length ? (
        <AgentNetDivider
          title={draftMode ? 'Create Jackets' : 'Jackets'}
          typoVariant="h1"
          primaryButtonName="Add New Jacket"
          disableButton={editMode}
          primaryButtonDisable={
            activeJacketsCount >= 5 ||
            (activeJacketsCount === 2 && jackets.length === 2 && jackets[0].policyType === '')
              ? true
              : false
          }
          onClickPrimary={handleNewJacket}
          buttonName={'Close All'}
          buttonName2={'Expand All'}
          onClick={handleCloseAll}
          onClick2={handleExpandAll}
          primaryButtonQaAttr="AddNewJacketTop"
          buttonNameQaAttr="JacketCloseAll"
          buttonName2QaAttr="JacketExpandAll"
          id={draftMode ? `CreateJackets${state}` : `Jackets${state}`}
        />
      ) : (
        ''
      )}
      <div className={classes.jacketsWrap}>
        {jackets.length ? (
          draftMode ? (
            <>
              {openJackets.length ? (
                <Notification
                  inline
                  severity="info"
                  title="Existing Open Jackets"
                  disablePadding
                  alertClass={classes.mb2}
                >
                  {openJackets.map((p, i) => (
                    <li key={i}>
                      {p.policyNumber} - {p.policyType} -{' '}
                      {p.policyAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                    </li>
                  ))}
                  {openJackets.length === 0 && <p>None</p>}
                </Notification>
              ) : null}
              {jackets
                .filter((j) => j.status && j.status === 'Draft')
                .map((p, i) => {
                  return (
                    <AccordionContent
                      accordianQAAttribute={'JacketAccordionContent' + [i + 1]}
                      icon={'document'}
                      type={'cpl'}
                      title={p.policyType === '' ? 'New Jacket' : p.policyType}
                      subtitle={[
                        `Policy Amount: ${
                          p.policyAmount > 0 && p.policyFields.length > 0
                            ? Number(p.policyAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                            : ''
                        }`,
                      ]}
                      key={p.randomId}
                      id={p.randomId ? p.randomId : ''}
                      expanded={p.expanded}
                      status={p.status}
                      className={classes.mb2}
                      onExpandChange={handleIndividual}
                      onDelete={
                        i !== 0
                          ? () => {
                              setIsDeleteJacket(true);
                              setDeleteJacketRandomId(p.randomId);
                            }
                          : undefined
                      }
                      removeAsterisk
                    >
                      <JacketForm
                        index={i + 1}
                        jacket={p}
                        jacketSelectedEndorsements={p.jacketEndorsements}
                        jacketEndorsements={endorsementType}
                        jacketTypes={jacketTypes}
                        contentDisabled={!draftMode}
                        updateJacket={updateJacket}
                        updateJacketEndorsement={(_jacketid, id, rflId, name, amount, reason, type, starsNumber) =>
                          updateJacketEndorsement(p.randomId, id, rflId, name, amount, reason, type, starsNumber)
                        }
                        updateIncorporatedEndorsement={(_jacketId, endoId, starsNumber, type, value, fieldName) =>
                          updateIncorporatedEndorsement(p.randomId, endoId, starsNumber, type, value, fieldName)
                        }
                        account={accountNumber}
                        state={state}
                        county={county}
                        addJacketFields={addJacketFields}
                        isPolicyTypeDisabled={i === 1 ? isPolicyTypeDisabled() : false}
                        isEditDisabled={false}
                        submitAttempted={submitAttempted}
                        setSubmitAttempted={setSubmitAttempted}
                        lenders={lenders}
                        updateAttempted={updateAttempted}
                        setUpdateAttempted={setUpdateAttempted}
                        setTriggerSaveCall={setTriggerSaveCall}
                        setTriggerUpdateCall={setTriggerUpdateCall}
                        selectedPolicyNumber={jacketActionsState.selectedPolicyNumber}
                        closeEndorsement={closeEndorsement}
                        disableCreate={disableCreateJacket}
                        enableCancelJacket={enableCancleJacket}
                      />
                    </AccordionContent>
                  );
                })}
              {activeJacketsCount < 5 && (
                <AgentNetButton
                  variant="outlined"
                  size="small"
                  onClick={handleNewJacket}
                  disabled={
                    activeJacketsCount >= 5 ||
                    (activeJacketsCount === 2 && jackets.length === 2 && jackets[0].policyType === '')
                      ? true
                      : false
                  }
                  data-qa="AddNewJacketBottom"
                >
                  Add New Jacket
                </AgentNetButton>
              )}
              <JacketActions
                hasJackets={false}
                saveJacket={() => saveJackets()}
                cancelJacket={cancelJacketModel}
                isSaveDisabled={disableCreate == true || actionStatus === 'pending' || isCreateJacketDisabled()}
                isCancelDisabled={!enableCancelJacket}
              />
              <>
                <AgentNetConfirmationDialog
                  qaAttrPrefix="ConfirmationCancel"
                  onConfirm={cancelJackets}
                  open={openCancelModel}
                  onDismissAction={dismissCancelJackets}
                  dialogTitle="Cancel Jacket Creation?"
                  dialogBtnContent="Yes, Discard"
                  secondaryActionBtnContent="Continue Editing"
                  dialogText="You haven't created your jacket yet. Cancelling now will discard any changes."
                />
              </>
              <>
                <AgentNetConfirmationDialog
                  qaAttrPrefix="ConfirmationDelete"
                  onConfirm={deleteJacket}
                  open={isdeleteJacket}
                  onDismissAction={cancelDeleteJacket}
                  dialogTitle="Delete Jacket"
                  dialogBtnContent="Delete Jacket"
                  dialogText="Are you sure you want to delete this jacket? You will not be able to recover it"
                />
              </>
            </>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {editMode && (
          <>
            <JacketActions
              hasJackets={editMode ? true : false}
              saveJacket={() => updateJackets()}
              cancelJacket={cancelJacketModel}
              isSaveDisabled={false}
              isCancelDisabled={false}
            />
            <AgentNetConfirmationDialog
              qaAttrPrefix="ConfirmationCancel"
              onConfirm={cancelJackets}
              open={openCancelModel}
              onDismissAction={dismissCancelJackets}
              dialogTitle="Cancel Jacket Creation?"
              dialogBtnContent="Yes, Discard"
              secondaryActionBtnContent="Continue Editing"
              dialogText="You haven't created your jacket yet. Cancelling now will discard any changes."
            />
          </>
        )}
      </div>
    </>
  );
};

export default JacketFC;
