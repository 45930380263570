import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing(2, 3),
      alignSelf: 'stretch',
      background: '#FAFAFA',
      borderTop: '1px solid var(--other-outline-border, #C4C4C4)',
      margin: 0,
      zIndex: 9,
      width: '100%',
    },
    centerArea: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    footerLinks: {
      display: 'flex',
      alignItems: 'flex-start',
      whiteSpace: 'nowrap',
      gap: '12px',
    },
    secondaryColor: {
      color: theme.palette.text.secondary,
    },
    link: {
      color: '#0075C3',
      textDecoration: 'underline !important',
      fontWeight: 500,
      fontSize: '12px',
      '&:hover': {
        opacity: '0.85',
      },
    },
    separator: {
      marginLeft: theme.spacing(0.62),
      marginRight: theme.spacing(0.62),
    },
    linkGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    w1400: {
      maxWidth: '1440px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

function HelpFooter() {
  const classes = useStyles();
  return (
    <Grid container className={classes.footer}>
      <div className={classes.w1400}>
        <Grid item md={6}>
          <Typography data-testid="kb-footer-copyright" variant="caption" className={classes.secondaryColor}>
            &copy; <span className={classes.secondaryColor}>{new Date().getFullYear()}</span> AgentNet<sup>&reg;</sup>{' '}
            and/or its affiliates. All rights reserved.{' '}
          </Typography>
        </Grid>
        <Grid item md={6} className={classes.linkGrid}>
          <div data-testid="kb-footer-terms-and-privacy-links" className={classes.footerLinks}>
            <a href="https://www.firstam.com/terms-of-use/" target="_blank" rel="noreferrer" className={classes.link}>
              Terms of Use
            </a>
            <span>|</span>
            <a href="https://www.firstam.com/privacy-policy/" target="_blank" rel="noreferrer" className={classes.link}>
              Privacy Policy
            </a>
          </div>
        </Grid>
      </div>
    </Grid>
  );
}

export { HelpFooter };
