import React, { ReactNode } from 'react';
import { Box, Drawer, DrawerProps, makeStyles, Theme } from '@material-ui/core';
import AgentNetButton, { ICustomButtonProps } from '../../components/button/AgentNetButton';
import DrawerTitle from './FormDrawerTitle';
import DrawerContent from './FormDrawerContent';
import DrawerActions from './FormDrawerActions';

export type FormDrawerProps = DrawerProps & {
  children?: ReactNode;
  header?: string;
  width?: number;
  onDismissAction?: () => void;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  primaryActionProps?: ICustomButtonProps;
  dismissActionProps?: ICustomButtonProps;
  secondaryActionProps?: ICustomButtonProps;
  testId?: string;
  loading?: boolean;
  disabled?: boolean;
  actionItems?: any;
  showDrawerActions?: boolean;
  disableEnforceFocus?: boolean;
  hidePrimaryAction?: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '100vw',
  },
  buttonsContainer: {
    [theme.breakpoints.only('xs')]: {
      minHeight: 'auto',
      flexDirection: 'column',
      display: 'flex',
      paddingBottom: 0,
    },
    [theme.breakpoints.up('sm')]: {
      '& > :first-child': {
        marginLeft: theme.spacing(2),
      },
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  buttonReverse: {
    display: 'flex !important',
    flexDirection: 'row-reverse',
    '& button': {
      marginLeft: '10px !important',
    },
  },
  secondaryButton: {
    display: 'flex',
    alignSelf: 'center',
    [theme.breakpoints.up('sm')]: {
      flex: 1,
    },
  },
  disabledStyle: {
    '&:disabled': {
      color: '#9e9e9e !important',
      border: '1px solid #9e9e9e !important',
    },
  },
}));
const FormDrawerBase = ({
  header,
  children,
  width = 720,
  onPrimaryAction = () => undefined,
  onDismissAction = () => undefined,
  onSecondaryAction = () => undefined,
  primaryActionLabel,
  secondaryActionLabel,
  dismissActionProps,
  primaryActionProps,
  secondaryActionProps,
  testId = 'form-drawer' || null,
  loading = false,
  disabled,
  actionItems,
  showDrawerActions = true,
  disableEnforceFocus = false,
  hidePrimaryAction = false,
  ...props
}: FormDrawerProps): JSX.Element => {
  const classes = useStyles();
  return (
    <Drawer anchor="right" {...props} disableEnforceFocus title="">
      <Box style={{ width: width }} className={classes.root} data-testid={testId}>
        <DrawerTitle title={header} />
        <DrawerContent>{children}</DrawerContent>
        {showDrawerActions && (
          <DrawerActions loading={loading}>
            {secondaryActionLabel && (
              <div className={classes.secondaryButton}>
                <AgentNetButton
                  id="secondary-action"
                  variant="outlined"
                  danger
                  className={classes.disabledStyle}
                  onClick={onSecondaryAction}
                  {...secondaryActionProps}
                >
                  {secondaryActionLabel}
                </AgentNetButton>
              </div>
            )}
            <div className={classes.buttonsContainer}>
              <div className={classes.buttonReverse}>
                {!hidePrimaryAction && (
                  <AgentNetButton
                    onClick={onPrimaryAction}
                    className={disabled ? 'disabled-btn' : ''}
                    disabled={disabled}
                    id="primary-action"
                    color="primary"
                    variant="contained"
                    {...primaryActionProps}
                  >
                    {primaryActionLabel}
                  </AgentNetButton>
                )}
                {actionItems}
              </div>
              <div>
                <AgentNetButton
                  onClick={onDismissAction}
                  id="dismiss-action"
                  color="primary"
                  variant="outlined"
                  {...dismissActionProps}
                >
                  Cancel
                </AgentNetButton>
              </div>
            </div>
          </DrawerActions>
        )}
      </Box>
    </Drawer>
  );
};
export default FormDrawerBase;
