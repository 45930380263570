import { AdminLinks, FilesLinks } from 'core/navbar/LeftNavLinks';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { NavLinkType } from '../core/navbar/NavLinkType';

interface ViewStateContextValue {
  viewState: string;
  setViewState: (viewState: string) => void;
  menuCollapsed: boolean;
  setMenuCollapse: (menuCollapsed: boolean) => void;
  navState: NavLinkType[];
  setNavState: (navlist: NavLinkType[]) => void;
  adminNavState: NavLinkType[];
  setAdminNavState: (navlist: NavLinkType[]) => void;
  routerParamValue: string;
  setRouterParamValue: (value: string) => void;
}

const defaultSetViewState: ViewStateContextValue['setViewState'] = () => {
  //this will be replaced by the provider's setState function
};

const defaultMenuCollapse: ViewStateContextValue['setMenuCollapse'] = () => {
  //this will be replaced by the provider's setState function
};

const defaultSetNavState: ViewStateContextValue['setNavState'] = () => {
  //this will be replaced by the provider's setState function
};

const defaultSetAdminNavState: ViewStateContextValue['setAdminNavState'] = () => {
  //this will be replaced by the provider's setState function
};

const defaultSetParamValue: ViewStateContextValue['setRouterParamValue'] = () => {
  //this will be replaced by the provider's setState function
};

const ViewStateContext = createContext<ViewStateContextValue>({
  viewState: 'portal',
  setViewState: defaultSetViewState,
  menuCollapsed: true,
  setMenuCollapse: defaultMenuCollapse,
  navState: FilesLinks,
  setNavState: defaultSetNavState,
  adminNavState: AdminLinks,
  setAdminNavState: defaultSetAdminNavState,
  routerParamValue: '', // Add this line
  setRouterParamValue: defaultSetParamValue, // Add this line
});

export const useViewState = () => useContext(ViewStateContext);

interface ViewStateProviderProps {
  children: ReactNode;
}

export const ViewStateProvider: React.FC<ViewStateProviderProps> = ({ children }) => {
  const isWindowMd = window.innerWidth < 1280;
  const [viewState, setViewState] = useState<string>('portal');
  const [menuCollapsed, setMenuCollapse] = useState<boolean>(isWindowMd);
  const [navState, setNavState] = useState(FilesLinks);
  const [adminNavState, setAdminNavState] = useState(AdminLinks);
  const [routerParamValue, setRouterParamValue] = useState<string>('');

  return (
    <ViewStateContext.Provider
      value={{
        viewState,
        setViewState,
        menuCollapsed,
        setMenuCollapse,
        navState,
        setNavState,
        adminNavState,
        setAdminNavState,
        routerParamValue,
        setRouterParamValue,
      }}
    >
      {children}
    </ViewStateContext.Provider>
  );
};
