import * as yup from 'yup';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'is a required field';
export const requiredFieldMessage = (fieldName: string): string => `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
export const maxLengthFieldMessage = (maxLength?: number): string => `Max length is ${maxLength}`;

const lender_max_characters = {
  addLenderName: 255,
  addLenderAttention: 1000,
  addLenderAddress1: 255,
  addLenderAddress2: 255,
  addLenderCity: 50,
  addLenderStateCode: 2,
  addLenderZipCode: 10,
  addLenderMortgageClause: 255,
};

export const addressBookLenderSchema = yup.object().shape({
  name: yup
    .string()
    .required(requiredFieldMessage('Name'))
    .max(lender_max_characters.addLenderName, maxLengthFieldMessage(lender_max_characters.addLenderName)),
  attention: yup
    .string()
    .max(lender_max_characters.addLenderAttention, maxLengthFieldMessage(lender_max_characters.addLenderAttention)),
  address1: yup
    .string()
    .required(requiredFieldMessage('Address 1'))
    .max(lender_max_characters.addLenderAddress1, maxLengthFieldMessage(lender_max_characters.addLenderAddress1)),
  address2: yup
    .string()
    .max(lender_max_characters.addLenderAddress2, maxLengthFieldMessage(lender_max_characters.addLenderAddress2)),
  city: yup
    .string()
    .required(requiredFieldMessage('City'))
    .max(lender_max_characters.addLenderCity, maxLengthFieldMessage(lender_max_characters.addLenderCity)),
  stateCode: yup
    .string()
    .required(requiredFieldMessage('State'))
    .max(lender_max_characters.addLenderStateCode, maxLengthFieldMessage(lender_max_characters.addLenderStateCode)),
  zipCode: yup
    .string()
    .required(requiredFieldMessage('Zip code'))
    .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.'),
  phoneNumber: yup.string().test('is-10-digits', 'Incomplete Phone Number.', (value) => {
    if (!value || value.trim() === '') {
      return true; // Skip validation if value is empty
    }
    return /^\d{3}-\d{3}-\d{4}$/.test(value) || /^\d{10}$/.test(value);
  }),
  emailAddress: yup
    .string()
    .trim()
    .email('Invalid Email, @ and . characters required.')
    .max(100, 'Maximum of 100 characters.')
    .nullable(),
  assignTypeCdId: yup.string().required(requiredFieldMessage('Care of, DBA Mortgage Clause')),
  assigns: yup
    .string()
    .max(
      lender_max_characters.addLenderMortgageClause,
      maxLengthFieldMessage(lender_max_characters.addLenderMortgageClause),
    ),
});

const closingAttorney_max_characters = {
  closingAttorneyName: 1000,
  closingAttorneyFirstName: 1000,
  closingAttorneyMiddleName: 1000,
  closingAttorneyLastName: 1000,
  closingAttorneyAddress1: 1000,
  closingAttorneyAddress2: 1000,
  closingAttorneyCity: 1000,
  closingAttorneyStateCode: 2,
  closingAttorneyZipCode: 10,
  closingAttorneyBusinessIn: 2,
};

export const addressBookClosingAttorneySchema = yup.object().shape({
  firstName: yup
    .string()
    .required(requiredFieldMessage('First Name'))
    .max(
      closingAttorney_max_characters.closingAttorneyFirstName,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyFirstName),
    ),
  middleName: yup
    .string()
    .max(
      closingAttorney_max_characters.closingAttorneyMiddleName,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyMiddleName),
    ),
  lastName: yup
    .string()
    .required(requiredFieldMessage('Last Name'))
    .max(
      closingAttorney_max_characters.closingAttorneyLastName,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyLastName),
    ),
  name: yup.string(),
  address1: yup
    .string()
    .max(
      closingAttorney_max_characters.closingAttorneyAddress1,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyAddress1),
    ),
  address2: yup
    .string()
    .max(
      closingAttorney_max_characters.closingAttorneyAddress2,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyAddress2),
    ),
  city: yup
    .string()
    .max(
      closingAttorney_max_characters.closingAttorneyCity,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyCity),
    ),
  stateCode: yup
    .string()
    .max(
      closingAttorney_max_characters.closingAttorneyStateCode,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyStateCode),
    ),
  zipCode: yup.string().matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.'),
  businessIn: yup
    .string()
    .required(requiredFieldMessage('State Doing Business'))
    .max(
      closingAttorney_max_characters.closingAttorneyBusinessIn,
      maxLengthFieldMessage(closingAttorney_max_characters.closingAttorneyBusinessIn),
    ),
});
