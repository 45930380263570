import React, { useEffect, useState } from 'react';
import { Firm, Office, Profile } from '../api/profile/types';
import { ADMIN_ROLE_ID, getDefaultFirm } from '../api/profile/profile-utilities';
import { useAuth } from '@agentnet/auth';
import { getProfile } from 'api/profile/profile-api';
import useAsync, { NoSessionError } from './useAsync';
import { PrismUserProfile } from 'api/prismPropertyProfile/types';
import { getPrefferedOffices } from 'api/admin/preferred-offices-api';

export interface ProfileContextInterface {
  userFirm?: Firm | null;
  currentOffice?: Office | null;
  setUserFirm?: (userFirm: Firm) => void;
  setCurrentOffice?: (currentOffice: Office) => void;
  profile?: Profile | null;
  setProfile?: (profile: Profile) => void;
  prismUserProfile?: PrismUserProfile | null;
  pfNotifications?: number;
  setPfNotifications?: (pfNotifications: number) => void;
  children?: React.ReactNode;
  isAdmin?: boolean;
  setNavigated?: any;
  profileStatus?: 'idle' | 'pending' | 'success' | 'error';
  preferredOffice?: any;
  setpreferredOffice?: (preferredOffice: any) => void;
  getFirmNameByFirmId?: (firmId: string) => string;
}
const ProfileContext = React.createContext<ProfileContextInterface | null>(null);

const ProfileProvider = (props: ProfileContextInterface): JSX.Element => {
  const { getAccessToken, account, signOut } = useAuth();
  const selectedUserFirm = JSON.parse(localStorage.getItem('userFirm') ?? '{}');
  const [userFirm, setUserFirm] = useState<Firm>(selectedUserFirm ?? { firmId: '', name: '' });
  const [currentOffice, setCurrentOffice] = useState<Office>();
  const [profile, setProfile] = useState<Profile>();
  const [prismUserProfile, setPrismUserProfile] = useState<PrismUserProfile>();
  const [pfNotifications, setPfNotifications] = useState(0);
  const [preferredOffice, setpreferredOffice] = useState<any>(null);
  const setNavigated = (value: any) => {
    window.localStorage.setItem('isNavigated', value);
  };
  const isAdmin = profile?.roleId == ADMIN_ROLE_ID;

  const getProfileData = async (): Promise<Profile> => {
    const token = await getAccessToken();
    const prefferedOfficeresponse = await getPrefferedOffices(token);
    setpreferredOffice(prefferedOfficeresponse);
    return getProfile(token);
  };
  const {
    execute: executeGetProfile,
    value: profileData,
    status: profileStatus,
  } = useAsync<Profile>(getProfileData, false);

  useEffect(() => {
    /* Use a cleanup function to prevent mem leak */
    let apiCallActive = true;
    if (apiCallActive && account) {
      executeGetProfile()
        .then()
        .catch((error) => {
          /* Handle the no-session error by forcing login */
          if (error instanceof NoSessionError) signOut().then();
        });
    }
    return () => {
      apiCallActive = false;
    };
  }, [account]);

  /* Put the profile in context */
  useEffect(() => {
    if (profileData && setProfile) {
      if (!profileData?.userId) signOut().then();

      // Set the profile data
      setProfile(profileData);

      // If user has not selected a firm, and a default is avail, use it
      const defaultFirm = getDefaultFirm(profileData.firms);
      if (defaultFirm && !userFirm?.firmId) {
        window.localStorage.setItem('userFirm', JSON.stringify(defaultFirm));
        setUserFirm && setUserFirm(defaultFirm);
      }

      // Set the prism user profile with selected firm
      const prismUser: PrismUserProfile = {
        firstName: profileData?.firstName ?? '',
        lastName: profileData?.lastName ?? '',
        email: profileData?.emailAddress ?? '',
        primaryPhone: profileData?.phoneNumber
          ? profileData?.phoneNumber.substring(profileData?.phoneNumber.length - 10)
          : '',
        idaaSguid: profileData?.azureObjectId ?? '',
        idaaSuserId: profileData?.login ?? '',
        idaaStype: 'B2C',
      };
      setPrismUserProfile(prismUser);
    }
  }, [profileData]);

  const getFirmNameByFirmId = (firmId: string) => {
    return profileData?.firms?.find((firm: Firm) => String(firm.firmId) === String(firmId))?.name ?? 'N/A';
  };

  const ctx: ProfileContextInterface = {
    currentOffice,
    userFirm,
    setCurrentOffice,
    setUserFirm,
    profile,
    setProfile,
    prismUserProfile,
    pfNotifications,
    setPfNotifications,
    // isAdmin,
    profileStatus,
    setNavigated,
    setpreferredOffice,
    preferredOffice,
    getFirmNameByFirmId,
  };

  return <ProfileContext.Provider value={ctx}>{props.children}</ProfileContext.Provider>;
};

export { ProfileContext, ProfileProvider };
