import { SaveStaffProfileDetails } from 'api/admin/interfaces';
import { useAuth } from '@agentnet/auth';
import React, { useEffect } from 'react';
import { updateUserProfileContact } from 'api/admin/user-profile-api';
import { useHistory } from 'react-router-dom';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';
import useAsync from 'hooks/useAsync';

const IdaasContent = (): JSX.Element => {
  const { getAccessToken, account } = useAuth();
  const history = useHistory();
  const getIdaasData = async () => {
    const token = await getAccessToken();

    const request: SaveStaffProfileDetails = {
      PhoneNumber: '',
    };

    await updateUserProfileContact(token, request);
    history.push('/admin/user/profile');
  };

  const { execute: executegetIdaasData, status: getIdaasDataStatus } = useAsync(getIdaasData, false);

  const actionStatus = getIdaasDataStatus === 'pending' ? 'pending' : 'idle';

  useEffect(() => {
    executegetIdaasData().then();
  }, [account]);

  return (
    <div>
      <LoadingSpinner status={actionStatus} variant="linear" />
    </div>
  );
};
export default IdaasContent;
