import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Paper, Tab, Tabs } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useViewState } from 'hooks/ViewStateContext';
import BottomAppBar from 'ui-kit/components/bottomAppBar/BottomAppBar';
//import AdminUserProfileContent from './AdminUserProfileContent';
//import { isEnableAllFeatures } from 'api/host-by-env';
//import PrefferedOfficeContent from './preferredOfficeContent';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { UserRoleTypes } from './constants';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import NestedRouteLayout from 'core/layout-core/NestedRouteLayout';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  fullWidth?: boolean;
}

type getFileInfoProps = {
  fileId?: string;
  tabId?: string;
};

function TabPanel(props: TabPanelProps) {
  const { children, value, index, fullWidth, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`file-tabpanel-${index}`}
      aria-labelledby={`file-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box style={!fullWidth ? { maxWidth: '1200px' } : {}}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      minHeight: 'calc(100vh - 64px)',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 64,
    },
    tabWrap: {
      padding: `0 ${theme.spacing(2)}px`,
      borderBottom: `1px solid #e5e5e5`,
    },
    tabMargin: {},
  }),
);

const AdminUserProfileContainer = ({ routes }: any): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const [footer, showFooter] = React.useState(false);
  const { fileId, tabId } = useParams<getFileInfoProps>();
  const history = useHistory();
  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { tabValue, setTabValue } = fileDataCtx;
  const { viewState, setViewState } = useViewState();

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};

  useEffect(() => {
    if (tabId) {
      tabId === 'backtitle' && setTabValue && setTabValue('1');
      tabId === 'cpl' && setTabValue && setTabValue('2');
      tabId === 'service-orders' && setTabValue && setTabValue('3');
      tabId === 'underwriting' && setTabValue && setTabValue('4');
      tabId === 'jackets' && setTabValue && setTabValue('5');
      tabId === undefined && setTabValue && setTabValue('0');
      console.log(tabId);
    }
  }, [tabId]);

  useEffect(() => {
    const activeTab = localStorage.getItem('activeTab');

    if (history.location.pathname === '/admin/user/profile/updateAuthentication/Cancel')
      history.push(`/admin/user/profile`);
    if (activeTab === null || activeTab === 'UserProfileTab') {
      setTabValue && setTabValue('0');
    } else {
      setTabValue && setTabValue('1');
    }
  }, []);

  const onTabChange = (v: any) => {
    if (setTabValue) {
      setTabValue(v);
    }
    if (v === 0) {
      history.push(`/admin/user/profile`);
      localStorage.setItem('activeTab', 'UserProfileTab');
    }
    if (v === 1) {
      history.push(`/admin/user/preferred-offices`);
      localStorage.setItem('activeTab', 'PreferredOfficesTab');
    }
  };

  const TabNavigation = (): JSX.Element => {
    return (
      <Paper square elevation={0} className={classes.tabWrap}>
        <Tabs
          value={parseInt(tabValue || '0')}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => onTabChange(v)}
        >
          <Tab data-qa="UserProfileTab" label="Profile" onClick={() => history.push(`/admin/user/profile`)} />
          {profileCtx.profile?.roleTypeId === UserRoleTypes.Staff ? null : (
            <Tab
              //disabled={!isEnableAllFeatures()}
              data-qa="PreferredOfficesTab"
              label="Preferred Offices"
              onClick={() => history.push(`/admin/user/preferred-offices`)}
            />
          )}
        </Tabs>
      </Paper>
    );
  };

  const randomID = () => (Math.random() + 1).toString(36).substring(7);

  return (
    <div className={classes.root}>
      {routes?.length > 0 ? (
        <>
          <TabNavigation />
          <NestedRouteLayout {...{ routes }} />
        </>
      ) : (
        <>
          {/* <TabNavigation /> */}
          <div className={classes.tabMargin}></div>
          {/* <div className={classes.tabMargin}>
            <TabPanel value={parseInt(tabValue || '0')} index={0} fullWidth>
              <AdminUserProfileContent />
            </TabPanel>
            <TabPanel value={parseInt(tabValue || '0')} index={1} fullWidth>
              <PrefferedOfficeContent />
            </TabPanel>
          </div> */}
          {footer && <BottomAppBar primaryActionLabel="Search" secondaryActionLabel="Clear All" />}
        </>
      )}
    </div>
  );
};

export default AdminUserProfileContainer;
