import React from 'react';
import { withBaseFormGroup } from 'utilities/form/components/base-form';
import { ICreateBuyerSellerRequest } from '../interfaces/CreateBuyerSellerRequest';
import { PartTypeProps, PartyTypeForm } from './PartyTypesForm';

const PartyTypeFormWithBaseFormGroup = <T extends ICreateBuyerSellerRequest>(props: PartTypeProps<T>): JSX.Element => {
  /* Handle Form Validation */
  const formWithBase = withBaseFormGroup<T, PartTypeProps<T>>(PartyTypeForm, {
    ...props,
    customValidation: true,
  });
  return <>{formWithBase}</>;
};
export default PartyTypeFormWithBaseFormGroup;
