import React, { ReactElement } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { SearchBarRow } from './SearchBarRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3, 0),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: theme.spacing(0),
    },
    text: {
      color: theme.palette.background.paper,
      marginBottom: '24px !important',
    },
    textSub: {
      color: theme.palette.background.paper,
      marginTop: theme.spacing(2),
    },
  }),
);
function Content(): ReactElement | null {
  const classes = useStyles();
  return (
    <div data-testid="knowledge-base-titleBar" className={classes.container}>
      <Typography variant="h1" data-testid="knowledge-base-title" className={classes.text}>
        What can we help you with?
      </Typography>
      <SearchBarRow />
      <Typography variant="body1" className={classes.textSub}>
        Popular topics: Service Orders (Title Search Products), Report (Remit) Files, Pay Files – Submit Payment
      </Typography>
    </div>
  );
}

export { Content };
