import React, { useEffect, useState } from 'react';
import { InputAdornment, TextField, TextFieldProps } from '@material-ui/core';
import { FieldValidationError } from '../../../utilities/validation/validation';
import { Control } from 'react-hook-form';
import ErrorSection from '../../../features/files/file-create/ErrorSection';

export type AgentTextInputProps = TextFieldProps & {
  error?: boolean;
  helperText?: string | React.ReactNode;
  control?: Control;
  label?: string | React.ReactNode;
  rows?: string | number;
  errorMap?: Record<string, string[]>;
  startAdornment?: React.ReactNode;
  endAdornment?: JSX.Element;
  errs?: FieldValidationError[];
  id?: string;
  alwaysDisplayError?: boolean;
  value?: string;
  showValidationOnBlur?: boolean;
  showValidationOnFocus?: boolean;
  showValidation?: boolean;
  firstLoad?: boolean;
};

export function USPostalCodeField({
  endAdornment,
  startAdornment,
  InputProps,
  errs,
  variant,
  label,
  id,
  name,
  showValidationOnBlur = false,
  showValidationOnFocus = false,
  alwaysDisplayError,
  onFocus,
  onBlur,
  onChange,
  showValidation,
  value,
  firstLoad,
  ...rest
}: AgentTextInputProps): JSX.Element {
  const [errors, setErrors] = useState(false);
  const [displayErrors, setDisplayError] = useState(alwaysDisplayError);
  const displayErrorOnCallback = (callback: () => void) => {
    setDisplayError(true);
    callback();
  };
  useEffect(() => {
    const hasError = errs?.find((err) => err.field === id);
    hasError ? setErrors(true) : setErrors(false);
  }, [errs]);
  const keyPress = (e: React.KeyboardEvent) => {
    const regex = new RegExp('^[0-9-]+$');
    if (regex.test(e.key)) return true;
    e.preventDefault();
    return false;
  };
  const zipValue = () => {
    let postalCode = value;
    if (postalCode && postalCode?.length > 5) {
      postalCode = postalCode?.replace('-', '');
      postalCode = postalCode?.substring(0, 5) + '-' + postalCode?.substring(5, postalCode?.length);
    }
    return postalCode;
  };
  useEffect(() => {
    setDisplayError(showValidation);
  }, [showValidation]);
  const val = value as string;
  return (
    <>
      <TextField
        InputProps={{
          startAdornment: startAdornment ? (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ) : undefined,
          endAdornment: endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined,
          onKeyPress: keyPress,
          ...InputProps,
        }}
        inputProps={{
          title: val ? val : label ? label.toString() : '',
        }}
        InputLabelProps={{ shrink: true }}
        error={errors && displayErrors}
        variant={'outlined'}
        label={label}
        onBlur={(e) => {
          showValidationOnBlur && displayErrorOnCallback(() => onBlur && onBlur(e));
        }}
        onFocus={(e) => {
          showValidationOnFocus && displayErrorOnCallback(() => onFocus && onFocus(e));
        }}
        onChange={onChange}
        type="text"
        value={zipValue()}
        {...rest}
      />
      {!firstLoad && errs && name && displayErrors && <ErrorSection errs={errs} field={name} />}
    </>
  );
}

export default USPostalCodeField;
