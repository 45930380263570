import React, { useContext, useEffect, useState } from 'react';
import FormDrawerComponent from 'ui-kit/components/drawer/FormDrawerComponent';

import { AddressBookLenderContainerProps } from '../../../api/admin/interfaces';
import { addressBookAddLender, addressBookUpdateLender } from '../../../api/admin/address-book-api';
import StateSelectField from '../../../ui-kit/inputs/StateSelectField/StateSelectField';
import useSnackBars from '../../../ui-kit/components/notification/useSnackbars';
import { mortgageClause, MortgageClauseTextOptions } from '../../files/parties/form/MortgageClause';
import { doValidate, FieldValidationError } from '../../../utilities/validation/validation';
import { AgentNetDropdownSelector, AgentNetTextInput } from '../../../ui-kit/inputs';
import { addressBookLenderSchema } from '../../../utilities/validation/schemas/addressBookContact-schema';
import { ProfileContext, ProfileContextInterface } from '../../../hooks/ProfileContext';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '@agentnet/auth';
import USPostalCodeField from '../../../ui-kit/inputs/USPostalCodeField/USPostalCodeField';
import PhoneField from '../../../ui-kit/inputs/PhoneField/PhoneField';

const AddressBookLender = ({ data, openDrawer, setOpenDrawer }: AddressBookLenderContainerProps): JSX.Element => {
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isShowValidation, setIsShowValidation] = useState<boolean>(false);

  const { addSnackbarMessage } = useSnackBars();

  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const { getAccessToken } = useAuth();

  //const DEFAULT_TOASTER_TIMEOUT = 6000;
  const useStyles = makeStyles((theme) => ({
    splitWidth: {
      display: 'flex',
      width: '100%',
      gap: '25px',
      marginBottom: theme.spacing(2),
    },
    splitWidth2: {
      display: 'flex',
      width: '100%',
      gap: '25px',
    },
    w50: {
      width: '50%',
    },
    fullWidth: {
      width: '100%',
    },
    whalf: {
      marginBottom: theme.spacing(2),
      width: 'calc(50% - 15px)',
    },
    mb2: {
      marginBottom: theme.spacing(0),
      border: 'none !important',
    },
  }));
  const classes = useStyles();
  const [lenderObj, setLenderObj] = useState<any>({
    id: '',
    name: '',
    attention: '',
    address1: '',
    address2: '',
    city: '',
    stateCode: '',
    zipCode: '',
    assignTypeCdId: '',
    assigns: '',
  });

  const handleCancelAdd = () => {
    // Resetting all the data
    setIsSubmitted(false);
    setValidationErrors([]);
    setIsShowValidation(false);
    setOpenDrawer(false);
  };

  const handleAddAddressBookContact = async () => {
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }
    const token = await getAccessToken();
    setIsSubmitted(true);
    setIsShowValidation(true);
    validateFields();
    if (!validationErrors || validationErrors.length === 0) {
      const requestObj = {
        AddressBookEntryId: null,
        FirmId: firmId,
        IsMyLender: true,
        LenderAddressBookEntry: {
          Name: lenderObj.name,
          LenderAttentionName: lenderObj.attention,
          Address: {
            Address1: lenderObj.address1,
            Address2: lenderObj.address2,
            City: lenderObj.city,
            State: lenderObj.stateCode,
            PostalCode: lenderObj.zipCode,
            County: '',
          },
          LenderClauseType: lenderObj.assignTypeCdId,
          LenderClauseText: lenderObj.assigns,
          PhoneNumber: lenderObj.phoneNumber?.replaceAll('-', ''),
          FaxNumber: lenderObj.faxNumber?.replaceAll('-', ''),
          EmailAddress: lenderObj.emailAddress,
        },
      };
      if (await addressBookAddLender(requestObj, firmId, token)) {
        setIsSubmitted(true);
        handleCancelAdd();
        window.location.reload();
      } else {
        addSnackbarMessage({
          message: `Lender save failed`,
          type: 'error',
        });
      }
    }
  };

  const handleUpdateAddressBookContact = async () => {
    let firmId = '';
    if (userFirm !== null && userFirm?.firmId !== undefined) {
      firmId = userFirm.firmId;
    }
    const token = await getAccessToken();
    setIsShowValidation(true);
    if (!validationErrors || validationErrors.length === 0) {
      const requestObj = {
        AddressBookEntryId: lenderObj.id,
        FirmId: firmId,
        IsMyLender: lenderObj.isMyLender,
        LenderAddressBookEntry: {
          Name: lenderObj.name,
          LenderAttentionName: lenderObj.attention,
          Address: {
            Address1: lenderObj.address1,
            Address2: lenderObj.address2,
            City: lenderObj.city,
            State: lenderObj.stateCode,
            PostalCode: lenderObj.zipCode,
            County: '',
          },
          LenderClauseType: lenderObj.assignTypeCdId,
          LenderClauseText: lenderObj.assigns,
          PhoneNumber: lenderObj.phoneNumber?.replaceAll('-', ''),
          FaxNumber: lenderObj.faxNumber?.replaceAll('-', ''),
          EmailAddress: lenderObj.emailAddress,
        },
      };
      if (await addressBookUpdateLender(requestObj, firmId, token)) {
        setIsSubmitted(true);
        handleCancelAdd();
        window.location.reload();
      }
    } else {
      addSnackbarMessage({
        message: `Lender save failed`,
        type: 'error',
      });
    }
  };

  const validateFields = async () => {
    return await doValidate(lenderObj, addressBookLenderSchema).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
      if (errs && errs.length > 0) {
        setIsFormValid(false);
        return false;
      } else {
        setIsFormValid(true);
        return true;
      }
    });
  };

  const onLenderClauseTypeChange = (type: string) => {
    for (const obj in MortgageClauseTextOptions) {
      if (obj === type) {
        if (type === 'MERS') {
          setLenderObj({
            ...lenderObj,
            assignTypeCdId: type,
            assigns: MortgageClauseTextOptions[obj].value?.replace('{{ COMPANY NAME }}', String(lenderObj?.name)),
          });
        } else {
          setLenderObj({ ...lenderObj, assignTypeCdId: type, assigns: MortgageClauseTextOptions[obj].value });
        }
      }
    }
  };

  useEffect(() => {
    if (isShowValidation) {
      validateFields();
    }
  }, [lenderObj]);

  useEffect(() => {
    if (openDrawer) {
      setLenderObj(data);
    }
    setIsShowValidation(false);
  }, [openDrawer]);

  return (
    <>
      <FormDrawerComponent
        title={data.id ? 'Edit Lender' : 'Add Lender'}
        open={openDrawer}
        primaryActionProps={{
          loading: isSubmitted,
          disabled: isSubmitted,
          'data-qa': 'AddAddressBookContact',
          size: 'small',
        }}
        dismissActionProps={{
          disabled: isSubmitted,
          'data-qa': 'AddAddressBookContactCancel',
          size: 'small',
        }}
        crossQAPrefix="AddressBookContact"
        primaryActionLabel="Save"
        onPrimaryAction={async () => {
          if (await validateFields()) {
            setIsSubmitted(true);
            if (data.id) {
              handleUpdateAddressBookContact().then();
            } else {
              handleAddAddressBookContact().then();
            }
          } else {
            setIsSubmitted(false);
            setIsShowValidation(true);
          }
        }}
        onDismissAction={handleCancelAdd}
        testId="scrollable-form-drawer"
        width={960}
      >
        <div className={classes.splitWidth}>
          <div className={classes.fullWidth}>
            <AgentNetTextInput
              variant="outlined"
              label="Lender Company Name"
              fullWidth
              name="name"
              id="addLenderCompanyName"
              required
              value={lenderObj.name}
              onChange={(event: any) =>
                setLenderObj({
                  ...lenderObj,
                  name: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
          </div>
          <AgentNetTextInput
            variant="outlined"
            label="Attention"
            fullWidth
            name="attention"
            id="addLenderAttention"
            value={lenderObj.attention}
            onChange={(event: any) =>
              setLenderObj({
                ...lenderObj,
                attention: event.target.value,
              })
            }
            showValidation={isShowValidation}
            errs={validationErrors}
          ></AgentNetTextInput>
        </div>
        <div className={classes.splitWidth}>
          <div className={classes.fullWidth}>
            <AgentNetTextInput
              variant="outlined"
              label="Address 1"
              fullWidth
              name="address1"
              id="addLenderAddress1"
              required
              value={lenderObj.address1}
              onChange={(event: any) =>
                setLenderObj({
                  ...lenderObj,
                  address1: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
          </div>
          <div className={classes.fullWidth}>
            <AgentNetTextInput
              variant="outlined"
              label="Address 2"
              fullWidth
              className={classes.mb2}
              name="address2"
              id="addLenderAddress2"
              value={lenderObj.address2}
              onChange={(event: any) =>
                setLenderObj({
                  ...lenderObj,
                  address2: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
          </div>
        </div>
        <div className={classes.splitWidth}>
          <div className={classes.fullWidth}>
            <AgentNetTextInput
              variant="outlined"
              label="City"
              fullWidth
              name="city"
              id="addLenderCity"
              required
              value={lenderObj.city}
              onChange={(event: any) =>
                setLenderObj({
                  ...lenderObj,
                  city: event.target.value,
                })
              }
              showValidation={isShowValidation}
              errs={validationErrors}
            ></AgentNetTextInput>
          </div>
          <div className={classes.splitWidth2}>
            <div className={classes.fullWidth}>
              <StateSelectField
                variant="outlined"
                name="stateCode"
                label="State"
                fullWidth
                value={lenderObj.stateCode}
                onChange={(e: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  setLenderObj({
                    ...lenderObj,
                    stateCode: e,
                  });
                }}
                required
                showValidation={isShowValidation}
                errs={validationErrors}
              />
            </div>
            <div className={classes.fullWidth}>
              <USPostalCodeField
                variant="outlined"
                label="Zip Code"
                fullWidth
                name="zipCode"
                id="addLenderZip"
                required
                value={lenderObj.zipCode}
                onChange={(event: any) =>
                  setLenderObj({
                    ...lenderObj,
                    zipCode: event.target.value,
                  })
                }
                showValidation={isShowValidation}
                errs={validationErrors}
              />
            </div>
          </div>
        </div>
        <div className={classes.splitWidth}>
          <PhoneField
            variant="outlined"
            className={classes.mb2}
            name={'phoneNumber'}
            label={'Phone Number'}
            errs={validationErrors}
            value={lenderObj.phoneNumber}
            showValidation={isShowValidation}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLenderObj({
                ...lenderObj,
                phoneNumber: e?.target?.value,
              });
            }}
          />
          <div className={classes.fullWidth}>
            <AgentNetTextInput
              variant="outlined"
              fullWidth
              className={classes.mb2}
              name="emailAddress"
              showValidation={isShowValidation}
              errs={validationErrors}
              label={'Email'}
              value={lenderObj.emailAddress?.trim() ?? ''}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLenderObj({
                  ...lenderObj,
                  emailAddress: e?.target?.value,
                });
              }}
            />
          </div>
        </div>
        <div className={classes.whalf}>
          <AgentNetDropdownSelector
            label={'Care of, DBA, Mortgage Clause'}
            name="assignTypeCdId"
            id="addLenderAssignType"
            qaAttribute={''}
            required
            className={classes.mb2}
            fullWidth
            value={lenderObj.assignTypeCdId ?? onLenderClauseTypeChange('Standard')}
            options={mortgageClause}
            menuOption={(value: any) => {
              setLenderObj({
                ...lenderObj,
                assignTypeCdId: value,
              });
              onLenderClauseTypeChange(value);
            }}
            showValidation={isShowValidation}
            errs={validationErrors}
          ></AgentNetDropdownSelector>
        </div>
        <AgentNetTextInput
          fullWidth
          variant="outlined"
          label="Mortgage Clause"
          name="assigns"
          id="addLenderAssigns"
          value={lenderObj.assigns}
          onChange={(event: any) =>
            setLenderObj({
              ...lenderObj,
              assigns: event.target.value,
            })
          }
          showValidation={isShowValidation}
          errs={validationErrors}
        ></AgentNetTextInput>
      </FormDrawerComponent>
    </>
  );
};

export default AddressBookLender;
