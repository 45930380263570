import React, { useContext, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioGroup, { RadioProps } from 'ui-kit/components/radios/RadioGroup';
import SearchField from 'features/files/files-summary/search-form/SearchField';
import AgentNetButton from 'ui-kit/components/button/AgentNetButton';
import { AgGridReact } from 'ag-grid-react';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { getFirmDetails, getFirmList } from 'api/onBehalfOf/api';
import { useHistory } from 'react-router-dom';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { searchFirmSchema } from 'utilities/validation/schemas/on-behalf-schema';
import NoResults from 'ui-kit/components/noResults/NoResults';
import useGlobalMessages from 'ui-kit/components/notification/useGlobalMessages';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import FileStatus from 'ui-kit/icons/FileStatus';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '11.4rem',
    },
    contentWrap: {
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    mb3: {
      marginBottom: theme.spacing(3),
    },
    spaceElements: {
      marginBottom: theme.spacing(2),
    },
    gridContainer: {
      height: 'calc(100vh - 292px) !important',
      width: '100%',
      fontFamily: theme.typography.fontFamily,
    },
    searchWrap: {
      display: 'flex',
      marginBottom: theme.spacing(2),
    },
    contentClass: {
      margin: '0 auto auto',
    },
    searchButton: {
      height: '30px',
    },
  }),
);

const OnBehalfOFSearch = () => {
  const { getAccessToken } = useAuth();
  const history = useHistory();
  const { addGlobalMsg } = useGlobalMessages();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { setUserFirm, profile } = profileCtx;
  if (profile?.roleId === 2 || profile?.roleId === 13) {
    history.push('/');
    return null;
  }
  const classes = useStyles();
  const firmFilters: RadioProps[] = [
    { label: 'Firm Name', value: 'name', dataQA: 'FirmSelectFirmName' },
    { label: 'Account No.', value: 'account', dataQA: 'FirmSelectAccountNo' },
  ];
  const [firmFilter, setFirmFilter] = useState<RadioProps>(firmFilters[0]);
  const [searchText, setSearchText] = useState('');
  const [selectedFirm, setSelectedFirm] = useState('');
  const [rowData, setRowData] = useState<any>([]);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [showValidationError, setShowValidationError] = useState<boolean>(false);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errMsgs, setErrMsgs] = useState([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [displayedRowCount, setDisplayedRowCount] = useState<number>(0);

  const statusCellRenderer = (params: GridRenderCellParams): React.ReactElement => {
    const { value } = params;
    const statusDotClassName = params.value === 'Active' ? 'green-dot' : 'blue-dot',
      statusClassName = params.value === 'Active' ? 'open-status' : 'closed-status';
    return FileStatus({
      value: value,
      statusDotClassName: statusDotClassName,
      statusClassName: statusClassName,
    });
  };

  const columnDefs: any = useMemo(
    () => [
      { headerName: 'Firm ID', field: 'firmId', filter: 'agTextColumnFilter', tooltipField: 'firmId' },
      {
        headerName: 'Title & Escrow',
        field: 'isTitleEscrow',
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        cellRenderer: (params: any) => {
          return params?.data?.isTitleEscrow ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircleIcon fontSize="small" style={{ color: 'green' }} />
            </div>
          ) : (
            ''
          );
        },
        tooltipField: 'isTitleEscrow',
        maxWidth: 150,
      },
      { headerName: 'Firm Name', field: 'name', filter: 'agTextColumnFilter', flex: 1.5, tooltipField: 'name' },
      { headerName: 'Licensed States', field: 'states', filter: 'agTextColumnFilter', tooltipField: 'states' },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: statusCellRenderer,
        tooltipField: 'status',
      },
      {
        headerName: 'Action',
        field: '',
        floatingFilter: false,
        sortable: false,
        cellRenderer: (params: any) => {
          return (
            <AgentNetButton
              data-qa={`FirmSelectLink${params?.node?.id}`}
              onClick={() => {
                setSelectedFirm(params?.data?.firmId);
              }}
              color="primary"
              variant="text"
            >
              Select
            </AgentNetButton>
          );
        },
        cellStyle: { display: 'flex', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' },
        tooltipValueGetter: () => 'Select Firm',
        pinned: 'right',
        maxWidth: 150,
      },
    ],
    [],
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      minWidth: 100,
      flex: 1,
      editable: false,
      suppressMenu: true,
    }),
    [],
  );

  const getFirms = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getFirmList(token, firmFilter?.value, searchText);
  };
  const {
    execute: executeFirmList,
    status: executeFirmListStatus,
    value: firmListResults,
    errors: firmListErrors,
    errorDetails: listErrorDetails,
  } = useAsync<any>(getFirms, false);

  const getFirmDetail = async (): Promise<{ result: any; errorCode?: string | number }> => {
    const token = await getAccessToken();
    return await getFirmDetails(token, selectedFirm);
  };
  const {
    execute: executeFirmDetails,
    status: executeFirmDetailsStatus,
    value: firmDetailsResults,
    errors: firmDetailsErrors,
    errorDetails: firmErrorDetails,
  } = useAsync<any>(getFirmDetail, false);

  useEffect(() => {
    setRowData(firmListResults);
  }, [firmListResults]);

  useEffect(() => {
    if (selectedFirm) executeFirmDetails().then();
  }, [selectedFirm]);

  useEffect(() => {
    if (firmDetailsResults) {
      window.localStorage.setItem('userFirm', JSON.stringify(firmDetailsResults));
      setUserFirm && setUserFirm(firmDetailsResults);
      history.push('/files');
    }
  }, [firmDetailsResults]);

  useEffect(() => {
    const isError = executeFirmListStatus === 'error' || executeFirmDetailsStatus === 'error';
    console.log(listErrorDetails?.errorCode || listErrorDetails?.statusCode);

    setDisplayError(listErrorDetails?.errorCode !== 400 && firmErrorDetails?.statusCode !== 400 && isError);
  }, [executeFirmListStatus, executeFirmDetailsStatus, firmErrorDetails?.errorCode, listErrorDetails?.errorCode]);

  useEffect(() => {
    if (displayError) {
      const errorMessages = [...(firmDetailsErrors ?? []), ...(firmListErrors ?? [])];
      setErrMsgs(errorMessages);
    }
  }, [displayError]);

  useEffect(() => {
    if (displayError && errMsgs.length) {
      errMsgs?.map((err) => {
        addGlobalMsg({
          message: err,
          type: 'error',
        });
      });
    }
  }, [errMsgs]);

  useEffect(() => {
    doValidate({ searchText }, searchFirmSchema(firmFilter.value === 'account')).then(
      (errs: FieldValidationError[]) => {
        getFilteredErrors(errs, ['searchText']);
      },
    );
  }, [searchText]);

  const getFilteredErrors = (errs: FieldValidationError[], fields: string[]) => {
    if (!validationErrors) {
      setValidationErrors(errs);
    } else if (errs) {
      setValidationErrors((prevErrs: any) => {
        return [...prevErrs.filter((err: { field: string }) => !fields.includes(err.field)), ...errs];
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      setValidationErrors((prevErrs: any) => {
        return prevErrs.filter((err: { field: string }) => {
          return !(fields.includes(err.field) && !errs);
        });
      });
    }
  };

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  const getDisplayedRowCount = () => {
    if (gridApi) {
      return gridApi.getDisplayedRowCount();
    }
    return 0;
  };

  const onFilterChanged = () => {
    if (gridApi?.getDisplayedRowCount() === 0) {
      gridApi?.showNoRowsOverlay();
    } else {
      gridApi?.hideOverlay();
    }
    setDisplayedRowCount(getDisplayedRowCount());
  };

  const handleSearch = () => {
    if (validationErrors?.some((err) => err.field === 'searchText')) {
      setShowValidationError(true);
    } else {
      executeFirmList().then();
    }
  };

  const CustomOverlayNoRows = () => {
    return (
      <div className={classes.contentClass}>
        <NoResults
          showButton={false}
          type="results"
          titleText="No Results Found"
          pText="Try adjusting your filters to find what you’re looking for."
        />
      </div>
    );
  };

  const searchActionStatus =
    executeFirmListStatus === 'pending' || executeFirmDetailsStatus === 'pending' ? 'pending' : 'idle';

  return (
    <>
      <LoadingSpinner status={searchActionStatus} variant="linear" />
      <div className={classes.root}>
        <div className={classes.contentWrap}>
          <Typography variant="h2" className={classes.mb3}>
            Select a Firm
          </Typography>
          <RadioGroup
            className={classes.spaceElements}
            selected={firmFilter}
            options={firmFilters}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirmFilter(firmFilters?.find((option) => option.value === e.target.value) ?? firmFilter);
              setSearchText('');
              setValidationErrors([]);
              setShowValidationError(false);
            }}
            compact
          />
          <div className={(classes.spaceElements, classes.searchWrap)}>
            <SearchField
              onChange={(e) => {
                if ((firmFilter.value === 'account' && e.target.value.length <= 10) || firmFilter.value === 'name')
                  setSearchText(e.target.value);
              }}
              value={searchText}
              onSearch={handleSearch}
              name="searchText"
              errs={validationErrors}
              executeOnSearchOnChange={false}
              enableNumCode={firmFilter.value === 'account'}
              displayError={showValidationError}
              qaAttribute="FirmSelectSearchInput"
              showCLoseIcon={true}
              onClose={() => setSearchText('')}
              compact
            />
            <AgentNetButton
              onClick={handleSearch}
              color="primary"
              variant="contained"
              data-qa="FirmSelectSearch"
              size="small"
              className={classes.searchButton}
            >
              Search
            </AgentNetButton>
          </div>

          {rowData && rowData.length > 0 ? (
            <div className={`ag-theme-alpine ${classes.gridContainer}`}>
              <AgGridReact
                onGridReady={onGridReady}
                onFilterChanged={onFilterChanged}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowSelection={'multiple'}
                suppressRowClickSelection={true}
                animateRows={true}
                components={{
                  customOverlayNoRows: CustomOverlayNoRows,
                }}
                noRowsOverlayComponent={'customOverlayNoRows'}
              />
            </div>
          ) : (
            <NoResults showButton={false} type="results" titleText="No Firms Found" pText="Try adjusting your search" />
          )}
          {rowData?.length ? (
            <div className="selected-rows">
              <span>
                Showing {getDisplayedRowCount()} of {rowData?.length} firms
              </span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default OnBehalfOFSearch;
