import React, { useContext, useEffect, useMemo, useRef } from 'react';
import AccordionContent from 'ui-kit/components/accordion/AccordionContent';
import PropertyGroup, { AddressTypeProperties } from './PropertyGroup';
import { FileDataContext, FileDataContextInterface } from '../../../hooks/FileDataContext';

const FileinfoProprty: React.FC<AddressTypeProperties> = (props: AddressTypeProperties) => {
  const { value, currentIndex, properties, setProperties, handleGenerateReport, handlePropertyDelete, propertyRefs } =
    props;

  const propertyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const id = value.propertyId ?? value.propertyCustomId;
    if (id) propertyRefs.current[id] = propertyRef.current;
  }, [value]);

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { fileData } = fileDataCtx;
  const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const capitalizeFirstLetter = (str: string) => {
    const words = str.split(' ');
    return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  const propertySubtitle = useMemo(() => {
    const subtitle = [value.propertyType];
    if (value.apn) {
      subtitle.push(`APN: ${value.apn}`);
    }
    if (value.county) {
      subtitle.push(`${capitalizeFirstLetter(value.county)} County`);
    }
    return subtitle;
  }, [value.propertyType, value.apn, value.county]);

  const onDelete = (index: number, propertyId: string) => {
    handlePropertyDelete && handlePropertyDelete(index, propertyId);
  };

  const onExpandChange = (isExpand: boolean) => {
    const tempProperty =
      properties?.map((propertyValue, propertyIndex) => {
        if (propertyIndex === currentIndex) {
          propertyValue.expand = isExpand;
        }
        return propertyValue;
      }) ?? [];
    setProperties([...tempProperty]);
  };

  const propertyTitle = useMemo(() => {
    const title = [];
    if (value.address1) {
      title.push(value.address1);
    }
    if (value.city) {
      title.push(value.city);
    }
    if (value.state) {
      title.push(value.state);
    }
    if (value.postalCode) {
      title.push(value.postalCode);
    }

    return title.join(', ');
  }, [value.address1, value.city, value.state, value.postalCode]);

  return (
    <div ref={propertyRef}>
      <AccordionContent
        id={value.propertyId ?? value.propertyCustomId ?? getRandomArbitrary(0, 1000)}
        title={propertyTitle}
        expanded={value.expand}
        subtitle={propertySubtitle}
        onExpandChange={onExpandChange}
        accordianQAAttribute={`FileInfoPropertySummary${(currentIndex ?? 0) + 1}`}
        deleteQAAttribute={`FileInfoProperty${(currentIndex ?? 0) + 1}Delete`}
        icon="property"
        type="property"
        {...(currentIndex &&
          currentIndex > 0 && {
            onDelete: () => onDelete(currentIndex, value.propertyId as string),
            isDeleteDisabled: fileData?.fileStatus !== 'Open',
            className: 'newProperty',
          })}
        onGenerateReport={value.propertyId ? handleGenerateReport : undefined}
      >
        <PropertyGroup {...props} />
      </AccordionContent>
    </div>
  );
};

export default FileinfoProprty;
