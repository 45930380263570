import React, { useContext, useEffect, useState } from 'react';
import CreateBuyerSeller from './CreateBuyerSeller';
import { propertyType } from '../../../api/file/interfaces/get-file';
import { PartyGroupType } from '../parties/enums/PartyTypeCategory';
import { ProfileContext, ProfileContextInterface } from 'hooks/ProfileContext';
import useAsync from 'hooks/useAsync';
import { useAuth } from '@agentnet/auth';
import { IOutsideJurisdictionForFirms } from 'features/files/parties/PartyGroup/OutsideJurisdictionForFirms';
import { OutsideJurisdictionStates } from 'features/files/file-property/PropertyGroup';
import { getOutsideJurisdictionForFirms } from 'api/profile/profile-api';
import { ICreateFileResponse } from 'api/file/interfaces/create-file';
import { createPropertySchema, fileInfoCreateFormSchema } from 'utilities/validation/schemas';
import PropertyGroupWithAgentNetBase from 'features/files/file-property/PropertyGroupWithBaseFormGroup';
import { IAgentNetBaseFormGroupProps } from 'utilities/form/components/base-form';
import FileInfoCreateFormWithBaseFormGroup from 'features/files/file-information/form/FileInfoCreateFormWithBaseFormGroup';
import './FileCreateForm.scss';
import CreateLender from './CreateLender';
import { FileCreateContext, FileCreateContextInterface } from '../../../hooks/FileCreateContext';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const FileCreateForm = ({
  isFormValid,
  showAllValidation,
  setSubFormGroupValidity,
  firstLoad,
  formValidationMap,
  setSavingFailedError,
  refsArray,
}: IAgentNetBaseFormGroupProps<ICreateFileResponse>): JSX.Element => {
  const fileCreateCtx: FileCreateContextInterface = useContext(FileCreateContext) ?? {};
  const { fileCreateData, setFileCreateData } = fileCreateCtx;

  const firstBuyer = fileCreateData?.buyers ? fileCreateData?.buyers[0] : {};
  const secondBuyer = fileCreateData?.buyers ? fileCreateData?.buyers[1] ?? {} : {};
  const firstProperty = fileCreateData?.properties ? fileCreateData?.properties[0] : {};
  const firstSeller = fileCreateData?.sellers ? fileCreateData?.sellers[0] : {};
  const secondSeller = fileCreateData?.sellers ? fileCreateData?.sellers[1] ?? {} : {};

  const [outsideJurisdictionStates, setOutsideJurisdictionStates] = useState<OutsideJurisdictionStates[]>([]);
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;
  const { getAccessToken } = useAuth();

  useEffect(() => {
    if (formValidationMap) {
      const validationKeys = Object.keys(formValidationMap);
      const msg: string[] = [];
      validationKeys.forEach((item: any) => {
        if (!formValidationMap[item]) {
          switch (item) {
            case 'FileInfoCreateForm':
              msg[0] = 'File Info details error';
              break;
            case 'PropertyForm':
              msg[1] = 'Property details error';
              break;
            case 'createBuyer':
            case 'createSeller':
            case 'createLender':
            case 'createSecondBuyer':
            case 'createSecondSeller':
              msg[2] = 'Party details error';
              break;
          }
        }
      });
      let type = '';
      if (formValidationMap['FileInfoCreateForm'] === false) {
        type = 'fileSection';
      } else if (formValidationMap['PropertyForm'] === false) {
        type = 'propertySection';
      } else if (formValidationMap['createLender'] === false) {
        type = 'lenderSection';
      } else if (formValidationMap['createBuyer'] === false) {
        type = 'buyerSection';
      } else if (formValidationMap['createSecondBuyer'] === false) {
        type = 'secondBuyerSection';
      } else if (formValidationMap['createSeller'] === false) {
        type = 'sellerSection';
      } else if (formValidationMap['createSecondSeller'] === false) {
        type = 'secondSellerSection';
      }

      setSavingFailedError({ msg: [...msg], type });
    }
  }, [formValidationMap]);

  const getOutsideJurisdictionFirmList = async (): Promise<IOutsideJurisdictionForFirms[]> => {
    const token = await getAccessToken();
    const firmId = userFirm?.firmId ?? '';
    return await getOutsideJurisdictionForFirms(firmId as string, token);
  };

  const { execute: executeOutsideJurisdictionFirms, value: outsideJurisdictionValues } = useAsync<
    IOutsideJurisdictionForFirms[]
  >(getOutsideJurisdictionFirmList, false);

  useEffect(() => {
    if (outsideJurisdictionValues) {
      setOutsideJurisdictionStates(
        outsideJurisdictionValues.map((state) => {
          return {
            name: state?.StateName || '',
            abbreviation: state?.StateCode || '',
          };
        }),
      );
    }
  }, [outsideJurisdictionValues]);

  useEffect(() => {
    if (firstLoad) {
      executeOutsideJurisdictionFirms().then();
    }
  }, [firstLoad]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      subtitle: {
        marginTop: theme.spacing(3),
      },
      subtitleY: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
      },
    }),
  );

  const classes = useStyles();
  return (
    <>
      <div className="file-create-root">
        {fileCreateData && setFileCreateData && (
          <>
            <div ref={refsArray.fileSection}>
              <FileInfoCreateFormWithBaseFormGroup
                value={fileCreateData}
                onChange={setFileCreateData}
                showAllValidation={showAllValidation}
                setIsFormValid={(isValid) =>
                  setSubFormGroupValidity && setSubFormGroupValidity('FileInfoCreateForm', isValid)
                }
                isFormValid={isFormValid}
                firstLoad={firstLoad}
                schema={fileInfoCreateFormSchema}
              />
            </div>
            <section ref={refsArray.propertySection} className="create-file-property-container">
              <PropertyGroupWithAgentNetBase
                isFormValid={isFormValid}
                showOutsideJurisdictionControl={!!(outsideJurisdictionValues && outsideJurisdictionValues?.length > 0)}
                isFileInformation={false}
                firstLoad={firstLoad}
                isDrawerOpen={true}
                outsideJurisdictionStates={outsideJurisdictionStates}
                showAllValidation={showAllValidation}
                value={firstProperty}
                onChange={(newPropertyData: propertyType) => {
                  setFileCreateData({ ...fileCreateData, properties: [newPropertyData] });
                }}
                isCreateProperty={true}
                hideErrorTitleNotification
                setIsFormValid={(isValid) =>
                  setSubFormGroupValidity && setSubFormGroupValidity('PropertyForm', isValid)
                }
                schema={createPropertySchema}
                showTitleTypography
              />
            </section>
            <Typography ref={refsArray.lenderSection} className={classes.subtitleY} variant="h3">
              Lender
            </Typography>
            {/* LENDER */}
            <section className="create-file-lender-type-container">
              <CreateLender
                setIsFormValid={(isValid) => {
                  setSubFormGroupValidity && setSubFormGroupValidity('createLender', isValid);
                }}
                showAllValidation={showAllValidation}
                isCreateForm
              />
            </section>
            <section ref={refsArray.buyerSection} className="create-file-buyer-type-container">
              <Typography className={classes.subtitle} variant="h3">
                Buyer/Borrower
              </Typography>
              <CreateBuyerSeller
                showAllValidation={showAllValidation}
                setIsFormValid={(isValid) => {
                  setSubFormGroupValidity && setSubFormGroupValidity('createBuyer', isValid);
                }}
                type="buyer"
                partyGroupType={PartyGroupType.Buyer}
                partyTypeValue={firstBuyer}
                updatePartyTypeValue={(buyer) => {
                  setFileCreateData({ ...fileCreateData, buyers: [buyer, secondBuyer] });
                }}
                qaAttr="CreateFileBuyer1"
              />
              <div ref={refsArray.secondBuyerSection}>
                <CreateBuyerSeller
                  showAllValidation={showAllValidation}
                  setIsFormValid={(isValid) => {
                    setSubFormGroupValidity && setSubFormGroupValidity('createSecondBuyer', isValid);
                  }}
                  type="buyer"
                  partyGroupType={PartyGroupType.Buyer}
                  partyTypeValue={secondBuyer}
                  updatePartyTypeValue={(secBuyer) => {
                    setFileCreateData({ ...fileCreateData, buyers: [firstBuyer, secBuyer] });
                  }}
                  qaAttr="CreateFileBuyer2"
                />
              </div>
            </section>
            <section ref={refsArray.sellerSection} className="create-file-seller-type-container">
              <Typography className={classes.subtitle} variant="h3">
                Seller/Owner
              </Typography>
              <CreateBuyerSeller
                setIsFormValid={(isValid) => {
                  setSubFormGroupValidity && setSubFormGroupValidity('createSeller', isValid);
                }}
                type="seller"
                showAllValidation={showAllValidation}
                partyGroupType={PartyGroupType.Seller}
                partyTypeValue={firstSeller}
                updatePartyTypeValue={(seller) => {
                  setFileCreateData({ ...fileCreateData, sellers: [seller, secondSeller] });
                }}
                qaAttr="CreateFileSeller1"
              />
              <div ref={refsArray.secondSellerSection}>
                <CreateBuyerSeller
                  setIsFormValid={(isValid) => {
                    setSubFormGroupValidity && setSubFormGroupValidity('createSecondSeller', isValid);
                  }}
                  type="seller"
                  showAllValidation={showAllValidation}
                  partyGroupType={PartyGroupType.Seller}
                  partyTypeValue={secondSeller}
                  updatePartyTypeValue={(seller) => {
                    setFileCreateData({ ...fileCreateData, sellers: [firstSeller, seller] });
                  }}
                  qaAttr="CreateFileSeller2"
                />
              </div>
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default FileCreateForm;
