import { gql } from '@apollo/client';
import { Topic } from '../types';
import performQuery from 'features/help/queries/contentfulClient';

const TOPIC_ARTICLES_COLLECTION = gql`
  query TopicWithArticles($topicSlug: String!, $limit: Int!, $preview: Boolean) {
    topicCollection(where: { slug: $topicSlug }, limit: 1, preview: $preview) {
      items {
        topicName
        slug
        previewDescription
        linkedFrom {
          kbArticleCollection(limit: $limit, order: sortOrder_ASC) {
            total
            items {
              sys {
                id
                spaceId
              }
              articleTitle
              articleSummary
              slug
            }
          }
        }
      }
    }
  }
`;

export const useTopicWithArticlesQuery = async (topicSlug: string, limit = 5) => {
  const response = await performQuery(TOPIC_ARTICLES_COLLECTION, { topicSlug, limit });

  if (response.hasError) {
    return {
      data: null,
      error: true,
    };
  }

  return {
    data: response.res?.data?.topicCollection?.items?.[0] as Topic | null,
    error: false,
  };
};
