import React, { useContext, useEffect, useRef } from 'react';
import type { Dispatch, RefObject, SetStateAction } from 'react';
import clsx from 'clsx';
import { MenuItem, Typography } from '@material-ui/core';
import {
  AgentNetResourceCenter,
  AgentNetTitleEscrow,
  AgentPrintPro,
  ComprehensiveCalc,
  Discownts,
  SoftwareIntegrationHub,
} from './AppSwitcherIcons';
import { makeStyles } from '@material-ui/core/styles';
import { getAgentNetKnowledgeUrl, getAnteUrl } from 'api/host-by-env';
import { ProfileContext, ProfileContextInterface } from '../../hooks/ProfileContext';
import { grey } from '@material-ui/core/colors';

type NavLinkType = {
  path: string;
  linkText: string;
  linkDescription: string;
  linkClassName?: string;
  icon?: React.ReactNode;
  category: string;
};

interface AppSwitcherProps {
  drawerWidth: number;
  visible: boolean;
  isHqUser: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 32px)',
    margin: '0 auto',
    transition: '.3s all ease-in',
    borderColor: grey[200],
    padding: `6px 0`,
  },
  svgContainer: {
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  drawerFloat: {
    [theme.breakpoints.up('xs')]: {
      flexShrink: 0,
      position: 'absolute',
      height: 'calc(100vh - 64px)',
      marginTop: '113px',
      left: 0,
      zIndex: -100,
      backgroundColor: 'white',
      width: 400,
      opacity: 0,
      transform: 'translateX(-100%)',
      transition: 'opacity .3s ease-out, transform .3s ease-out, box-shadow .3s ease-out',
      padding: '1.2rem 0',
    },
  },
  drawerFloatVisible: {
    opacity: 1,
    transform: 'translateX(0)',
    boxShadow: `0px 10px 9px 0px rgba(0, 0, 0, 0.80)`,
    zIndex: 1500,
  },
  categoryHeader: {
    padding: '16px 16px 8px',
  },
}));

const AppSwitcherLinks: React.FC<AppSwitcherProps> = ({ drawerWidth, visible, isHqUser, setVisible }) => {
  const classes = useStyles();
  const profileCtx: ProfileContextInterface = useContext(ProfileContext) ?? {};
  const { userFirm } = profileCtx;

  const agentNetKnowledgeUrl = isHqUser ? getAgentNetKnowledgeUrl() + '?type=b2e' : getAgentNetKnowledgeUrl();
  const anteUrl = `${getAnteUrl()}TitleEscrow/FileService/FileService.html?v1=${userFirm?.firmId}`;
  const agentNetKnowledgeEducationTrainingUrl = isHqUser
    ? agentNetKnowledgeUrl + '&callbackUrl=/?landing-page=education-training'
    : agentNetKnowledgeUrl + '?callbackUrl=/?landing-page=education-training';

  const appSwitcherLinks: NavLinkType[] = [
    {
      path: agentNetKnowledgeUrl,
      linkText: 'AgentNet Knowledge',
      linkDescription: 'Forms, Bulletins, Education and UW Guidance',
      icon: <AgentNetResourceCenter />,
      category: 'Your Applications',
    },
    {
      path: anteUrl,
      linkText: 'AgentNet Production',
      linkDescription: 'First American Title & Escrow Production Solution',
      icon: <AgentNetTitleEscrow />,
      category: 'Your Applications',
    },
    {
      path: 'https://agency.myfirstam.com/LaunchPage.aspx?AgentXpress',
      linkText: 'discownts.com®',
      linkDescription: 'Special Offers From Our Favorite Suppliers',
      icon: <Discownts />,
      category: 'Other Services',
    },
    {
      path: 'https://agency.facc.firstam.com/',
      linkText: 'First American Comprehensive Calculator',
      linkDescription: 'Title Rates & Recording Fee Estimates',
      icon: <ComprehensiveCalc />,
      category: 'Other Services',
    },
    {
      path: 'https://agency.myfirstam.com/AgentPrintPro/Authentication',
      linkText: 'Marketing & Print Services',
      linkDescription: 'High-Quality Content to Build Your Brand',
      icon: <AgentPrintPro />,
      category: 'Other Services',
    },
    {
      path: 'https://agency.myfirstam.com/ApplicationTabGUI/IntegrationTraining.html',
      linkText: 'Software Integration Hub',
      linkDescription: 'Master AgentNet® Integrations with Ease',
      icon: <SoftwareIntegrationHub />,
      category: 'Other Services',
    },
    // {
    //   path: 'https://www.firstam.com/title/agency/advantage/',
    //   linkText: 'AgentAdvantage™ Marketplace',
    //   linkDescription: 'Products & Services to Grow Your Business',
    //   icon: <AgentAdvantage />,
    //   category: 'Your Applications',
    // },

    // {
    //   path: agentNetKnowledgeEducationTrainingUrl,
    //   linkText: 'Education & Training',
    //   linkDescription: 'Online Courses & Continuing Education (CE/CLE)',
    //   icon: <EagleAcademy />,
    //   category: 'Other Services',
    // },
    // {
    //   path: 'https://records.firstam.com/',
    //   linkText: 'Records Center',
    //   linkDescription: 'Search Agency Imaging',
    //   icon: <RecordsCenter />,
    //   category: 'Other Services',
    // },
  ];

  const groupedLinks = appSwitcherLinks.reduce((acc, link) => {
    if (!acc[link.category]) {
      acc[link.category] = [];
    }
    acc[link.category].push(link);
    return acc;
  }, {} as Record<string, NavLinkType[]>);

  const handleLinkClick = (path: string) => {
    if (path !== '/') {
      window.open(path, '_blank');
    }
  };

  const menuRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target: HTMLElement = e.target as HTMLElement;
      const isOutsideClick: boolean | null = menuRef.current && !menuRef.current.contains(target);
      const isNotAppSwitcherButton: boolean = target.getAttribute('data-QA') !== 'appswitcher-button';

      if (isOutsideClick && isNotAppSwitcherButton && visible) {
        setVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [visible, setVisible]);

  return (
    <nav ref={menuRef} className={clsx(classes.drawerFloat, { [classes.drawerFloatVisible]: visible })}>
      {Object.entries(groupedLinks).map(([category, links]) => (
        <React.Fragment key={category}>
          <Typography variant="h6" className={classes.categoryHeader} color="primary">
            {category}
          </Typography>
          {links.map((link, i) => (
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleLinkClick(link.path)}
              key={`${category}-${i}`}
              divider
            >
              <div className={classes.svgContainer}>{link.icon}</div>
              <div className={classes.textContainer}>
                <Typography variant="body1">{link.linkText}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {link.linkDescription}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </React.Fragment>
      ))}
    </nav>
  );
};

export default AppSwitcherLinks;
