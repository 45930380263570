import { handleBusinessError, handleError } from 'utilities/utilities';
import { getAgencyApiHost } from 'api/host-by-env';
import {
  DeleteDocumentRequest,
  GetPolicyUploadHistoryRequest,
  GetPolicyUploadHistoryResponse,
  PolicyUploadData,
  PolicyUploadResponse,
  ViewDocumentRequest,
  ViewDocumentResponse,
} from 'features/services/policyupload/type';
import { PolicyUploadMapper } from 'features/services/policyupload/policyUpload.mapper';

export async function postPolicyUpload(payload: PolicyUploadData, token: string): Promise<PolicyUploadResponse> {
  const url = `${getAgencyApiHost()}policy-upload/v1/images`;
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.Result;
}

export async function deletePolicyUpload(payload: DeleteDocumentRequest, token: string): Promise<boolean> {
  const url = `${getAgencyApiHost()}policy-upload/v1/images?DocumentKey=${payload.DocumentKey}&firmId=${
    payload.FirmId
  }`;
  const response = await fetch(url, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  handleBusinessError(json);
  return json.Result;
}

export async function getHistory(
  payload: GetPolicyUploadHistoryRequest,
  token: string,
): Promise<GetPolicyUploadHistoryResponse[]> {
  const url = `${getAgencyApiHost()}policy-upload/v1/history?firmId=${payload.firmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return PolicyUploadMapper.getPolicyUploadHistory(json.Result);
}

export async function getDocument(payload: ViewDocumentRequest, token: string): Promise<ViewDocumentResponse> {
  const url = `${getAgencyApiHost()}policy-upload/v1/images?FileName=${payload.FileName}&InRPS=${
    payload.InRPS
  }&FirmId=${payload.FirmId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.Result;
}
