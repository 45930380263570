export const fileStatus = [
  { name: 'All', value: 'All' },
  { name: 'Open', value: 'Open' },
  { name: 'Opened in Error', value: 'Opened in Error' },
  { name: 'Cancelled', value: 'Cancelled' },
  { name: 'Closed', value: 'Closed' },
];
export const fileStatusFileInformation = [
  { name: 'Open', value: 'Open' },
  { name: 'Opened in Error', value: 'Opened in Error' },
  { name: 'Cancelled', value: 'Cancelled' },
  { name: 'Closed', value: 'Closed' },
];
export const getFileStatus = [
  { name: 'A Married Couple', value: 'A Married Couple' },
  { name: 'Individual', value: 'Individual' },
  { name: 'Entity', value: 'Entity' },
  { name: 'Trust/Estate', value: 'Trust/Estate' },
];
export const getMaritalStatus = [
  { name: 'Marital status to be determined', value: '(marital status to be determined)' },
  { name: 'Married Couple', value: 'a married couple' },
  { name: 'Married man', value: 'a married man' },
  { name: 'Married woman', value: 'a married woman' },
  { name: 'Registered Domestic Partner', value: 'a registered domestic partner' },
  { name: 'Registered Domestic Partners', value: 'as registered domestic partners' },
  { name: 'Single Man', value: 'a single man' },
  { name: 'Single Woman', value: 'a single woman' },
  { name: 'Single Person', value: 'a single person' },
  { name: 'Widow', value: 'a widow' },
  { name: 'Widower', value: 'a widower' },
  { name: 'Unmarried Man', value: 'an unmarried man' },
  { name: 'Unmarried Woman', value: 'an unmarried woman' },
  { name: 'Unmarried Person', value: 'an unmarried person' },
  { name: 'Husband and Husband', value: 'husband and husband' },
  { name: 'Husband and Wife', value: 'husband and wife' },
  { name: 'Married Spouses', value: 'married spouses' },
  { name: 'Wife and Husband', value: 'wife and husband' },
  { name: 'Wife and Wife', value: 'wife and wife' },
];
export const PropertyTypeOptions = [
  { name: 'Residential (1-4 family)', value: 'Residential (1-4 family)' },
  { name: 'Non-residential', value: 'Non-residential' },
  { name: 'Commercial', value: 'Commercial' },
];

export const EntityType = [
  { name: '', value: '' },
  { name: 'Business Trust', value: 'Business Trust' },
  { name: 'Corporation', value: 'Corporation' },
  { name: 'General Partnership', value: 'General Partnership' },
  { name: 'Joint Venture', value: 'Joint Venture' },
  { name: 'Limited Liability Company', value: 'Limited Liability Company' },
  { name: 'Limited Partnership', value: 'Limited Partnership' },
  { name: 'Non-Profit Corporation', value: 'Non-Profit Corporation' },
  { name: 'Non-Profit Public Benefit Corporation', value: 'Non-Profit Public Benefit Corporation' },
  { name: 'Other', value: 'Other' },
  { name: 'Professional Corporation', value: 'Professional Corporation' },
];
export const officeOptions = [{ name: 'Integrity Lending, Irvine CA ', value: 'integrityLending' }];
export const transactionTypes = [
  { name: 'Sale/Cash', value: 'Sale/Cash' },
  { name: 'Sale w/Mortgage', value: 'Sale w/Mortgage' },
  { name: 'Refinance', value: 'Refinance' },
  { name: 'Equity Loan', value: 'Equity Loan' },
  { name: 'Sale w/Construction Loan', value: 'Sale w/Construction Loan' },
  { name: 'Other', value: 'Other' },
];
export const searchTypes = [
  { name: 'All Files', value: 'all' },
  { name: 'File #', value: 'fileNumber' },
  { name: 'Service Order #', value: 'serviceOrderNumber' },
  { name: 'Buyer', value: 'buyer' },
  { name: 'Seller', value: 'seller' },
  { name: 'Address', value: 'address' },
  { name: 'Lender', value: 'lender' },
  { name: 'Underwriter', value: 'underwriter' },
  { name: 'Transaction Type', value: 'transactionType' },
];
export const dateRangeTypes = [
  { name: 'Last 30 Days', value: '1' },
  { name: 'Last 3 Months', value: '3' },
  { name: 'Last 6 Months', value: '6' },
  { name: 'Last 1 Year', value: '12' },
  { name: 'Custom Range', value: 'custom' },
];
export const dateRangeFilters = {
  equal: 'Equal',
  greaterThan: 'Greater Than',
  lessThan: 'Less Than',
  inRange: 'In Range',
};
export const Notice = `Name(s) to Search (IMPORTANT NOTICE: This ad hoc SDN search page is provided as an accommodation. 
  However, the search results will not be retained in AgentNet. 
  For open title orders, use the SDN tab where AgentNet provides default SDN searches for the buyers, sellers and lenders and retains the result in the file. 
  For information on (i) searching mandates, and (ii) clearing and documenting possible "hits", please review the applicable underwriting bulletins in the Underwriting Library, 
  contact your counsel or visit the federal SDN web site at: <a href="http://www.treasury.gov/sdn" target="_blank" class="linkBtn">www.treasury.gov/sdn</a>)`;

export const PolicyNotice = `The Policy Upload tool in AgentNet® is only to upload policy schedules and endorsements which have already been reported or are in the process of being reported. Submitting policies on the upload tool does not send them to accounting for remittance purposes. Generally policies are available in the Back Title database within 2 weeks.`;

export const Content = `Disclaimer: The starter image in the Back Title Database is provided to you solely in your capacity as a
  policy issuing agent of First American and is subject to the terms and conditions of the AgentNet End User License Agreement, the terms and conditions of your current First American policy-issuing agency agreement, as well as all relevant First American National and local underwriting communications and guidance.`;

export const docType = [
  { name: 'All', value: 'All' },
  { name: 'Legal Description', value: 'Legal Description' },
  { name: 'Starter', value: 'Starter' },
  { name: 'Prior Loan Policy', value: 'Prior Loan Policy' },
  { name: 'Prior Owner Policy', value: 'Prior Owner Policy' },
];

export const StartnewBTSearch =
  'We could not find any documents associated with the address on this file. Would you like to start a new search?';

export const OFACPdfWindowName = 'OFAC PDF';
export const SdnPdfWindowName = 'OFAC PDF';
export const BackTitlePdfWindowName = 'BackTitle PDF';
export const newWindow = '_blank';
export const WindowFeatures = 'popup';
export const Regex = `^[A-Za-z0-9-' ]+$`;
export const RegexAlphanumeric = `^[A-Za-z0-9 ]+$`;
export const RegexNumeric = `^[0-9 ]+$`;
export const SdnErrorAlert = 'Unable to return search results';
export const SdnWarningAlert = 'Search name is required';
export const SdnAlertDuration = 6000;
export const DateFormat = 'MM/DD/YYYY';
export const DocumentType = 'application/pdf;base64';
export const zipDocType = 'application/zip';
export const excelDocType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const wordDocType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const xmlDocType = 'application/xml';
export const xlsDocType = 'application/vnd.ms-excel';
export const msgDocType = 'application/vnd.ms-outlook';
export const addRowsMaxCount = 3;
export enum httpErrorCodes {
  badRequest = '400',
  notFound = '404',
  forbidden = '403',
  internalServerError = '500',
}

export const policyUploadStatus = [
  { name: 'All', value: 'All' },
  { name: 'Uploaded', value: 'Uploaded' },
  { name: 'In process', value: 'In process' },
  { name: 'Available in production', value: 'Available in production' },
  { name: 'Invalid File Type', value: 'Invalid File Type' },
  { name: 'Server Error', value: 'Server Error' },
  { name: 'Password Proteced Document', value: 'Password Proteced Document' },
  {
    name: 'Invalid File Type or Password Protected Document',
    value: 'Invalid File Type or Password Protected Document',
  },
];

export const contactUsReasons = [
  { name: 'Add Agent User', value: 'Add Agent User' },
  { name: 'Agent Activity Rights', value: 'Agent Activity Rights' },
  { name: 'Closing Protection Letters', value: 'Closing Protection Letters' },
  { name: 'Jackets', value: 'Jackets' },
  { name: 'Login Issue', value: 'Login Issue' },
  { name: 'My Files', value: 'My Files' },
  { name: 'Rates & Fees', value: 'Rates & Fees' },
  { name: 'Third Party Integration', value: 'Third Party Integration' },
  { name: 'Update Contact Info', value: 'Update Contact Info' },
  { name: 'Other', value: 'Other' },
];

export const frequency = [
  { name: 'Daily', value: 'Daily' },
  { name: 'Weekly', value: 'Weekly' },
  { name: 'Bi-Monthly (15th & 25th)', value: 'Bi-Monthly (15th & 25th)' },
  { name: 'Monthly (25th)', value: 'Monthly (25th)' },
];

export const daysAfterPolicyDate = [
  { name: '5', value: '5' },
  { name: '15', value: '15' },
  { name: '30', value: '30' },
  { name: '45', value: '45' },
  { name: '60', value: '60' },
];

export const reportDays = [
  { name: 'Monday', value: 'Monday' },
  { name: 'Tuesday', value: 'Tuesday' },
  { name: 'Wednesday', value: 'Wednesday' },
  { name: 'Thursday', value: 'Thursday' },
  { name: 'Friday', value: 'Friday' },
];

export const DeleteEsignature = {
  DELETE_ESIG_CONFIRM_MSG: `You are about to permanently delete this eSignature. Are you sure want to Proceed?`,
};

export const userType = ['Agent', 'Staff'];
export const hqUser = ['Yes', 'No'];
export const userStatus = ['Inactive', 'Not Activated', 'Active', 'Disabled'];
export const firmStatus = ['Active', 'Inactive'];
export const userRoleAgent = ['Agent', 'Title & Escrow User', 'Escrow Only User', 'CPL Only', 'Title Only User'];
export const userRoleStaff = ['User', 'Staff Administrator', 'System Administrator', 'On Behalf Of'];
