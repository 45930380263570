import React, { ReactNode, useEffect, useState } from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import Endorsements from 'features/jackets/Endorsements';
import { Endorsement } from 'features/jackets/types';
import AgentNetDivider from 'ui-kit/components/dividers/AgentNetDivider2';
import QuestionItem from 'ui-kit/components/tables/AdditionalQuestionItem';
import IncorporatedEndorsements from 'features/jackets/IncorporatedEndorsement';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginBottom: '16px',
      marginTop: '8px',
    },
    marginZero: {
      margin: 0,
    },
    paddingTopZero: {
      paddingTop: '0px !important',
    },
    endorsement: {
      padding: '16px 8px 8px 8px',
    },
    incorporatedEndorsements: {
      paddingTop: '16px',
      paddingLeft: '10px',
    },
  }),
);

interface IPolicy {
  callbacks: {
    updateCalcCriteriaData: any;
    updateJacketEndorsement: (
      jacketId: string | null,
      randomId: string | null,
      rflId: number | null,
      name: string | null,
      amount: number | null,
      reason: string,
      type: string,
      starsNumber: string | null,
      index: number,
    ) => void;
    renderFieldComponent: (
      field: any,
      type: string,
      validateErrors?: any,
      index?: any,
      subIndex?: any,
      filterRateType?: boolean,
    ) => ReactNode;
    openStatCodePDF: () => void;
    additionalQuestionRef: any;
  };
  disableFields: boolean;
  index: number;
  endorsementsList: Endorsement[];
  validationErrors: any;
  shouldShowValidation: boolean;
  isReissue: boolean;
  item: any;
  propertyState: string;
}
const Policy = ({
  callbacks,
  isReissue,
  item,
  index,
  endorsementsList,
  propertyState,
  validationErrors,
  shouldShowValidation,
  disableFields,
}: IPolicy) => {
  const [filteredEndorsements, setFilteredEndorsements] = useState<Endorsement[]>([]);
  const [firstLoad, setFirstLoad] = useState(false);

  const {
    updateCalcCriteriaData,
    renderFieldComponent,
    updateJacketEndorsement,
    openStatCodePDF,
    additionalQuestionRef,
  } = callbacks;
  const { policyType, fields, endorsements, incorporatedEndorsements, questions } = item;
  const policyRow = ['RateType', 'PolicyDate', 'PolicyAmount', 'StatCode'];
  const statCodeState = ['TX', 'NM', 'NY'];
  const classes = useStyles();
  const IsExtendedCoverage =
    fields.filter((field: any) => field.name === 'IsExtendedCoverage')?.[0]?.isChecked ?? false;

  const filterEndorsements = (): void => {
    if (firstLoad) {
      const policyCategory = item?.policyCategory ?? 'All';
      const policyCoverage = IsExtendedCoverage ? item?.extendedPolicyCoverage ?? 'All' : item?.policyCoverage ?? 'All';
      const filteredEndorsements = endorsementsList.filter(
        (x: any) =>
          x.endorsementFormNumber === 'Miscellaneous Endorsement' ||
          ((x.policyCategories.includes('All') || x.policyCategories.includes(policyCategory)) &&
            (x.policyCoverages.includes('All') || x.policyCoverages.includes(policyCoverage))),
      );
      setFilteredEndorsements(filteredEndorsements);
    } else {
      setFirstLoad(true);
    }
  };

  const onChangeIncorporatedEndorsement = (
    randomId: string | null,
    endoId: string | null,
    endorsementFormNumber: string | null,
    reason: string | null,
    value: string | null,
  ) => {
    updateJacketEndorsement(
      null,
      randomId,
      null,
      value,
      null,
      reason as string,
      'incorporatedEndorsements',
      endorsementFormNumber,
      index,
    );
  };

  useEffect(() => {
    if (firstLoad) filterEndorsements();
  }, [IsExtendedCoverage, firstLoad]);

  return (
    <>
      <Grid item sm={12} className={classes.heading}>
        <AgentNetDivider typoVariant="h3" title={policyType} disablePadding />
      </Grid>
      <Grid container spacing={2} className={classes.marginZero}>
        {fields?.length > 0 &&
          fields?.map((field: any, subIndex: any) => (
            <>
              {field.dataType === 'checkbox' &&
                renderFieldComponent(field, 'updatePolicySettings', validationErrors, index, subIndex)}
            </>
          ))}
      </Grid>
      <Grid container spacing={2} className={classes.marginZero}>
        {fields?.length > 0 &&
          fields?.map((field: any, subIndex: any) => (
            <>
              {field.dataType !== 'checkbox' &&
                policyRow.includes(field.name) &&
                renderFieldComponent(
                  field,
                  'updatePolicySettings',
                  validationErrors,
                  index,
                  subIndex,
                  field.name === 'RateType' && IsExtendedCoverage,
                )}
            </>
          ))}
      </Grid>
      {isReissue && (
        <Grid container spacing={2} className={classes.marginZero}>
          {fields?.length > 0 &&
            fields?.map((field: any, subIndex: any) => (
              <>
                {field.dataType !== 'checkbox' &&
                  !policyRow.includes(field.name) &&
                  renderFieldComponent(field, 'updatePolicySettings', validationErrors, index, subIndex)}
              </>
            ))}
        </Grid>
      )}

      {incorporatedEndorsements?.length > 0 && (
        <Grid container spacing={2} className={classes.incorporatedEndorsements}>
          <IncorporatedEndorsements
            contentDisabled={disableFields}
            endorsements={incorporatedEndorsements}
            isFromJacket={false}
            showStatCode={statCodeState.includes(propertyState)}
            onChangeIncorporatedEndorsement={onChangeIncorporatedEndorsement}
          />
        </Grid>
      )}
      {endorsements && (
        <Grid container className={classes.endorsement}>
          <Endorsements
            isEditDisabled={disableFields}
            onChangeEndorsement={(...args) => updateJacketEndorsement(...args, index)}
            endorsements={endorsements}
            filterEndorsements={filterEndorsements}
            endorsementOptions={filteredEndorsements}
            validationErrors={validationErrors}
            openStatCodePDF={openStatCodePDF}
            shouldShowValidation={shouldShowValidation}
            shouldDisableScroll
            showStatCode={statCodeState.includes(propertyState)}
          />
        </Grid>
      )}
      {questions?.length > 0 && (
        <Grid container spacing={2} className={classes.marginZero}>
          <Grid item sm={12} className={classes.heading}>
            <AgentNetDivider typoVariant="h3" title={'Additional Questions'} disablePadding />
          </Grid>
          <Grid item sm={12}>
            {item?.questions.map((question: any, subindex: number) => (
              <QuestionItem
                {...question}
                isDisabled={disableFields || question?.isDisabled}
                additionalQuestionRef={additionalQuestionRef}
                key={question.randomId}
                validationErrors={validationErrors}
                shouldShowValidation={shouldShowValidation}
                onChange={(val: any) => {
                  updateCalcCriteriaData('updateQuestions', 'jacketRates', val, index, subindex);
                }}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Policy;
