import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

function PDFIcon(props: SvgIconProps): React.ReactElement {
  return (
    <SvgIcon {...props} height={32} width={32} viewBox="0 0 32 32">
      <path
        fill={props.htmlColor ? props.htmlColor : `#0074CA`}
        d="M18.667 2.667H8a2.663 2.663 0 00-2.653 2.666l-.014 21.334a2.663 2.663 0 002.654 2.666H24c1.467 0 2.667-1.2 2.667-2.666v-16l-8-8zM8 26.667V5.333h9.333V12H24v14.667H8z"
      ></path>
      <path
        fill={props.htmlColor ? props.htmlColor : `#0074CA`}
        fillRule="evenodd"
        d="M17.364 15.249c-.012-.525-.046-1.916-1.367-1.916h-.02c-.35.004-.627.117-.825.337-.371.414-.357 1.109-.293 1.734.08.784.415 1.657.544 1.97-.095.282-.358 1.05-.69 1.852l-.03.071-.015.035-.03.072-.03.07c-.352.82-.622 1.354-.735 1.569-.406.144-1.684.63-2.321 1.22-.391.361-1.307 1.21-.66 2.085.196.265.54.42.948.423h.014c.69 0 1.417-.434 1.998-1.192.792-1.034 1.242-1.761 1.405-2.038l.867-.233.08-.022c.363-.097.723-.191.91-.237.308-.073.663-.138.864-.172.389.355 1.61 1.368 2.852 1.322.621-.022 1.071-.188 1.338-.493a.99.99 0 00.245-.745l-.326.033.325-.035c-.022-.222-.227-1.322-2.101-1.277-1.134.027-1.706.084-1.971.121-.301-.318-1.104-1.198-1.482-1.93.134-.415.528-1.72.506-2.624zm-4.698 7.501l.223-.143c-.347.505-.75.971-1.062 1.06-.081.023-.123.007-.154-.012h-.002c.045-.197.479-.556.946-.872l.05-.033zm6.734-2.078c.638-.052 1.308-.035 1.524.146.033.027.036.044.036.067 0 .117-.043.145-.095.167-.272.116-.884-.034-1.465-.38zm-3.463-.369c.172-.384.345-.832.476-1.19.242.355.506.69.715.943-.175.036-.392.084-.654.148-.213.052-.4.104-.562.154l.024-.055zm0-6a.024.024 0 00.004-.008l.028.001.001.002.002.003c.018.024.079.133.083.47.003.26-.07.65-.16 1.023a3.788 3.788 0 01-.094-.798c0-.51.113-.662.136-.694z"
        clipRule="evenodd"
      ></path>
    </SvgIcon>
  );
}
export default PDFIcon;
