import React, { useEffect, useState } from 'react';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { DeepMap, FieldError, FieldValues, useForm } from 'react-hook-form';
import { isObjectEmpty } from 'utilities/utilities';

export interface IBaseFormProps<T> {
  value: T;
  onChange: (value: T) => void;
  showAllValidation?: boolean;
  setIsFormValid?: (isValid: boolean) => void;
  isFormValid?: boolean;
  schema?: any;
  customValidation?: boolean;
  validationErrors?: FieldValidationError[];
  setValidationErrors?: (errors: FieldValidationError[]) => void;
  currentStatus?: string;
  refsArray?: any;
}
export interface IAgentNetBaseFormGroupProps<T> extends IBaseFormProps<T> {
  formValidationMap?: Record<string, boolean>;
  setFormValidationMap?: (isValid: Record<string, boolean>) => void;
  errors?: DeepMap<FieldValues, FieldError>;
  setSubFormGroupValidity?: (formGroupId: string, isValid: boolean) => void;
  registerSubFormGroups?: (formGroupIds: string[]) => void;
  firstLoad?: boolean;
  evaluateSubFormGroupValidity?: (formGroupIds: string[]) => boolean;
  resetVisibility?: boolean;
  setResetVisibility?: any;
  setSavingFailedError?: any;
  hideErrorTitleNotification?: boolean;
}
export const withBaseFormGroup = <T, G extends IAgentNetBaseFormGroupProps<T>>(
  FormComponent: React.FC<G>,
  props: G,
) => {
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const [activeSubFormGroups, setActiveSubFormGroups] = useState<string[] | null>(null);
  const [formValidationMap, setFormValidationMap] = useState<Record<string, boolean>>({});
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const { errors } = useForm();
  const { setIsFormValid, onChange, value, customValidation } = props;

  useEffect(() => {
    if (firstLoad) {
      !customValidation && evaluateFormValidity({ ...value });
      setFirstLoad(false);
    }
  }, [firstLoad]);

  useEffect(() => {
    !customValidation && evaluateFormValidity({ ...value });
  }, [formValidationMap]);

  useEffect(() => {
    !isObjectEmpty(value as Record<string, unknown>) && !customValidation && evaluateFormValidity({ ...value });
  }, [value]);

  const evaluateFormValidationMap = (errors: FieldValidationError[]) => {
    let isValid = false;
    if (activeSubFormGroups === null) {
      isValid =
        formValidationMap &&
        Object.keys(formValidationMap).every((formGroupId) => formValidationMap && formValidationMap[formGroupId]) &&
        errors.length === 0;
    } else {
      isValid = activeSubFormGroups.every((formGroupId) => formValidationMap[formGroupId]);
    }

    return isValid;
  };

  const evaluateFormValidity = async (newValue: T) => {
    const formErrors = props.schema ? (props.schema && (await doValidate(newValue, props.schema))) || [] : [];
    setIsFormValid && setIsFormValid(!!evaluateFormValidationMap(formErrors));
    setValidationErrors(formErrors);
  };

  const evaluateSubFormGroupValidity = (formGroupIds: string[]) => {
    return formGroupIds.every((id) => formValidationMap[id]);
  };
  // const onChangeWithValidation = async (newValue: T) => {
  //   await evaluateFormValidity({ ...newValue });
  //   onChange && onChange({ ...newValue });
  // };

  const setSubFormGroupValidity = (formGroupId: string, isValid: boolean) => {
    if (formValidationMap) {
      const tempFormValidationMap = formValidationMap;
      tempFormValidationMap[formGroupId] = isValid;
      setFormValidationMap && setFormValidationMap({ ...tempFormValidationMap });
    }
  };

  const registerSubFormGroups = (formGroupIds: string[]) => {
    const tempFormValidationMap: Record<string, boolean> = {};
    for (const formGroupId in formGroupIds) {
      tempFormValidationMap[formGroupId] = false;
    }
    setFormValidationMap(tempFormValidationMap);
  };

  const inputs: G = {
    ...props,
    validationErrors,
    errors,
    formValidationMap,
    setValidationErrors,
    setSubFormGroupValidity,
    registerSubFormGroups,
    evaluateSubFormGroupValidity,
    setActiveSubFormGroups,
    firstLoad,
    onChange,
  };

  return <FormComponent {...inputs} />;
};
