import React, { createContext, FC, ReactNode, useCallback, useReducer, useState } from 'react';
import { Snackbar, SnackbarOrigin } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

interface SnackbarContext {
  state: any;
  addSnackbarMessage: any;
  removeSnackbarMessage: any;
}
interface Message {
  id: string;
  type: string;
  msg?: string;
}
enum SnackbarType {
  ADD_MESSAGE = 'ADD_MESSAGE',
  REMOVE_MESSAGE = 'REMOVE_MESSAGE',
}
interface SnackbarAction {
  type: SnackbarType;
  payload?: any;
}
interface SnackbarState {
  messages: Message[];
  totalMsgs: number;
}

export const SnackbarMessengerCtx = createContext<SnackbarContext>({} as SnackbarContext);

const SnackbarReducer = (state: SnackbarState, action: SnackbarAction) => {
  switch (action.type) {
    case SnackbarType.ADD_MESSAGE:
      return {
        ...state,
        messages: [{ type: 'success', ...action.payload }, ...state.messages].slice(0, state.totalMsgs),
      };
    case SnackbarType.REMOVE_MESSAGE:
      return { ...state, messages: state.messages.filter((message) => message.id !== action.payload.id) };
  }
};

export const SnackbarMessageProvider: FC<{
  children: ReactNode;
  totalMsgs?: number;
  duration?: number;
}> = ({ children, totalMsgs = 10, duration = 6000 }) => {
  const [state, dispatch] = useReducer(SnackbarReducer, { messages: [], totalMsgs });
  const [position, setPosition] = useState<SnackbarOrigin>({ vertical: 'top', horizontal: 'right' });
  const addSnackbarMessage = useCallback(
    (data: any) => {
      const id = (Math.random() + 1).toString(36).substring(7);
      if (data.alignCenter) {
        setPosition({ vertical: 'top', horizontal: 'center' });
      } else {
        setPosition({ vertical: 'top', horizontal: 'right' });
      }
      dispatch({ type: SnackbarType.ADD_MESSAGE, payload: { id, ...data } });
      if (!data.autoHide) {
        setTimeout(() => {
          dispatch({ type: SnackbarType.REMOVE_MESSAGE, payload: { id } });
          data?.onClose?.();
        }, duration);
      }
    },
    [dispatch, duration],
  );
  const removeSnackbarMessage = useCallback(
    (id: any) => {
      dispatch({ type: SnackbarType.REMOVE_MESSAGE, payload: { id } });
    },
    [dispatch],
  );

  return (
    <SnackbarMessengerCtx.Provider value={{ state, addSnackbarMessage, removeSnackbarMessage }}>
      {children}
      <div className="snackbar-messenger-wrapper">
        <Snackbar
          className="snackbar-messenger"
          open={true}
          autoHideDuration={duration ? duration : null}
          anchorOrigin={position}
        >
          <div>
            {state.messages.map(({ type, title, message, id, onClose, html = false }) => (
              <Alert
                key={id}
                className={`snackbar-message`}
                severity={type}
                onClose={() => {
                  removeSnackbarMessage(id);
                  onClose && onClose();
                }}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start', // Aligns content at the top
                }}
              >
                {title && <AlertTitle>{title}</AlertTitle>}
                {html ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message}
              </Alert>
            ))}
          </div>
        </Snackbar>
      </div>
    </SnackbarMessengerCtx.Provider>
  );
};
