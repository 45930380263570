import React, { useState, ReactNode, ReactElement, useContext, createContext } from 'react';
import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';
import { msalConfig } from './config';
import config from './config';
import { b2cPolicies } from './config';
import { useLocation } from 'react-router-dom';

export type AuthContextType = {
  isAuthenticated: () => boolean;
  account: AccountInfo | undefined;
  getAccessToken: () => Promise<string>;
  signOut: () => Promise<void>;
  login: () => Promise<void>;
  editProfile: () => Promise<void>;
  changePassword: () => Promise<void>;
  editPhone: () => Promise<void>;
};

export type ProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: ProviderProps): ReactElement {
  const auth = useProviderAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextType {
  return useContext(AuthContext);
}

declare const window: any;

function useProviderAuth(): AuthContextType {
  const location = useLocation();
  const [account, setAccount] = useState<AccountInfo>();
  const msalClient = new PublicClientApplication(msalConfig);
  const msalScopes = config.msalScope;
  const apiScopes = config.apiScope;

  const clearWebStorage = () => {
    if (typeof window?.sessionStorage !== 'undefined') {
      window.sessionStorage.clear();
      window.localStorage.clear();
    }
  };

  const getAccessToken = async () => {
    let staffAcount = false;

    const currentAccount = account ? account : msalClient.getAllAccounts()[0];

    if (!currentAccount) {
      await signOut();
    }

    if (currentAccount?.homeAccountId.includes('b2c_1a_b2e_signin')) {
      staffAcount = true;
    }

    const request = {
      authority: staffAcount
        ? b2cPolicies.authorities.employeeSignIn.authority
        : b2cPolicies.authorities.signUpSignIn.authority,
      scopes: apiScopes,
      account: currentAccount,
    };

    const tokenResponse = await msalClient.acquireTokenSilent(request).catch(async (error) => {
      console.log('Silent token acquisition fails.', error);
      return await signOut();
    });

    return tokenResponse?.accessToken ?? '';
  };

  const login = async () => {
    // https://stackoverflow.com/questions/63347299/unable-to-acquire-token-silently-or-via-redirect-using-msal-browser
    console.log('login called');
    const tokenResponse = await msalClient.handleRedirectPromise().catch(async (error) => {
      console.log('HandleRedirectPromise fails.', error);
      clearWebStorage();
      await msalClient.loginRedirect({ scopes: msalScopes });
    });

    const currentAccount = tokenResponse ? tokenResponse.account : msalClient.getAllAccounts()[0];
    if (currentAccount) {
      setAccount(currentAccount);
    } else {
      clearWebStorage();
      if (location.pathname.includes('/staff')) {
        await msalClient.loginRedirect({
          authority: b2cPolicies.authorities.employeeSignIn.authority,
          scopes: msalScopes,
        });
      } else {
        await msalClient.loginRedirect({ scopes: msalScopes });
      }
    }
  };

  const editProfile = async () => {
    if (account) {
      await msalClient.acquireTokenRedirect({
        authority: b2cPolicies.authorities.editProfile.authority,
        scopes: msalScopes,
        redirectUri: config.redirectUri + 'admin/user/profile/update',
      });
    } else {
      if (location.pathname.includes('/staff')) {
        await msalClient.loginRedirect({
          authority: b2cPolicies.authorities.employeeSignIn.authority,
          scopes: msalScopes,
        });
      } else {
        await msalClient.loginRedirect({ scopes: msalScopes });
      }
    }
  };

  const editPhone = async () => {
    const tokenResponse = await msalClient.handleRedirectPromise();
    const currentAccount = tokenResponse ? tokenResponse.account : msalClient.getAllAccounts()[0];

    if (currentAccount) {
      setAccount(currentAccount);
      await msalClient.loginRedirect({
        authority: b2cPolicies.authorities.editPhone.authority,
        scopes: msalScopes,
        redirectUri: config.redirectUri + 'admin/user/profile/update',
        onRedirectNavigate: (url) => {
          console.log(`Redirecting to: ${url}`);
          return true;
        },
      });
    } else {
      if (location.pathname.includes('/staff')) {
        await msalClient.loginRedirect({
          authority: b2cPolicies.authorities.employeeSignIn.authority,
          scopes: msalScopes,
        });
      } else {
        await msalClient.loginRedirect({ scopes: msalScopes });
      }
    }
  };

  const changePassword = async () => {
    const tokenResponse = await msalClient.handleRedirectPromise();
    const currentAccount = tokenResponse ? tokenResponse.account : msalClient.getAllAccounts()[0];

    if (currentAccount) {
      setAccount(currentAccount);
      await msalClient.loginRedirect({
        authority: b2cPolicies.authorities.changePassword.authority,
        scopes: msalScopes,
        redirectUri: config.redirectUri + 'admin/user/profile/update',
        onRedirectNavigate: (url) => {
          console.log(`Redirecting to: ${url}`);
          return true;
        },
      });
    } else {
      if (location.pathname.includes('/staff')) {
        await msalClient.loginRedirect({
          authority: b2cPolicies.authorities.employeeSignIn.authority,
          scopes: msalScopes,
        });
      } else {
        console.log('calling loginRedirect');
        await msalClient.loginRedirect({ scopes: msalScopes });
      }
    }
  };

  const signOut = async () => {
    clearWebStorage();
    if (account) {
      let staffAcount = false;

      if (account?.homeAccountId.includes('b2c_1a_b2e_signin')) {
        staffAcount = true;
      }

      setAccount(undefined);
      await msalClient.logoutRedirect({
        authority: staffAcount
          ? b2cPolicies.authorities.employeeSignIn.authority
          : b2cPolicies.authorities.signUpSignIn.authority,
        account: msalClient.getAllAccounts()[0],
      });
    }
  };

  const isAuthenticated = () => {
    if (account && (account.username.length || account.localAccountId.length) > 0) {
      return true;
    } else {
      return false;
    }
  };

  return {
    isAuthenticated,
    account,
    getAccessToken,
    signOut,
    login,
    editProfile,
    editPhone,
    changePassword,
  };
}
