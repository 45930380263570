import React, { useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { ChevronRightOutlined, ExpandMore } from '@material-ui/icons';
import { CollapseArrow, ExpandArrow } from 'ui-kit/icons/AgentNetIcon';
import { useViewState } from '../../hooks/ViewStateContext';
import { NavLinkType } from './NavLinkType';
import { NavBadge } from './NavBadge';
import ReportsAndPayDrawer from 'core/layout-core/core-content/ReportsAndPayDrawer';
import useRatesAndFee from 'features/Remittance/useRatesAndFee';
import { FileDataContext, FileDataContextInterface } from 'hooks/FileDataContext';
import { getPrismUrl } from 'api/host-by-env';
import { getStateOptions } from 'api/profile/profile-utilities';
import { createPrismUser } from 'api/prismPropertyProfile/prismProperty-profile-api';
import { useAuth } from '@agentnet/auth';
import useSnackBars from 'ui-kit/components/notification/useSnackbars';
import LoadingSpinner from 'ui-kit/components/LoadingSpinner';

interface LeftNavProps {
  localWindow?: () => Window;
  appSwitcher?: boolean;
  onLinkClick?: () => void;
  menuCollapsed: boolean;
  setMenuCollapse: (menuCollapsed: boolean) => void;
  navState: NavLinkType[];
  setNavState: (navlist: NavLinkType[]) => void;
  pathParams?: any;
  topMargin?: number;
  children?: React.ReactNode;
  hasPermission: any;
  userFirm: any;
  prismUserProfile: any;
  fileData: any;
}

const reportAndReviewTabs = ['Report', 'Review'];
const LeftNav = (props: LeftNavProps): JSX.Element => {
  const {
    localWindow,
    menuCollapsed,
    setMenuCollapse,
    navState,
    setNavState,
    topMargin,
    children,
    hasPermission,
    userFirm,
    prismUserProfile,
    fileData,
  } = props;
  const [drawerWidth, setDrawerWidth] = useState(menuCollapsed ? 72 : 311);
  const [popperEl, setPopperEl] = useState<any>(null);
  const [openedPopperId, setOpenedPopperId] = useState<any>(null);
  const shownCategories: { [key: string]: boolean } = {};
  const { routerParamValue } = useViewState();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      drawerPaper: {
        backgroundColor: theme.palette.brand.lightGrey25,
        width: drawerWidth,
        top: topMargin ? topMargin : 113,
        height: `calc(100% - 51px)`,
      },
      list: {
        padding: 0,
        color: theme.palette.text.primary,
        marginTop: children ? theme.spacing(1) : theme.spacing(3),
        marginBottom: 113,
      },
      listCollapsed: {
        '& .MuiListItem-button': {
          paddingRight: 0,
          justifyContent: 'center',
          paddingLeft: theme.spacing(2),
        },
      },
      linkItemWrap: {
        textDecoration: 'none',
      },
      linkItem: {
        color: theme.palette.text.primary,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        transition: '.3s all',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&>div svg path': {
          fill: grey[500],
        },
        '&:hover>div svg path': {
          fill: grey[800],
        },
      },
      disabledLink: {
        pointerEvents: 'none',
        opacity: 0.5,
      },
      listItemTypeParent: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
      },
      listItemType: {
        fontWeight: `500 !important` as any,
        color: grey[800],
        '& span': {
          fontWeight: `500 !important` as any,
          color: grey[800],
        },
      },
      linkItemCollapsed: {
        paddingRight: 0,
        alignItems: 'center',
        justifyContent: 'center',
      },
      linkItemText: {
        '&>span': { fontWeight: 500, color: grey[800] },
      },
      subLinkItem: {
        padding: `4px 24px 4px 40px`,
        fontSize: '1.4rem',
        textDecoration: 'none',
        fontWeight: 400,
        '&>div': {
          padding: '4px 14px',
          transition: '.3s all',
          borderRadius: '4px',
        },
        '&:hover': {
          background: 'transparent',
        },
        '&:focus': {
          background: 'transparent',
        },
        '&:hover>div': {
          backgroundColor: theme.palette.primary.light,
        },
      },
      subLinkItemPopover: {
        paddingLeft: theme.spacing(3),
      },
      linkItemActive: {
        '& span': {
          fontWeight: `700 !important` as any,
          color: grey[800],
        },
      },
      sublinkItemActive: {
        '& span': {
          fontWeight: `700 !important` as any,
          color: grey[800],
        },
        '&>div>div': {
          backgroundColor: theme.palette.primary.light,
        },
      },
      subLinkItemText: {
        margin: 0,
        '& .MuiListItemText-primary': {
          color: theme.palette.text.secondary,
          fontSize: '1.4rem',
          lineHeight: '1.7rem',
          fontWeight: 400,
        },
      },
      subLinkHeader: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
      popoverPaper: {
        minWidth: 250,
        overflow: 'visible',
      },
      popoverContent: {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 'calc(16px)',
          left: '-15px',
          borderWidth: '10px',
          borderStyle: 'solid',
          borderColor: 'transparent white transparent transparent',
        },
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
      listItemIcon: {
        minWidth: '38px',
      },
      listItemIconActive: {
        '&>svg path': {
          fill: `${grey[800]} !important` as any,
        },
      },
      p0: {
        padding: 0,
      },
      navBottomWrap: {
        margin: `auto`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderTop: '1px solid',
        borderColor: theme.palette.divider,
        width: '100%',
      },
      navBottomWrapNotCollapsed: {
        position: 'fixed',
        zIndex: 1,
        width: drawerWidth,
        bottom: 0,
      },
      navBottomWrapCollapsed: {
        marginBottom: 63,
      },
      toggleWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: theme.palette.brand.lightGrey25,
        height: 51,
      },
      toggleWrapCollapsed: {
        flexDirection: 'column-reverse',
        height: 49,
      },
      collapseToggleIconBtn: {
        marginRight: '0.2rem',
        '&:hover': {
          background: 'transparent',
        },
      },
      categoryTitleWrap: {
        marginTop: theme.spacing(1),
        borderTop: '1px solid',
        borderTopColor: theme.palette.divider,
        '&#category-0': {
          borderTop: 'none',
          marginTop: 0,
        },
      },
      categoryTitle: {
        color: theme.palette.primary.main,
        width: '100%',
        display: 'block',
        padding: '8px 0px 0px 16px',
      },
      categoryTitleCollapsed: {
        padding: '8px 0',
        textAlign: 'center',
      },
      collapseNavText: {
        fontWeight: 600,
        color: grey[800],
        lineHeight: 1,
        marginLeft: theme.spacing(1),
      },
    }),
  );
  useEffect(() => {
    setDrawerWidth(menuCollapsed ? 72 : 311);
  }, [menuCollapsed]);

  const classes = useStyles({ drawerWidth, topMargin });
  const container = localWindow !== undefined ? () => localWindow().document.body : undefined;

  const fileDataCtx: FileDataContextInterface = useContext(FileDataContext) ?? {};
  const { setTabValue, setExecuteFileApiCall } = fileDataCtx;
  const [isLoading, setIsLoading] = useState(true);

  const [isApprovedAttorneyAccount, setIsApprovedAttorneyAccount] = useState<boolean>(false);
  const manageUserSubLinkText = 'manage users';
  const usersLinkText = 'users';
  useEffect(() => {
    const accountNumber = parseInt(window.sessionStorage.getItem('accountNumber') || '0', 10);
    if (fileData && userFirm?.offices)
      for (const office of userFirm.offices)
        for (const account of office.accounts)
          if (accountNumber === account.accountId) {
            setIsApprovedAttorneyAccount(account.clientType === 'Approved Attorney');
            break;
          }
  }, [fileData]);

  useEffect(() => {
    if (hasPermission) {
      setIsLoading(false);
    }
  }, [hasPermission]);

  useEffect(() => {
    const newNavState = navState.map((link) => ({
      ...link,
      expanded: location.pathname.startsWith(link.path) && location.pathname !== link.path,
    }));
    setNavState(newNavState);
  }, [location.pathname]);

  const handleMenuCollapse = () => {
    setNavState(navState.map((obj) => ({ ...obj, expanded: false })));
    setMenuCollapse(!menuCollapsed);
  };

  const handlePopperClose = () => {
    setPopperEl(null);
    setOpenedPopperId(null);
  };

  const handleParentClick = (event: any, link: any, idx: any) => {
    if (!menuCollapsed) {
      setNavState(navState.map((obj) => (obj.linkText === link.linkText ? { ...obj, expanded: !obj.expanded } : obj)));
    } else {
      setPopperEl(event.currentTarget);
      setOpenedPopperId(idx);
    }
  };

  //Rates and Fees Logic
  const { drawerData, setDrawerData, openDrawer, setOpenDrawer, handleDrawerOpen, rateAndFeeExecute } = useRatesAndFee(
    userFirm?.firmId ?? '',
    1,
  );

  const handleRatesandFees = () => {
    const hasDataList = [
      fileData?.serviceStatus?.hasRemittableCpl,
      fileData?.serviceStatus?.hasActiveJacket,
      fileData?.serviceStatus?.hasStandAloneEndorsement,
      fileData?.serviceStatus?.hasRemittedJacket,
      fileData?.serviceStatus?.hasActiveCpl,
      fileData?.serviceStatus?.hasRemittedCpl,
    ];
    handleDrawerOpen({ data: { fileNumber: fileData?.fileNumber } }, 'report', true);
    if (!hasDataList.includes(true)) {
      handleDrawerOpen({ data: { fileNumber: fileData?.fileNumber } }, 'report', true);
    } else {
      handleDrawerOpen(
        {
          data: {
            fileId: fileData?.fileId,
            fileNumber: fileData?.fileNumber,
            firmId: fileData?.firmId,
            underwriter: fileData?.underwriterCode,
            isReopened: fileData?.serviceStatus?.isReopenedForRemit,
          },
        },
        fileData?.serviceStatus?.isPricingValid ? 'report' : 'review',
      );
    }
  };

  const onReport = () => setExecuteFileApiCall?.(true);

  //Property Reports Logic
  const [isPending, setPendingFlag] = useState(false);
  const { getAccessToken } = useAuth();
  const { addSnackbarMessage } = useSnackBars();
  const showMessage = (errorMessage: string[]) => {
    errorMessage?.map((err) => {
      addSnackbarMessage({
        message: err,
        type: 'error',
      });
    });
  };

  const handlePropertyReports = async () => {
    const stateOptions = userFirm?.offices ? getStateOptions(userFirm) : [];
    const names = stateOptions.map((option) => option.name)?.join(',');
    const officeAddress = userFirm?.offices?.find(
      (office: any) => office.isDefault && office.status === 'Active',
    )?.address;

    if (!prismUserProfile || names?.length == 0) {
      return;
    }

    setPendingFlag(true);
    prismUserProfile.availableLocations = names;
    prismUserProfile.firmName = userFirm?.name ?? '';
    prismUserProfile.officeAddress1 = officeAddress?.address1 ?? '';
    prismUserProfile.officeAddress2 = officeAddress?.address2 ?? '';
    prismUserProfile.officeCity = officeAddress?.city ?? '';
    prismUserProfile.officeState = officeAddress?.state ?? '';
    prismUserProfile.officeZipCode = officeAddress?.postalCode ?? '';

    const token = await getAccessToken();
    const response = await createPrismUser(prismUserProfile, token);

    if (response?.error) {
      showMessage([response.error]);
    }
    setPendingFlag(false);

    if (response?.userId > 0) {
      const url = getPrismUrl();
      window.open(url, '_blank');
    }
  };

  const handleTopLevelClick = (link: NavLinkType) => {
    if (link.path.includes('#ratesfees')) {
      handleRatesandFees();
    }
    if (link.path.includes('/services/property-reports')) {
      handlePropertyReports();
    }
    return;
  };

  const hideSubMenu = (sublink: any) => {
    const hideReportAndPay =
      (sublink?.linkText === 'Pay' && !hasPermission.pay) ||
      (!hasPermission.remit && reportAndReviewTabs.includes(sublink?.linkText)) ||
      (sublink.linkText === 'Service Order Invoices' && !hasPermission.pay && !hasPermission.remit);
    return sublink.isHidden || hideReportAndPay;
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <>
      {hasPermission.staffAdmin ? null : (
        <div>
          <LoadingSpinner status={isPending ? 'pending' : 'success'} variant="linear" />
          <nav className={classes.drawer}>
            <Drawer variant="permanent" classes={{ paper: classes.drawerPaper }} anchor="left" container={container}>
              {children && !menuCollapsed && <>{children}</>}
              <List className={clsx(classes.list, { [classes.listCollapsed]: menuCollapsed })}>
                {navState.map((link, idx) => {
                  const filesLink = link.path.includes(':fileId')
                    ? link.path.replace(':fileId', routerParamValue)
                    : link.path;
                  if (
                    link.isHidden ||
                    (link.linkText === 'Report & Pay' && !hasPermission.pay && !hasPermission.remit) ||
                    (link.linkText === 'Documents' && !hasPermission.documents) ||
                    (link.linkText === 'CPL' && !hasPermission.cpl) ||
                    (link.linkText === 'Jackets' && !hasPermission.jackets) ||
                    (link.linkText.toLowerCase() === usersLinkText && !hasPermission.manageUsers) ||
                    (link.linkText === 'Billing' && !hasPermission.billing) ||
                    (link.linkText === 'Back Title' && !hasPermission.canRunBackTitleFiles) ||
                    (link.linkText === 'Back Title Search' && !hasPermission.canRunBackTitleAdHoc)
                  )
                    return null;

                  const renderNavItems = link.sublinks ? (
                    <>
                      <ListItem
                        className={classes.linkItem}
                        button
                        key={idx}
                        onClick={(e) => handleParentClick(e, link, idx)}
                      >
                        {!menuCollapsed ? (
                          <>
                            <ListItemIcon
                              className={clsx(classes.listItemIcon, {
                                [classes.listItemIconActive]: location.pathname === filesLink,
                              })}
                            >
                              {link.icon}
                            </ListItemIcon>
                            <Typography variant="h5" className={clsx(classes.listItemType, classes.listItemTypeParent)}>
                              <ListItemText
                                primary={link.linkText}
                                data-qa={link.dataQA ? link.dataQA : link.testId}
                                className={clsx({
                                  [classes.linkItemActive]: location.pathname === filesLink,
                                })}
                              />
                              {link.expanded ? (
                                <ExpandMore fontSize="large" />
                              ) : (
                                <ChevronRightOutlined fontSize="large" />
                              )}
                            </Typography>
                          </>
                        ) : (
                          <Tooltip placement="right" title={link.linkText}>
                            <div>
                              <ListItemIcon
                                className={clsx(classes.listItemIcon, {
                                  [classes.listItemIconActive]: location.pathname === filesLink,
                                })}
                                onClick={(e) => setPopperEl(e.currentTarget)}
                              >
                                {link.icon}
                              </ListItemIcon>
                              <NavBadge link={link} shouldShowDot />
                            </div>
                          </Tooltip>
                        )}
                      </ListItem>
                      {!menuCollapsed ? (
                        <Collapse key={'SubListOf_' + link.linkText} in={link.expanded}>
                          <List disablePadding>
                            {link.sublinks.map((sublink) =>
                              hideSubMenu(sublink) ? null : (
                                <Link
                                  to={sublink.path}
                                  className={clsx(classes.p0, classes.linkItemWrap, {
                                    [classes.sublinkItemActive]: location.pathname.includes(sublink.path),
                                    [classes.disabledLink]:
                                      !hasPermission.hasFirmId &&
                                      hasPermission.behalfOfUser &&
                                      sublink.linkText.toLocaleLowerCase() !== manageUserSubLinkText,
                                  })}
                                  key={sublink.linkText}
                                  data-qa={sublink.dataQA ? sublink.dataQA : sublink.testId}
                                  aria-label={sublink.linkText}
                                >
                                  <ListItem className={classes.subLinkItem} button disableRipple disableTouchRipple>
                                    <ListItemText className={classes.subLinkItemText} primary={sublink.linkText}>
                                      <Typography variant="h5" className={clsx(classes.listItemType)}>
                                        {sublink.linkText}
                                      </Typography>
                                    </ListItemText>
                                    <NavBadge link={sublink} shouldShowDot={menuCollapsed} />
                                  </ListItem>
                                </Link>
                              ),
                            )}
                          </List>
                        </Collapse>
                      ) : (
                        <Popover
                          open={openedPopperId === idx}
                          id={'popover-' + idx}
                          anchorEl={popperEl}
                          onClose={handlePopperClose}
                          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                          PaperProps={{ square: true, className: classes.popoverPaper }}
                        >
                          <List disablePadding className={classes.popoverContent}>
                            <Typography variant="h5" className={classes.subLinkHeader}>
                              {link.linkText}
                            </Typography>
                            {link.sublinks.map((sublink, index) =>
                              hideSubMenu(sublink) ? null : (
                                <Link
                                  to={sublink.path}
                                  className={clsx(classes.p0, classes.linkItemWrap, {
                                    [classes.sublinkItemActive]: location.pathname.includes(sublink.path),
                                    [classes.disabledLink]:
                                      !hasPermission.hasFirmId &&
                                      hasPermission.behalfOfUser &&
                                      sublink.linkText !== manageUserSubLinkText,
                                  })}
                                  key={index}
                                  data-qa={sublink.dataQA ? sublink.dataQA : sublink.testId}
                                >
                                  <ListItem
                                    className={clsx(classes.subLinkItem, classes.subLinkItemPopover)}
                                    button
                                    disableRipple
                                    disableTouchRipple
                                  >
                                    <ListItemText className={classes.subLinkItemText} primary={sublink.linkText} />
                                    <NavBadge link={sublink} />
                                  </ListItem>
                                </Link>
                              ),
                            )}
                          </List>
                        </Popover>
                      )}
                    </>
                  ) : (
                    <Link
                      to={filesLink}
                      className={clsx(classes.linkItemWrap, {
                        [classes.linkItemActive]: location.pathname === filesLink,
                        [classes.disabledLink]:
                          !hasPermission.hasFirmId &&
                          hasPermission.behalfOfUser &&
                          link.linkText.toLocaleLowerCase() !== usersLinkText,
                      })}
                      data-qa={link.dataQA ? link.dataQA : link.testId}
                      onClick={() => handleTopLevelClick(link)}
                    >
                      <ListItem className={classes.linkItem} button key={link.linkText}>
                        {!menuCollapsed ? (
                          <>
                            <ListItemIcon
                              className={clsx(classes.listItemIcon, {
                                [classes.listItemIconActive]: location.pathname === filesLink,
                              })}
                            >
                              {link.icon}
                            </ListItemIcon>
                            <Typography
                              variant="h5"
                              className={clsx(classes.listItemType, {
                                [classes.linkItemActive]: location.pathname === filesLink,
                              })}
                            >
                              <ListItemText className={classes.linkItemText} primary={link.linkText} />
                            </Typography>
                          </>
                        ) : (
                          <Tooltip placement="right" title={link.linkText}>
                            <ListItemIcon
                              className={clsx(classes.listItemIcon, {
                                [classes.listItemIconActive]: location.pathname === filesLink,
                              })}
                            >
                              {link.icon}
                            </ListItemIcon>
                          </Tooltip>
                        )}
                        <NavBadge link={link} shouldShowDot={menuCollapsed} />
                      </ListItem>
                    </Link>
                  );

                  if (link.category && !shownCategories[link.category]) {
                    shownCategories[link.category] = true;
                    return (
                      <div key={idx} id={`category-${idx}`} className={classes.categoryTitleWrap}>
                        <Typography
                          variant="h6"
                          className={clsx(classes.categoryTitle, { [classes.categoryTitleCollapsed]: menuCollapsed })}
                        >
                          {link.category}
                        </Typography>
                        {renderNavItems}
                      </div>
                    );
                  }

                  return renderNavItems;
                })}
              </List>
              <div
                className={clsx(classes.navBottomWrap, {
                  [classes.navBottomWrapNotCollapsed]: !menuCollapsed,
                  [classes.navBottomWrapCollapsed]: menuCollapsed,
                })}
              >
                <div className={clsx(classes.toggleWrap, { [classes.toggleWrapCollapsed]: menuCollapsed })}>
                  <IconButton
                    aria-label="Collapse Menu Toggle"
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    onClick={handleMenuCollapse}
                    className={classes.collapseToggleIconBtn}
                  >
                    {menuCollapsed ? (
                      <ExpandArrow />
                    ) : (
                      <>
                        <CollapseArrow />
                        <Typography variant="body1" className={classes.collapseNavText}>
                          Collapse Nav
                        </Typography>
                      </>
                    )}
                  </IconButton>
                </div>
              </div>
            </Drawer>
          </nav>
          <ReportsAndPayDrawer
            isFromFile
            openDrawer={openDrawer}
            disableFields={fileData?.fileStatus !== 'Open'}
            onReport={onReport}
            setOpenDrawer={setOpenDrawer}
            drawerData={drawerData}
            fileId={fileData?.fileId}
            setFileTab={setTabValue}
            setDrawerData={setDrawerData}
            rateAndFeeExecute={rateAndFeeExecute}
          />
        </div>
      )}
    </>
  );
};

export default LeftNav;
