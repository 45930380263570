import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import ReportsList from 'features/Remittance/ReportsList';
import './RemittanceContent.scss';
import { useNavPermissions } from 'hooks/useNavPermissions';

const ReportContent = () => {
  const { hasPermission } = useNavPermissions();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        marginTop: '11.3rem',
      },
      list: {
        fontSize: '16px',
        padding: '0 16px',
      },
      contentWrap: {
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100%)',
      },
      contentClass: {
        margin: '0 auto auto',
      },
    }),
  );
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(1);
  const [reviewCount, setReviewCount] = useState(0);
  const URI_PREFIX = '/accounting/remittance';

  const tabName = selectedTab === 0 ? 'Review' : selectedTab === 1 ? 'Report' : 'Pay';

  const handleTabChange = (navigateToPay: boolean, tab: number) => {
    if (navigateToPay) {
      history.push({
        pathname: `${URI_PREFIX}/pay`,
      });
    } else {
      if (
        (tab === 0 && hasPermission.remit) ||
        (tab === 1 && hasPermission.remit) ||
        (tab === 2 && hasPermission.pay)
      ) {
        setSelectedTab(tab);
      }
    }
  };

  // TODO: FIX THIS!
  // This is really a hack and should be replaced with a wrapper component
  // for each report. That all it does is control the rendering of the base component based on routes.
  useEffect(() => {
    const pathToTabIndex: { [key: string]: number } = {
      [`${URI_PREFIX}/review`]: 0,
      [`${URI_PREFIX}/report`]: 1,
      [`${URI_PREFIX}/pay`]: 2,
    };
    const currentPath = location.pathname;
    const matchedPath = Object.keys(pathToTabIndex).find((path) => currentPath.startsWith(path));
    if (matchedPath !== undefined) {
      setSelectedTab(pathToTabIndex[matchedPath]);
    }
  }, [location.pathname]);

  useEffect(() => {
    // if (hasPermission.remit) {
    //   setSelectedTab(1); //default to Report tab if they have remit permission
    // } else if (hasPermission.pay) {
    //   setSelectedTab(2); //default to Pay tab if they only have pay permission
    // } else {
    //   history.push('/accounting/service-order-invoices/view-invoices');
    // }
  }, [hasPermission, history]);

  return (
    <div className={classes.root}>
      <div className={classes.contentWrap}>
        <ReportsList
          tabSelection={selectedTab}
          tabName={tabName}
          contentClassName={classes.contentClass}
          handleTabChange={handleTabChange}
        />
      </div>
    </div>
  );
};

export default ReportContent;
