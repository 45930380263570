import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import { AgentNetTextInput } from 'ui-kit/inputs';
import AgentNetButton from '../button/AgentNetButton';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';
import { emailSchema } from 'utilities/validation/schemas/email-schema';

interface AgentNetEmailDialogProps {
  onClose: () => void;
  onSend: (value: any) => void;
  isSendingMessage: boolean;
  open: boolean;
  title: string;
}

const useStyles = makeStyles((theme) => ({
  footer: {
    margin: '6.4rem 0 0 0',
    display: 'flex',
    width: 'calc(100% - 64px)',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10.5px 32px',
    borderTop: '1px solid #C4C4C4',
  },
  footerText: {
    textAlign: 'right',
  },
  footerTos: {
    fontSize: '1.4rem',
    '& span': {
      padding: '0 10px',
      color: theme.palette.text.secondary,
      transform: `translateY(1px)`,
      display: 'inline-block',
    },
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  dialogWrap: {
    minWidth: 600,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  dialogClose: {
    padding: 0,
  },
  dialogContent: {
    padding: '2.4rem',
  },
  logoWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    '& svg': {
      width: '231px',
      height: 'auto',
    },
  },
  menuIconBtn: {
    marginLeft: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover, &:visited': {
      color: theme.palette.primary.dark,
    },
  },
}));

const AgentNetEmailDialog = ({ onClose, onSend, isSendingMessage, open, title }: AgentNetEmailDialogProps) => {
  const [emailObj, setEmailObj] = useState<any>({
    emails: '',
    message: '',
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<FieldValidationError[]>([]);
  const classes = useStyles();
  const handleSendEmail = async () => {
    setIsSubmitted(true);
    if (!validationErrors || validationErrors.length === 0) {
      onSend(emailObj);
    }
  };

  useEffect(() => {
    if (!open) {
      handleCloseDialog();
    }
  }, [open]);

  useEffect(() => {
    doValidate(emailObj, emailSchema()).then((errs: FieldValidationError[]) => {
      setValidationErrors(errs);
    });
  }, [emailObj]);

  const handleCloseDialog = () => {
    setIsSubmitted(false);
    setEmailObj({ emails: '', message: '' });
    onClose();
  };
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="contact-us"
      open={open}
      maxWidth={'xs'}
      PaperProps={{ className: classes.dialogWrap }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Typography variant="h3" color="textPrimary">
            {title}
          </Typography>
          <IconButton className={classes.dialogClose} onClick={handleCloseDialog}>
            <Close fontSize="large" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <AgentNetTextInput
          fullWidth
          helperText="To add multiple email addresses, please separate email addresses with a semi-colon."
          name="emails"
          required
          showValidation={isSubmitted}
          label="Send Copy To"
          value={emailObj.emails?.trim() ?? ''}
          id="emails"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmailObj({ ...emailObj, emails: e.target.value.trim() ?? '' });
          }}
          Data-QA="EmailAddress"
          errs={validationErrors}
        />
        <AgentNetTextInput
          multiline
          fullWidth
          variant="outlined"
          rows={4}
          label="Message"
          name="message"
          id="message"
          className={classes.mt3}
          Data-QA={'EmailMessage'}
          value={emailObj.message}
          showValidation={isSubmitted}
          onChange={(event: any) => setEmailObj({ ...emailObj, message: event.target.value })}
          errs={validationErrors}
          counter={true}
          inputProps={{
            maxLength: 250,
          }}
        />
      </DialogContent>
      <DialogActions className="modal-btn-container ghost-white flex-sb p-16-24">
        <AgentNetButton
          variant="outlined"
          onClick={handleCloseDialog}
          data-testid="cancel-action"
          aria-label="cancel-delete"
          data-qa={'EmailCancel'}
        >
          Cancel
        </AgentNetButton>
        <AgentNetButton
          color="primary"
          variant="contained"
          autoFocus
          aria-label="confirm-delete"
          data-qa={'EmailSend'}
          onClick={handleSendEmail}
          loading={isSendingMessage}
          disabled={isSendingMessage}
        >
          Send
        </AgentNetButton>
      </DialogActions>
    </Dialog>
  );
};

export default AgentNetEmailDialog;
