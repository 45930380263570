//  Validation schema for Underwriting
import * as yup from 'yup';
import { underwritingText } from '../../../features/underwriting/constants';

export const REQUIRED_FIELD_MESSAGE_TEXT = 'Required';
export const INVALID_FIELD_MESSAGE_TEXT = 'Invalid';

export const requiredFieldMessage = (fieldName: any): string => {
  return `${fieldName} ${REQUIRED_FIELD_MESSAGE_TEXT}`;
};

// export function invalidFieldMessage({ fieldName }: { fieldName: any }): string {
//   return `${fieldName} ${INVALID_FIELD_MESSAGE_TEXT}`;
// }

// export const maxLengthFieldMessage = (fieldName?: string, maxLength?: number): string =>
//   `${fieldName} Max length is ${maxLength}`;

declare module 'yup' {
  interface StringSchema {
    validateMiscEndo(fieldName: string): StringSchema;
  }
}

yup.addMethod<yup.StringSchema>(yup.string, 'validateMiscEndo', function (fieldName) {
  return this.test({
    test(value) {
      const { parent, createError, path } = this;
      const isMiscEndo = parent.Key === underwritingText.MISC_ENDO_OPTION;

      if (isMiscEndo && !value) {
        return createError({
          message: requiredFieldMessage(fieldName),
          // path: `${path}[0].${fieldName}`,
          path: path,
        });
      }
      return true;
    },
  });
});

// Validation Rules for Risk Name

export const RiskSchema = yup.object().shape({
  Name: yup.string().required(requiredFieldMessage('Risk Name')),
  Summary: yup.string().optional(),
});

export const CplModSchema = (isRequired?: any): any => {
  return yup.object().shape({
    sellerAddress: yup.string(),
    ApprovedAttorney: yup.number().when('sellerAddress', {
      is: () => isRequired?.[0],
      then: yup.number().required(requiredFieldMessage('Approved Attorney')),
    }),
    AgentClosingAttorney: yup.number().when('sellerAddress', {
      is: () => isRequired?.[1],
      then: yup.number().required(requiredFieldMessage('Agent Closing Attorney')),
    }),
    MyClosingAttorney: yup.number().when('sellerAddress', {
      is: () => isRequired?.[2],
      then: yup.number().required(requiredFieldMessage('My Closing Attorney')),
    }),
  });
};

export const AgentNameAddressSchema = yup.object().shape({
  AgentName: yup.string().required(requiredFieldMessage('Agent Name')),
  Address1: yup.string().required(requiredFieldMessage('Address 1')),
  City: yup.string().required(requiredFieldMessage('city')),
  State: yup.string().required(requiredFieldMessage('state')),
  Zip: yup
    .string()
    .matches(/(^$|^\b\d{5}(-\d{4})?\b$)/, 'Invalid zip code, 5 or 9 characters required.')
    .required(requiredFieldMessage('zip')),
});

export const InternationalPartiesSchema = yup.object().shape({
  Name: yup.string().required(requiredFieldMessage('name')),
  Address: yup.string().required(requiredFieldMessage('address')),
});

export const SecondPartySchema = yup.object().shape({
  SecondPartyId: yup.number().required(requiredFieldMessage('Second Party')),
});

export const AdditionalAddressSchema = yup.object().shape({
  Address: yup.string().required(requiredFieldMessage('address')),
});

export const VerbiageEditedRemovedSchema = yup.object().shape({
  Summary: yup.string().required(requiredFieldMessage('Summary Summary of Risk')),
});

export const RequestInformationSchema = yup.object().shape({
  RequestName: yup.string().required(requiredFieldMessage('Request Name')),
  EstimatedClosingDate: yup
    .mixed()
    .required(requiredFieldMessage('Estimated Closing Date'))
    .test('is-valid-date', 'Invalid Estimated Closing Date', function (value) {
      if (!value) {
        return true; // Allow blank values
      }
      return yup.date().min(new Date('0500-01-01')).max(new Date('2100-01-01')).isValidSync(value);
    }),

  MaximumLiabilityAmount: yup
    .number()
    .test('more-than-equal', requiredFieldMessage('Maximum Liability Amount'), (value: any) => value > 0),

  TransactionType: yup.string().optional().nullable(true),
  TransactionTypeDescription: yup.string().test('TransactionTypeDescription Validation', '', function (value) {
    if (!value && this.parent.TransactionType === 'Other Financing') {
      return this.createError({
        message: requiredFieldMessage('TransactionType Description'),
        path: 'TransactionTypeDescription',
      });
    } else return true;
  }),
});

// Validation Rules for Proposed Policy

export const ProposedPolicySchema = yup.object().shape({
  PolicyType: yup.string().required(requiredFieldMessage('Policy Type')),
  PolicyTypeDescription: yup.string().test('DescribePolicyType Validation', '', function (value) {
    if (!value && this.parent.PolicyType === 'Other') {
      return this.createError({
        message: requiredFieldMessage('Describe Policy Type'),
        path: 'DescribePolicyType',
      });
    } else return true;
  }),

  EstateInterestDescription: yup.string().test('DescribeEstateInterest Validation', '', function (value) {
    if (!value && this.parent.IsOtherEstateInterest) {
      return this.createError({
        message: requiredFieldMessage('Describe Estate/Interest'),
        path: 'DescribeEstateInterest',
      });
    } else return true;
  }),

  Endorsements: yup
    .array()
    .of(
      yup.object().shape({
        FormNumber: yup.string().optional(),
        ModifiedFormNumber: yup.string().validateMiscEndo('Modified Form Number'),
        Description: yup.string().validateMiscEndo('Description'),
      }),
    )
    .optional(),
});
