import { handleError } from 'utilities/utilities';
import { getApiHost } from '../host-by-env';
import { ClosingAttorneyDTO, LenderDTO, LenderResponseDTO } from './types';

export type getAddressBookProps = {
  firmId?: string;
};

export async function addressBookGetLenders(token: string, firmId?: string): Promise<LenderResponseDTO[]> {
  const url = `${getApiHost()}firms/${firmId}/lenders`;
  //const url = 'https://localhost:7053/firm-service/v1/firms/3188810/lender-address-book';
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function addressBookGetClosingAttorneys(token: string, firmId?: string): Promise<ClosingAttorneyDTO[]> {
  const url = `${getApiHost()}firms/${firmId}/closing-attorney`;
  //const url = 'https://localhost:7053/firm-service/v1/firms/3188810/closingAttorney-address-book';
  const response = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });
  const json = await response.json();
  handleError(json);
  return json.result;
}

export async function addressBookAddLender(payload: LenderDTO, firmId: string, token: string): Promise<boolean> {
  try {
    const registerUrl = `${getApiHost()}firms/${firmId}/lenders`;
    //const registerUrl = 'https://localhost:7053/firm-service/v1/firms/admin/3188810/add-lender';
    const response = await fetch(registerUrl, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in addressBook', error);
    return false;
  }
}

export async function addressBookUpdateLender(payload: LenderDTO, firmId: string, token: string): Promise<boolean> {
  try {
    const registerUrl = `${getApiHost()}firms/${firmId}/lenders`;
    //const registerUrl = 'https://localhost:7053/firm-service/v1/firms/admin/3188810/update-lender';

    const response = await fetch(registerUrl, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in addressBook', error);
    return false;
  }
}

export async function addressBookDeleteLender(payload: LenderDTO, firmId: string, token: string): Promise<boolean> {
  try {
    const registerUrl = `${getApiHost()}firms/${firmId}/lenders/${payload.AddressBookEntryId}`;
    //const registerUrl = 'https://localhost:7053/firm-service/v1/firms/admin/delete-lender';
    const response = await fetch(registerUrl, {
      method: 'DELETE',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in addressBook', error);
    return false;
  }
}

export async function addressBookAddClosingAttorney(
  payload: ClosingAttorneyDTO,
  firmId: string,
  token: string,
): Promise<boolean> {
  try {
    const registerUrl = `${getApiHost()}firms/${firmId}/closing-attorney`;
    //const registerUrl = 'https://localhost:7053/firm-service/v1/firms/admin/3188810/add-closing-attorney';
    const response = await fetch(registerUrl, {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in addressBook', error);
    return false;
  }
}

export async function addressBookUpdateClosingAttorney(
  payload: ClosingAttorneyDTO,
  firmId: string,
  token: string,
): Promise<boolean> {
  try {
    const registerUrl = `${getApiHost()}firms/${firmId}/closing-attorney`;
    //const registerUrl = 'https://localhost:7053/firm-service/v1/firms/admin/3188810/update-closing-attorney';

    const response = await fetch(registerUrl, {
      method: 'PUT',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in addressBook', error);
    return false;
  }
}

export async function addressBookDeleteClosingAttorney(
  payload: ClosingAttorneyDTO,
  firmId: string,
  token: string,
): Promise<boolean> {
  try {
    const registerUrl = `${getApiHost()}firms/${firmId}/closing-attorney/${payload.LicenseState}/${
      payload.ClosingAttorneyId
    }`;
    //const registerUrl = 'https://localhost:7053/firm-service/v1/firms/admin/delete-closing-attorney';
    const response = await fetch(registerUrl, {
      method: 'DELETE',
      body: JSON.stringify(payload),
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    });
    const json = await response.json();
    handleError(json);
    return json.result;
  } catch (error) {
    console.error('Error in addressBook', error);
    return false;
  }
}
