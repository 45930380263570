import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { CurrencyField } from 'ui-kit/inputs';
import { CPLPolicySchema } from 'utilities/validation/schemas/jacket-schema';
import { doValidate, FieldValidationError } from 'utilities/validation/validation';

interface LetterItem {
  id?: string;
  coveredParty: string;
  letterType: string;
  effectiveDate: string;
  fields: any[];
}

interface CPLCalcTable {
  letters?: Array<LetterItem>;
  propertyState?: string;
  updateCalcCriteriaData?: any;
  setCplValidationError?: any;
  cplValidationError?: any;
  shouldShowValidation?: boolean;
  disableFields?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentsTable: {
      width: '100%',
      padding: '0 0 24px',
    },
    documentsTableHeader: {
      backgroundColor: '#F2F5F6',
      borderBottom: '1px solid #D9E0E5',
      fontWeight: 600,
      fontSize: '1.4rem',
    },
    tableHeaderCell: {
      padding: '1.2rem',
    },
    required: {
      color: 'red',
    },
    documentsTableList: {},
    tableRow: {
      borderBottom: `1px solid #E0E0E0`,
    },
    tableCell: {
      padding: `1.2rem`,
      display: 'flex',
      alignItems: 'center',
    },
    my1: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    mb1: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const CPLCalcTable = ({
  letters,
  propertyState,
  disableFields,
  updateCalcCriteriaData,
  cplValidationError,
  setCplValidationError,
  shouldShowValidation,
}: CPLCalcTable) => {
  const classes = useStyles();

  const [items, setItems] = useState<LetterItem[]>([]);

  const validateErrors = async (data: any, index: number) => {
    const errs: FieldValidationError[] = (await doValidate(data, CPLPolicySchema)) || [];
    setCplValidationError((prev: any) => {
      const item = [...prev];
      item[index] = errs;
      return item;
    });
  };

  useEffect(() => {
    if (letters && letters.length > 0) {
      setItems(letters);
      letters.forEach((cpl: any, index: number) => {
        validateErrors(cpl, index);
      });
    }
  }, [letters]);

  const showLiablityAmount = React.useMemo(() => propertyState === 'NC', [propertyState]);
  return (
    <>
      {items && items.length > 0 && (
        <>
          <div className={clsx(classes.documentsTable, 'documentsTableWrapper')}>
            <Grid container className={classes.documentsTableHeader}>
              <Grid item sm={showLiablityAmount ? 3 : 4} className={classes.tableHeaderCell}>
                Covered Party
              </Grid>
              <Grid item sm={showLiablityAmount ? 3 : 4} className={classes.tableHeaderCell}>
                Letter Type
              </Grid>
              <Grid item sm={showLiablityAmount ? 3 : 4} className={classes.tableHeaderCell}>
                Effective Date
              </Grid>
              {showLiablityAmount && (
                <Grid item sm={3} className={classes.tableHeaderCell}>
                  <span className={classes.required}>*</span> Liability Amount
                </Grid>
              )}
            </Grid>
            <Grid container className={classes.documentsTableList}>
              {items.map((e: any, i) => (
                <Grid container key={i} className={clsx(classes.tableRow)}>
                  <Grid item sm={showLiablityAmount ? 3 : 4} className={clsx(classes.tableCell)}>
                    <Typography variant="body2">{e.coveredParty}</Typography>
                  </Grid>
                  <Grid item sm={showLiablityAmount ? 3 : 4} className={clsx(classes.tableCell)}>
                    <Typography variant="body2">{e.letterType}</Typography>
                  </Grid>
                  <Grid item sm={showLiablityAmount ? 3 : 4} className={clsx(classes.tableCell)}>
                    <Typography variant="body2">
                      {e.effectiveDate ? format(new Date(e.effectiveDate), 'MM/dd/yyyy') : ''}
                    </Typography>
                  </Grid>
                  {showLiablityAmount && e?.fields?.length > 0 && (
                    <Grid item sm={3} className={clsx(classes.tableCell)}>
                      <CurrencyField
                        variant="outlined"
                        fullWidth
                        name={e?.fields[0].name}
                        id={e?.fields[0].name}
                        Data-QA={'CalcCriteria' + e?.fields[0].name}
                        value={e?.fields[0].value ?? ''}
                        max={999999999}
                        allowedBlank
                        allowNegative={false}
                        min={0}
                        onChange={(event) => {
                          updateCalcCriteriaData(
                            'updateCPLSettings',
                            e?.fields[0].name,
                            event.target.value === '' ? '' : (event.target.value as unknown as number).toFixed(2),
                            i,
                          );
                        }}
                        disabled={disableFields || e?.fields[0].isDisabled}
                        required={e?.fields[0].isRequired}
                        error={
                          shouldShowValidation &&
                          (cplValidationError?.[i] ?? []).some((cplErr: any) => cplErr.field === e?.fields[0].name)
                        }
                        errs={cplValidationError?.[i] ?? []}
                        alwaysDisplayError={shouldShowValidation}
                        showValidationOnBlur={shouldShowValidation}
                      />
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default CPLCalcTable;
